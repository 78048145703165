import { Button, Container, Grid, Typography } from '@material-ui/core';
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Check } from '@material-ui/icons';
import axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Toggle from 'react-toggle';
import Modal from 'react-awesome-modal';
import modelImg1 from '../Assets/Images/modelImg1.png';
import modelImg2 from '../Assets/Images/modelImg2.png';
import modelImg3 from '../Assets/Images/modelImg3.png';
import Blogs from '../Components/Blogs';
import Hero from '../Components/Hero';
import ModelCard from '../Components/ModelCard';
import { API } from '../Constants/APIs';
import right from '../Assets/Images/Right.png';
import wrong from '../Assets/Images/Wrong.png';
import cross from '../Assets/Images/cross.png';
import workflow from '../Assets/Images/workflow.png';
import modeling from '../Assets/Images/modeling.png';
import code from '../Assets/Images/code.png';
import init from '../Helpers/Windotoken';
import UserAuth from '../Helpers/UserAuth';
import { authToken, isAuth } from '../Helpers/Authhelper';
const percentage1 = 60;
const percentage2 = 50;
const percentage3 = 94;
export default class Landing extends Component {
  state = {
    // services: [],
    models: [
      {
        title: 'Retro Television from Herman Miller',
        img_src: modelImg1,
      },
      {
        title: 'Kitchen Furniture by ',
        img_src: modelImg2,
      },
      {
        title: 'Gold Lamp from Herman Miller',
        img_src: modelImg3,
      },

      {
        title: 'Kitchen Furniture by ',
        img_src: modelImg2,
      },
      {
        title: 'Retro Television from Herman Miller',
        img_src: modelImg1,
      },
      {
        title: 'Gold Lamp from Herman Miller',
        img_src: modelImg3,
      },
      {
        title: 'Gold Lamp from Herman Miller',
        img_src: modelImg3,
      },

      {
        title: 'Kitchen Furniture by ',
        img_src: modelImg2,
      },
      {
        title: 'Retro Television from Herman Miller',
        img_src: modelImg1,
      },
    ],
    services: [
      {
        image: workflow,
        title: 'Smooth Workflow',
        description:
          'Easy coordination between multiple team members in the marketing and sales team. Dashboard to get an overview of product status and performance',
      },
      {
        image: modeling,
        title: 'Product Modelling',
        description:
          'Don’t have a digital 3D model yet? We can craft your products in 3D from photographs and specs sheet.',
      },
      {
        image: code,
        title: 'AR with a single line of code',
        description:
          'Insert AR functionality into your website, social media with a single line of code, or use a QR code anywhere, transforming your website experience.',
      },
    ],
    VAS: [
      {
        title: 'VR',
      },
      {
        title: 'Virtual Photography',
      },
      {
        title: 'Photorealistic Renders',
      },
    ],
    plans: [
      {
        title: 'Beginner Plan',
        no_of_models: 10,
        is_first_model_free: true,
        ar_360_view_fee: '$300',
        platform_fee: '$1200',
      },
      {
        title: 'Growth Plan',
        no_of_models: 30,
        is_first_model_free: true,
        ar_360_view_fee: '$300',
        platform_fee: '$1200',
      },
      {
        title: 'Enterprise Plan',
        no_of_models: 50,
        is_first_model_free: true,
        ar_360_view_fee: '$300',
        platform_fee: '$1200',
      },
    ],
    isImgLoaded: false,
    itemId: '',
    itemPrice: '',
    loadingBeg: false,
    loadingGrow: false,
    loadingEnte: false,
    alreadypurchased: false,
    toggleStatus: false,
    userEmail: '',
    userName: '',
    userToken: '',
    purchasedPlanPrice: '',
  };

  componentDidMount() {
    this.fetchModels();
    this.setUpImageloader();

    axios.get(API.getUrls.getPlans).then((resp) => {
      this.setState({
        purchasedPlanPrice: resp.data.orders.docs.at(-1).order_summary.content
          .subscription.subscription_items
          ? resp.data.orders.docs.at(-1).order_summary.content.subscription
              .subscription_items[0].amount
          : '',
      });
    });
  }

  setUpImageloader = () => {
    window.addEventListener('load', (event) => {
      var image = document.querySelector('img');
      this.setState({
        isImgLoaded: image.complete && image.naturalHeight !== 0,
      });
    });
  };

  fetchModels = () => {
    axios.get('/SingleModel.json').then((res) => {
      this.setState({ models: res.data.models });
    });
  };

  payment = () => {
    let authToken = localStorage.getItem('token');

    axios
      .get(API.getUrls.getPlans, {
        headers: { Authorization: 'Bearer ' + authToken },
      })
      .then((resp) => {
        const payload = {
          item_id: this.state.itemId,
          item_price: this.state.itemPrice * 100,
        };
        axios
          .post(API.postUrls.chargebeePayment, payload, {
            headers: { Authorization: 'Bearer ' + authToken },
          })
          .then((resp) => {
            if (resp.data.status === 'success') {
              window.open(resp.data.result.hosted_page.url, '_blank').focus();
              this.setState({
                loadingBeg: false,
                loadingGrow: false,
                loadingEnte: false,
              });
            } else {
              window.location.href = '/account/login';
              this.setState({
                loadingBeg: false,
                loadingGrow: false,
                loadingEnte: false,
              });
            }
          });
      });
  };

  handleToggleChange = () => {
    if (this.state.toggleStatus === true) {
      this.setState({
        toggleStatus: false,
      });
    } else {
      this.setState({
        toggleStatus: true,
      });
    }

    // console.log(this.state.toggleStatus);
  };

  getInfo = (userData) => {
    // console.log("hiiii");
    // console.log(this.props.isAuthenticated);
    this.setState(
      {
        userEmail: userData.email,
        userName: userData.name,
        userToken: userData.accessToken,
      },
      () => {
        // console.log(this.state.userEmail);
      }
    );
  };

  render() {
    return (
      <div>
        {/* {
          this.props.isAuthenticated ? "" : <UserAuth getInfo={this.getInfo}/>
        } */}
        <Hero />
        {/* <Banner /> */}
        <Container>
          <div
            // maxWidth={"lg"}
            style={{
              paddingBottom: '64px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingLeft: '10px',
              paddingRight: '10px',
            }}
          >
            <iframe
              src='/modelviewer/NQ=='
              title='some'
              frameborder='0'
              style={{
                minHeight: '728px',
                width: '95vw',
                alignSelf: 'center',
              }}
            ></iframe>
          </div>
        </Container>

        {/* Progress Bar */}
        <section className='sectionInnerSpacingBrowse'>
          <Typography
            align={'center'}
            variant={'h5'}
            className={'bolder verticalSpacing browseHead'}
          >
            Better product visuals mean better results
          </Typography>
          <Container className='containerSpacing' maxWidth={'lg'}>
            <Grid container spacing={3}>
              <Grid item sm={4} style={{ textAlign: 'center' }}>
                <CircularProgressbar
                  value={percentage1}
                  text={`${percentage1}%`}
                  style={{ width: '200px', height: '200px' }}
                  strokeWidth={12}
                  styles={buildStyles({
                    strokeLinecap: 'butt',
                    textColor: '#25262B',
                    pathColor: '#25262B',
                    trailColor: '##BDC4C7',
                  })}
                />
                <p className='progressPara'>
                  People say they want to be able to visualize where and how a
                  product could fit into their lives
                </p>
              </Grid>
              <Grid item sm={4} style={{ textAlign: 'center' }}>
                <CircularProgressbar
                  value={percentage2}
                  text={`${percentage2}%`}
                  style={{ width: '200px', height: '200px' }}
                  strokeWidth={12}
                  styles={buildStyles({
                    strokeLinecap: 'butt',
                    textColor: '#25262B',
                    pathColor: '#25262B',
                    trailColor: '##BDC4C7',
                  })}
                />
                <p className='progressPara'>
                  People say they want to be able to visualize where and how a
                  product could fit into their lives
                </p>
              </Grid>
              <Grid item sm={4} style={{ textAlign: 'center' }}>
                <CircularProgressbar
                  value={percentage3}
                  text={`${percentage3}%`}
                  style={{ width: '200px', height: '200px' }}
                  strokeWidth={12}
                  styles={buildStyles({
                    strokeLinecap: 'butt',
                    textColor: '#25262B',
                    pathColor: '#25262B',
                    trailColor: '##BDC4C7',
                  })}
                />
                <p className='progressPara'>
                  People say they want to be able to visualize where and how a
                  product could fit into their lives
                </p>
              </Grid>
            </Grid>
          </Container>
        </section>

        <section className='sectionInnerSpacingBrowse'>
          <Typography
            align={'center'}
            variant={'h5'}
            className={'bolder verticalSpacing browseHead'}
          >
            Browse some of our models in your space
          </Typography>
          <Container className='containerSpacing' maxWidth={'lg'}>
            <Grid container spacing={3}>
              {this.state.models.map((ele, index) =>
                ele.is_visible_in_landing ? (
                  <Grid item sm={4} key={index}>
                    <ModelCard
                      imgSrc={ele.poster_path ? ele.poster_path : modelImg1}
                      id={ele.id}
                      title={ele.title}
                    />
                  </Grid>
                ) : null
              )}
            </Grid>
          </Container>
          <Container style={{ textAlign: 'center' }}>
            <a
              href='/'
              style={{
                textDecoration: 'none',
                color: '#666666',
                cursor: 'pointer',
              }}
            >
              <Button
                variant='contained'
                className='serviceBtnLanding'
                style={{
                  backgroundColor: '#bdbdbd',
                  borderRadius: '8px',
                  marginTop: '80px',
                }}
              >
                <Typography
                  component='body'
                  className={'bold'}
                  style={{
                    color: '#fff',
                    fontSize: '28px',
                    fontWeight: 'bold',
                    textTransform: 'none',
                  }}
                >
                  See More
                </Typography>
              </Button>
            </a>
          </Container>
        </section>
        <section className='sectionInnerSpacingAr'>
          <Container maxWidth={'lg'} className='containerSpacing'>
            <Typography className='serviceHead' gutterBottom>
              AR can make your sales process a breeze
            </Typography>
            <Typography className='serviceSubHead' gutterBottom>
              We enable your team to express the best features of your products
              through augmented reality, in a way that your customers can
              interact with your product range in more engaging ways.
            </Typography>
            <Grid container spacing={2}>
              {this.state.services.map((service, i) => (
                <Grid
                  className={'serviceWrapper'}
                  item
                  sm={4}
                  spacing={3}
                  key={i}
                >
                  <div>
                    <img
                      src={service.image}
                      alt=''
                      width='20%'
                      style={{ paddingBottom: '20px' }}
                    />
                    <Typography className='serviceTitle'>
                      {service.title}
                    </Typography>
                    <Typography className='serviceDescription'>
                      {service.description}
                    </Typography>
                  </div>
                </Grid>
              ))}
            </Grid>
            <a
              href='https://calendly.com/rishabh_gupta/30min'
              target='_blank'
              style={{
                textDecoration: 'none',
                color: '#666666',
                cursor: 'pointer',
              }}
            >
              <Button
                variant='contained'
                className='serviceBtnLanding'
                style={{
                  backgroundColor: '#bdbdbd',
                  borderRadius: '8px',
                  marginTop: '0px',
                  width: '24%',
                }}
              >
                <Typography
                  component='body'
                  className={'bold'}
                  style={{
                    color: '#fff',
                    fontSize: '28px',
                    fontWeight: 'bold',
                    textTransform: 'none',
                  }}
                >
                  Book Demo
                </Typography>
              </Button>
            </a>
          </Container>
        </section>
        <section
          className='sectionInnerSpacingPricing'
          style={{ marginTop: '-50px' }}
        >
          <Container>
            <section>
              <div>
                <div>
                  <h1 className='plans'>Plans And Pricing</h1>
                  <p className='subTitlePlans'>
                    30-day free trial, cancel any time.
                  </p>
                  <span
                    className={
                      window.innerWidth < 1100 ? 'toggleArea1100' : 'toggleArea'
                    }
                  >
                    <span className='annualPlan'>Monthly Plans</span>
                    <span style={{ position: 'relative', top: '6px' }}>
                      <Toggle
                        checked={this.state.toggleStatus ? true : false}
                        onChange={(e) => this.handleToggleChange(e)}
                        value={this.state.toggleStatus}
                        icons={false}
                      />
                    </span>
                    <span className='monthlyPlan'>Annual Plans</span>
                  </span>
                </div>
              </div>
            </section>

            <section
              className='sectionInnerSpacingAbout2'
              style={{
                marginLeft: window.innerWidth < 1100 ? '-18px' : '0',
                marginTop: '40px',
              }}
            >
              <Grid container spacing={4}>
                <Grid item sm={3}>
                  <div
                    className={
                      window.innerWidth < 1100
                        ? 'pricingCard1100'
                        : 'pricingCard'
                    }
                  >
                    <div className='imgWrapper'>
                      <h1 className='beginner'>Beginner</h1>
                      <h1 className='price'>
                        ${this.state.toggleStatus ? 249 : 299}
                      </h1>
                      <div className='month'>/month</div>
                      <p className='modelTitle'>
                        All the basics for businesses that are just getting
                        started.
                      </p>
                      {/* <div className="buyNow"><span className="buyBtn">Buy Now</span></div> */}
                      {/* <a
                        href="javascript:void(0)"
                        data-cb-type="checkout"
                        data-cb-item-0="cbdemo_advanced-USD-monthly"
                        data-cb-item-0-quantity="1"
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          size={"large"}
                          variant="contained"
                          className="themeBtn txtShadow roundedPlansPricing buyNow"
                        >
                          Buy Now
                        </Button>
                      </a> */}
                      <Button
                        size={'large'}
                        variant='contained'
                        className='themeBtn txtShadow roundedPlansPricing buyNow'
                        onClick={() => {
                          this.setState(
                            {
                              itemId: 'Beginner-USD-Monthly',
                              itemPrice: this.state.toggleStatus
                                ? 249 * 12
                                : 299,
                              loadingBeg: true,
                            },
                            () => {
                              this.payment();
                            }
                          );
                        }}
                      >
                        {this.state.loadingBeg
                          ? 'Loading...'
                          : this.state.purchasedPlanPrice === 299 ||
                            this.state.purchasedPlanPrice === 249 * 12
                          ? 'Buy Now'
                          : 'Upgrade'}
                      </Button>

                      <div className='bottomBorder'></div>

                      <div className='vectorArea'>
                        <div className='paddingTop'>
                          <span>
                            <img
                              src={right}
                              alt=''
                              width='12px'
                              height='12px'
                            />
                            <span className='hostedModels'>
                              20 Hosted Models
                            </span>
                          </span>
                        </div>
                        <div className='paddingTop'>
                          <span>
                            <img
                              src={right}
                              alt=''
                              width='12px'
                              height='12px'
                            />
                            <span className='hostedModels'>
                              20,000 Monthly Website Views
                            </span>
                          </span>
                        </div>
                        <div className='paddingTop'>
                          <span>
                            <img
                              src={right}
                              alt=''
                              width='12px'
                              height='12px'
                            />
                            <span className='hostedModels'>
                              First Model Optimization Free
                            </span>
                          </span>
                        </div>
                        <div className='paddingTop'>
                          <span>
                            <img
                              src={right}
                              alt=''
                              width='12px'
                              height='12px'
                            />
                            <span className='hostedModels'>
                              Installation Support
                            </span>
                          </span>
                        </div>
                        <div className='paddingTop'>
                          <span>
                            <img
                              src={right}
                              alt=''
                              width='12px'
                              height='12px'
                            />
                            <span className='hostedModels'>Analytics</span>
                          </span>
                        </div>
                        <div className='paddingTop'>
                          <span>
                            <img
                              src={cross}
                              alt=''
                              width='5px'
                              height='5px'
                              className='crossImg'
                            />
                            <img
                              src={wrong}
                              alt=''
                              width='12px'
                              height='12px'
                              style={{ position: 'relative' }}
                            />
                            <span className='hostedModels'>
                              Dedicated Account Manager
                            </span>
                          </span>
                        </div>
                        <div className='paddingTop'>
                          <span>
                            <img
                              src={cross}
                              alt=''
                              width='5px'
                              height='5px'
                              className='crossImg2'
                            />
                            <img
                              src={wrong}
                              alt=''
                              width='12px'
                              height='12px'
                              style={{ position: 'relative' }}
                            />
                            <span className='hostedModels'>
                              Animated Models
                            </span>
                          </span>
                        </div>
                        <div className='paddingTop'>
                          <span>
                            <img
                              src={cross}
                              alt=''
                              width='5px'
                              height='5px'
                              className='crossImg3'
                            />
                            <img
                              src={wrong}
                              alt=''
                              width='12px'
                              height='12px'
                              style={{ position: 'relative' }}
                            />
                            <span className='hostedModels'>White Labeled</span>
                          </span>
                        </div>
                      </div>
                      <div className='bottomBorder2'></div>
                      <div className='vectorArea2'>
                        <div className='paddingTop2'>
                          <div className='hostedModels'>
                            AR Model Optamization starts from
                          </div>
                          <span className='varient'>$150 + $20/Varient</span>
                        </div>
                        <div className='paddingTop2'>
                          <span className='hostedModels'>VR starts from </span>
                          <span className='varient2'>$300</span>
                        </div>
                        <div className='paddingTop2'>
                          <span className='gamefied'>
                            Gamefied Walkthrough starts <br />{' '}
                            <span style={{ paddingLeft: '10px' }}>
                              from <span className='varient2'>$4000</span>{' '}
                            </span>
                          </span>
                        </div>
                        <div className='paddingTop2'>
                          <span className='gamefied'>
                            Photo Realistic Renderings starts <br />{' '}
                            <span style={{ paddingLeft: '10px' }}>
                              from <span className='varient2'>$150</span>{' '}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>

                <Grid item sm={3}>
                  <div
                    className={
                      window.innerWidth < 1100
                        ? 'pricingCard1100'
                        : 'pricingCard'
                    }
                  >
                    <div className='imgWrapper'>
                      <h1 className='beginner'>Growth</h1>
                      <h1 className='price'>
                        ${this.state.toggleStatus ? 599 : 699}
                      </h1>
                      <div className='month'>/month</div>
                      <p className='modelTitle'>
                        All the basics for businesses that are just getting
                        started.
                      </p>
                      {/* <div className="buyNow2"><span className="buyBtn">Buy Now</span></div> */}
                      {/* <a
                        href="javascript:void(0)"
                        data-cb-type="checkout"
                        data-cb-item-0="cbdemo_advanced-USD-monthly"
                        data-cb-item-0-quantity="1"
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          size={"large"}
                          variant="contained"
                          className="themeBtn txtShadow roundedPlansPricing buyNow"
                        >
                          Buy Now
                        </Button>
                      </a> */}

                      <Button
                        size={'large'}
                        variant='contained'
                        className='themeBtn txtShadow roundedPlansPricing buyNow'
                        onClick={() => {
                          this.setState(
                            {
                              itemId: 'Growth-USD-Monthly',
                              itemPrice: this.state.toggleStatus
                                ? 599 * 12
                                : 699,
                              loadingGrow: true,
                            },
                            () => {
                              this.payment();
                            }
                          );
                        }}
                      >
                        {this.state.loadingGrow
                          ? 'Loading...'
                          : this.state.purchasedPlanPrice === 699 ||
                            this.state.purchasedPlanPrice === 599 * 12
                          ? 'Buy Now'
                          : 'Upgrade'}
                      </Button>

                      <div className='bottomBorder'></div>

                      <div className='vectorArea'>
                        <div className='paddingTop'>
                          <span>
                            <img
                              src={right}
                              alt=''
                              width='12px'
                              height='12px'
                            />
                            <span className='hostedModels'>
                              50 Hosted Models
                            </span>
                          </span>
                        </div>
                        <div className='paddingTop'>
                          <span>
                            <img
                              src={right}
                              alt=''
                              width='12px'
                              height='12px'
                            />
                            <span className='hostedModels'>
                              80,000 Monthly Website Views
                            </span>
                          </span>
                        </div>
                        <div className='paddingTop'>
                          <span>
                            <img
                              src={right}
                              alt=''
                              width='12px'
                              height='12px'
                            />
                            <span className='hostedModels'>
                              First Model Optimization Free
                            </span>
                          </span>
                        </div>
                        <div className='paddingTop'>
                          <span>
                            <img
                              src={right}
                              alt=''
                              width='12px'
                              height='12px'
                            />
                            <span className='hostedModels'>
                              Installation Support
                            </span>
                          </span>
                        </div>
                        <div className='paddingTop'>
                          <span>
                            <img
                              src={right}
                              alt=''
                              width='12px'
                              height='12px'
                            />
                            <span className='hostedModels'>Analytics</span>
                          </span>
                        </div>
                        <div className='paddingTop'>
                          <span>
                            <img
                              src={right}
                              alt=''
                              width='12px'
                              height='12px'
                            />
                            <span className='hostedModels'>
                              Animated Models
                            </span>
                          </span>
                        </div>
                        <div className='paddingTop'>
                          <span>
                            <img
                              src={cross}
                              alt=''
                              width='5px'
                              height='5px'
                              className='crossImg2'
                            />
                            <img
                              src={wrong}
                              alt=''
                              width='12px'
                              height='12px'
                              style={{ position: 'relative' }}
                            />
                            <span className='hostedModels'>White Labeled</span>
                          </span>
                        </div>
                        <div className='paddingTop'>
                          <span>
                            <img
                              src={cross}
                              alt=''
                              width='5px'
                              height='5px'
                              className='crossImg3'
                            />
                            <img
                              src={wrong}
                              alt=''
                              width='12px'
                              height='12px'
                              style={{ position: 'relative' }}
                            />
                            <span className='hostedModels'>
                              Dedicated Account Manager
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className='bottomBorder2'></div>
                      <div className='vectorArea2'>
                        <div className='paddingTop2'>
                          <div className='hostedModels'>
                            AR Model Optamization starts from
                          </div>
                          <span className='varient'>$125 + $20/Varient</span>
                        </div>
                        <div className='paddingTop2'>
                          <span className='hostedModels'>VR starts from </span>
                          <span className='varient2'>$250</span>
                        </div>
                        <div className='paddingTop2'>
                          <span className='gamefied'>
                            Gamefied Walkthrough starts <br />{' '}
                            <span style={{ paddingLeft: '10px' }}>
                              from <span className='varient2'>$3500</span>{' '}
                            </span>
                          </span>
                        </div>
                        <div className='paddingTop2'>
                          <span className='gamefied'>
                            Photo Realistic Renderings starts <br />{' '}
                            <span style={{ paddingLeft: '10px' }}>
                              from <span className='varient2'>$100</span>{' '}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>

                <Grid item sm={3}>
                  <div
                    className={
                      window.innerWidth < 1100
                        ? 'pricingCard1100'
                        : 'pricingCard'
                    }
                  >
                    <div className='imgWrapper'>
                      <h1 className='beginner'>Enterprise</h1>
                      <h1 className='price'>
                        ${this.state.toggleStatus ? 899 : 999}
                      </h1>
                      <div className='month'>/month</div>
                      <p className='modelTitle'>
                        All the basics for businesses that are just getting
                        started.
                      </p>
                      {/* <div className="buyNow"><span className="buyBtn">Buy Now</span></div> */}
                      {/* <a
                        href="javascript:void(0)"
                        data-cb-type="checkout"
                        data-cb-item-0="cbdemo_advanced-USD-monthly"
                        data-cb-item-0-quantity="1"
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          size={"large"}
                          variant="contained"
                          className="themeBtn txtShadow roundedPlansPricing buyNow"
                        >
                          Buy Now
                        </Button>
                      </a> */}

                      <Button
                        size={'large'}
                        variant='contained'
                        className='themeBtn txtShadow roundedPlansPricing buyNow'
                        onClick={() => {
                          this.setState(
                            {
                              itemId: 'Enterprise-USD-Monthly',
                              itemPrice: this.state.toggleStatus
                                ? 899 * 12
                                : 999,
                              loadingEnte: true,
                            },
                            () => {
                              this.payment();
                            }
                          );
                        }}
                      >
                        {this.state.loadingEnte
                          ? 'Loading...'
                          : this.state.purchasedPlanPrice === 999 ||
                            this.state.purchasedPlanPrice === 899 * 12
                          ? 'Buy Now'
                          : 'Upgrade'}
                      </Button>

                      <div className='bottomBorder'></div>

                      <div className='vectorArea'>
                        <div className='paddingTop'>
                          <span>
                            <img
                              src={right}
                              alt=''
                              width='12px'
                              height='12px'
                            />
                            <span className='hostedModels'>
                              100 Hosted Models
                            </span>
                          </span>
                        </div>
                        <div className='paddingTop'>
                          <span>
                            <img
                              src={right}
                              alt=''
                              width='12px'
                              height='12px'
                            />
                            <span className='hostedModels'>
                              150,000 Monthly Website Views
                            </span>
                          </span>
                        </div>
                        <div className='paddingTop'>
                          <span>
                            <img
                              src={right}
                              alt=''
                              width='12px'
                              height='12px'
                            />
                            <span className='hostedModels'>
                              First Model Optimization Free
                            </span>
                          </span>
                        </div>
                        <div className='paddingTop'>
                          <span>
                            <img
                              src={right}
                              alt=''
                              width='12px'
                              height='12px'
                            />
                            <span className='hostedModels'>
                              Installation Support
                            </span>
                          </span>
                        </div>
                        <div className='paddingTop'>
                          <span>
                            <img
                              src={right}
                              alt=''
                              width='12px'
                              height='12px'
                            />
                            <span className='hostedModels'>Analytics</span>
                          </span>
                        </div>
                        <div className='paddingTop'>
                          <span>
                            <img
                              src={right}
                              alt=''
                              width='12px'
                              height='12px'
                            />
                            <span className='hostedModels'>
                              Dedicated Account Manager
                            </span>
                          </span>
                        </div>
                        <div className='paddingTop'>
                          <span>
                            <img
                              src={right}
                              alt=''
                              width='12px'
                              height='12px'
                            />
                            <span className='hostedModels'>
                              Animated Models
                            </span>
                          </span>
                        </div>
                        <div className='paddingTop'>
                          <span>
                            <img
                              src={right}
                              alt=''
                              width='12px'
                              height='12px'
                            />
                            <span className='hostedModels'>White Labeled</span>
                          </span>
                        </div>
                      </div>
                      <div className='bottomBorder2'></div>
                      <div className='vectorArea2'>
                        <div className='paddingTop2'>
                          <div className='hostedModels'>
                            AR Model Optamization starts from
                          </div>
                          <span className='varient'>$100 + $20/Varient</span>
                        </div>
                        <div className='paddingTop2'>
                          <span className='hostedModels'>VR starts from </span>
                          <span className='varient2'>$200</span>
                        </div>
                        <div className='paddingTop2'>
                          <span className='gamefied'>
                            Gamefied Walkthrough starts <br />{' '}
                            <span style={{ paddingLeft: '10px' }}>
                              from <span className='varient2'>$3000</span>{' '}
                            </span>
                          </span>
                        </div>
                        <div className='paddingTop2'>
                          <span className='gamefied'>
                            Photo Realistic Renderings starts <br />{' '}
                            <span style={{ paddingLeft: '10px' }}>
                              from <span className='varient2'>$80</span>{' '}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>

                <Grid item sm={3}>
                  <div
                    className={
                      window.innerWidth < 1100
                        ? 'pricingCard1100'
                        : 'pricingCard'
                    }
                  >
                    <div className='imgWrapper'>
                      <h1 className='beginner'>Build Your Plan</h1>
                      <h1 className='price'>Flexible</h1>
                      <div className='month'>/month</div>
                      <p className='modelTitle'>
                        All the basics for businesses that are just getting
                        started.
                      </p>
                      {/* <div className="buyNow"><span className="buyBtn">Contact Seller</span></div> */}
                      <Link to='/contact-us' style={{ textDecoration: 'none' }}>
                        <Button
                          size={'large'}
                          variant='contained'
                          className='themeBtn txtShadow roundedPlansPricing buyNow'
                        >
                          Contact Seller
                        </Button>
                      </Link>

                      <div className='bottomBorder'></div>

                      <div className='vectorArea'>
                        <div className='paddingTop'>
                          <span>
                            <img
                              src={right}
                              alt=''
                              width='12px'
                              height='12px'
                            />
                            <span className='hostedModels'>
                              Custom Hosted Models
                            </span>
                          </span>
                        </div>
                        <div className='paddingTop'>
                          <span>
                            <img
                              src={right}
                              alt=''
                              width='12px'
                              height='12px'
                            />
                            <span className='hostedModels'>
                              Custom Monthly Website Views
                            </span>
                          </span>
                        </div>
                        <div className='paddingTop'>
                          <span>
                            <img
                              src={right}
                              alt=''
                              width='12px'
                              height='12px'
                            />
                            <span className='hostedModels'>
                              First Model Optimization Free
                            </span>
                          </span>
                        </div>
                        <div className='paddingTop'>
                          <span>
                            <img
                              src={right}
                              alt=''
                              width='12px'
                              height='12px'
                            />
                            <span className='hostedModels'>
                              Installation Support
                            </span>
                          </span>
                        </div>
                        <div className='paddingTop'>
                          <span>
                            <img
                              src={right}
                              alt=''
                              width='12px'
                              height='12px'
                            />
                            <span className='hostedModels'>
                              Custom Analytics
                            </span>
                          </span>
                        </div>
                        <div className='paddingTop'>
                          <span>
                            <img
                              src={right}
                              alt=''
                              width='12px'
                              height='12px'
                            />
                            <span className='hostedModels'>
                              Dedicated Account Manager
                            </span>
                          </span>
                        </div>
                        <div className='paddingTop'>
                          <span>
                            <img
                              src={right}
                              alt=''
                              width='12px'
                              height='12px'
                            />
                            <span className='hostedModels'>
                              Animated Models
                            </span>
                          </span>
                        </div>
                        <div className='paddingTop'>
                          <span>
                            <img
                              src={right}
                              alt=''
                              width='12px'
                              height='12px'
                            />
                            <span className='hostedModels'>Custom UI/UX</span>
                          </span>
                        </div>
                      </div>
                      <div className='bottomBorder2'></div>
                      <div className='vectorArea2'>
                        <div className='paddingTop2'>
                          <div className='hostedModels'>
                            AR Model Optamization starts from
                          </div>
                          <span className='varient'>$100 + $20/Varient</span>
                        </div>
                        <div className='paddingTop2'>
                          <span className='hostedModels'>VR starts from </span>
                          <span className='varient2'>$200</span>
                        </div>
                        <div className='paddingTop2'>
                          <span className='gamefied'>
                            Gamefied Walkthrough starts <br />{' '}
                            <span style={{ paddingLeft: '10px' }}>
                              from <span className='varient2'>$3000</span>{' '}
                            </span>
                          </span>
                        </div>
                        <div className='paddingTop2'>
                          <span className='gamefied'>
                            Photo Realistic Renderings starts <br />{' '}
                            <span style={{ paddingLeft: '10px' }}>
                              from <span className='varient2'>$80</span>{' '}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </section>
          </Container>
        </section>
        {/* <section className="sectionInnerSpacing">
          <Typography className="pricingTitle" align="center">
            Pricing
          </Typography>
          <Container maxWidth={"lg"} className="containerSpacing">
            <div className="priceTableWrapper">
              <Grid container>
                <Grid item sm={3}>
                  <div style={{ height: 113 }}></div>
                  <Typography className="priceTabHead">
                    Free first model
                  </Typography>
                  <Typography className="priceTabHead">
                    Number of models
                  </Typography>
                  <Typography className="priceTabHead">
                    AR & 360° view
                  </Typography>
                  <Typography className="priceTabHead noBorder">
                    Platform Fee
                  </Typography>
                </Grid>
                {this.state.plans.map((plan, i) => (
                  <Grid
                    item
                    sm={3}
                    key={i}
                    alignItems="center"
                    justify="center"
                  >
                    <Typography className="priceTabHead planTitle alignCenter noBorder">
                      {plan.title}
                    </Typography>
                    <Typography className="priceTabData">
                      <Check fs />
                    </Typography>
                    <Typography className="priceTabData">
                      {plan.no_of_models}
                    </Typography>
                    <Typography className="priceTabData">
                      {plan.ar_360_view_fee}
                      <Typography color="inherit" style={{ fontWeight: 500 }}>
                        per model
                      </Typography>
                    </Typography>
                    <Typography className="priceTabData noBorder">
                      {plan.platform_fee}
                      <Typography color="inherit" style={{ fontWeight: 500 }}>
                        per year
                      </Typography>
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </div>
          </Container>
        </section> */}
        <section className='sectionInnerSpacing'>
          <Container>
            <div className='text-center VAS'>
              <Typography className='VAStitle'>Value Added Services</Typography>
              <Typography className='VASsubtitle' gutterBottom>
                Priced based on requirement
              </Typography>
              <div className='VASwrapper'>
                <Grid container spacing={3}>
                  {this.state.VAS.map((item, index) => (
                    <Grid item sm={4} key={index} className='text-center'>
                      <Typography className='VASitem'>{item.title}</Typography>
                    </Grid>
                  ))}
                </Grid>
              </div>
              <div className='touchBtnWrapper text-center'>
                <a
                  href='/contact-us'
                  style={{
                    textDecoration: 'none',
                    color: '#666666',
                    cursor: 'pointer',
                  }}
                >
                  <Button variant='contained' className='touchBtn'>
                    Get In Touch
                  </Button>
                </a>
              </div>
            </div>
          </Container>
        </section>
        <section className='sectionInnerSpacingBlogs'>
          <Container>
            <Blogs />
          </Container>
        </section>
        {/* <BackdropComponent
          isVisible={!this.state.isImgLoaded}
          bgColor={"#fff"}
        /> */}

        {/* Purchase plan modal */}
        <Modal
          visible={this.state.alreadypurchased}
          width='350'
          height='230'
          effect='fadeInUp'
          onClickAway={() => this.setState({ alreadypurchased: false })}
        >
          <div
            className='confirmationModalPublish'
            style={{ textAlign: 'center' }}
          >
            <Typography>You already have purchased plan.</Typography>
            <Button
              onClick={() => this.setState({ alreadypurchased: false })}
              className='DeleteBtnPublish'
              style={{ background: '#bdbdbd', marginTop: '30px' }}
            >
              Ok
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}
