import actualityLogoMobile from '../../Assets/newIcons/actualityLogoMobile.png';
import leftClick from '../../Assets/newIcons/leftClick.png';
import rightClick from '../../Assets/newIcons/rightClick.png';
import { Check } from '@material-ui/icons';
import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  IconButton,
  NativeSelect,
  Snackbar,
  Typography,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { Edit } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import AddCircleOutlineSharpIcon from '@material-ui/icons/AddCircleOutlineSharp';
import CloseIcon from '@material-ui/icons/Close';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import RemoveRedEyeOutlinedIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import axios from 'axios';
import QRCode from 'qrcode.react';
import React, { Component, createRef } from 'react';
import Modal from 'react-awesome-modal';
import { SketchPicker } from 'react-color';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Lottie from 'react-lottie';
import edit from '../../Assets/Images/edit.png';
import remove from '../../Assets/Images/delete.png';
import rectangle from '../../Assets/Images/Rectangle 54.png';
import rotate from '../../Assets/Images/rotate.png';
import share from '../../Assets/Images/share.png';
import verticalLine from '../../Assets/Images/Vector 12.png';
import zoom from '../../Assets/Images/zoom-in.png';
import loader from '../../Assets/lottie/3dloader.json';
import { API } from '../../Constants/APIs';
import Resizer from 'react-image-file-resizer';
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop from 'react-image-crop';
import language from '../../Assets/Images/language.png';
import './style.css';
import { v4 as uuid } from 'uuid';
import AnnotationBtns, { NewAnnotPositionPlus } from '../AnnotationBtns';
import Drawer from '../Drawer';
import SunEditor from '../SunEditor';
import HotspotContentDrawer from '../HotspotContentDrawer';
import Passcode from '../Passcode';
import { validateUrl } from '../../Helpers/os';
import MaterialEditModel from '../../Screens/MaterialEditModel';
import PasscodeChange from '../PasscodeChange';
import TSEMultiSelect from './TSEMultiSelect';
import RecommendProduct from '../RecommendProduct';
import TopRightBoxes from '../TopRightBoxes';

import ColorMetarial from '../ComponentsGroups';
import VariantsDeleteConfirmation from '../VariantsDeleteConfirmation';
import HotspotContainer from '../HotspotContainer';
import {
  canPublishProduct,
  firstLetterCapital,
  scrollToTop,
} from '../../Helpers/utils';
import ComponentEdit from '../ComponentEdit';
import PlacementRadio from '../PlacementRadio';
import ProductSharePopUp from '../ProductSharePopUp';
import TSEDialog from '../TSEDialog';
const resizeFile = (file) =>
  new Promise((resolve) => {
    // console.log(file);
    Resizer.imageFileResizer(
      file,
      800,
      600,
      'JPEG',
      80,
      0,
      (uri) => {
        resolve(uri);
      },
      'base64'
    );
  });

const pId = document.location.href;
var url = new URL(pId);
var productId = url.searchParams.get('id');
let fd = new FormData();
class UnPublishModal extends Component {
  constructor(props) {
    super(props);
    this.modelBlockRef = createRef();
    this.modelRef = createRef();
    this.annotBtnCloseCB = createRef();
    this.rightBoxRef = createRef();
    this.bottomMostBox = createRef();
    window.addEventListener('resize', (e) => {
      // console.log(this.rightBoxRef.current.offsetWidth, " e", this.rightBoxRef);
      this.setState({ rightBoxWidth: this.rightBoxRef.current.offsetWidth });
    });
    window.addEventListener('scroll', (e) => {
      // console.clear();
      const scrollTo =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTo >= this.state.modelBlockTop) {
        if (!this.state?.fixModelBlock)
          this.setState({
            fixModelBlock: true,
          });
      } else if (this.state.fixModelBlock) {
        // if (scrollTo == this.state.modelBlockTop) {
        this.setState({
          fixModelBlock: false,
        });
      }
      // }
      // console.log(this.rightBoxRef.current.offsetWidth, " e", this.rightBoxRef);

      // this.setState({ rightBoxWidth: this.rightBoxRef.current.offsetWidth });
    });
  }
  componentDidMount() {
    if (this.modelBlockRef?.current && !this.state.modelBlockWidth) {
      // console.clear();
      // console.log(
      //   "this.modelBlockRef.curren ",
      //   this.modelBlockRef.current?.getBoundingClientRect()
      // );
      const c = this.modelBlockRef.current?.getBoundingClientRect();
      // console.log("ccccccc ", c);
      this.setState({
        modelBlockTop: c.top || c.y,
        modelBlockWidth: c.width,
      });
    }
    if (this.rightBoxRef?.current && !this.state.rightBoxWidth)
      if (this.bottomMostBox?.current && !this.state.bottomMostBoxHeight)
        this.setState({ rightBoxWidth: this.rightBoxRef.current.offsetWidth });

    this.setState({
      bottomMostBoxHeight: this.bottomMostBox.current.offsetHeight,
    });
    let pId = document.location.href;
    var url = new URL(pId);
    var productId = url.searchParams.get('id');
    axios.get(API.getUrls.getSingleProduct + productId).then((resp) => {
      const firstModelSrc = resp.data.product?.variants?.[0]?.model_file_url;
      this.setState({ language: resp.data?.product?.language, firstModelSrc });

      // console.log(resp);
      if (resp.data.status === 'success') {
        this.setState({
          visiblity: resp.data.product.visibility,
          productStatus: resp.data.product.status,
          language: resp.data.product.language,
        });
        // console.log(this.state.productStatus);
        if (this.state.productStatus === 'draft') {
          this.setState({
            modalId: productId,
            embedCode:
              "<iframe src='" +
              'https://portal.actuality.live/modelDynamicviewer/' +
              productId +
              "' title='some' scrolling='No' height='750px' width='100%' frameborder='0'></iframe>",
          });
        } else {
          this.setState({
            modalId: productId,
            embedCode:
              "<iframe src='" +
              'https://actuality.live/modelDynamicviewer/' +
              productId +
              "' title='some' scrolling='No' height='750px' width='100%' frameborder='0'></iframe>",
          });
        }
      }
    });
    this.setModel();
    this.getSingleProduct();
    this.getComponents();
    this.getUser();
    this.getVariants();
    this.fetchCategories();
  }
  state = {
    loadPublish: false,
    openEdit: false,
    openBuyNow: false,
    visible: false,
    showFirst: false,
    showSecond: false,
    showThird: false,
    showFourth: false,
    compname: '',
    productId: 0,
    addColor: true,
    type: 'color',
    material_value: '',
    compId: 0,
    file: '',
    showMaterials: true,
    setValue: 0,
    array1: [],
    array2: [],
    emptyVariants: false,
    emptyComponents: false,
    model_file: '',
    processing: false,
    userdetail: {},
    title: '',
    uploadCount: 0,
    posterImage: '',
    deleteConfirmation: false,
    confirmationComponentDelete: false,
    removeProductLogo: false,
    deleteMatId: 0,
    deleteCompId: 0,
    loading: false,
    confirmationComponentAdd: false,
    selectedMaterials: [],
    colorPicker: false,
    model: {},
    product: {},
    variantIndex: 0,
    revealModel: false,
    mesh: [],
    language: 'English',
    Components: [],
    frames: [],
    selected_material_id: 0,
    selected_component_id: '',
    // showModalBtn: screen === "desktop",
    showVariant: false,
    selectedVariant: {},
    meshes: [],
    Variants: [],
    model_file_url: '',
    ModalImage: '',
    variantsCombo: [],
    Component1: [],
    Component2: [],
    value: 'https://portal.actuality.live/modelDynamicviewer/' + productId,
    value2: 'https://actuality.live/modelDynamicviewer/' + productId,
    QRCode: 'https://portal.actuality.live/modelDynamicviewer/' + productId,
    QRCode2: 'https://actuality.live/modelDynamicviewer/' + productId,
    copied: false,
    qrCopy: false,
    embedCopy: false,
    embedCode: '',
    accordionHeight: '43px',
    active: 0,
    activeComponents: [],
    link_title: '',
    link_description: '',
    link_url: '',
    userId: '',
    purchasePlan: false,
    cancelledPlan: false,
    zeroModelsLeft: false,
    zeroDaysLeft: false,
    zeroViewsLeft: false,
    modalId: '',
    productStatus: '',
    prdStatus: '',
    selectVal: '',
    visiblity: true,
    visiblityoff: false,

    urlId: '',
    searching: false,
    categories: [],
    catIds: [],
    image: '',
    title: '',
    productId: 0,
    filename: {},
    data: {},
    productadded: false,
    imgWidth: 0,
    imgHeight: 0,
    link_url: '',
    link_title: '',
    link_description: '',
    uploadJPG: false,
    visibleCrop: false,
    visibleCropProduct: false,
    src1: null,
    crop1: {
      aspect: 1,
      width: 100,
      // height: 80,
      unit: '%',
    },
    imgPreview: false,
    uploadModel: false,
    src: null,
    crop: {
      aspect: 1,
      width: 80,
      // height: 80,
      unit: '%',
    },
    canAddHotspot: false,
    addHotspot: false,
    variantToRender: {},
    possibleVariants: {},

    showHotspot: {
      isTrue: false,
      data: {},
    },
  };

  canPublish = async () => {
    const canPublishTemp = await canPublishProduct(this.state.product._id);
    return canPublishTemp;
  };

  publish = async () => {
    if (!(await this.canPublish())) {
      alert('Variants are not uploaded properly');
    } else if (this.state.ModalImage === null) {
      alert("Please add components and upload .glb file");
      return;
    }
    var url_string = window.location.href; //window.location.href
    var url = new URL(url_string);
    var payId = url.searchParams.get("id");
    const payload = {
      status: "published",
    };
    axios
      .post(API.postUrls.changeStatus + payId + "?type=product", payload)
      .then((statusResp) => {
        // console.log(statusResp);
        if (statusResp.data.status === "success") {
          // let payLoad = {
          //   prodId: payId
          // }
          // axios.put(API.postUrls.websiteViews, payLoad).then((resViews) => {
          // //  console.log(resViews);
          // });
          let token = localStorage.getItem("token");
          window.location.href =
            "https://actuality.live/modelDynamicviewer/" + payId + "?self=true";
        } else {
          this.setState({ purchasePlan: true })
        }
      })
      .catch(() => {
        alert("Server is busy please try again!");
      })
      .finally(() => { });
    return;
    if (this.state.ModalImage === null) {
      // alert("Please add components and upload .glb file");
      alert('Please add components and upload .glb file');
    } else {
      let authToken = localStorage.getItem('token');
      // console.log(authToken);
      axios
        .get(API.getUrls.getPlansNew, {
          headers: { Authorization: 'Bearer ' + authToken },
        })
        .then((respPlan) => {
          // console.log(respPlan);
          if (respPlan.data.status === 'failed') {
            this.setState({
              purchasePlan: true,
            });
          } else {
            let orderStatus = respPlan.data.updatedOrders.order_status;
            let modelsLeft = respPlan.data.updatedOrders.no_of_models_left;
            let daysLeft = respPlan.data.updatedOrders.no_of_days_left;
            let viewsLeft = respPlan.data.updatedOrders.no_of_views_left;
            // if(orderStatus==="cancelled")
            // {
            //   this.setState({
            //     cancelledPlan: true,
            //     loadPublish: true
            //   });
            // }
            // else

            if (modelsLeft === 0) {
              this.setState({
                zeroModelsLeft: true,
                loadPublish: true,
              });
            } else if (daysLeft === 0) {
              this.setState({
                zeroDaysLeft: true,
                loadPublish: true,
              });
            } else if (viewsLeft === 0) {
              this.setState({
                zeroViewsLeft: true,
                loadPublish: true,
              });
            } else {
              axios
                .get(API.getUrls.getUser, {
                  headers: { Authorization: 'Bearer ' + authToken },
                })
                .then((resp) => {
                  // console.log(resp);
                  if (resp.data.status === 'success') {
                    this.setState({
                      userId: resp.data.user._id,
                    });

                    axios.get(API.getUrls.getPlansNew).then((res) => {
                      // console.log(res);
                      if (res.data.status === 'success') {
                        if (res.data.data !== null) {
                          // console.log(res.data.updatedOrders.user._id);
                          // console.log(this.state.userId);
                          if (
                            res.data.updatedOrders.user._id ===
                            this.state.userId
                          ) {
                            if (
                              res.data.data.subscription.subscription_items
                                .length > 0
                            ) {
                              if (
                                res.data.updatedOrders.order_summary.content
                                  .subscription.billing_period_unit === 'month'
                              ) {
                                let createdDate =
                                  res.data.updatedOrders.createdAt.slice(0, 10);
                                // console.log(createdDate);
                                let today = new Date();
                                let currentMonth = today.getMonth();
                                let currentYear = new Date().getFullYear();
                                let lastDate = new Date(
                                  currentYear,
                                  currentMonth + 1,
                                  0
                                ).getDate();
                                // console.log(today);
                                // console.log(currentMonth);
                                // console.log(currentYear);

                                var time = new Date();
                                time.setDate(time.getDate() + 30);
                                // console.log(time);
                              }
                              // if (res.data.data.subscription.status==='active') {
                              var url_string = window.location.href; //window.location.href
                              var url = new URL(url_string);
                              var payId = url.searchParams.get('id');
                              const payload = {
                                status: 'published',
                              };
                              axios
                                .post(
                                  API.postUrls.changeStatus +
                                  payId +
                                  '?type=product',
                                  payload
                                )
                                .then((statusResp) => {
                                  if (statusResp.data.status === 'success') {
                                    let token = localStorage.getItem('token');
                                    window.location.href =
                                      'https://actuality.live/modelDynamicviewer/' +
                                      payId +
                                      '?self=true';
                                  }
                                });
                            } else {
                              this.setState({
                                purchasePlan: true,
                              });
                            }
                          } else {
                            this.setState({
                              purchasePlan: true,
                            });
                          }
                        } else {
                          this.setState({
                            purchasePlan: true,
                          });
                        }
                      } else {
                        this.setState({
                          purchasePlan: true,
                        });
                      }
                    });
                  }
                });
            }
          }
        });
    }
  };

  publishedProduct = async () => {
    if (!(await this.canPublish())) {
      alert('Variants are not uploaded properly');
      return;
    }
    let authToken = localStorage.getItem('token');
    axios
      .get(API.getUrls.getPlansNew, {
        headers: { Authorization: 'Bearer ' + authToken },
      })
      .then((respPlan) => {
        // console.log(respPlan);
        if (respPlan.data.status === 'failed') {
          this.setState({
            purchasePlan: true,
          });
        } else {
          let orderStatus = respPlan.data.updatedOrders.order_status;
          let modelsLeft = respPlan.data.updatedOrders.no_of_models_left;
          let daysLeft = respPlan.data.updatedOrders.no_of_days_left;
          let viewsLeft = respPlan.data.updatedOrders.no_of_views_left;
          // if(orderStatus==="cancelled")
          // {
          //   this.setState({
          //     cancelledPlan: true,
          //     loadPublish: true
          //   });
          // }
          // else
          if (modelsLeft === 0) {
            this.setState({
              zeroModelsLeft: true,
              loadPublish: true,
            });
          } else if (daysLeft === 0) {
            this.setState({
              zeroDaysLeft: true,
              loadPublish: true,
            });
          } else if (viewsLeft === 0) {
            this.setState({
              zeroViewsLeft: true,
              loadPublish: true,
            });
          } else {
            window.location.href =
              'https://actuality.live/modelDynamicviewer/' +
              productId +
              '?self=true';
          }
        }
      });
  };

  changeProductStatus(e) {
    // console.log(e);
    axios.get(API.getUrls.getPlansNew).then((res) => {
      // console.log(res);
      if (res.data.status === 'success') {
        if (res.data.data.subscription.subscription_items.length > 0) {
          // if (res.data.data.subscription.status==='active') {
          var url_string = window.location.href; //window.location.href
          var url = new URL(url_string);
          var payId = url.searchParams.get('id');
          const payload = {
            status: e,
          };
          axios
            .post(API.postUrls.changeStatus + payId + '?type=product', payload)
            .then((statusResp) => {
              // console.log(statusResp);
              if (statusResp.data.status === 'success') {
                if (payload.status === 'published') {
                  this.setState({
                    prdStatus: 'published',
                    stsProduct: 'published',
                  });
                } else {
                  this.setState({
                    prdStatus: 'draft',
                    stsProduct: 'draft',
                  });
                }
                this.setState({
                  notification: true,
                  successMsg: statusResp.data.message,
                  statusProduct: this.state.stsProduct,
                  productStatus: this.state.prdStatus,
                });
                setTimeout(() => {
                  // this.setState({
                  //   notification: false
                  // })
                  window.location.reload();
                }, 1000);
              }
              // if (statusResp.data.status === "success") {
              //   // window.location.href = "/modelDynamicviewer/" + payId
              //   window.location.href = "https://actuality.live/modelDynamicviewer/" + payId
              // }
            });
          // }
          // else
          // {
          //   this.setState({
          //     purchasePlan: true
          //   })
          // }
        } else {
          this.setState({
            purchasePlan: true,
          });
        }
      } else {
        this.setState({
          purchasePlan: true,
        });
      }
    });
  }

  fetchCategories = () => {
    axios
      .get(API.getUrls.Categories + '?page=1&limit=10&status=active')
      .then((res) => {
        this.setState({
          categories: res.data.categories.docs.map(
            ({ title: label, _id: value }) => ({ value, label })
          ),
        });
      })
      .catch((err) => { });
  };
  selectedComponents = (CompId) => {
    if (this.state.activeComponents.includes(CompId)) {
      let index = this.state.activeComponents.indexOf(CompId);
      this.state.activeComponents.splice(index, 1);
    } else {
      this.state.activeComponents.push(CompId);
    }
  };
  setModel = () => {
    let pId = document.location.href;
    var url = new URL(pId);
    var productId = url.searchParams.get('id');
    axios.get(API.getUrls.getProducts + '/' + productId).then((res) => {
      this.setState(
        {
          product: res.data.product,
          placement: res.data.product.placement,
          revealModel: true,
        },
        () => {
          this.setUpModal();
        }
      );
    });
  };
  setUpModal = () => {
    var modal = document.getElementById('myModal');
    var modalContent = document.querySelector('.modal-content');

    var btn = document.getElementById('qrtext');

    var span = document.getElementsByClassName('closeBtn')[0];

    if (btn) {
      btn.onclick = function () {
        modal.style.display = 'block';
        modalContent.classList.add('slideIn');
      };
    }

    span.onclick = function () {
      modalContent.classList.remove('slideIn');
      modal.style.display = 'none';
    };

    window.onclick = function (event) {
      if (event.target === modal) {
        modalContent.classList.remove('slideIn');
        modal.style.display = 'none';
      }
    };
  };
  getVariants = () => {
    let pId = document.location.href;
    var url = new URL(pId);
    var productId = url.searchParams.get('id');
    axios
      .get(API.getUrls.variants + '?page=1&limit=10&product=' + productId)
      .then((res) => {
        console.log(`res `, res.data.docs);
        this.setState({
          Variants: res.data.docs,
          selectedVariant: res.data.docs[0],
          ModalImage: res.data
            ? res.data.docs
              ? res.data.docs[0]
                ? res.data.docs[0].model_file_url
                : null
              : null
            : null,
        });

        const possibleVariants = res.data.docs.map((variant) => {
          const combinator = variant.materials
            .map(({ _id }) => _id)
            .sort()
            .join('-');
          return { combinator, variant };
        });
        const selectedMaterials = possibleVariants[0].variant.materials.map(
          ({ component, _id }) => ({ compoId: component, matId: _id })
        );
        this.setState({
          selectedVariant: possibleVariants[0].variant,
          possibleVariants,
          selectedMaterials,
        });
      });
  };

  isSelected = (compoId, matId) =>
    this.state.selectedMaterials?.findIndex(
      (s) => s.compoId == compoId && s.matId == matId
    ) != -1;

  onMaterialClick = (compoId, matId) => {
    const indexOf = this.state.selectedMaterials.findIndex(
      (mat) => mat.compoId == compoId
    );
    if (indexOf != -1) {
      const tempSelectedMaterials = this.state.selectedMaterials.map(
        (ma, index) => (index == indexOf ? { compoId, matId } : ma)
      );
      const combinator = tempSelectedMaterials
        .map(({ matId }) => matId)
        .sort()
        .join('-');
      const selectedVariant = this.state.possibleVariants.find(
        (pos) => pos.combinator == combinator
      )?.variant;
      if (!selectedVariant) {
        this.setState({ noVariantAvaliable: true });
        return;
      }
      this.setState({
        selectedMaterials: tempSelectedMaterials,
        selectedVariant,
      });
    } else {
      const tempSelectedMaterials = [
        ...this.state.selectedMaterials,
        { compoId, matId },
      ];
      const combinator = tempSelectedMaterials
        .map(({ matId }) => matId)
        .sort()
        .join('-');
      const selectedVariant = this.state.possibleVariants.find(
        (pos) => pos.combinator == combinator
      )?.variant;
      if (!selectedVariant) {
        this.setState({ noVariantAvaliable: true });

        return;
      }
      this.setState({
        selectedMaterials: tempSelectedMaterials,
        selectedVariant,
      });
    }
  };
  uploadAnnot = async ({ hotspotId, isDelete, isEdit } = {}) => {
    let hotspots = [];

    if (isDelete) {
      hotspots = this.state?.selectedVariant?.hotspots.filter(
        (hotspot) => hotspot._id != hotspotId
      );
    } else {
      const title = this.state.newAnnotTitle,
        description = this.state?.newAnnotDescription;

      if (!title || !description) {
        this.setState({
          descriptionIsEmpty: !!!description,
          titleIsEmpty: !!!title,
        });
        return;
      }
      if (this.state.isEditHotspot || isEdit) {
        const editHotspot = this.state.editHotspot;
        hotspots = this.state?.selectedVariant?.hotspots.map((hotspot) =>
          hotspot._id == editHotspot._id
            ? {
              ...editHotspot,
              title: this.state.newAnnotTitle,
              description: this.state?.newAnnotDescription || '',
            }
            : hotspot
        );
      } else {
        const newAnnot = {
          _id: uuid(10),
          ...this.state.newAnnotPosition,
          title: this.state.newAnnotTitle,
          description: this.state?.newAnnotDescription || '',
        };
        hotspots = [...this.state?.selectedVariant?.hotspots, newAnnot];
      }
    }

    const selectedVariantId = this.state?.selectedVariant._id;

    try {
      const postUrl = API.postUrls.addHotspot + selectedVariantId;

      await axios({
        method: 'post',
        url: postUrl,
        data: { hotspots },
      });
    } catch (err) {
      // console.log("error from backend ", err);
    }
    const newVariant = {
      ...this.state.selectedVariant,
      hotspots,
    };

    const newPossibleVariants = this.state.possibleVariants.map((v) => {
      if (v.variant._id == selectedVariantId)
        return { ...v, variant: newVariant };
      else return v;
    });
    const { hotspots: teee, ...rest } = this.state.selectedVariant;
    this.setState((pre) => ({ ...pre, selectedVariant: rest }));
    this.setState({
      selectedVariant: newVariant,

      possibleVariants: newPossibleVariants,
    });
    this.closeEditorDrawer();
    // this.setState({
    //   canAddHotspot: false,
    //   openHotspotInstruction: false,
    //   openHotspotDrawer: false,
    //   newAnnotPosition: null,
    //   newAnnotTitle: "",
    //   newAnnotDescription: "",
    // });
  };

  checkVariants = () => { };
  // getVariants = () => {
  //   let pId = document.location.href;
  //   var url = new URL(pId);
  //   var productId = url.searchParams.get("id");
  //   axios
  //     .get(API.getUrls.variants + "?page=1&limit=10&product=" + productId)
  //     .then((res) => {
  //       this.setState({
  //         Variants: res.data.docs,
  //         ModalImage: res.data
  //           ? res.data.docs
  //             ? res.data.docs[0]
  //               ? res.data.docs[0].model_file_url
  //               : null
  //             : null
  //           : null,
  //       });
  //     });
  // };
  onImageChange = (event) => {
    const target = event.target;
    if (!target.files.length) return;
    this.setState({
      file: target.files[0],
    });
    if (target.files && target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ swatchFile: e.target.result });
      };
      reader.readAsDataURL(target.files[0]);
    }
  };
  getComponents = () => {
    let pId = document.location.href;
    var url = new URL(pId);
    var productId = url.searchParams.get('id');
    this.setState({ productId });
    axios
      .get(API.getUrls.getComponents + productId + '?page=1&limit=10')
      .then((res) => {
        this.setState(
          {
            Components: res.data.components.docs,
            allComponentTitles: res.data.components.docs.map(
              ({ component_name, _id: compId }) => ({ component_name, compId })
            ),
          },
          () => {
            this.state.Components.forEach((item) => {
              this.state.activeComponents.push(item._id);
            });
            this.setState({
              variantsCombo: this.getCombinations(this.state.Components),

              emptyComponents:
                this.state.Components.length === 0 ? true : false,
            });
          }
        );
      });
    // }
  };
  getUser = () => {
    // if (init() === "success") {
    let authToken = localStorage.getItem('token');
    axios
      .get(API.getUrls.getUser, {
        headers: { Authorization: 'Bearer ' + authToken },
      })
      .then((res) => {
        // console.log(res);
        this.setState({ userdetail: res.data.user });
      });
    // }
  };

  openModal() {
    this.setState({
      showFirst: true,
      showSecond: false,
      showThird: false,
      showFourth: false,
      visible: true,
    });
  }
  secondModal() {
    this.setState({
      showFirst: false,
      showSecond: true,
      showThird: false,
      showFourth: false,
      visible: true,
    });
  }

  thirdModal() {
    this.setState({
      showFirst: false,
      showSecond: false,
      showThird: true,
      showFourth: false,
      visible: true,
    });
  }

  fourthModal() {
    this.setState({
      showFirst: false,
      showSecond: false,
      showThird: false,
      showFourth: true,
      visible: true,
    });
  }
  closeModal() {
    this.setState({
      visible: false,
      openEdit: false,
      compnentEdit: false,
      materialEdit: false,
    });
    if (this.state.ShowComponent) {
      document.getElementById('componentForm')?.reset?.();
    } else {
      document.getElementById('materialForm')?.reset?.();
    }
  }

  handleInput = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        // console.log(this.state.language);
      }
    );
  };

  addComponent = (e) => {
    e.preventDefault();
    let authToken = localStorage.getItem('token');
    this.setState({ loading: true });
    let rawData = {
      component_name: this.state.compname,
      product: this.state.productId,
    };
    axios
      .post(API.postUrls.addComponents, rawData, {
        headers: { Authorization: 'Bearer ' + authToken },
      })
      .then((resp) => {
        if (resp.data.status === 'success') {
          this.setState({
            notification: true,
            successMsg: resp.data.message,
            loading: false,
            compId: resp.data.component._id,
            showFirst: !true,
            visible: !true,
            ShowComponent: false,
          });
          setTimeout(() => {
            this.setState({ notification: false });
            document.getElementById('componentForm').reset();

            this.getComponents();
          }, 2000);
        } else {
          this.setState({
            notification: true,
            successMsg: resp.data.message,
            ShowComponent: true,
            // loading: false,
          });
          setTimeout(() => {
            this.setState({ notification: false });
          }, 2000);
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  addMaterial = (e) => {
    let pId = document.location.href;
    var url = new URL(pId);
    var productId = url.searchParams.get('id');
    e.preventDefault();

    this.setState({ loading: true });

    let data = new FormData();
    data.append('material_name', this.state.material_name);
    data.append('material_type', this.state.type);
    if (this.state.type === 'color') {
      data.append('material_value', this.state.material_value || '#ff0000');
    }
    data.append('component', this.state.compId);
    if (this.state.type === 'swatch') {
      data.append('swatch_image', this.state.file);
    }
    // if (init() === "success") {
    axios.post(API.postUrls.addMaterial, data).then((resp) => {
      if (resp.data.status === 'success') {
        this.setState({
          notification: true,
          successMsg: resp.data.message,
          visible: false,
          loading: false,
          swatchFile: '',
          file: '',
        });
        setTimeout(() => {
          this.setState({ notification: false });
          document.getElementById('materialForm').reset();
          this.getComponents();
          window.location.href = '/product-edit' + '?id=' + productId;
        }, 4000);
      } else {
        this.setState({
          notification: true,
          successMsg: resp.data.message,
        });
        setTimeout(() => {
          this.setState({ notification: false });
        }, 2000);
      }
    });
    // }
  };
  // handleFile = (e) => {
  //   this.setState({ [e.target.name]: e.target.files[0] });
  // };

  getCombinations(arr) {
    if (arr.length === 0) return [[]];
    let [current, ...rest] = arr;
    let combinations = this.getCombinations(rest);
    return current.materials.reduce(
      (a, string) => [...a, ...combinations.map((c) => [string, ...c])],
      []
    );
  }

  handleChangeComplete = (color) => {
    this.setState({ material_value: color.hex }, () => { });
  };

  getSingleProduct = () => {
    let pId = document.location.href;
    var url = new URL(pId);
    var productId = url.searchParams.get('id');
    // if (init() === "success") {
    axios.get(API.getUrls.getProducts + '/' + productId).then((res) => {
      // console.log(res);
      if (res.data.product.status === 'published') {
        this.setState({
          statusProduct: 'published',
        });
      } else {
        this.setState({
          statusProduct: 'drafted',
        });
      }
      this.setState({
        product: res.data.product,
        productStatus: res.data.product.status,
        catIds: res.data.product.categories.map(
          ({ title: label, _id: value }) => ({ value, label })
        ),
      });
    });
    // }
  };

  updateProduct = (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    let data = new FormData();
    if (!this.state.catIds.length) {
      this.setState({
        notification: true,
        successMsg: 'Category is required',
        loading: false,
        // passcodeMsg: resp.data.passcodeMsg || "",
      });
      setTimeout(() => {
        this.setState({ notification: false });
      }, 2000);
      return;
    }
    if (this.state.title) data.append('title', this.state.title);
    if (this.state.placement) data.append('placement', this.state.placement);
    if (this.state.sub_title) data.append('sub_title', this.state.sub_title);
    if (this.state.filename) {
      data.append('posterImage', this.state.filename);
    }

    this.state.catIds.forEach((cat) => {
      data.append('categories[]', cat.value);
    });
    if (this.state.product.is_protected) {
      if (this.state.deletePasscode) {
        data.append('delete_passcode', true);
        data.append('passcode', this.state.passcode);
      } else if (this.state.changePasscode) {
        data.append('change_passcode', true);
        data.append('prevPasscode', this.state.prevPasscode);
        data.append('passcode', this.state.passcode);
      }
    } else if (this.state.is_protected && this.state.passcode.trim().length) {
      data.append('is_protected', true);
      data.append('passcode', this.state.passcode);
    }

    data.append('link_url', this.state.link_url);
    data.append('link_title', this.state.link_title);
    data.append('link_description', this.state.link_description);
    data.append('language', this.state.language);

    axios
      .put(API.postUrls.addproduct + '/' + this.state.product._id, data)
      .then((resp) => {
        if (resp.data.status === 'success') {
          this.setState({
            notification: true,
            loading: false,
            successMsg: resp.data.message,
            productadded: true,
            openEdit: false,
            openBuyNow: false,
            changePasscode: false,
            deletePasscode: false,
          });
          setTimeout(() => {
            this.setState({ notification: false });
            this.getSingleProduct();
          }, 2000);
        } else {
          this.setState({
            notification: true,
            successMsg: resp.data.message,
            loading: false,
          });
          setTimeout(() => {
            this.setState({ notification: false });
          }, 2000);
        }
      })
      .catch((err) => {
        this.setState({
          notification: true,
          successMsg: 'Server is busy. Please try again!',
          loading: false,
        });
        setTimeout(() => {
          this.setState({ notification: false });
        }, 2000);
      });
    // }
  };

  updateComponent = (e) => {
    e.preventDefault();
    let authToken = localStorage.getItem('token');
    this.setState({ loading: true });
    let rawData = {
      component_name: this.state.compname,
      product: this.state.productId,
    };
    axios
      .put(API.postUrls.addComponents + '/' + this.state.compId, rawData, {
        headers: { Authorization: 'Bearer ' + authToken },
      })
      .then((resp) => {
        if (resp.data.status === 'success') {
          this.setState({
            notification: true,
            successMsg: resp.data.message,
            loading: false,
            compnentEdit: false,
          });
          setTimeout(() => {
            this.setState({ notification: false });

            this.getComponents();
          }, 2000);
        } else {
          this.setState({
            notification: true,
            successMsg: resp.data.message,
          });
          setTimeout(() => {
            this.setState({ notification: false });
          }, 2000);
        }
      });
  };

  updateMaterial = (e, data) => {
    e.preventDefault();

    this.setState({ loading: true });

    let formData = new FormData();
    formData.append('material_name', data.material_name);
    formData.append('material_type', data.material_type);
    if (data.material_type === 'color') {
      formData.append('material_value', data.material_value);
    }
    formData.append('component', data.compId);
    if (data.material_type === 'swatch') {
      formData.append('swatch_image', data.file);
    }
    // if (init() === "success") {
    axios
      .put(API.postUrls.addMaterial + '/' + data.material_id, formData)
      .then((resp) => {
        if (resp.data.status === 'success') {
          this.setState({
            notification: true,
            successMsg: resp.data.message,
            visible: false,
            loading: false,
            materialEdit: false,
          });
          setTimeout(() => {
            this.setState({ notification: false });
            this.getComponents();
          }, 2000);
        } else {
          this.setState({
            notification: true,
            successMsg: resp.data.message,
          });
          setTimeout(() => {
            this.setState({ notification: false });
          }, 2000);
        }
      });
    // }
  };

  openEditModal = () => {
    this.setState({
      openEdit: true,
    });
  };

  variantDelete = () => {
    let pId = document.location.href;
    var url = new URL(pId);
    var productId = url.searchParams.get('id');
    if (this.state.Variants.length > 0) {
      // if (init() === "success") {
      axios
        .delete(API.postUrls.deleteAllVariants + '/' + productId)
        .then((res) => {
          if (res.data.status === 'success') {
            this.setState({
              notification: true,
              successMsg: res.data.message,
              confirmationComponentAdd: false,
            });
            setTimeout(() => {
              this.setState({
                notification: false,
              });
              window.location.href = '/product-edit' + '?id=' + productId;
            }, 5000);
          }
        });
      // }
    }
  };

  deleteMaterial = (id, isValid) => {
    // if (init() === "success") {
    axios.delete(API.postUrls.addMaterial + '/' + id).then((res) => {
      if (res.data.status === 'failed') {
        this.setState(
          {
            notification: true,
            successMsg: res.data.message,
            deleteConfirmation: false,
          },
          () => {
            if (this.state.Variants.length > 0 && false) {
              this.state.Variants.forEach((item) => {
                item.materials.forEach((mat) => {
                  if (mat.is_variant_made === isValid && mat._id === id) {
                    // if (init() === "success") {
                    axios
                      .delete(API.getUrls.variants + '/' + item._id)
                      .then((res) => {
                        if (res.data.status === 'success') {
                          this.setState({
                            notification: true,
                            successMsg: res.data.message,
                            deleteConfirmation: false,
                          });
                          this.getVariants();
                        }
                      });
                    // }
                  }
                });
              });
            }
          }
        );
        setTimeout(() => {
          this.setState({
            notification: false,
          });
          window.location.href = 'product-edit' + '?id=' + this.state.productId;
        }, 5000);
      }
    });
    // }
  };
  redirectToEditScreen = () => {
    setTimeout(() => {
      this.setState({
        notification: false,
      });
      window.location.href = 'product-edit' + '?id=' + this.state.productId;
    }, 5000);
  };
  deleteComponent = (id) => {
    let authToken = localStorage.getItem('token');
    this.setState({ loading: true });
    const URI = API.postUrls.addComponents + '/' + id;

    axios
      .delete(URI, {
        method: 'delete',
        url: URI,
        headers: {
          Authorization: 'Bearer ' + authToken,
          'Content-Type': 'application/json',
        },
      })
      .then((resp) => {
        const isHaveMoreThanOneMaterial =
          this.state.Components.find(({ _id }) => _id == id).materials.length >
          0;
        if (isHaveMoreThanOneMaterial) this.redirectToEditScreen();

        if (resp.data.status === 'failed') {
          this.setState({
            notification: true,
            successMsg: resp.data.message,
            confirmationComponentDelete: false,
          });
          setTimeout(() => {
            this.setState({
              notification: false,
            });
          }, 5000);
        } else {
          this.setState({
            notification: true,
            successMsg: resp.data.message,
            confirmationComponentDelete: false,
          });
          setTimeout(() => {
            this.setState({
              notification: false,
            });
          }, 2000);
        }
      })
      .finally(() => {
        this.setState({ loading: false, confirmationComponentDelete: false });

        this.getComponents();
      });
  };

  downloadQR = () => {
    const canvas = document.getElementById('QRCode');
    const pngUrl = canvas
      .toDataURL(window.location.href)
      .replace(window.location.href, window.location.href);
    let downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = 'QRCode';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  openInnewWindow = () => {
    // console.log(this.state.productStatus);
    if (this.state.ModalImage === null) {
      alert('Please add components and upload .glb file');
    } else {
      let token = localStorage.getItem('token');
      var url_string = window.location.href; //window.location.href
      var url = new URL(url_string);
      var payId = url.searchParams.get('id');
      if (this.state.productStatus === 'draft') {
        window.open(
          'https://portal.actuality.live/modelDynamicviewer/' +
          this.state.productId,
          '_blank'
        );
        // window.open("http://localhost:3000/modelDynamicviewer/" + this.state.productId, "_blank");
      } else {
        window.open(
          'https://actuality.live/modelDynamicviewer/' + this.state.productId,
          '_blank'
        );
      }
    }
  };

  addVisibilty = (e) => {
    let pId = document.location.href;
    var url = new URL(pId);
    var productId = url.searchParams.get('id');
    let data = new FormData();
    data.append('visibility', e);
    axios.put(API.postUrls.updateProduct + productId, data).then((resp) => {
      // console.log(resp);
      this.setState({
        openEdit: false,
        openBuyNow: false,
      });
    });
  };

  copyUrl = (e) => {
    this.setState({
      copied: true,
    });
    setTimeout(() => {
      this.setState({
        copied: false,
      });
    }, 2000);
  };

  copyEmbed = (e) => {
    this.setState({
      embedCopy: true,
    });
    setTimeout(() => {
      this.setState({
        embedCopy: false,
      });
    }, 2000);
  };
  // For product Image crop

  handleFile = (e) => {
    // console.log(e.target.files[0]);
    this.setState({ [e.target.name]: e.target.files[0] }, () => {
      var fileUpload = document.getElementById('uploadFile');

      var regex = /(\.jpg|\.jpeg|\.png|\.PNG)$/i;
      if (regex.test(fileUpload.value.toLowerCase())) {
        if (typeof fileUpload.files != 'undefined') {
          var reader = new FileReader();
          var scope = this;
          reader.readAsDataURL(fileUpload.files[0]);
          reader.onload = function (e) {
            var image = new Image();

            image.src = e.target.result;

            image.onload = function () {
              var height = this.height;
              var width = this.width;
              scope.setState({
                visibleCrop: true,
              });

              // if (height > 600 || width > 800) {
              //   alert("Height and Width must not exceed 800px and 600px.");
              //   return false;
              // }
              scope.setState({ uploadJPG: true });
              return true;
            };
          };
        } else {
          alert('This browser does not support HTML5.');
          return false;
        }
      } else {
        var thisvar = this;
        alert('Please upload file having extensions .jpeg/.jpg/.png/ only.');
        thisvar.setState({ uploadJPG: false });
        return false;
      }
    });
  };

  onImageLoaded1 = (image) => {
    this.imageRef = image;
  };

  onCropComplete1 = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange1 = (crop1, percentCrop) => {
    this.setState({ crop1 }, () => { });
  };

  makeClientCrop = async (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl });
      this.convertUrlToImageData(croppedImageUrl);
      // console.log(this.imageRef);
      // console.log(crop);
      // console.log(croppedImageUrl);
      // this.blobToFile(croppedImageUrl, "my-image.png");
    }
  };

  getBlobFromUrl = (myImageUrl) => {
    return new Promise((resolve, reject) => {
      let request = new XMLHttpRequest();
      request.open('GET', myImageUrl, true);
      request.responseType = 'blob';
      request.onload = () => {
        resolve(request.response);
      };
      request.onerror = reject;
      request.send();
    });
  };

  getDataFromBlob = (myBlob) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(myBlob);
    });
  };

  convertUrlToImageData = async (myImageUrl) => {
    // console.log(myImageUrl);
    try {
      let myBlob = await this.getBlobFromUrl(myImageUrl);
      // console.log(myBlob)
      let myImageData = await this.getDataFromBlob(myBlob);
      // console.log(myImageData)
      this.b64toBlob(myImageData);
      this.dataURLtoFile(myImageData, 'newfile.png');
    } catch (err) {
      // console.log(err);
      return null;
    }
  };

  dataURLtoFile(dataurl, filename) {
    // console.log(dataurl,filename);
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    var blob = new File([u8arr], filename, { type: mime });
    // console.log(blob);
    this.setState({
      filename: blob,
    });
  }

  //Usage example:
  // var file = dataURLtoFile('data:text/plain;base64,aGVsbG8gd29ybGQ=','hello.txt');
  // console.log(file);

  blobToFile(theBlob, fileName) {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    // console.log(theBlob);
    // this.convertedFile(theBlob)
  }

  convertedFile(theBlob) {
    // console.log(theBlob);
    var myBlob = new Blob();

    //do stuff here to give the blob some data...

    var myFile = this.blobToFile(theBlob, 'my-image.png');
    // console.log(myFile);
  }

  b64toBlob(dataURI) {
    // console.log(dataURI);

    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: 'image/jpeg' });
    // console.log(blob);
    this.blobToFile(blob, 'my-image.png');
  }

  getCroppedImg(image, crop, fileName) {
    // console.log(image,image.width, "image width");
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    // console.log(pixelRatio);
    const scaleX = image.naturalWidth / image.width;
    // console.log(image.naturalWidth, this.state.imageWidth);

    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      // console.log(canvas);
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error('Canvas is empty');
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve(this.fileUrl);
        },
        'image/png',
        1
      );
    });
  }

  onSelectFile = (e) => {
    // console.log(e.target.files[0])
    this.setState({
      filename: '',
      src: '',
    });
    // console.log(this.state.visibleCropProduct);
    // console.log(e.target.files);
    // console.log(e.target.files.length);
    if (e.target.files && e.target.files.length > 0) {
      this.setState(
        {
          visibleCropProduct: true,
        },
        () => {
          // console.log(this.state.visibleCropProduct);
        }
      );
      const reader = new FileReader();
      // console.log(reader);
      reader.addEventListener('load', () =>
        this.setState({ src1: reader.result }, () => {
          // console.log(this.state.src1);
        })
      );
      // console.log(this.state.src);
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // For company logo crop

  handleFile = (e) => {
    // console.log(e.target.files[0]);
    this.setState({ [e.target.name]: e.target.files[0] }, () => {
      var fileUpload = document.getElementById('uploadFile');

      var regex = /(\.jpg|\.jpeg|\.png|\.PNG)$/i;
      if (regex.test(fileUpload.value.toLowerCase())) {
        if (typeof fileUpload.files != 'undefined') {
          var reader = new FileReader();
          var scope = this;
          reader.readAsDataURL(fileUpload.files[0]);
          reader.onload = function (e) {
            var image = new Image();

            image.src = e.target.result;

            image.onload = function () {
              var height = this.height;
              var width = this.width;
              scope.setState({
                visibleCrop: true,
              });

              // if (height > 600 || width > 800) {
              //   alert("Height and Width must not exceed 800px and 600px.");
              //   return false;
              // }
              scope.setState({ uploadJPG: true });
              return true;
            };
          };
        } else {
          alert('This browser does not support HTML5.');
          return false;
        }
      } else {
        var thisvar = this;
        alert('Please upload file having extensions .jpeg/.jpg/.png/ only.');
        thisvar.setState({ uploadJPG: false });
        return false;
      }
    });
  };

  onImageLoaded = (image) => {
    this.setState({ imageWidth: image.width });
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop }, () => { });
  };

  makeClientCrop = async (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl });
      this.convertUrlToImageData(croppedImageUrl);
      // console.log(this.imageRef);
      // console.log(crop);
      // console.log(croppedImageUrl);
      // this.blobToFile(croppedImageUrl, "my-image.png");
    }
  };

  getBlobFromUrl = (myImageUrl) => {
    return new Promise((resolve, reject) => {
      let request = new XMLHttpRequest();
      request.open('GET', myImageUrl, true);
      request.responseType = 'blob';
      request.onload = () => {
        resolve(request.response);
      };
      request.onerror = reject;
      request.send();
    });
  };

  getDataFromBlob = (myBlob) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(myBlob);
    });
  };

  convertUrlToImageData = async (myImageUrl) => {
    // console.log(myImageUrl);
    try {
      let myBlob = await this.getBlobFromUrl(myImageUrl);
      // console.log(myBlob)
      let myImageData = await this.getDataFromBlob(myBlob);
      // console.log(myImageData)
      this.b64toBlob(myImageData);
      this.dataURLtoFile(myImageData, 'newfile.png');
    } catch (err) {
      // console.log(err);
      return null;
    }
  };

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    var blob = new File([u8arr], filename, { type: mime });
    // console.log(blob);
    this.setState({
      filename: blob,
    });
  }

  blobToFile(theBlob, fileName) {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    // console.log(theBlob);
    // this.convertedFile(theBlob)
  }

  convertedFile(theBlob) {
    var myBlob = new Blob();

    //do stuff here to give the blob some data...

    var myFile = this.blobToFile(theBlob, 'my-image.png');
    // console.log(myFile);
  }

  b64toBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: 'image/jpeg' });
    // console.log(blob);
    this.blobToFile(blob, 'my-image.png');
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    // console.log(pixelRatio);
    const scaleX = image.naturalWidth / image.width;
    // console.log(image.naturalWidth, this.state.imageWidth);

    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      // console.log(canvas);
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error('Canvas is empty');
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve(this.fileUrl);
        },
        'image/png',
        1
      );
    });
  }

  onSelectLogo = (e) => {
    this.setState({
      filename: '',
      src: '',
    });
    // console.log(this.state.visibleCrop);
    // console.log(e.target.files);
    // console.log(e.target.files.length);
    if (e.target.files && e.target.files.length > 0) {
      this.setState(
        {
          visibleCrop: true,
        },
        () => {
          // console.log(this.state.visibleCrop);
        }
      );
      const reader = new FileReader();
      // console.log(reader);
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result }, () => {
          // console.log(this.state.src);
        })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onRemoveFile = () => {
    this.setState({
      removeProductLogo: true,
    });
  };

  removeLogo = () => {
    let pId = document.location.href;
    var url = new URL(pId);
    var productId = url.searchParams.get('id');
    axios.put(API.postUrls.removeProductLogo + productId).then((resp) => {
      // console.log(resp);
      if (resp.data.status === 'success') {
        this.setState({
          removeProductLogo: false,
        });
        this.getSingleProduct();
      }
    });
  };

  saveImage = () => {
    // console.log(this.state.filename);
    fd.append('productLogo', this.state.filename);
    axios
      .put(API.postUrls.addproduct + '/' + this.state.product._id, fd)
      .then((resp) => {
        // console.log(resp);
        if (resp.data.status === 'success') {
          axios.get(API.getUrls.getSingleProduct + productId).then((resp) => {
            // console.log(resp);
            if (resp.data.status === 'success') {
              this.setState({
                visiblity: resp.data.product.visibility,
                productStatus: resp.data.product.status,
              });
              // console.log(this.state.productStatus);
              if (this.state.productStatus === 'draft') {
                this.setState({
                  modalId: productId,
                  embedCode:
                    "<iframe src='" +
                    'https://portal.actuality.live/modelDynamicviewer/' +
                    productId +
                    "' title='some' scrolling='No' height='750px' width='100%' frameborder='0'></iframe>",
                });
              } else {
                this.setState({
                  modalId: productId,
                  embedCode:
                    "<iframe src='" +
                    'https://actuality.live/modelDynamicviewer/' +
                    productId +
                    "' title='some' scrolling='No' height='750px' width='100%' frameborder='0'></iframe>",
                });
              }
            }
          });
          this.setModel();
          this.getSingleProduct();
          this.getComponents();
          this.getUser();
          this.getVariants();
        }
      });
  };

  toggleScrollOnBody = () => {
    const body = document.querySelector('body');
    body.classList.toggle('NO_SCROLLBAR');
  };

  toggleHotspotAddingInstruction = (closeInstruction = false) => {
    if (closeInstruction) {
      this.toggleModelAutoplay();
    }
    this.setState({
      canAddHotspot: true,
      openHotspotInstruction: !!!this.state.openHotspotInstruction,
    });
  };
  closeHotspotInstructions = () => {
    this.setState({ canAddHotspot: false, openHotspotInstruction: false });
  };
  closeEditorDrawer = () => {
    this.toggleScrollOnBody();
    this.toggleModelAutoplay();
    this.setState({
      isEditHotspot: false,
      editHotspot: {},

      canAddHotspot: false,
      openHotspotInstruction: false,
      openHotspotDrawer: false,

      newAnnotPosition: null,
      newAnnotTitle: '',
      newAnnotDescription: '',
      titleIsEmpty: false,
      descriptionIsEmpty: false,
    });
  };

  openHotspotEditorSlider = (newAnnotPosition) => {
    this.toggleScrollOnBody();
    this.setState({
      newAnnotPosition,
      openHotspotDrawer: true,
    });
  };

  closeHotspotContentDrawer = () => {
    this.toggleModelAutoplay();
    this.toggleScrollOnBody();
    this.setState({ showHotspot: { isTrue: false, data: {} } });
    this.annotBtnCloseCB.current();
    this.annotBtnCloseCB.current = null;
  };

  toggleModelAutoplay = () => {
    return;

    if (this.modelRef.current?.paused) this.modelRef.current?.play();
    else this.modelRef.current.pause();
    // this.modelRef.current.toggleAttribute("autoplay");
  };
  canIgnoreDeleteConfirmation = (compId) => {
    let isSecondCompoEmpty = false;
    if (compId && this.state.Components.length == 2) {
      isSecondCompoEmpty = this.state.Components.find(
        ({ _id }) => _id != compId
      );
      isSecondCompoEmpty = isSecondCompoEmpty?.materials.length == 0;
    }
    return (
      !this.state?.Variants?.length ||
      this.state.Components.length <= 1 ||
      !!isSecondCompoEmpty
    );
  };
  toggleShareDialog = () => {
    this.setState({ openShareDialog: !!!this.state.openShareDialog });
  };
  render() {
    const { crop, croppedImageUrl, src, src1, crop1 } = this.state;
    const model = this.state.model;
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: loader,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
    const isDrawerOpen =
      this.state.openHotspotDrawer || this.state.showHotspot?.isTrue;

    const showTooltip =
      this.state.canAddHotspot && !this.state.openHotspotInstruction;

    return (
      <Container
        className='containerSpacingdashBoard md_container_xx'
        maxWidth={'lg'}
        style={{
          position: 'relative',
          overflowX: isDrawerOpen && false ? 'hidden' : 'visible',
          border: '0px solid blue',
          padding: 0,
          margin: 'auto',
          marginTop: '1.5rem',
          marginBottom: '1.5rem',
        }}
      >
        {' '}
        <ProductSharePopUp
          openShareDialog={this.state.openShareDialog}
          handleClose={this.toggleShareDialog}
          productId={this.state.productId}
          language={this.state.language}
          modelSrc={this.state.selectedVariant.model_file_url}
        />
        <Snackbar
          autoHideDuration={2000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={this.state.noVariantAvaliable}
          onClose={() => {
            this.setState({ noVariantAvaliable: false });
          }}
          message='No model available for this combination'
        />
        <VariantsDeleteConfirmation
          visible={this.state.OPEN_ADD_MAT_CONFIRM_MODAL}
          onClickAway={() => {
            this.setState({ OPEN_ADD_MAT_CONFIRM_MODAL: false });
          }}
          handleDelete={() => {
            this.setState({ OPEN_ADD_MAT_CONFIRM_MODAL: false });
            this.openModal();
          }}
        />
        <TSEDialog
          open={this.state.openEdit}
          handleClose={() => this.setState({ openEdit: false })}
        >
          <div
            style={{
              minWidth: 350,
              // border: "2px solid red",
            }}
          >
            <div
              className='modalWrapper'
              style={{ border: '0px solid red', paddingBottom: '1rem' }}
            >
              <h4 style={{ margin: '20px 0', marginTop: 10, fontSize: 20 }}>
                Edit product details
              </h4>
              <form action='' onSubmit={this.updateProduct}>
                <div
                  className='contentWrap'
                  style={{
                    margin: '0 auto',
                  }}
                >
                  <TSEInputLabel label='Product Name' />
                  <input
                    type='text'
                    name='title'
                    className='modalInput'
                    style={{
                      marginBottom: '5px',
                      width: '100%',
                    }}
                    defaultValue={
                      this.state.product ? this.state.product.title : ''
                    }
                    onChange={this.handleInput}
                    required
                  />
                  <TSEInputLabel label='Product Sub Title' />
                  <input
                    type='text'
                    name='sub_title'
                    className='modalInput'
                    style={{ marginBottom: '5px', width: '100%' }}
                    defaultValue={
                      this.state.product ? this.state.product.sub_title : ''
                    }
                    onChange={this.handleInput}
                    required
                  />
                  <TSEInputLabel label='Categories' />
                  {this.state.openEdit && (
                    <TSEMultiSelect
                      options={this.state.categories}
                      value={this.state.catIds}
                      onChange={(data) => {
                        this.setState({ catIds: data });
                      }}
                      labelledBy={'Categories'}
                    />
                  )}
                  <TSEInputLabel label='Select Language' />
                  <select
                    name='language'
                    id=''
                    className='modalInput'
                    style={{ width: '100%' }}
                    value={this.state.language}
                    onChange={this.handleInput}
                  >
                    <option value='English'>English</option>
                    <option value='French'>French</option>
                  </select>
                  <TSEInputLabel label='Upload Document' />
                  <Button
                    variant='outlined'
                    size='medium'
                    className='uploadbtn'
                    component='label'
                    style={{
                      border: '1px solid #bdbdbd',
                      width: '100%',
                      textTransform: 'capitalize',
                      background: '#bdbdbd73',
                      marginBottom: '5px',
                    }}
                  >
                    Upload Image
                    <input
                      type='file'
                      onChange={this.onSelectFile}
                      name='filename'
                      id='uploadFile'
                      hidden
                    />
                  </Button>
                  <PlacementRadio
                    propState={this.state}
                    alignItems='flex-end'
                    onChange={(placement) => this.setState({ placement })}
                  />

                  {this.state.product.is_protected ? (
                    <PasscodeChange
                      passcodeMsg={this.state.passcodeMsg}
                      passcode={this.state?.passcode || ''}
                      setPasscode={(passcode) => this.setState({ passcode })}
                      prevPasscode={this.state?.prevPasscode || ''}
                      setPrevPasscode={(prevPasscode) =>
                        this.setState({ prevPasscode })
                      }
                      className='modalInput'
                      setDeletePasscode={() => {
                        this.setState((pre) => ({
                          ...pre,
                          deletePasscode: true,
                          changePasscode: false,
                        }));
                      }}
                      setChangePasscode={() => {
                        this.setState((pre) => ({
                          ...pre,
                          changePasscode: true,
                          deletePasscode: false,
                        }));
                      }}
                      setNonePasscode={() => {
                        this.setState((pre) => ({
                          ...pre,
                          deletePasscode: false,
                          changePasscode: false,
                        }));
                      }}
                    />
                  ) : (
                    <Passcode
                      isProtected={this.state.is_protected}
                      toggleIsProtected={() => {
                        this.setState((pre) => {
                          // console.log("is ", !!!pre?.is_protected);
                          return {
                            ...pre,
                            is_protected: !!!pre?.is_protected,
                          };
                        });
                      }}
                      passcode={this.state?.passcode || ''}
                      setPasscode={(passcode) => this.setState({ passcode })}
                      className='modalInput'
                    />
                  )}
                  <Button
                    className='addBtn'
                    type='submit'
                    style={{
                      width: '100%',
                      marginLeft: '0px',
                      marginTop: 10,
                      marginBottom: 20,
                    }}
                  >
                    {this.state.loading ? (
                      <CircularProgress color={'white'} size={25} />
                    ) : (
                      'Update'
                    )}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </TSEDialog>
        <Modal
          visible={this.state.openEdit && false}
          width='350'
          height='720'
          effect='fadeInUp'
          onClickAway={() => this.closeModal()}
        >
          <div
            className='modalWrapper'
            style={{ border: '0px solid red', paddingBottom: '1rem' }}
          >
            <h4>Edit product details</h4>
            <form action='' onSubmit={this.updateProduct}>
              {/* <Typography
                style={{
                  margin: 0,
                }}
              >
                {this.state.filename ? "File updated" : null}
              </Typography> */}
              <div
                className='contentWrap'
                style={{
                  margin: '0 auto',
                }}
              >
                <TSEInputLabel label='Product Name' />
                <input
                  type='text'
                  name='title'
                  className='modalInput'
                  style={{ marginBottom: '5px' }}
                  defaultValue={
                    this.state.product ? this.state.product.title : ''
                  }
                  onChange={this.handleInput}
                  required
                />
                <TSEInputLabel label='Product Sub Title' />
                <input
                  type='text'
                  name='sub_title'
                  className='modalInput'
                  style={{ marginBottom: '5px' }}
                  defaultValue={
                    this.state.product ? this.state.product.sub_title : ''
                  }
                  onChange={this.handleInput}
                  required
                />
                <TSEInputLabel label='Categories' />
                {this.state.openEdit && (
                  <TSEMultiSelect
                    options={this.state.categories}
                    value={this.state.catIds}
                    onChange={(data) => {
                      this.setState({ catIds: data });
                    }}
                    labelledBy={'Categories'}
                  />
                )}
                <TSEInputLabel label='Select Language' />
                <select
                  name='language'
                  id=''
                  className='modalInput'
                  style={{ width: '100%' }}
                  value={this.state.language}
                  onChange={this.handleInput}
                >
                  <option value='English'>English</option>
                  <option value='French'>French</option>
                </select>
                <TSEInputLabel label='Upload Document' />
                <Button
                  variant='outlined'
                  size='medium'
                  className='uploadbtn'
                  component='label'
                  style={{
                    border: '1px solid #bdbdbd',
                    width: '100%',
                    textTransform: 'capitalize',
                    background: '#bdbdbd73',
                    marginBottom: '5px',
                  }}
                >
                  Upload Image
                  <input
                    type='file'
                    onChange={this.onSelectFile}
                    name='filename'
                    id='uploadFile'
                    hidden
                  />
                </Button>
                <PlacementRadio
                  propState={this.state}
                  alignItems='flex-end'
                  onChange={(placement) => this.setState({ placement })}
                />

                {this.state.product.is_protected ? (
                  <PasscodeChange
                    passcodeMsg={this.state.passcodeMsg}
                    passcode={this.state?.passcode || ''}
                    setPasscode={(passcode) => this.setState({ passcode })}
                    prevPasscode={this.state?.prevPasscode || ''}
                    setPrevPasscode={(prevPasscode) =>
                      this.setState({ prevPasscode })
                    }
                    className='modalInput'
                    setDeletePasscode={() => {
                      this.setState((pre) => ({
                        ...pre,
                        deletePasscode: true,
                        changePasscode: false,
                      }));
                    }}
                    setChangePasscode={() => {
                      this.setState((pre) => ({
                        ...pre,
                        changePasscode: true,
                        deletePasscode: false,
                      }));
                    }}
                    setNonePasscode={() => {
                      this.setState((pre) => ({
                        ...pre,
                        deletePasscode: false,
                        changePasscode: false,
                      }));
                    }}
                  />
                ) : (
                  <Passcode
                    isProtected={this.state.is_protected}
                    toggleIsProtected={() => {
                      this.setState((pre) => {
                        // console.log("is ", !!!pre?.is_protected);
                        return {
                          ...pre,
                          is_protected: !!!pre?.is_protected,
                        };
                      });
                    }}
                    passcode={this.state?.passcode || ''}
                    setPasscode={(passcode) => this.setState({ passcode })}
                    className='modalInput'
                  />
                )}
                <Button
                  className='addBtn'
                  type='submit'
                  style={{ width: '100%', marginLeft: '0px', marginTop: 10 }}
                >
                  {this.state.loading ? (
                    <CircularProgress color={'white'} size={25} />
                  ) : (
                    'Update'
                  )}
                </Button>
              </div>
            </form>
          </div>
        </Modal>
        <Drawer
          open={true && this.state.openHotspotDrawer}
          onClose={() => this.closeEditorDrawer()}
          style={{
            height: '100vh',
            maxHeight: '100vh',
            left: '60%',
            border: '0px solid red',
            position: 'fixed',
            // paddingRight: "0.5rem",
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',

              height: '100%',
              maxHeight: '100%',
              overflow: 'hidden',
              border: '0px solid green',
              paddingRight: '1.5rem',
              paddingLeft: '0.5rem',
            }}
          >
            <div
              style={{
                fontSize: '1.5rem',
                fontWeight: 400,
              }}
            >
              Title
            </div>
            <div
              style={{
                width: '100%',
                paddingRight: '0.5rem',
                marginTop: '0.5rem',
                border: '0px solid black',
                display: 'flex',
              }}
            >
              <input
                style={{
                  width: '100%',
                  fontSize: '1.5rem',
                  padding: '0.8rem',
                  border: '1px solid #ddd',
                  outline: 'none',
                  display: 'block',
                  borderRadius: '0.3rem',
                }}
                value={this.state.newAnnotTitle}
                onChange={(e) => {
                  this.setState({ newAnnotTitle: e.target.value });
                }}
              />
            </div>
            <div
              style={{
                color: 'red',
                fontSize: 16,
                padding: '0.5rem 0',
              }}
            >
              {this.state.titleIsEmpty ? 'Title is required' : ''}
            </div>
            <div
              style={{
                fontSize: '1.5rem',
                fontWeight: 400,
              }}
            >
              Description
            </div>
            <div
              style={{
                color: 'red',
                fontSize: 16,
                padding: '0.5rem 0',
              }}
            >
              {this.state.descriptionIsEmpty ? 'Description is required' : ''}
            </div>
            <div
              style={{
                width: '100%',
                paddingRight: '0.3rem',
                margin: '0.5rem 0',
                flexGrow: 1,
                border: '0px solid blue',
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  right: 0,
                  left: 0,
                  border: '0px solid red',
                }}
              >
                <SunEditor
                  value={this.state.newAnnotDescription}
                  setValue={(e) => {
                    this.setState({ newAnnotDescription: e });
                  }}
                />
              </div>
              {/* <SunEditor
                value={this.state.newAnnotDescription}
                setValue={(e) => {
                  this.setState({ newAnnotDescription: e });
                }}
              /> */}
            </div>
            {/* <div
              style={{
                border: "0px solid aqua",

                boxShadow: `1px 2px 5px rgba(0, 0, 0, 0.1)`,
                borderRadius: 15,
                padding: "2rem 1rem",
              }}
            >
              <input
                className="titleInput"
                placeholder="Title"
                style={{
                  border: "0px solid red",
                  minWidth: "100%",
                }}
                value={this.state.newAnnotTitle}
                onChange={(e) => {
                  this.setState({ newAnnotTitle: e.target.value });
                }}
              />
            </div> */}
            {/* <div
              style={{
                color: "red",
                fontSize: 16,
                padding: "0.5rem",
                paddingLeft: "1rem",
              }}
            >
              {this.state.titleIsEmpty ? "Title is required" : ""}
            </div> */}
            {/* <div
              style={{
                flexGrow: 1,
                boxShadow: `1px 2px 5px rgba(0, 0, 0, 0.1)`,
                border: "0px solid cyan",
                maxWidth: "100%",
                width: "100%",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
              }}
            >
              <div
                style={{
                  fontSize: 24,
                  fontWeight: 500,
                  padding: "0.5rem",
                  paddingLeft: "1rem",
                }}
              >
                Content
              </div>
              <div
                style={{
                  color: "red",
                  fontSize: 16,
                  padding: "0.5rem",
                  paddingLeft: "1rem",
                  margin: this.state.descriptionIsEmpty ? "0.5rem 0" : 0,
                }}
              >
                {this.state.descriptionIsEmpty ? "Content is required" : ""}
              </div>
              <div
                style={{
                  flexGrow: 1,
                  border: "0px solid black",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                <SunEditor
                  value={this.state.newAnnotDescription}
                  setValue={(e) => {
                    this.setState({ newAnnotDescription: e });
                  }}
                />
              </div>
            </div> */}
            {/* Buttons save and cancel */}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                // padding: "1rem 0",
                margin: '1rem 0',
                border: '0px solid red',
              }}
            >
              <button
                className='btnCommonStyles hotspotBtn'
                onClick={() => this.closeEditorDrawer()}
                style={{
                  padding: '0.5rem',
                }}
              >
                Cancel
              </button>
              <div
                style={{
                  width: 15,
                }}
              />

              <button
                className='btnCommonStyles hotspotBtn'
                onClick={() => this.uploadAnnot()}
                style={{
                  padding: '0.5rem',
                }}
              >
                Save
              </button>
            </div>
            <div
              style={{
                width: '100%',
                height: '2rem',
                border: '0px solid red',
              }}
            />
          </div>
        </Drawer>
        <HotspotContentDrawer
          style={{
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            height: '100vh',
            maxHeight: '100vh',
          }}
          open={this.state.showHotspot.isTrue}
          onClose={this.closeHotspotContentDrawer}
          title={this.state.showHotspot.data.title}
          description={this.state.showHotspot.data.description}
        />
        <div
          className='modelViewer editWrapper'
          style={{
            margin: 0,
            border: '0px solid red',
          }}
        >
          {this.state.notification ? (
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              open={true}
              style={{ bottom: '40px' }}
              color={'#bdbdbd'}
              autoHideDuration={6000}
              message={this.state.successMsg}
            />
          ) : null}
          <Grid
            container
            className='editWrapper'
            style={{
              margin: 0,
            }}
          >
            <Grid
              item
              sm={9}
              style={{
                height: window.innerWidth < 1100 ? '400px' : '100vh',
                position: 'relative',
                // border: "1px solid blue",
                margin: 0,
              }}
              ref={this.modelBlockRef}
            >
              <div
                style={{
                  width: this.state.modelBlockWidth,

                  // height: 100,
                  // border: this.state.fixModelBlock
                  //   ? "1px solid aqua"
                  //   : "1px solid red",
                  position: 'fixed',
                  maxHeight: this.state.fixModelBlock
                    ? '100vh'
                    : `calc(100vh-${this.state.modelBlockTop}px)`,
                  height: '100vh',
                  top: this.state.fixModelBlock ? 0 : this.state.modelBlockTop,
                }}
              >
                <div
                  className={
                    this.state.openHotspotInstruction
                      ? 'openHotspotInstruction show'
                      : 'openHotspotInstruction'
                  }
                  style={{
                    border: '0px solid blue',
                    zIndex: this.state.openHotspotInstruction
                      ? 2147483645
                      : 'auto',
                  }}
                  onClick={() => {
                    if (this.state.openHotspotInstruction) {
                      // alert("outermost");
                      this.closeHotspotInstructions(true);
                    }
                  }}
                >
                  <div
                    onClick={(e) => {
                      e?.stopPropagation?.();
                      e?.preventDefault?.();
                      // alert("box");
                    }}
                    className={
                      this.state.openHotspotInstruction
                        ? 'hotspotInstructions show'
                        : 'hotspotInstructions'
                    }
                    style={{
                      boxShadow: '1px 2px 5px rgba(0,0,0,0.1)',
                      borderRadius: 18,
                      background: '#fff',
                      border: '1px solid #ddd',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      padding: '1.6rem 5rem',
                      marginBottom: '1.5rem',
                      boxSizing: 'border-box',
                      zIndex: 2147483645,
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        padding: 10,
                        boxSizing: 'border-box',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        paddingRight: 0,
                        zIndex: 2147483645,
                      }}
                    >
                      <div
                        style={{
                          border: '1px solid #ddd',
                          borderRadius: '50%',
                          transform: 'translateX(100%)',
                        }}
                      >
                        <IconButton
                          style={{
                            zIndex: 999999,
                            border: '0px solid red',
                          }}
                          onClick={() => {
                            // alert("cencel");
                            this.closeHotspotInstructions(true);
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: 32,
                        fontWeight: 500,
                        color: '#555555',
                        marginBottom: '1.5rem',
                      }}
                    >
                      How to add hotspots
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        margin: '2rem 0',
                        border: '0px solid red',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          marginRight: '1rem',
                          border: '0px solid green',
                        }}
                      >
                        <img src={leftClick} width={100} height={110} />

                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginTop: '1.5rem',
                            border: '0px solid aqua',
                          }}
                        >
                          <div style={{ fontSize: '1.2rem', color: '#555555' }}>
                            Rotate the object 360
                            <span>&#176;</span>
                            {` `}
                            by
                          </div>
                          <div style={{ fontSize: '1.2rem', color: '#555555' }}>
                            holding click and dragging
                          </div>
                          <div style={{ fontSize: '1.2rem', color: '#555555' }}>
                            your mouse
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginLeft: '1rem',
                          alignItems: 'center',
                          border: '0px solid green',
                        }}
                      >
                        <img src={rightClick} width={100} height={110} />
                        <div
                          style={{
                            width: '235px',
                            marginTop: '1.5rem',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            border: '0px solid aqua',
                          }}
                        >
                          <div style={{ fontSize: '1.2rem', color: '#555555' }}>
                            Right click at any point on
                          </div>
                          <div style={{ fontSize: '1.2rem', color: '#555555' }}>
                            the 3D model to add
                          </div>
                          <div style={{ fontSize: '1.2rem', color: '#555555' }}>
                            hotspot
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={() => this.toggleHotspotAddingInstruction(true)}
                      className='btnCommonStyles'
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '1rem 6rem',
                        lineHeight: 1,
                        fontSize: 20,
                        borderRadius: '0.6rem',
                        marginBottom: '2rem',
                        marginTop: '2rem',
                        fontWeight: 600,
                        color: '#000',
                      }}
                    >
                      Got It
                    </button>
                    <img src={actualityLogoMobile} height={16} />
                  </div>
                </div>
                <model-viewer
                  onMouseUp={(e) => { }}
                  autoplay
                  ref={(elem) => {
                    this.modelRef.current = elem;
                  }}
                  onContextMenu={(e) => {
                    e.preventDefault();
                    if (!this.state.canAddHotspot) return;
                    const annotCords =
                      this.modelRef.current.positionAndNormalFromPoint(
                        e.clientX,
                        e.clientY
                      );
                    if (!annotCords) return;
                    const cameraTarget = this.modelRef.current
                      .getCameraTarget()
                      .toString();
                    const cameraOrbit = this.modelRef.current
                      .getCameraOrbit()
                      .toString();
                    if (!cameraOrbit || !cameraTarget) return;

                    const dataPosition = `${annotCords.position.x} ${annotCords.position.y} ${annotCords.position.z}`;
                    const dataNormal = `${annotCords.normal.x} ${annotCords.normal.y} ${annotCords.normal.z}`;

                    const newAnnotPosition = {
                      cameraTarget,
                      cameraOrbit,
                      dataNormal,
                      dataPosition,
                    };
                    scrollToTop();
                    this.openHotspotEditorSlider(newAnnotPosition);
                  }}
                  exposure={`${this.state.selectedVariant?.exposure || 1}`}
                  scale='1'
                  src={API.imgUrl + this.state.selectedVariant.model_file_url}
                  shadow-intensity={`${this.state.selectedVariant?.shadow_intensity || 0
                    }`}
                  camera-controls
                  ar
                  ar-modes='webxr scene-viewer quick-look'
                  ar-placement={'floor'}
                  style={{
                    width: '100%',
                    height: '100%',
                    zIndex: this.state.openHotspotInstruction ? 0 : 'auto',
                  }}
                >
                  {this.state?.newAnnotPosition?.dataPosition ? (
                    <NewAnnotPositionPlus
                      dataPosition={this?.state?.newAnnotPosition?.dataPosition}
                      dataNormal={this.state.newAnnotPosition?.dataNormal}
                      title='newTitle'
                    />
                  ) : null}
                  <AnnotationBtns
                    annots={this.state?.selectedVariant?.hotspots || []}
                    openHotspotDrawer={(annot, cb) => {
                      this.closeEditorDrawer();
                      this.annotBtnCloseCB?.current?.();

                      const tempFunc = () => {
                        // alert("ok");
                        this.annotBtnCloseCB.current = cb;
                        this.modelRef.current.setAttribute(
                          'camera-target',
                          annot.cameraTarget
                        );
                        this.modelRef.current.setAttribute(
                          'camera-orbit',
                          annot.cameraOrbit
                        );
                        // this.modelRef.current.setAttribute("zoom", annot?.zoom||12);
                        this.setState({
                          showHotspot: {
                            isTrue: true,
                            _id: annot._id,
                            data: {
                              title: annot.title,
                              description: annot.description,
                            },
                          },
                        });
                      };
                      if (this.annotBtnCloseCB.current) {
                        setTimeout(tempFunc, 0);
                      } else tempFunc();
                    }}
                  />

                  <div className='uploadLogoPlace'>
                    {this.state.product.product_logo ? (
                      <img
                        src={
                          this.state.product.product_logo
                            ? API.imgUrl + this.state.product.product_logo
                            : ''
                        }
                        alt=''
                        style={{
                          width: '100%',
                          height: '100%',
                          borderRadius: '15px',
                        }}
                      />
                    ) : (
                      ''
                    )}
                    <Button
                      variant='outlined'
                      size='medium'
                      className='profilePic'
                      component='label'
                      style={{
                        border: 'none',
                        width: '50px',
                        position: 'absolute',
                        right: '-23%',
                        top: '-2%',
                      }}
                    >
                      {this.state.product.product_logo ? (
                        <>
                          <img
                            src={remove}
                            width='15px'
                            height='15px'
                            alt=''
                            className='prodLogo'
                            onClick={this.onRemoveFile}
                          />
                        </>
                      ) : (
                        <>
                          <img
                            src={edit}
                            width='15px'
                            height='15px'
                            alt=''
                            className='prodLogo'
                          />
                          <input
                            type='file'
                            onChange={this.onSelectLogo}
                            name='profile_pic'
                            hidden
                          />
                        </>
                      )}
                    </Button>
                  </div>

                  <div
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                    slot='poster'
                  >
                    <Lottie
                      options={defaultOptions}
                      height={100}
                      width={100}
                      autoplay
                      loop
                    />
                    <h2 style={{ textAlign: 'center' }}>Loading...</h2>
                  </div>
                  <div className='iconWrapper'>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <div
                        className='text-center'
                        style={{ marginRight: 12, height: '100%' }}
                      >
                        <img src={rotate} alt='' />
                      </div>
                      <div
                        className='text-center'
                        style={{ marginRight: 4, height: '100%' }}
                      >
                        <img src={zoom} width={'32px'} alt='' />
                      </div>
                    </div>

                    <div>
                      <div
                        className='shareBtn'
                        onClick={() => {
                          // alert("dnhkj");
                          this.toggleShareDialog();
                          // this.setState({ openShareModal: true });
                        }}
                      >
                        <img src={share} height={'24px'} alt='' />
                      </div>
                    </div>
                  </div>
                </model-viewer>
              </div>
            </Grid>

            <Grid
              item
              sm={3}
              style={{
                border: '0px solid aqua',
                padding: '0.5rem',
                paddingTop: 0,
                height: isDrawerOpen && false ? '100vh' : 'auto',
                overflow: isDrawerOpen ? 'hidden' : 'visible',
              }}
            >
              <div
                className='editRightWrapper'
                style={{ border: '0px solid black', margin: 0 }}
                ref={this.rightBoxRef}
              >
                <TopRightBoxes
                  product={this.state.product}
                  openEditModal={() => {
                    this.openEditModal();
                  }}
                  enableBuyNow={() => {
                    this.setState({ openBuyNow: true });
                  }}
                  handleVisiblity={() => {
                    this.setState(
                      {
                        visiblity: !!!this.state.visiblity,
                        visiblityoff: !!!this.state.visiblityoff,
                      },
                      () => {
                        this.addVisibilty(this.state.visiblity);
                      }
                    );
                  }}
                  propState={this.state}
                />
                {/* <HotspotContainer
                  toggleHotspotAddingInstruction={() => {
                    this.toggleHotspotAddingInstruction();
                    // scrollToTop();
                  }}
                  propState={this.state}
                  onClickEdit={(title, description, hotspot) => {
                    this.setState({
                      openHotspotDrawer: true,
                      newAnnotTitle: title,
                      newAnnotDescription: description,
                      isEditHotspot: true,
                      editHotspot: hotspot,
                    });
                  }}
                  uploadAnnot={this.uploadAnnot}
                /> */}
                {/* Components Mapping */}
                <div
                  className='addComp'
                  onClick={() =>
                    this.setState({
                      ShowComponent: true,
                      showFirst: false,
                      showSecond: false,
                      showThird: false,
                      showFourth: false,
                      visible: true,
                    })
                  }
                  style={{
                    cursor: 'pointer',
                    marginBottom: 10,
                  }}
                >
                  <div
                    className='inlineWrapper'
                    style={{
                      borderRadius: 'inherit',
                    }}
                  >
                    {this.state.Variants.length > 0 && false ? (
                      <Typography
                        className='text'
                        onClick={() =>
                          this.setState({
                            confirmationComponentAdd: true,
                          })
                        }
                      >
                        Add Component
                      </Typography>
                    ) : (
                      <Typography
                        className='text'
                        onClick={() =>
                          this.setState({
                            ShowComponent: true,
                            showFirst: false,
                            showSecond: false,
                            showThird: false,
                            showFourth: false,
                            visible: true,
                          })
                        }
                      >
                        Add Component
                      </Typography>
                    )}
                    <AddCircleOutlineSharpIcon
                      style={{
                        position: 'absolute',
                        right: '23px',
                        top: '15px',
                      }}
                    />
                  </div>
                </div>
                {this.state.canAddHotspot
                  ? this.state.Components
                    ? this.state.Components.map((comp, index) => (
                      <div
                        key={comp._id}
                        className='box1'
                        style={{
                          height: this.state.activeComponents.includes(
                            comp._id
                          )
                            ? '226px'
                            : '38px',
                          overflowY: this.state.activeComponents.includes(
                            comp._id
                          )
                            ? 'auto'
                            : '',
                          position: 'relative',
                          transitionDuration: '222ms',
                        }}
                      >
                        <ExpandMoreRoundedIcon
                          className='downArrow'
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            this.selectedComponents(comp._id);

                            this.setState({
                              showMaterials:
                                this.state.activeComponents.includes(comp._id)
                                  ? true
                                  : false,
                            });
                          }}
                        />

                        <div
                          className='inlineWrapper'
                          style={{ marginLeft: '10px', cursor: 'pointer' }}
                        >
                          <Typography
                            className='buyNow'
                            style={{
                              cursor: 'pointer',
                              fontWeight: '500',
                              textTransform: 'capitalize',
                              color: '#2E2E2E',
                              fontSize: 18,
                            }}
                          >
                            {comp.component_name}
                          </Typography>
                        </div>
                        <br />
                        {this.state.activeComponents.includes(comp._id) ? (
                          <div
                            className='inlineWrapper'
                            style={{
                              marginLeft: '3px',
                            }}
                          >
                            {comp
                              ? comp.materials.length > 0
                                ? comp.materials.map((singleMat) => (
                                  <div
                                    key={singleMat._id}
                                    style={{
                                      border: '0px solid red',
                                    }}
                                    onClick={() =>
                                      this.onMaterialClick(
                                        comp._id,
                                        singleMat._id
                                      )
                                    }
                                  >
                                    {singleMat ? (
                                      singleMat.material_type ? (
                                        <div
                                          className='smallBox'
                                          style={{
                                            position: 'relative',
                                          }}
                                        >
                                          {!this.isSelected(
                                            comp._id,
                                            singleMat._id
                                          ) ? null : (
                                            <div
                                              style={{
                                                position: 'absolute',
                                                top: 0,
                                                right: 0,
                                                bottom: 0,
                                                left: 0,
                                                display: 'grid',
                                                placeItems: 'center',
                                                zIndex: 1000,
                                              }}
                                            >
                                              <Check
                                                size='large'
                                                style={{
                                                  color: '#000',
                                                }}
                                              />
                                            </div>
                                          )}
                                          {singleMat.material_type ===
                                            'color' ? (
                                            <div
                                              onClick={() => { }}
                                              style={{
                                                zIndex: 999,
                                                backgroundColor:
                                                  singleMat.material_value,
                                                position: 'absolute',
                                                top: 0,
                                                right: 0,
                                                bottom: 0,
                                                left: 0,
                                                cursor: 'pointer',
                                                border: '0px solid green',
                                                borderRadius: 5,
                                              }}
                                            />
                                          ) : (
                                            <img
                                              style={{
                                                background: 'absolute',
                                                position: 'relative',
                                                top: 0,
                                                right: 0,
                                                bottom: 0,
                                                left: 0,
                                                borderRadius: 5,
                                              }}
                                              src={
                                                API.imgUrl +
                                                singleMat.material_value
                                              }
                                              width='50'
                                              height='50'
                                              alt=''
                                              onClick={() => {
                                                this.setState({
                                                  // materialEdit: true,
                                                  material_value:
                                                    singleMat.material_value,
                                                  material_name:
                                                    singleMat.material_name,
                                                  material_id:
                                                    singleMat._id,
                                                  compId: comp._id,
                                                });
                                              }}
                                            />
                                          )}
                                        </div>
                                      ) : null
                                    ) : null}
                                    <div
                                      style={{
                                        inlineSize: '60px',
                                        overflowWrap: 'break-word',
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize:
                                            window.innerWidth < 1100
                                              ? '12px'
                                              : '14px',
                                          fontFamily: 'Inter',
                                          textAlign: 'center',
                                          cursor: 'pointer',
                                          textTransform: 'capitalize',
                                          paddingLeft: '0px',
                                          marginTop:
                                            singleMat.material_type ===
                                              'color'
                                              ? '-9px'
                                              : '6px',
                                          color: '#565656',
                                          color: '#565656',
                                        }}
                                      >
                                        {singleMat
                                          ? singleMat.material_name
                                          : null}
                                      </p>
                                    </div>
                                  </div>
                                ))
                                : null
                              : null}

                            <br />
                          </div>
                        ) : null}
                      </div>
                    ))
                    : null
                  : this.state.Components
                    ? this.state.Components.map((comp, index) => (
                      <div
                        key={comp._id}
                        className='box1'
                        style={{
                          width: '100%',
                          height: this.state.activeComponents.includes(comp._id)
                            ? '226px'
                            : '38px',
                          overflowY: this.state.activeComponents.includes(
                            comp._id
                          )
                            ? 'auto'
                            : '',
                          position: 'relative',
                          transitionDuration: '222ms',
                          paddingTop: '15px',
                        }}
                      >
                        <ExpandMoreRoundedIcon
                          className='downArrow'
                          style={{
                            cursor: 'pointer',
                            transform: `rotate(${this.state.activeComponents.includes(comp._id)
                              ? '180deg'
                              : '0deg'
                              })`,
                          }}
                          onClick={() => {
                            this.selectedComponents(comp._id);

                            this.setState({
                              showMaterials:
                                this.state.activeComponents.includes(comp._id)
                                  ? true
                                  : false,
                            });
                          }}
                        />
                        <CloseIcon
                          onClick={() => {
                            this.setState({
                              confirmationComponentDelete: true,
                              deleteCompId: comp._id,
                              isThisHaveMultipleMaterials:
                                !!comp.materials.length,
                            });
                            // this.deleteComponent(comp._id);
                          }}
                          style={{
                            color: '#bdbdbd',
                            fontSize: '18px',
                            position: 'absolute',
                            right: '40px',
                            top: '17px',
                            cursor: 'pointer',
                          }}
                        />
                        <div
                          className='inlineWrapper'
                          style={{
                            marginLeft: '10px',
                            cursor: 'pointer',
                            // border: "1px solid blue",
                            paddingLeft: 0,
                          }}
                        >
                          <Typography
                            className='buyNow'
                            style={{
                              cursor: 'pointer',
                              fontWeight: 600,
                              border: '0px solid red',
                              marginLeft: 0,
                              textTransform: 'capitalize',
                              color: '#2E2E2E',
                              fontSize: 16 || 18,
                            }}
                          >
                            {comp.component_name}
                          </Typography>
                          <ComponentEdit
                            component_name={comp.component_name}
                            compId={comp._id}
                            product={this.state.productId}
                            allComponentTitles={this.state.allComponentTitles}
                            postUpdateCB={() => this.getComponents()}
                          />
                          {/* <EditOutlinedIcon
                            style={{
                              marginLeft: "10px",
                              marginTop: "16px",
                              cursor: "pointer",
                              position: "absolute",
                              right: window.innerWidth < 1000 ? "56px" : "64px",
                              top: "2px",
                              fontSize: "16px",
                              color: "#bdbdbd",
                            }}
                            onClick={() =>
                              this.setState({
                                compId: comp._id,
                                compnentEdit: true,
                                component_name: comp.component_name,
                              })
                            }
                          /> */}
                        </div>
                        <br />
                        {this.state.activeComponents.includes(comp._id) ? (
                          <div
                            className='inlineWrapper'
                            style={{ marginLeft: '15px', marginTop: 8 }}
                          >
                            {comp
                              ? comp.materials.length > 0
                                ? comp.materials.map((singleMat) => (
                                  <ColorMetarial
                                    onMaterialClick={() =>
                                      this.onMaterialClick(
                                        comp._id,
                                        singleMat._id
                                      )
                                    }
                                    isSelected={() =>
                                      this.isSelected(comp._id, singleMat._id)
                                    }
                                    material={singleMat}
                                    handleEdit={() => {
                                      this.setState({
                                        materialEdit: true,
                                        materialEditObject: {
                                          material_value:
                                            singleMat.material_value,
                                          material_name:
                                            singleMat.material_name,
                                          material_id: singleMat._id,
                                          compId: comp._id,
                                          material_type:
                                            singleMat.material_type,

                                          deleteMatId: singleMat._id,
                                          isAvailable:
                                            singleMat.is_variant_made,
                                        },
                                      });
                                    }}
                                  />
                                ))
                                : null
                              : null}

                            <br />

                            <div
                              className='secondBox'
                              style={{
                                cursor: 'pointer',
                                border: '0px solid red',
                                marginLeft: 0,
                                marginRight: 0,
                              }}
                              onClick={() =>
                                this.setState(
                                  {
                                    compId: comp._id,
                                    OPEN_ADD_MAT_CONFIRM_MODAL:
                                      this.state.Variants.length &&
                                      comp.materials.length == 0,
                                    // this.canIgnoreDeleteConfirmation(comp._id)
                                    //   ? false
                                    //   : true,
                                    ShowComponent: false,
                                  },
                                  () => {
                                    if (
                                      !this.state.Variants.length ||
                                      comp.materials.length
                                      // this.canIgnoreDeleteConfirmation(comp._id)
                                    )
                                      this.openModal();
                                  }
                                )
                              }
                            >
                              <AddIcon
                                style={{
                                  marginTop: '14px',
                                  color: 'gray',
                                  cursor: 'pointer',
                                }}
                              />

                              <Typography
                                className='default'
                                style={{
                                  marginLeft: '0px',
                                  width: '55px',
                                  lineHeight: '19px',
                                }}
                              >
                                Add Material
                              </Typography>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ))
                    : null}
                {/* Duplicate */}
                {!this?.state?.product?.title ? null : (
                  <RecommendProduct
                    productId={url.searchParams.get('id')}
                    parentProduct={this.state.product._id}
                  />
                )}

                {/* add hotspot */}
                <HotspotContainer
                  toggleHotspotAddingInstruction={() => {
                    this.toggleHotspotAddingInstruction();
                    // scrollToTop();
                  }}
                  propState={this.state}
                  onClickEdit={(title, description, hotspot) => {
                    this.setState({
                      openHotspotDrawer: true,
                      newAnnotTitle: title,
                      newAnnotDescription: description,
                      isEditHotspot: true,
                      editHotspot: hotspot,
                    });
                  }}
                  uploadAnnot={this.uploadAnnot}
                />

                {/* xjkas */}

                <div className='addComp'>
                  <span>
                    <Typography className='text'>
                      Status:
                      <FormControl
                        variant='standard'
                        sx={{ m: 1, minWidth: 120 }}
                        style={{
                          position: 'relative',
                          top: '14px',
                          left: '15px',
                          width: '80px',
                        }}
                      >
                        <NativeSelect
                          defaultValue='draft'
                          inputProps={{
                            name: 'status',
                            id: 'uncontrolled-native',
                          }}
                          value={this.state.statusProduct}
                          onChange={(e) =>
                            this.setState(
                              {
                                selectVal: e.target.value,
                              },
                              () =>
                                this.changeProductStatus(this.state.selectVal)
                            )
                          }
                          label='Status'
                        >
                          <option
                            value='draft'
                            selected={
                              this.state.statusProduct === 'draft'
                                ? true
                                : false
                            }
                          >
                            Draft
                          </option>
                          <option
                            value='published'
                            selected={
                              this.state.statusProduct === 'published'
                                ? true
                                : false
                            }
                          >
                            Live
                          </option>
                        </NativeSelect>
                      </FormControl>
                    </Typography>
                  </span>
                </div>
                <div className='addComp' style={{ display: 'inline-flex' }}>
                  <img
                    src={language}
                    width={'20px'}
                    alt=''
                    style={{ width: '20px', height: '20px', marginTop: '18px' }}
                  />
                  <select
                    name='language'
                    id=''
                    className='languageBtn'
                    value={this.state.language}
                    onChange={(e) => {
                      this.setState({ language: e.target.value }, () => {
                        this.updateProduct(e);
                      });
                    }}
                  >
                    <option value='English' className='langOption'>
                      English
                    </option>
                    <option value='French' className='langOption'>
                      French
                    </option>
                  </select>
                </div>
                <div
                  style={{
                    height: this.state.bottomMostBoxHeight,
                    background: 'white',
                  }}
                />
                <Grid
                  container
                  style={{
                    background: 'white',
                  }}
                >
                  <Grid
                    item={12}
                    style={{
                      position: 'relative',
                      background: 'white',
                    }}
                  >
                    <div
                      style={{
                        position: 'fixed',
                        bottom: 0,
                        // border: "1px solid red",
                        width: this.state.rightBoxWidth - 10,
                        background: 'white',
                        // boxShadow: `10px 10px 282px 37px rgba(255,255,255,1)`,
                        // border: "2px solid red",
                        paddingBottom: 10,
                        paddingRight: 40,
                        // zIndex: 9999,
                      }}
                      ref={this.bottomMostBox}
                    >
                      <Button
                        className='saveBtn'
                        type='submit'
                        style={{
                          background: '#E1E1E1',
                          color: '#757575',
                          width: '109%',
                          position: 'relative',
                          fontSize: '24px',
                          border: '0px solid red',
                        }}
                        onClick={() => this.openInnewWindow()}
                      >
                        Preview
                        <RemoveRedEyeOutlinedIcon
                          style={{
                            color: 'black',
                            marginLeft: '20px',
                            position: 'absolute',
                            right: '24px',
                          }}
                        />
                      </Button>
                      {this.state.productStatus === 'published' ? (
                        <span
                          // onClick={this.publishedProduct}
                          onClick={this.publish}
                          style={{
                            textDecoration: 'none',
                            border: '0px solid aqua',
                          }}
                        >
                          <Button
                            className='saveBtn'
                            type='submit'
                            style={{ width: '109%', fontSize: '24px' }}
                          >
                            {this.state.loadPublish ? (
                              <CircularProgress />
                            ) : (
                              'Published'
                            )}
                          </Button>
                        </span>
                      ) : (
                        <span
                          onClick={this.publish}
                          style={{
                            textDecoration: 'none',
                            border: '0px solid red',
                          }}
                        >
                          <Button
                            className='saveBtn'
                            type='submit'
                            style={{
                              width: '109%',
                              fontSize: '24px',
                              border: '0px solid red',
                            }}
                          >
                            Publish
                          </Button>
                        </span>
                      )}
                    </div>
                  </Grid>
                </Grid>
                <Typography style={{ color: 'red' }}>
                  {this.state.warn1 ? '* Modal file is required.' : ''}
                </Typography>
                {/* <div className="chatBoxEdit">
                  <p>Edits</p>
                  <span>
                    do you need any changes to be made? Please share notes for
                    our model maker.
                  </span>
                  <textarea className="chatBox" />
                </div> */}
              </div>
            </Grid>
          </Grid>
          <div id='myModal' className='modal'></div>
          <div className='modal-content'>
            <div className='contentArea'>
              <h1>How to View in Augmented Reality</h1>
              <p>
                Scan this QR code with your phone to view the object in your
                space. The experience launches directly from your browser - no
                app required!
              </p>
            </div>
            <div id='qrCodeWrapper'>
              {this.state.productStatus === 'draft' ? (
                <QRCode
                  value={this.state.value}
                  style={{ height: 200, width: 200, marginTop: 12 }}
                />
              ) : (
                <QRCode
                  value={this.state.value2}
                  style={{ height: 200, width: 200, marginTop: 12 }}
                />
              )}
            </div>
            <span className='closeBtn'>Close</span>
          </div>

          {/* Remove Product logo */}
          <Modal
            visible={this.state.removeProductLogo}
            width='400'
            height='300'
            effect='fadeInUp'
            onClickAway={() => this.setState({ removeProductLogo: false })}
          >
            <div className='confirmationModal' style={{ textAlign: 'center' }}>
              <Typography>
                Are you sure you want to remove product logo ?
              </Typography>
              <Button
                onClick={() => {
                  this.removeLogo();
                  // this.deleteMaterial(
                  //   this.state.deleteMatId,
                  //   this.state.isAvailable
                  // );
                }}
                className='DeleteBtn'
              >
                Remove{' '}
              </Button>
              <Button
                onClick={() => this.setState({ removeProductLogo: false })}
                className='DeleteBtn'
                style={{ background: '#bdbdbd' }}
              >
                Cancel
              </Button>
            </div>
          </Modal>

          {/* Purchase plan modal */}
          <Modal
            visible={this.state.purchasePlan}
            width='400'
            height='300'
            effect='fadeInUp'
            onClickAway={() => this.setState({ purchasePlan: false })}
          >
            <div
              className='confirmationModalPublish'
              style={{ textAlign: 'center' }}
            >
              <Typography>
                Please Purchase Plan To Publish Your Product.
              </Typography>
              <Button
                onClick={() =>
                (window.location.href =
                  '/pricing?modalId=' + this.state.modalId)
                }
                className='DeleteBtnPublish'
                style={{ background: '#bdbdbd' }}
              >
                Click To Purchase Plan
              </Button>
              <Button
                onClick={() => this.setState({ purchasePlan: false })}
                className='DeleteBtnPublish'
                style={{ background: '#bdbdbd' }}
              >
                Cancel
              </Button>
            </div>
          </Modal>

          {/* Cancelled plan modal */}
          <Modal
            visible={this.state.cancelledPlan}
            width='400'
            height='400'
            effect='fadeInUp'
            onClickAway={() => this.setState({ cancelledPlan: false })}
          >
            <div
              className='confirmationModalPublish2'
              style={{ textAlign: 'center' }}
            >
              <Typography>
                You Have Cancelled Your Previous Purchased Plan. <br /> Please
                Purchase Another Plan To Publish Your Product.
              </Typography>
              <Button
                onClick={() =>
                (window.location.href =
                  '/pricing?modalId=' + this.state.modalId)
                }
                className='DeleteBtnPublish'
                style={{ background: '#bdbdbd' }}
              >
                Click To Purchase Plan
              </Button>
              <Button
                onClick={() =>
                  this.setState({ cancelledPlan: false, loadPublish: false })
                }
                className='DeleteBtnPublish'
                style={{ background: '#bdbdbd' }}
              >
                Cancel
              </Button>
              <Button
                onClick={() =>
                (window.location.href =
                  'https://actuality.live/modelDynamicviewer/' + productId)
                }
                className='DeleteBtnPublish'
                style={{ background: '#bdbdbd' }}
              >
                Continue
              </Button>
            </div>
          </Modal>

          {/*Zero Models left modal */}
          <Modal
            visible={this.state.zeroModelsLeft}
            width='400'
            height='300'
            effect='fadeInUp'
            onClickAway={() => this.setState({ zeroModelsLeft: false })}
          >
            <div
              className='confirmationModalPublish2'
              style={{ textAlign: 'center' }}
            >
              <Typography>
                You Don't Have Left Models. <br /> Please Purchase Another Plan
                To Get Models.
              </Typography>
              <Button
                onClick={() =>
                (window.location.href =
                  '/pricing?modalId=' + this.state.modalId)
                }
                className='DeleteBtnPublish'
                style={{ background: '#bdbdbd' }}
              >
                Click To Purchase Plan
              </Button>
              <Button
                onClick={() =>
                  this.setState({ zeroModelsLeft: false, loadPublish: false })
                }
                className='DeleteBtnPublish'
                style={{ background: '#bdbdbd' }}
              >
                Cancel
              </Button>
            </div>
          </Modal>

          {/*Zero Days left modal */}
          <Modal
            visible={this.state.zeroDaysLeft}
            width='400'
            height='300'
            effect='fadeInUp'
            onClickAway={() => this.setState({ zeroDaysLeft: false })}
          >
            <div
              className='confirmationModalPublish2'
              style={{ textAlign: 'center' }}
            >
              <Typography>
                Your Plan Validity Is Expired. <br /> Please Purchase Another
                Plan To Publish Your Product.
              </Typography>
              <Button
                onClick={() =>
                (window.location.href =
                  '/pricing?modalId=' + this.state.modalId)
                }
                className='DeleteBtnPublish'
                style={{ background: '#bdbdbd' }}
              >
                Click To Purchase Plan
              </Button>
              <Button
                onClick={() =>
                  this.setState({ zeroDaysLeft: false, loadPublish: false })
                }
                className='DeleteBtnPublish'
                style={{ background: '#bdbdbd' }}
              >
                Cancel
              </Button>
            </div>
          </Modal>

          {/*Zero Views left modal */}
          <Modal
            visible={this.state.zeroViewsLeft}
            width='400'
            height='300'
            effect='fadeInUp'
            onClickAway={() => this.setState({ zeroViewsLeft: false })}
          >
            <div
              className='confirmationModalPublish2'
              style={{ textAlign: 'center' }}
            >
              <Typography>
                You Don't Have Left Views. <br /> Please Purchase Another Plan
                To Get Views.
              </Typography>
              <Button
                onClick={() =>
                (window.location.href =
                  '/pricing?modalId=' + this.state.modalId)
                }
                className='DeleteBtnPublish'
                style={{ background: '#bdbdbd' }}
              >
                Click To Purchase Plan
              </Button>
              <Button
                onClick={() =>
                  this.setState({ zeroViewsLeft: false, loadPublish: false })
                }
                className='DeleteBtnPublish'
                style={{ background: '#bdbdbd' }}
              >
                Cancel
              </Button>
            </div>
          </Modal>

          <Modal
            visible={this.state.visible}
            width='300'
            height='470'
            effect='fadeInUp'
            onClickAway={() => this.closeModal()}
          >
            {this.state.showFirst ? (
              <div
                className='modalWrapper'
                style={{
                  border: '0px solid red',
                }}
              >
                {/* <img
                  src={yes}
                  alt=""
                  style={{ position: "absolute", right: "44px", top: "10px" }}
                /> */}
                <CloseSharpIcon
                  style={{ position: 'absolute', right: '10px', top: '5px' }}
                  onClick={() => this.closeModal()}
                />
                <form action='' id='materialForm' onSubmit={this.addMaterial}>
                  <h1 className='modalHead'>Preview</h1>
                  {this.state.type === 'color' ? (
                    <div
                      className='modalBox'
                      style={{
                        backgroundColor: this.state.material_value
                          ? this.state.material_value
                          : 'red',
                      }}
                    ></div>
                  ) : (
                    <div>
                      {this.state.swatchFile ? (
                        <img
                          src={this.state.swatchFile}
                          width='105px'
                          height='105px'
                          style={{ marginBottom: '10px' }}
                          alt=''
                        />
                      ) : (
                        <img
                          src={rectangle}
                          width='105px'
                          height='105px'
                          style={{ marginBottom: '10px' }}
                          alt=''
                        />
                      )}
                    </div>
                  )}
                  <div className='inputTextWrap'>
                    <span className='modalText'>Name</span>
                    <input
                      type='text'
                      className='modalInput'
                      required
                      name='material_name'
                      onChange={this.handleInput}
                    />
                  </div>

                  <Typography className='modalMaterialHead'>
                    {' '}
                    Material
                  </Typography>

                  <div style={{ marginBottom: '20px' }}>
                    <span
                      className='modalMaterialHead'
                      style={{
                        marginRight: '80px',
                        borderBottom: 'none',
                        fontWeight: this.state.type === 'color' ? 'bold' : 400,
                      }}
                      onClick={() =>
                        this.setState({
                          addColor: true,
                          addSwatch: false,
                          type: 'color',
                        })
                      }
                    >
                      Solid Color
                    </span>
                    <img src={verticalLine} className='varticalLine' alt='' />

                    <span
                      className='modalText'
                      style={{
                        fontWeight: this.state.type === 'swatch' ? 'bold' : 400,
                      }}
                      onClick={() =>
                        this.setState({
                          addSwatch: true,
                          addColor: false,
                          type: 'swatch',
                        })
                      }
                    >
                      Swatch
                    </span>
                  </div>

                  {this.state.addColor ? (
                    <div>
                      <div
                        className={
                          this.state.colorPicker
                            ? 'inputTextWrap2'
                            : 'inputTextWrap'
                        }
                      >
                        <span
                          className='modalText'
                          style={{ marginLeft: ' -8px' }}
                        >
                          Hexcode
                        </span>
                        <input
                          type='text'
                          className='modalInput'
                          name='material_value'
                          value={
                            this.state.material_value
                              ? this.state.material_value
                              : '#ff0000'
                          }
                          onClick={() =>
                            this.setState({
                              colorPicker: !this.state.colorPicker,
                            })
                          }
                        />
                      </div>
                      <div
                        style={{
                          position: 'relative',
                          bottom: '210px',
                          left: '300px',
                        }}
                      >
                        {this.state.colorPicker ? (
                          <SketchPicker
                            name='material_value'
                            color={this.state.material_value}
                            onChangeComplete={this.handleChangeComplete}
                            width='170px'
                          />
                        ) : null}
                      </div>
                    </div>
                  ) : null}

                  {this.state.addSwatch ? (
                    <div>
                      <Button
                        variant='outlined'
                        size='medium'
                        className='uploadbtnswatch'
                        component='label'
                        style={{ margin: '11px auto' }}
                      >
                        Upload Swatch
                        <input
                          type='file'
                          onChange={this.onImageChange}
                          name='file'
                          hidden
                        />
                      </Button>
                      <Typography className='modalHead'>
                        {this.state.file
                          ? 'Swatch Uploaded'
                          : 'accepted file types : .jpeg, .png'}
                      </Typography>
                    </div>
                  ) : null}
                  <Button
                    className={
                      this.state.colorPicker
                        ? 'uploadbtnswatchPicker'
                        : 'uploadbtnswatch'
                    }
                    type='submit'
                    style={
                      this?.state?.addSwatch
                        ? {}
                        : {
                          position: 'relative',
                          bottom: this.state.colorPicker ? '280px' : 0,
                          // border: "1px solid red",
                        }
                    }
                  >
                    Add Material
                  </Button>
                </form>
              </div>
            ) : null}

            {this.state.ShowComponent ? (
              <div className='modalWrapper' style={{ marginTop: '115px' }}>
                <form action='' id='componentForm' onSubmit={this.addComponent}>
                  <h1 className='modalHead' style={{ fontSize: '18px' }}>
                    Add Component
                  </h1>
                  {/* <img
                    src={yes}
                    alt=""
                    style={{ position: "absolute", right: "44px", top: "10px" }}
                  /> */}
                  <CloseSharpIcon
                    style={{ position: 'absolute', right: '10px', top: '5px' }}
                    onClick={() => this.closeModal()}
                  />

                  <div className='inputTextWrap'>
                    <span className='modalText'>Name</span>
                    <input
                      type='text'
                      className='modalInput'
                      name='compname'
                      required
                      onChange={this.handleInput}
                    />
                  </div>
                  <br />
                  <Button className='addBtn' type='submit'>
                    {this.state.loading ? (
                      <CircularProgress />
                    ) : (
                      'Add Component'
                    )}
                  </Button>
                </form>
              </div>
            ) : null}
          </Modal>
          <Modal
            visible={this.state.processing}
            width='450'
            height='200'
            effect='fadeInUp'
          >
            <div style={{ textAlign: 'center', marginTop: '66px' }}>
              <p>Please wait...</p>
              <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress
                  variant='determinate'
                  value={Math.round(
                    (100 / this.state.variantsCombo.length) *
                    this.state.uploadCount
                  )}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant='caption'
                    component='div'
                    color='text.secondary'
                  >
                    {`${Math.round(
                      (100 / this.state.variantsCombo.length) *
                      this.state.uploadCount
                    )}%`}
                  </Typography>
                </Box>
              </Box>

              <p>
                {this.state.uploadCount +
                  '/' +
                  this.state.variantsCombo.length +
                  ' models are uploaded'}
              </p>
            </div>
          </Modal>

          <Modal
            visible={this.state.compnentEdit}
            width='300'
            height='350'
            effect='fadeInUp'
            onClickAway={() => this.closeModal()}
          >
            <div className='modalWrapper' style={{ marginTop: '80px' }}>
              <form
                action=''
                id='componentForm'
                onSubmit={this.updateComponent}
              >
                <h1 className='modalHead' style={{ fontSize: '18px' }}>
                  Edit component details
                </h1>

                <CloseSharpIcon
                  style={{ position: 'absolute', right: '10px', top: '5px' }}
                  onClick={() => this.closeModal()}
                />

                <div className='inputTextWrap'>
                  <span className='modalText'>Name</span>
                  <input
                    type='text'
                    className='modalInput'
                    name='compname'
                    defaultValue={this.state.component_name}
                    required
                    onChange={this.handleInput}
                  />
                </div>
                <br />
                <Button className='addBtn' type='submit'>
                  {this.state.loading ? (
                    <CircularProgress />
                  ) : (
                    'Update Component'
                  )}
                </Button>
              </form>
            </div>
          </Modal>

          {this.state.materialEdit && (
            <MaterialEditModel
              handleDelete={() => {
                this.setState({
                  deleteConfirmation: true,
                  deleteMatId: this.state.materialEditObject.deleteMatId,
                  isAvailable: this.state.materialEditObject.is_variant_made,
                  deleteMatIdCompId: this.state.materialEditObject.compId,
                });
              }}
              open={this.state.materialEdit}
              closeModal={() => this.closeModal()}
              data={this.state.materialEditObject}
              updateMaterial={this.updateMaterial}
            />
          )}
          {/* <Modal
            visible={this.state.materialEdit}
            width="300"
            height="470"
            effect="fadeInUp"
            onClickAway={() => this.closeModal()}
          >
            <div className="modalWrapper">
          
              <CloseSharpIcon
                style={{ position: "absolute", right: "10px", top: "5px" }}
                onClick={() => this.closeModal()}
              />
              <form action="" id="materialForm" onSubmit={this.updateMaterial}>
                <h1 className="modalHead">Preview</h1>
                {this.state.type === "color" ? (
                  <div
                    className="modalBox"
                    style={{
                      backgroundColor: this.state.material_value
                        ? this.state.material_value
                        : "red",
                    }}
                  ></div>
                ) : (
                  <div>
                    {this.state.swatchFile ? (
                      <img
                        src={this.state.swatchFile}
                        width="105px"
                        height="105px"
                        style={{ marginBottom: "10px" }}
                        alt=""
                      />
                    ) : (
                      <img
                        src={rectangle}
                        width="105px"
                        height="105px"
                        style={{ marginBottom: "10px" }}
                        alt=""
                      />
                    )}
                  </div>
                )}
                <div className="inputTextWrap">
                  <span className="modalText">Name</span>
                  <input
                    type="text"
                    className="modalInput"
                    required
                    name="material_name"
                    defaultValue={this.state.material_name}
                    onChange={this.handleInput}
                  />
                </div>

                <Typography className="modalMaterialHead"> Material</Typography>

                <div style={{ marginBottom: "20px" }}>
                  <span
                    className="modalMaterialHead"
                    style={{
                      marginRight: "80px",
                      borderBottom: "none",
                      fontWeight: this.state.type === "color" ? "bold" : 400,
                    }}
                    onClick={() =>
                      this.setState({
                        addColor: true,
                        addSwatch: false,
                        type: "color",
                      })
                    }
                  >
                    Solid Color
                  </span>
                  <img src={verticalLine} className="varticalLine" alt="" />
                  <span
                    className="modalText"
                    style={{
                      fontWeight: this.state.type === "swatch" ? "bold" : 400,
                    }}
                    onClick={() =>
                      this.setState({
                        addSwatch: true,
                        addColor: false,
                        type: "swatch",
                      })
                    }
                  >
                    Swatch
                  </span>
                </div>

                {this.state.addColor ? (
                  <div>
                    <div className="inputTextWrap">
                      <span
                        className="modalText"
                        style={{ marginLeft: " -8px" }}
                      >
                        Hexcode
                      </span>
                      <input
                        type="text"
                        className="modalInput"
                        name="material_value"
                        onClick={() =>
                          this.setState({
                            colorPicker: !this.state.colorPicker,
                          })
                        }
                        value={
                          this.state.material_value
                            ? this.state.material_value
                            : "#000"
                        }
                      />
                    </div>
                  </div>
                ) : null}
                {this.state.colorPicker ? (
                  <SketchPicker
                    name="material_value"
                    color={this.state.material_value}
                    onChangeComplete={this.handleChangeComplete}
                  />
                ) : null}
                {this.state.addSwatch ? (
                  <div>
                    <Button
                      variant="outlined"
                      size="medium"
                      className="uploadbtnswatch"
                      component="label"
                      style={{ margin: "11px auto" }}
                    >
                      Upload Swatch
                      <input
                        type="file"
                        onChange={this.onImageChange}
                        name="file"
                        hidden
                      />
                    </Button>
                    <Typography className="modalHead">
                      {this.state.file
                        ? "Swatch Uploaded"
                        : "accepted file types : .jpeg, .png"}
                    </Typography>
                  </div>
                ) : null}
                <Button className="uploadbtnswatch" type="submit">
                  Update Material
                </Button>
              </form>
            </div>
          </Modal> */}
          <Modal
            visible={this.state.deleteConfirmation}
            width='400'
            height='300'
            effect='fadeInUp'
            onClickAway={() => this.setState({ deleteConfirmation: false })}
          >
            <div className='confirmationModal' style={{ textAlign: 'center' }}>
              <Typography>
                {!this.canIgnoreDeleteConfirmation(this.state.deleteMatIdCompId)
                  ? `All Variants of this product will be deleted. Are you sure you
                want to delete this material`
                  : 'Are you sure you want to delete this component ?'}
              </Typography>
              <Button
                onClick={() => {
                  this.deleteMaterial(
                    this.state.deleteMatId,
                    this.state.isAvailable
                  );
                  this.setState({
                    materialEdit: false,
                    materialEditObject: {},
                    deleteMatIdCompId: '',
                  });
                }}
                className='DeleteBtn'
              >
                Delete{' '}
              </Button>
              <Button
                onClick={() => this.setState({ deleteConfirmation: false })}
                className='DeleteBtn'
                style={{ background: '#bdbdbd' }}
              >
                Cancel
              </Button>
            </div>
          </Modal>
          <Modal
            visible={this.state.confirmationComponentDelete}
            width='400'
            height='300'
            effect='fadeInUp'
            onClickAway={() =>
              this.setState({ confirmationComponentDelete: false })
            }
          >
            <div className='confirmationModal' style={{ textAlign: 'center' }}>
              {this.state.Variants.length > 0 &&
                this.state.isThisHaveMultipleMaterials ? (
                <Typography>
                  All Variants of this product will be deleted. Are you sure you
                  want to delete this component ?
                </Typography>
              ) : (
                <Typography>
                  Are you sure you want to delete this component ?
                </Typography>
              )}
              <Button
                onClick={() => {
                  this.deleteComponent(this.state.deleteCompId);
                }}
                className='DeleteBtn'
              >
                Delete{' '}
              </Button>
              <Button
                onClick={() =>
                  this.setState({ confirmationComponentDelete: false })
                }
                className='DeleteBtn'
                style={{ background: '#bdbdbd' }}
              >
                Cancel
              </Button>
              <Typography style={{ color: 'green' }}>
                {' '}
                {this.state.notification ? this.setState.successMsg : ''}
              </Typography>
            </div>
          </Modal>
          <Modal
            visible={this.state.confirmationComponentAdd}
            width='400'
            height='300'
            effect='fadeInUp'
            onClickAway={() =>
              this.setState({ confirmationComponentAdd: false })
            }
          >
            <div className='confirmationModal' style={{ textAlign: 'center' }}>
              <Typography>
                All Variants of this product will be deleted. Are you sure you
                want to delete Variants ?
              </Typography>

              <Button
                onClick={() => {
                  // this.variantDelete();
                  this.setState({ confirmationComponentAdd: false });
                }}
                className='DeleteBtn'
              >
                Delete{' '}
              </Button>
              <Button
                onClick={() =>
                  this.setState({ confirmationComponentAdd: false })
                }
                className='DeleteBtn'
                style={{ background: '#bdbdbd' }}
              >
                Cancel
              </Button>
              <Typography style={{ color: 'green' }}>
                {' '}
                {this.state.notification ? this.setState.successMsg : ''}
              </Typography>
            </div>
          </Modal>

          <Modal
            visible={this.state.openShareModal}
            width='550'
            height='450'
            effect='fadeInUp'
            onClickAway={() => this.setState({ openShareModal: false })}
          >
            <div className='shareModal web'>
              <span
                className='textMain'
                style={{ display: 'block', textAlign: 'center' }}
              >
                Share
              </span>
              <img
                src={remove}
                width='15px'
                height='15px'
                alt=''
                className='colseModelbtn'
                onClick={() => this.setState({ openShareModal: false })}
              />
              {/* <span
                className="textMain"
                style={{ marginLeft: "84px", fontWeight: 400 }}
              >
                For internal testing only
              </span> */}
              <Grid container spacing={2} style={{ position: 'relative' }}>
                <Grid item sm={3}>
                  {' '}
                  <Typography>URL</Typography>{' '}
                </Grid>
                <Grid item sm={7}>
                  {this.state.productStatus === 'draft' ? (
                    <input
                      className='input'
                      value={this.state.value}
                      onChange={({ target: { value } }) =>
                        this.setState({ value, copied: false })
                      }
                      style={{
                        width: window.innerWidth < 1100 ? '81%' : '',
                        marginLeft: window.innerWidth < 1100 ? '10px' : '',
                      }}
                    />
                  ) : (
                    <input
                      className='input'
                      value={this.state.value2}
                      onChange={({ target: { value2 } }) =>
                        this.setState({ value2, copied: false })
                      }
                      style={{
                        width: window.innerWidth < 1100 ? '81%' : '',
                        marginLeft: window.innerWidth < 1100 ? '10px' : '',
                      }}
                    />
                  )}
                </Grid>
                <Grid item sm={2} style={{ position: 'relative' }}>
                  {this.state.productStatus === 'draft' ? (
                    <CopyToClipboard
                      text={this.state.value}
                      onCopy={this.copyUrl}
                    >
                      <Button
                        className='CopyBtn'
                        style={{
                          marginLeft: window.innerWidth < 1100 ? '-45px' : '',
                        }}
                      >
                        Copy
                      </Button>
                    </CopyToClipboard>
                  ) : (
                    <CopyToClipboard
                      text={this.state.value2}
                      onCopy={this.copyUrl}
                    >
                      <Button
                        className='CopyBtn'
                        style={{
                          marginLeft: window.innerWidth < 1100 ? '-45px' : '',
                        }}
                      >
                        Copy
                      </Button>
                    </CopyToClipboard>
                  )}
                  {this.state.copied ? (
                    <Typography
                      style={{
                        color: 'red',
                        marginTop: window.innerWidth < 1100 ? '' : '40px',
                        marginLeft: window.innerWidth < 1100 ? '' : '22px',
                      }}
                    >
                      Copied.
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ position: 'relative' }}>
                <Grid item sm={3}>
                  {window.innerWidth < 1100 ? (
                    <Typography>Code</Typography>
                  ) : (
                    <Typography>Embed Code</Typography>
                  )}
                </Grid>
                <Grid item sm={7}>
                  <input
                    type='text'
                    className='input'
                    value={this.state.embedCode}
                    style={{ width: window.innerWidth < 1100 ? '84%' : '' }}
                    onChange={({ target: { embedCode } }) =>
                      this.setState({ embedCopy: false })
                    }
                  />
                </Grid>
                <Grid item sm={2}>
                  <CopyToClipboard
                    text={this.state.embedCode}
                    onCopy={this.copyEmbed}
                  >
                    <Button
                      className='CopyBtn'
                      style={{
                        marginLeft: window.innerWidth < 1100 ? '-45px' : '',
                      }}
                    >
                      Copy
                    </Button>
                  </CopyToClipboard>
                  {this.state.embedCopy ? (
                    <Typography
                      style={{
                        color: 'red',
                        marginTop: window.innerWidth < 1100 ? '' : '40px',
                        marginLeft: window.innerWidth < 1100 ? '' : '22px',
                      }}
                    >
                      Copied.
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ position: 'relative' }}>
                <Grid item sm={3}>
                  {' '}
                  <Typography>QR code</Typography>{' '}
                </Grid>
                <Grid item sm={4}>
                  {this.state.productStatus === 'draft' ? (
                    <QRCode
                      id='QRCode'
                      value={
                        'https://portal.actuality.live/modelDynamicviewer/' +
                        productId
                      }
                      style={{ height: 150, width: 150, marginTop: 12 }}
                    />
                  ) : (
                    <QRCode
                      id='QRCode'
                      value={
                        'https://actuality.live/modelDynamicviewer/' + productId
                      }
                      style={{ height: 150, width: 150, marginTop: 12 }}
                    />
                  )}
                </Grid>
                <Grid item sm={5}>
                  <Button
                    className='CopyBtn'
                    onClick={this.downloadQR}
                    style={{
                      marginTop: window.innerWidth < 1100 ? '122px' : '120px',
                      marginLeft: window.innerWidth < 1100 ? '78px' : '',
                      position:
                        window.innerWidth < 1100 ? 'absolute' : 'relative',
                    }}
                  >
                    Download
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Modal>
          <Modal
            visible={this.state.openBuyNow}
            width='400'
            height='450'
            effect='fadeInUp'
            onClickAway={() => this.setState({ openBuyNow: false })}
          >
            <div
              className='inputWrapper'
              style={{
                padding: '20px',
                textAlign: 'center',
                marginTop: '30px',
              }}
            >
              <form
                action=''
                onSubmit={(e) => {
                  if (this.state.invalidUrl) return;

                  this.updateProduct(e);
                }}
              >
                <div className='modalWrapper'>
                  <h4 style={{ marginTop: '-10px' }}>Edit product details</h4>

                  <div className='contentWrap'>
                    <p>Link URL</p>
                    <input
                      type='text'
                      name='link_url'
                      className='modalInput'
                      style={{ marginBottom: '10px' }}
                      defaultValue={
                        this.state.product ? this.state.product.link_url : ''
                      }
                      onChange={(e) => {
                        const isValidUrl = validateUrl(e.target.value);
                        // console.log("isValidUrl ", isValidUrl);
                        if (!isValidUrl) this.setState({ invalidUrl: true });
                        else if (this.state.invalidUrl)
                          this.setState({ invalidUrl: false });
                        this.handleInput(e);
                      }}
                      required
                    />{' '}
                    {this.state.invalidUrl && (
                      <p
                        style={{
                          color: 'rgba(255,0,0,0.8)',
                          fontStyle: 'italic',
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        Link is invalid
                      </p>
                    )}
                    <p>Link Title</p>
                    <input
                      type='text'
                      name='link_title'
                      className='modalInput'
                      style={{ marginBottom: '10px' }}
                      defaultValue={
                        this.state.product ? this.state.product.link_title : ''
                      }
                      onChange={this.handleInput}
                      required
                    />{' '}
                    <p>Link Description</p>
                    <input
                      type='text'
                      name='link_description'
                      className='modalInput'
                      style={{ marginBottom: '10px' }}
                      defaultValue={
                        this.state.product
                          ? this.state.product.link_description
                          : ''
                      }
                      onChange={this.handleInput}
                      required
                    />
                    <br />
                    <Button
                      className='addBtn'
                      type='submit'
                      disabled={this.state.invalidUrl}
                      style={{ width: '100%', marginLeft: '0px' }}
                    >
                      {this.state.loading ? (
                        <CircularProgress color={'white'} size={25} />
                      ) : (
                        'Update'
                      )}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </Modal>
          {/* model for crop product image */}
          <Modal
            visible={this.state.visibleCropProduct}
            // width="700"
            width='1100'
            height='650'
            effect='fadeInUp'
            onClickAway={() => this.setState({ visibleCropProduct: false })}
          >
            <Grid
              container
              spacing={2}
              className='PreeditWrapper'
              style={{ marginTop: '25px' }}
            >
              <Grid
                item
                sm={12}
                style={{
                  padding: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {src1 && (
                  <ReactCrop
                    src={src1}
                    crop={crop1}
                    aspect={1}
                    ruleOfThirds
                    onImageLoaded={this.onImageLoaded1}
                    onComplete={this.onCropComplete1}
                    onChange={this.onCropChange1}
                  />
                )}
              </Grid>
              {/* <Grid item sm={6} style={{padding: "20px"}}>
                {croppedImageUrl && (
                  <img alt="Crop" style={{ width: "100%", height:"430px", position: "relative", top: "60px" }} src={croppedImageUrl} />
                )}
              </Grid> */}
            </Grid>
            <Grid
              container
              spacing={2}
              className='PreeditWrapper'
              style={{ marginTop: '25px' }}
            >
              {/* <Grid item sm={6} style={{padding: "20px"}}></Grid> */}
              <Grid
                item
                sm={12}
                style={{
                  padding: '20px',
                  textAlign: 'center',
                  marginRight: '170px',
                }}
              >
                <span>
                  {/* <button style={{cursor:"pointer"}} className="saveBtnCrop" onClick={() => {
                    this.setState({
                      visibleCropProduct: false,
                      imgPreview: true
                    })
                  }}>Save</button> */}
                  <button
                    className='saveCroppedImg'
                    onClick={() => {
                      this.setState(
                        {
                          visibleCropProduct: false,
                          imgPreview: true,
                        },
                        () => {
                          // console.log(this.state.visibleCropProduct);
                        }
                      );
                    }}
                  >
                    Save
                  </button>
                </span>
                <span>
                  {/* <button className="cancelBtnCrop" 
                  onClick={() => {
                    this.setState({
                      visibleCropProduct: false,
                      imgPreview: false
                    }, () => {
                   // console.log(this.state.visibleCropProduct);
                    })
                  }}>Cancel</button> */}
                  <button
                    className='cancelCroppedImg'
                    onClick={() => {
                      this.setState(
                        {
                          visibleCropProduct: false,
                          imgPreview: false,
                        },
                        () => {
                          // console.log(this.state.visibleCropProduct);
                        }
                      );
                    }}
                  >
                    Cancel
                  </button>
                </span>
              </Grid>
            </Grid>
          </Modal>
          {/* crop model for logo */}
          <Modal
            visible={this.state.visibleCrop}
            // width="700"
            width='1100'
            height='650'
            effect='fadeInUp'
            onClickAway={() => this.setState({ visibleCrop: false })}
          >
            <Grid
              container
              spacing={2}
              className='PreeditWrapper'
              style={{ marginTop: '25px' }}
            >
              <Grid
                item
                sm={12}
                style={{
                  padding: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {src && (
                  <ReactCrop
                    src={src}
                    crop={crop}
                    aspect={1}
                    ruleOfThirds
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                  />
                )}
              </Grid>
              {/* <Grid item sm={6} style={{padding: "20px"}}>
                {croppedImageUrl && (
                  <img alt="Crop" style={{ width: "100%", height:"430px", position: "relative", top: "60px" }} src={croppedImageUrl} />
                )}
              </Grid> */}
            </Grid>
            <Grid
              container
              spacing={2}
              className='PreeditWrapper'
              style={{ marginTop: '25px' }}
            >
              {/* <Grid item sm={6} style={{padding: "20px"}}></Grid> */}
              <Grid
                item
                sm={12}
                style={{
                  padding: '20px',
                  textAlign: 'center',
                  marginRight: '170px',
                }}
              >
                <span>
                  {/* <button style={{cursor:"pointer"}} className="saveBtnCrop" onClick={() => {
                    this.setState({
                      visibleCrop: false,
                      imgPreview: true
                    })
                  }}>Save</button> */}
                  <button
                    className='saveCroppedImg'
                    onClick={() => {
                      this.setState(
                        {
                          visibleCrop: false,
                          imgPreview: true,
                        },
                        () => {
                          // console.log(this.state.visibleCrop);
                          this.saveImage();
                        }
                      );
                    }}
                  >
                    Save
                  </button>
                </span>
                <span>
                  {/* <button className="cancelBtnCrop" 
                  onClick={() => {
                    this.setState({
                      visibleCrop: false,
                      imgPreview: false
                    }, () => {
                   // console.log(this.state.visibleCrop);
                    })
                  }}>Cancel</button> */}
                  <button
                    className='cancelCroppedImg'
                    onClick={() => {
                      this.setState(
                        {
                          visibleCrop: false,
                          imgPreview: false,
                        },
                        () => {
                          // console.log(this.state.visibleCrop);
                        }
                      );
                    }}
                  >
                    Cancel
                  </button>
                </span>
              </Grid>
            </Grid>
          </Modal>
        </div>
      </Container>
    );
  }
}

export default UnPublishModal;

const TSEInputLabel = ({ label }) => (
  <p
    style={{
      padding: 0,
      marginTop: 15,
      marginBottom: 10,
    }}
  >
    {label}
  </p>
);
