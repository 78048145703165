import React, { Component } from 'react';
import Sidebar from '../Components/Sidebar';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import {
  Button,
  Container,
  Grid,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import file from '../../src/Assets/Images/export 1.png';
import customImg from '../../src/Assets/Images/timetable (1) 3.png';
import Modal from 'react-awesome-modal';
import {
  AreaChart,
  Legend,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  RadialBarChart,
  RadialBar,
} from 'recharts';
import { API } from '../Constants/APIs';
import axios from 'axios';
import { AdminPortal } from '@frontegg/react';
import ProductViewsGraph from '../Components/ProductViewsGraph';
import { customLegend } from '../Helpers/utils';
import InfiniteScroll from 'react-infinite-scroll-component';

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const color = [
  '#D62828',
  '#2EC4B6',
  '#00BBF9',
  '#FFC300',
  '#FF5D8F',
  '#AF6BFF',
  '#F08080',
];

const data = [
  {
    name: 'Jan',
    value: 5,
    uv: 5,
    pv: 2400,
    amt: 2400,
    fill: '#F08080',
  },
  {
    name: 'Fab',
    value: 12,
    uv: 3000,
    pv: 1398,
    amt: 2210,
    fill: '#A3E4D7',
  },
  {
    name: 'Mar',
    value: 17,
    uv: 2000,
    pv: 9800,
    amt: 2290,
    fill: '#EDBB99',
  },
  {
    name: 'Apr',
    value: 10,
    uv: 2780,
    pv: 3908,
    amt: 2000,
    fill: '#BDC3C7',
  },
  {
    name: 'May',
    value: 30,
    uv: 1890,
    pv: 4800,
    amt: 2181,
    fill: '#1F618D',
  },
  {
    name: 'Jun',
    uv: 2390,
    value: 35,
    pv: 3800,
    amt: 2500,
    fill: '#1ABC9C',
  },
  {
    name: 'Jul',
    uv: 3490,
    value: 31,
    pv: 4300,
    amt: 2100,
    fill: '#B7950B',
  },
  {
    name: 'Aug',
    uv: 3490,
    value: 26,
    pv: 4300,
    amt: 2100,
    fill: '#D62828',
  },
  {
    name: 'Sep',
    uv: 3490,
    value: 21,
    pv: 4300,
    amt: 2100,
    fill: '#00BBF9',
  },
  {
    name: 'Oct',
    uv: 3490,
    value: 34,
    pv: 4300,
    amt: 2100,
    fill: '#FFC300',
  },
  {
    name: 'Nov',
    uv: 3490,
    value: 42,
    pv: 4300,
    amt: 2100,
    fill: '#FF5D8F',
  },
  {
    name: 'Dec',
    uv: 3490,
    value: 38,
    pv: 4300,
    amt: 2100,
    fill: '#AF6BFF',
  },
];
const data1 = [
  {
    Country: 'United States',
    Views: 80,
    per: 92.23,
    name: 'Windows',
    city: 'Mumbai',
    fill: '#D62828',
  },
  {
    Country: 'Canada',
    Views: 60,
    per: 90.23,
    name: 'Chrome OS',
    city: 'Hyderabad',
    fill: '#2EC4B6',
  },
  {
    Country: 'India',
    Views: 40,
    per: 69.23,
    name: 'ios',
    city: 'Pune',
    fill: '#00BBF9',
  },
  {
    Country: 'Germany',
    Views: 67,
    per: 56.23,
    name: 'Linux',
    city: 'Berlin',
    fill: '#FFC300',
  },
  {
    Country: 'Australia',
    Views: 80,
    per: 49.23,
    name: 'OS x',
    city: 'Mumbai',
    fill: '#FF5D8F',
  },
  {
    Country: 'UAE',
    Views: 70,
    per: 89.23,
    city: 'Nagpur',
    name: 'Android OS',
    fill: '#AF6BFF',
  },
];
const style = {
  top: 0,
  left: 350,
  lineHeight: '24px',
};

const showData = [
  { item: 'This Week' },
  { item: 'This Month' },
  { item: 'This Year' },
  { item: 'Last Week' },
  { item: 'Last Month' },
  { item: 'Last Year' },
  { item: 'Last 60 Days' },
  { item: 'Last 90 days' },
];

const column_heading = [
  {
    _id: 'createdAt',
    value: 'Date',
    visible: true,
  },
  {
    _id: 'product',
    value: 'Product Name',
    visible: true,
  },
  {
    _id: 'status',
    value: 'Status',
    visible: true,
  },
  {
    _id: 'country',
    value: 'Country',
    visible: true,
  },
  {
    _id: 'views',
    value: 'Views',
    visible: true,
  },
  {
    _id: 'unique_views',
    value: 'Unique Views',
    visible: true,
  },
  {
    _id: 'city',
    value: 'City',
    visible: true,
  },
  {
    _id: 'session',
    value: 'Sessions',
    visible: true,
  },
  {
    _id: 'platform',
    value: 'Device Type',
    visible: true,
  },

  {
    _id: 'actionClicks',
    value: 'Clicks (Call to action button)',
    visible: true,
  },
  {
    _id: 'spaceClicks',
    value: 'Clicks (View in your space)',
    visible: true,
  },
  {
    _id: 'referrerBySession',
    value: 'Referrer By Session',
    visible: true,
  },
];
const months_arr = [
  { _id: 1, value: 0, unique_views: 0 },
  { _id: 2, value: 0, unique_views: 0 },
  { _id: 3, value: 0, unique_views: 0 },
  { _id: 4, value: 0, unique_views: 0 },
  { _id: 5, value: 0, unique_views: 0 },
  { _id: 6, value: 0, unique_views: 0 },
  { _id: 7, value: 0, unique_views: 0 },
  { _id: 8, value: 0, unique_views: 0 },
  { _id: 9, value: 0, unique_views: 0 },
  { _id: 10, value: 0, unique_views: 0 },
  { _id: 11, value: 0, unique_views: 0 },
  { _id: 12, value: 0, unique_views: 0 },
];
const months_arr1 = [
  { _id: 1, value: 0, unique_views: 0 },
  { _id: 2, value: 0, unique_views: 0 },
  { _id: 3, value: 0, unique_views: 0 },
  { _id: 4, value: 0, unique_views: 0 },
  { _id: 5, value: 0, unique_views: 0 },
  { _id: 6, value: 0, unique_views: 0 },
  { _id: 7, value: 0, unique_views: 0 },
  { _id: 8, value: 0, unique_views: 0 },
  { _id: 9, value: 0, unique_views: 0 },
  { _id: 10, value: 0, unique_views: 0 },
  { _id: 11, value: 0, unique_views: 0 },
  { _id: 12, value: 0, unique_views: 0 },
];

const months_arrC = [
  { _id: 1, actionClicks: 0, spaceClicks: 0 },
  { _id: 2, actionClicks: 0, spaceClicks: 0 },
  { _id: 3, actionClicks: 0, spaceClicks: 0 },
  { _id: 4, actionClicks: 0, spaceClicks: 0 },
  { _id: 5, actionClicks: 0, spaceClicks: 0 },
  { _id: 6, actionClicks: 0, spaceClicks: 0 },
  { _id: 7, actionClicks: 0, spaceClicks: 0 },
  { _id: 8, actionClicks: 0, spaceClicks: 0 },
  { _id: 9, actionClicks: 0, spaceClicks: 0 },
  { _id: 10, actionClicks: 0, spaceClicks: 0 },
  { _id: 11, actionClicks: 0, spaceClicks: 0 },
  { _id: 12, actionClicks: 0, spaceClicks: 0 },
];
const months_arrC1 = [
  { _id: 1, actionClicks: 0, spaceClicks: 0 },
  { _id: 2, actionClicks: 0, spaceClicks: 0 },
  { _id: 3, actionClicks: 0, spaceClicks: 0 },
  { _id: 4, actionClicks: 0, spaceClicks: 0 },
  { _id: 5, actionClicks: 0, spaceClicks: 0 },
  { _id: 6, actionClicks: 0, spaceClicks: 0 },
  { _id: 7, actionClicks: 0, spaceClicks: 0 },
  { _id: 8, actionClicks: 0, spaceClicks: 0 },
  { _id: 9, actionClicks: 0, spaceClicks: 0 },
  { _id: 10, actionClicks: 0, spaceClicks: 0 },
  { _id: 11, actionClicks: 0, spaceClicks: 0 },
  { _id: 12, actionClicks: 0, spaceClicks: 0 },
];

const postColumn = [
  'createdAt',
  'product',
  'status',
  'country',
  'city',
  'platform',
  'session',
  'views',
  'unique_views',
  'actionClicks',
  'spaceClicks',
  'referrerBySession',
];

const __ON_SERIAL_NO = false;
export default class ProductAnalytics extends Component {
  state = {
    show: false,
    show1: false,
    show2: false,
    set: 'this_week',
    set1: 'this_week',
    display: false,
    // userId: window.location.pathname.split("/")[3],
    productId: window.location.pathname.split('/')[2],
    viewsData: [],
    clickData: [],
    showModel: false,
    dateState: [
      {
        startDate: new Date(),
        endDate: addDays(new Date(), 7),
        key: 'selection',
      },
    ],
    startDate: '',
    endDate: '',
    showModel1: false,
    dateState1: [
      {
        startDate: new Date(),
        endDate: addDays(new Date(), 7),
        key: 'selection',
      },
    ],
    startDate1: '',
    endDate1: '',
    showModel2: false,
    dateState2: [
      {
        startDate: new Date(),
        endDate: addDays(new Date(), 7),
        key: 'selection',
      },
    ],
    startDate2: '',
    endDate2: '',
    countryData: [],
    totalcountry: 0,
    cityData: [],
    totalcity: 0,
    osData: [],
    totalosvalue: 0,
    allLimit: 20,
    getAllData: [],
    sessionTime: 0,

    heading: column_heading,
    displaylist: false,
    postColData: postColumn,
    currentPage: 1
  };

  componentDidMount() {
    this.viewsGraphdata();
    this.clickGraphdata();
    this.tableDataforViewsCountry();
    this.tableDataforViewsCity();
    this.piDataforOS();
    this.getAllAnalysis();
    // this.setColumn();
  }
  setColumn = () => {
    column_heading.forEach((head) => {
      this.state.heading.push(head.value);
    });
  };
  viewsGraphdata = () => {
    axios
      .get(
        API.getUrls.product_analysis_for_views +
        this.state.productId +
        '?filter_params=' +
        this.state.set +
        '&start_date=' +
        this.state.startDate +
        '&end_date=' +
        this.state.endDate
      )
      .then((resp) => {
        // console.log(resp);
        let viewsData = resp.data.count;
        //  resp.data.count.forEach(c =>{
        //   viewsData.push({
        //     name:c._id,
        //     value:c.value,
        //   });
        if (this.state.set === 'this_week') {
          let curr = new Date();
          let week = [];

          for (let i = 0; i <= 6; i++) {
            // let first = curr.getDate() - curr.getDay() - 7;
            // let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
            let day = new Date(curr);
            day.setDate(day.getDate() - i);
            let month =
              day.getMonth() + 1 < 10
                ? '0' + (day.getMonth() + 1)
                : day.getMonth() + 1;
            let todate =
              day.getDate().toString() < 10
                ? '0' + day.getDate().toString()
                : day.getDate().toString();
            week.push({
              _id: day.getFullYear() + '-' + month + '-' + todate,
              // day.getDate().toString(),
              value: 0,
              unique_views: 0,
            });
          }

          for (var i = 0, l = week.length; i < l; i++) {
            for (var j = 0, ll = viewsData.length; j < ll; j++) {
              if (week[i]._id === viewsData[j]._id) {
                week[i].value = viewsData[j].value;
                week[i].unique_views = viewsData[j].unique_views;
                break;
              }
            }
          }
          viewsData = week;
          // console.log(viewsData, week);
        }

        if (this.state.set === 'this_month') {
          let curr = new Date();
          let crrMonth = [];

          for (let i = 0; i <= 29; i++) {
            let day = new Date(curr);
            day.setDate(day.getDate() - i);
            let month =
              day.getMonth() + 1 < 10
                ? '0' + (day.getMonth() + 1)
                : day.getMonth() + 1;
            let todate =
              day.getDate().toString() < 10
                ? '0' + day.getDate().toString()
                : day.getDate().toString();
            crrMonth.push({
              _id: day.getFullYear() + '-' + month + '-' + todate,
              // day.getDate().toString(),
              value: 0,
              unique_views: 0,
            });
          }

          for (var i = 0, l = crrMonth.length; i < l; i++) {
            for (var j = 0, ll = viewsData.length; j < ll; j++) {
              if (crrMonth[i]._id === viewsData[j]._id) {
                crrMonth[i].value = viewsData[j].value;
                crrMonth[i].unique_views = viewsData[j].unique_views;
                break;
              }
            }
          }
          // console.log(viewsData, crrMonth);
          viewsData = crrMonth;
        }

        if (this.state.set === 'this_year') {
          viewsData = [];
          // console.log(resp.data.count);
          let viewsData1 = resp.data.count;

          for (var i = 0, l = months_arr.length; i < l; i++) {
            for (var j = 0, ll = viewsData1.length; j < ll; j++) {
              if (months_arr[i]._id === viewsData1[j]._id) {
                months_arr[i].value = viewsData1[j].value;
                months_arr[i].unique_views = viewsData1[j].unique_views;
                break;
              }
            }
          }
          // console.log(months_arr, viewsData1);
          months_arr.forEach((m) => {
            viewsData.push({
              _id: months[m._id - 1],
              value: m.value,
              unique_views: m.unique_views,
            });
          });
          // console.log(viewsData);
        }

        if (this.state.set === 'last_year') {
          viewsData = [];
          // console.log(resp.data.count);
          let viewsData2 = resp.data.count;

          for (var i = 0, l = months_arr1.length; i < l; i++) {
            for (var j = 0, ll = viewsData2.length; j < ll; j++) {
              if (months_arr1[i]._id === viewsData2[j]._id) {
                months_arr1[i].value = viewsData2[j].value;
                months_arr1[i].unique_views = viewsData2[j].unique_views;
                break;
              }
            }
          }
          // console.log(months_arr1, viewsData2);
          months_arr1.forEach((m) => {
            viewsData.push({
              _id: months[m._id - 1],
              value: m.value,
              unique_views: m.unique_views,
            });
          });
          // console.log(viewsData);
        }

        if (this.state.set === 'last_week') {
          const now = new Date();
          let curr = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() - 7
          );
          // console.log(curr);
          let week = [];

          for (let i = 0; i <= 6; i++) {
            let day = new Date(curr);
            day.setDate(day.getDate() - i);
            let month =
              day.getMonth() + 1 < 10
                ? '0' + (day.getMonth() + 1)
                : day.getMonth() + 1;
            let todate =
              day.getDate().toString() < 10
                ? '0' + day.getDate().toString()
                : day.getDate().toString();
            week.push({
              _id: day.getFullYear() + '-' + month + '-' + todate,
              // day.getDate().toString(),
              value: 0,
              unique_views: 0,
            });
          }

          for (var i = 0, l = week.length; i < l; i++) {
            for (var j = 0, ll = viewsData.length; j < ll; j++) {
              if (week[i]._id === viewsData[j]._id) {
                week[i].value = viewsData[j].value;
                week[i].unique_views = viewsData[j].unique_views;
                break;
              }
            }
          }
          viewsData = week;
          // console.log(viewsData, week);
        }

        if (this.state.set === 'last_month') {
          const now = new Date();

          let curr = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() - 30
          );
          // console.log(curr);
          let crrMonth = [];

          for (let i = 0; i <= 29; i++) {
            let day = new Date(curr);
            day.setDate(day.getDate() - i);
            let month =
              day.getMonth() + 1 < 10
                ? '0' + (day.getMonth() + 1)
                : day.getMonth() + 1;
            let todate =
              day.getDate().toString() < 10
                ? '0' + day.getDate().toString()
                : day.getDate().toString();
            crrMonth.push({
              _id: day.getFullYear() + '-' + month + '-' + todate,
              // day.getDate().toString(),
              value: 0,
              unique_views: 0,
            });
          }

          for (var i = 0, l = crrMonth.length; i < l; i++) {
            for (var j = 0, ll = viewsData.length; j < ll; j++) {
              if (crrMonth[i]._id === viewsData[j]._id) {
                crrMonth[i].value = viewsData[j].value;
                crrMonth[i].unique_views = viewsData[j].unique_views;
                break;
              }
            }
          }
          // console.log(viewsData, crrMonth);
          viewsData = crrMonth;
        }

        if (this.state.set === 'last_60_days') {
          let curr = new Date();
          // console.log(curr);
          let crrMonth = [];

          for (let i = 0; i <= 59; i++) {
            let day = new Date(curr);
            day.setDate(day.getDate() - i);
            let month =
              day.getMonth() + 1 < 10
                ? '0' + (day.getMonth() + 1)
                : day.getMonth() + 1;
            let todate =
              day.getDate().toString() < 10
                ? '0' + day.getDate().toString()
                : day.getDate().toString();
            crrMonth.push({
              _id: day.getFullYear() + '-' + month + '-' + todate,
              // day.getDate().toString(),
              value: 0,
              unique_views: 0,
            });
          }

          for (var i = 0, l = crrMonth.length; i < l; i++) {
            for (var j = 0, ll = viewsData.length; j < ll; j++) {
              if (crrMonth[i]._id === viewsData[j]._id) {
                crrMonth[i].value = viewsData[j].value;
                crrMonth[i].unique_views = viewsData[j].unique_views;
                break;
              }
            }
          }
          // console.log(viewsData, crrMonth);
          viewsData = crrMonth;
        }

        if (this.state.set === 'last_90_days') {
          let curr = new Date();
          // console.log(curr);
          let crrMonth = [];

          for (let i = 0; i <= 89; i++) {
            let day = new Date(curr);
            day.setDate(day.getDate() - i);
            let month =
              day.getMonth() + 1 < 10
                ? '0' + (day.getMonth() + 1)
                : day.getMonth() + 1;
            let todate =
              day.getDate().toString() < 10
                ? '0' + day.getDate().toString()
                : day.getDate().toString();
            crrMonth.push({
              _id: day.getFullYear() + '-' + month + '-' + todate,
              // day.getDate().toString(),
              value: 0,
              unique_views: 0,
            });
          }

          for (var i = 0, l = crrMonth.length; i < l; i++) {
            for (var j = 0, ll = viewsData.length; j < ll; j++) {
              if (crrMonth[i]._id === viewsData[j]._id) {
                crrMonth[i].value = viewsData[j].value;
                crrMonth[i].unique_views = viewsData[j].unique_views;
                break;
              }
            }
          }
          // console.log(viewsData, crrMonth);
          viewsData = crrMonth;
        }

        if (this.state.set === 'custom') {
          let srtDate = new Date(this.state.startDate);
          let enDate = new Date(this.state.endDate);
          // console.log(srtDate, enDate);
          let a = -(
            (srtDate.getTime() - enDate.getTime()) /
            (1000 * 3600 * 24)
          );
          // console.log(a);
          let curr = enDate;
          let week = [];
          for (let i = 0; i <= a; i++) {
            let day = new Date(curr);
            day.setDate(day.getDate() - i);
            let month =
              day.getMonth() + 1 < 10
                ? '0' + (day.getMonth() + 1)
                : day.getMonth() + 1;
            let todate =
              day.getDate().toString() < 10
                ? '0' + day.getDate().toString()
                : day.getDate().toString();
            week.push({
              _id: day.getFullYear() + '-' + month + '-' + todate,
              // day.getDate().toString(),
              value: 0,
              unique_views: 0,
            });
          }
          // console.log(week);
          for (var i = 0, l = week.length; i < l; i++) {
            for (var j = 0, ll = viewsData.length; j < ll; j++) {
              if (week[i]._id === viewsData[j]._id) {
                week[i].value = viewsData[j].value;
                week[i].unique_views = viewsData[j].unique_views;
                break;
              }
            }
          }
          viewsData = week;
        }

        let finalData = [];
        viewsData.forEach((d) => {
          finalData.push({
            _id: d._id,
            Total: d.value,
            Unique: d.unique_views,
          });
        });

        this.setState(
          {
            viewsData: finalData,
          },
          () => {
            // console.log(this.state.viewsData);
          }
        );
      })
      .catch((err) => {
        if (err) {
          // console.log(err.response);
        }
      });
  };

  clickGraphdata = () => {
    axios
      .get(
        API.getUrls.product_analysis_for_clicks +
        this.state.productId +
        '?filter_params=' +
        this.state.set1 +
        '&start_date=' +
        this.state.startDate1 +
        '&end_date=' +
        this.state.endDate1
      )
      .then((resp) => {
        // console.log(resp);
        let clickData = resp.data.count;
        //  resp.data.count.forEach(c =>{
        //   clickData.push({
        //     name:c._id,
        //     value:c.value,
        //   });
        if (this.state.set1 === 'this_week') {
          let curr = new Date();
          let week = [];

          for (let i = 0; i <= 6; i++) {
            // let first = curr.getDate() - curr.getDay() - 7;
            // let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
            let day = new Date(curr);
            day.setDate(day.getDate() - i);
            let month =
              day.getMonth() + 1 < 10
                ? '0' + (day.getMonth() + 1)
                : day.getMonth() + 1;
            let todate =
              day.getDate().toString() < 10
                ? '0' + day.getDate().toString()
                : day.getDate().toString();
            week.push({
              _id: day.getFullYear() + '-' + month + '-' + todate,
              // day.getDate().toString(),
              actionClicks: 0,
              spaceClicks: 0,
            });
          }

          for (var i = 0, l = week.length; i < l; i++) {
            for (var j = 0, ll = clickData.length; j < ll; j++) {
              if (week[i]._id === clickData[j]._id) {
                week[i].actionClicks = clickData[j].actionClicks;
                week[i].spaceClicks = clickData[j].spaceClicks;
                break;
              }
            }
          }
          // console.log(clickData, week);
          clickData = week;
          let finalData = [];
          clickData.forEach((d) => {
            finalData.push({
              _id: d._id,
              'Call to Action': d.actionClicks,
              'View in your space': d.spaceClicks,
            });
          });
          // console.log(finalData);
          clickData = finalData;
        }

        if (this.state.set1 === 'this_month') {
          let curr = new Date();
          let week = [];

          for (let i = 0; i <= 29; i++) {
            // let first = curr.getDate() - curr.getDay() - 7;
            // let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
            let day = new Date(curr);
            day.setDate(day.getDate() - i);
            let month =
              day.getMonth() + 1 < 10
                ? '0' + (day.getMonth() + 1)
                : day.getMonth() + 1;
            let todate =
              day.getDate().toString() < 10
                ? '0' + day.getDate().toString()
                : day.getDate().toString();
            week.push({
              _id: day.getFullYear() + '-' + month + '-' + todate,
              // day.getDate().toString(),
              actionClicks: 0,
              spaceClicks: 0,
            });
          }

          for (var i = 0, l = week.length; i < l; i++) {
            for (var j = 0, ll = clickData.length; j < ll; j++) {
              if (week[i]._id === clickData[j]._id) {
                week[i].actionClicks = clickData[j].actionClicks;
                week[i].spaceClicks = clickData[j].spaceClicks;
                break;
              }
            }
          }
          // console.log(clickData, week);
          clickData = week;
          let finalData = [];
          clickData.forEach((d) => {
            finalData.push({
              _id: d._id,
              'Call to Action': d.actionClicks,
              'View in your space': d.spaceClicks,
            });
          });
          // console.log(finalData);
          clickData = finalData;
        }
        if (this.state.set1 === 'this_year') {
          clickData = [];
          let clickData1 = resp.data.count;
          for (var i = 0, l = months_arrC.length; i < l; i++) {
            for (var j = 0, ll = clickData1.length; j < ll; j++) {
              if (months_arrC[i]._id === clickData1[j]._id) {
                months_arrC[i].actionClicks = clickData1[j].actionClicks;
                months_arrC[i].spaceClicks = clickData1[j].spaceClicks;
                break;
              }
            }
          }

          months_arrC.forEach((c) => {
            clickData.push({
              _id: months[c._id - 1],
              'Call to Action': c.actionClicks,
              'View in your space': c.spaceClicks,
            });
          });
        }

        if (this.state.set1 === 'last_week') {
          const now = new Date();
          let curr = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() - 7
          );
          // console.log(curr);
          let week = [];

          for (let i = 0; i <= 6; i++) {
            let day = new Date(curr);
            day.setDate(day.getDate() - i);
            let month =
              day.getMonth() + 1 < 10
                ? '0' + (day.getMonth() + 1)
                : day.getMonth() + 1;
            let todate =
              day.getDate().toString() < 10
                ? '0' + day.getDate().toString()
                : day.getDate().toString();
            week.push({
              _id:
                day.getFullYear() +
                '-' +
                month +
                '-' +
                day.getDate().toString(),
              spaceClicks: 0,
              actionClicks: 0,
            });
          }

          for (var i = 0, l = week.length; i < l; i++) {
            for (var j = 0, ll = clickData.length; j < ll; j++) {
              if (week[i]._id === clickData[j]._id) {
                week[i].actionClicks = clickData[j].actionClicks;
                week[i].spaceClicks = clickData[j].spaceClicks;
                break;
              }
            }
          }
          // console.log(clickData, week);
          clickData = week;
          let finalData = [];
          clickData.forEach((d) => {
            finalData.push({
              _id: d._id,
              'Call to Action': d.actionClicks,
              'View in your space': d.spaceClicks,
            });
          });
          // console.log(finalData);
          clickData = finalData;
        }

        if (this.state.set1 === 'last_month') {
          const now = new Date();
          let curr = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() - 7
          );
          // console.log(curr);
          let week = [];

          for (let i = 0; i <= 29; i++) {
            let day = new Date(curr);
            day.setDate(day.getDate() - i);
            let month =
              day.getMonth() + 1 < 10
                ? '0' + (day.getMonth() + 1)
                : day.getMonth() + 1;
            let todate =
              day.getDate().toString() < 10
                ? '0' + day.getDate().toString()
                : day.getDate().toString();
            week.push({
              _id: day.getFullYear() + '-' + month + '-' + todate,
              // day.getDate().toString(),
              spaceClicks: 0,
              actionClicks: 0,
            });
          }

          for (var i = 0, l = week.length; i < l; i++) {
            for (var j = 0, ll = clickData.length; j < ll; j++) {
              if (week[i]._id === clickData[j]._id) {
                week[i].actionClicks = clickData[j].actionClicks;
                week[i].spaceClicks = clickData[j].spaceClicks;
                break;
              }
            }
          }
          // console.log(clickData, week);
          clickData = week;
          let finalData = [];
          clickData.forEach((d) => {
            finalData.push({
              _id: d._id,
              'Call to Action': d.actionClicks,
              'View in your space': d.spaceClicks,
            });
          });
          // console.log(finalData);
          clickData = finalData;
        }

        if (this.state.set1 === 'last_year') {
          clickData = [];
          // console.log(resp.data.count);
          let clickData2 = resp.data.count;

          for (var i = 0, l = months_arrC1.length; i < l; i++) {
            for (var j = 0, ll = clickData2.length; j < ll; j++) {
              if (months_arrC1[i]._id === clickData2[j]._id) {
                months_arrC1[i].actionClicks = clickData2[j].actionClicks;
                months_arrC1[i].spaceClicks = clickData2[j].spaceClicks;
                break;
              }
            }
          }
          // console.log(months_arrC1, clickData2);
          months_arrC1.forEach((c) => {
            clickData.push({
              _id: months[c._id - 1],
              'Call to Action': c.actionClicks,
              'View in your space': c.spaceClicks,
            });
          });
          // console.log(clickData);
        }
        if (this.state.set1 === 'last_60_days') {
          let curr = new Date();
          // console.log(curr);
          let crrMonth = [];

          for (let i = 0; i <= 59; i++) {
            let day = new Date(curr);
            day.setDate(day.getDate() - i);
            let month =
              day.getMonth() + 1 < 10
                ? '0' + (day.getMonth() + 1)
                : day.getMonth() + 1;
            let todate =
              day.getDate().toString() < 10
                ? '0' + day.getDate().toString()
                : day.getDate().toString();
            crrMonth.push({
              _id: day.getFullYear() + '-' + month + '-' + todate,
              // day.getDate().toString(),
              actionClicks: 0,
              spaceClicks: 0,
            });
          }

          for (var i = 0, l = crrMonth.length; i < l; i++) {
            for (var j = 0, ll = clickData.length; j < ll; j++) {
              if (crrMonth[i]._id === clickData[j]._id) {
                crrMonth[i].actionClicks = clickData[j].actionClicks;
                crrMonth[i].spaceClicks = clickData[j].spaceClicks;
                break;
              }
            }
          }
          // console.log(clickData, crrMonth);
          clickData = crrMonth;
          let finalData = [];
          clickData.forEach((d) => {
            finalData.push({
              _id: d._id,
              'Call to Action': d.actionClicks,
              'View in your space': d.spaceClicks,
            });
          });
          // console.log(finalData);
          clickData = finalData;
        }

        if (this.state.set1 === 'last_90_days') {
          let curr = new Date();
          // console.log(curr);
          let crrMonth = [];

          for (let i = 0; i <= 89; i++) {
            let day = new Date(curr);
            day.setDate(day.getDate() - i);
            let month =
              day.getMonth() + 1 < 10
                ? '0' + (day.getMonth() + 1)
                : day.getMonth() + 1;
            let todate =
              day.getDate().toString() < 10
                ? '0' + day.getDate().toString()
                : day.getDate().toString();
            crrMonth.push({
              _id: day.getFullYear() + '-' + month + '-' + todate,
              // day.getDate().toString(),
              actionClicks: 0,
              spaceClicks: 0,
            });
          }

          for (var i = 0, l = crrMonth.length; i < l; i++) {
            for (var j = 0, ll = clickData.length; j < ll; j++) {
              if (crrMonth[i]._id === clickData[j]._id) {
                crrMonth[i].actionClicks = clickData[j].actionClicks;
                crrMonth[i].spaceClicks = clickData[j].spaceClicks;
                break;
              }
            }
          }
          // console.log(clickData, crrMonth);
          clickData = crrMonth;
          let finalData = [];
          clickData.forEach((d) => {
            finalData.push({
              _id: d._id,
              'Call to Action': d.actionClicks,
              'View in your space': d.spaceClicks,
            });
          });
          // console.log(finalData);
          clickData = finalData;
        }

        if (this.state.set1 === 'custom') {
          let srtDate = new Date(this.state.startDate1);
          let enDate = new Date(this.state.endDate1);
          // console.log(srtDate, enDate);
          let a = -(
            (srtDate.getTime() - enDate.getTime()) /
            (1000 * 3600 * 24)
          );
          // console.log(a);
          let curr = enDate;
          let crrMonth = [];
          for (let i = 0; i <= a; i++) {
            let day = new Date(curr);
            day.setDate(day.getDate() - i);
            let month =
              day.getMonth() + 1 < 10
                ? '0' + (day.getMonth() + 1)
                : day.getMonth() + 1;
            let todate =
              day.getDate().toString() < 10
                ? '0' + day.getDate().toString()
                : day.getDate().toString();
            crrMonth.push({
              _id: day.getFullYear() + '-' + month + '-' + todate,
              // day.getDate().toString(),
              actionClicks: 0,
              spaceClicks: 0,
            });
          }
          // console.log(crrMonth, clickData);
          for (var i = 0, l = crrMonth.length; i < l; i++) {
            for (var j = 0, ll = clickData.length; j < ll; j++) {
              if (crrMonth[i]._id === clickData[j]._id) {
                crrMonth[i].actionClicks = clickData[j].actionClicks;
                crrMonth[i].spaceClicks = clickData[j].spaceClicks;
                break;
              }
            }
          }
          // console.log(clickData, crrMonth);
          clickData = crrMonth;
          let finalData = [];
          clickData.forEach((d) => {
            finalData.push({
              _id: d._id,
              'Call to Action': d.actionClicks,
              'View in your space': d.spaceClicks,
            });
          });
          // console.log(finalData);
          clickData = finalData;
        }
        this.setState(
          {
            clickData,
          },
          () => {
            // console.log(this.state.clickData);
          }
        );
      });
  };

  tableDataforViewsCountry = () => {
    axios
      .get(
        API.getUrls.product_analysis_view_top +
        this.state.productId +
        '?query=' +
        'country'
      )
      .then((resp) => {
        // console.log(resp);
        let totalcountry = 0;
        resp.data.data.forEach((country) => {
          totalcountry += country.value;
        });

        this.setState(
          {
            countryData: resp.data.data,
            totalcountry,
          },
          () => {
            // console.log(this.state.totalcountry);
          }
        );
      })
      .catch((err) => {
        if (err) {
          // console.log(err.response);
        }
      });
  };

  tableDataforViewsCity = () => {
    axios
      .get(
        API.getUrls.product_analysis_view_top +
        this.state.productId +
        '?query=' +
        'city'
      )
      .then((resp) => {
        // console.log(resp);
        let totalcity = 0;
        resp.data.data.forEach((city) => {
          totalcity += city.value;
        });
        this.setState({
          cityData: resp.data.data,
          totalcity,
        });
      })
      .catch((err) => {
        if (err) {
          // console.log(err.response);
        }
      });
  };

  piDataforOS = () => {
    axios
      .get(API.getUrls.product_analysis_view_platform + this.state.productId)
      .then((resp) => {
        // console.log(resp);
        let totalosvalue = 0;
        resp.data.data.forEach((os) => {
          totalosvalue += os.value;
        });

        let osData = [];
        resp.data.data.forEach((c, i) => {
          osData.push({
            name:
              c._id === ''
                ? 'N/A' +
                '  ' +
                Math.round((c.value / totalosvalue) * 100) +
                '%'
                : c._id +
                ' ' +
                Math.round((c.value / totalosvalue) * 100) +
                '%',
            value: Math.round((c.value / totalosvalue) * 100),
            fill: color[i],
          });
        });
        osData.push({
          name: '',
          value: Math.round(1 * 100),
          fill: '#fff',
        });
        // console.log(osData);

        // color.forEach((item, i) => {
        //   osData[i].fill = item;
        // });
        this.setState(
          {
            osData,
            totalosvalue,
          },
          () => {
            // console.log(this.state.osData, this.state.totalosvalue);
          }
        );
      })
      .catch((err) => {
        if (err) {
          // console.log(err.response);
        }
      });
  };

  getAllAnalysis = (Show_Length_Of_Data = false) => {
    axios
      .get(`${API.getUrls.getAll_product_analysis + this.state.productId}?sort_column=createdAt&limit=${this.state.allLimit}&page=${this.state.currentPage}`).then((resp) => {
        // console.log(resp);
        let avgTime = 0;
        resp.data.data.docs.forEach((add) => {
          avgTime += add.session;
        });

        this.setState({
          Show_Length_Of_Data: Show_Length_Of_Data
            ? resp.data.data.docs.length
            : 0,
          getAllData: [...this.state.getAllData, ...resp.data.data.docs],
          analyticsLength: resp.data.data.totalDocs,
          hasMore: resp.data.data.hasNextPage,
          totalViews: resp.data.data.docs.sort((a, b) => b.views + b.views)?.[0]
            ?.views,
          totalUniqueViews: resp.data.data.docs.reduce(
            (total, row) => (total + row.fth ? 1 : 0),
            0
          ),
          sessionTime: this.secondsToHms(resp.data.avgSession),
        });
        if (Show_Length_Of_Data)
          setTimeout(() => {
            this.setState({ Show_Length_Of_Data: false });
          }, 2000);
      })
      .catch((err) => {
        if (err) {
          // console.log(err.response);
        }
      });
  };

  fetchNext = () => {
    this.setState({
      currentPage: this.state.currentPage + 1
    }, () => {
      this.getAllAnalysis();
    })
  }

  postExportAnalytics = () => {
    let payload = {
      columns: this.state.postColData,
    };

    axios
      .post(API.postUrls.productExportAnalytics + this.state.productId, payload)
      .then((resp) => {
        // console.log(resp);
        window.open(API.imgUrl + resp.data.url);
      })
      .catch((err) => {
        if (err) {
          // console.log(err.respones);
        }
      });
  };

  secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? ' hr ' : ' hrs ') : '';
    var mDisplay = m > 0 ? m + (m == 1 ? ' min ' : ' mins ') : '';
    var sDisplay = s > 0 ? s + (s == 1 ? ' sec' : ' secs') : '';
    return hDisplay + mDisplay + sDisplay;
  };

  Hiden = () => {
    this.setState({
      show: this.state.show === false ? true : false,
    });
  };

  Hiden1 = () => {
    this.setState({
      show1: this.state.show1 === false ? true : false,
    });
  };

  Hiden2 = () => {
    this.setState({
      show2: this.state.show2 === false ? true : false,
    });
  };

  display = () => {
    this.setState({
      display: this.state.display === false ? true : false,
    });
  };

  handleChange = (event) => {
    this.setState(
      {
        set: event.target.value,
      },
      () => {
        if (this.state.set === 'custom') {
          return;
        }
        this.viewsGraphdata();
      }
    );
  };

  handleChange1 = (event) => {
    this.setState(
      {
        set1: event.target.value,
      },
      () => {
        if (this.state.set1 === 'custom') {
          return;
        }
        this.clickGraphdata();
      }
    );
  };

  isCheck = (e) => {
    // console.log(e);
  };

  handleSelect = (ranges) => {
    this.setState(
      {
        dateState: [ranges.selection],
      },
      () => {
        var event = new Date(this.state.dateState[0].endDate);
        let adddata =
          event.getFullYear() +
          '-' +
          (event.getMonth() + 1) +
          '-' +
          event.getDate();

        var event1 = new Date(this.state.dateState[0].startDate);
        let adddata1 =
          event1.getFullYear() +
          '-' +
          (event1.getMonth() + 1) +
          '-' +
          event1.getDate();

        this.setState(
          {
            endDate: adddata,
            startDate: adddata1,
            set: 'custom',
          },
          () => {
            this.viewsGraphdata();
          }
        );
      }
    );
  };

  handleSelect1 = (ranges) => {
    // console.log(ranges);
    this.setState(
      {
        dateState1: [ranges.selection],
      },
      () => {
        // console.log(this.state.dateState1);
        var event = new Date(this.state.dateState1[0].endDate);
        let adddata =
          event.getFullYear() +
          '-' +
          (event.getMonth() + 1) +
          '-' +
          event.getDate();

        var event1 = new Date(this.state.dateState1[0].startDate);
        let adddata1 =
          event1.getFullYear() +
          '-' +
          (event1.getMonth() + 1) +
          '-' +
          event1.getDate();
        this.setState(
          {
            endDate1: adddata,
            startDate1: adddata1,
            set1: 'custom',
          },
          () => {
            // console.log(this.state.startDate1, this.state.endDate1);
            this.clickGraphdata();
          }
        );
      }
    );
  };
  handleSelect3 = (ranges) => {
    this.setState(
      {
        dateState2: [ranges.selection],
      },
      () => {
        var event = new Date(this.state.dateState[0].endDate);
        let adddata =
          event.getFullYear() +
          '-' +
          (event.getMonth() + 1) +
          '-' +
          event.getDate();

        var event1 = new Date(this.state.dateState[0].startDate);
        let adddata1 =
          event1.getFullYear() +
          '-' +
          (event1.getMonth() + 1) +
          '-' +
          event1.getDate();

        this.setState(
          {
            endDate2: adddata,
            startDate2: adddata1,
            // set2: "custom",
          },
          () => {
            // this.viewsGraphdata();
            // console.log(this.state.endDate2, this.state.startDate2);
          }
        );
      }
    );
  };

  handleProfile = () => {
    AdminPortal.show();
  };

  handleHeadingSelect = (e) => {
    // console.log(e.target.value);
    const value = e.target.value;
    this.state.heading.forEach((head) => {
      if (head.value === value) {
        head.visible = !head.visible;
      }
    });
    // console.log(this.state.heading);
    this.setState({ heading: this.state.heading }, () => {
      let postColData = [];
      this.state.heading.forEach((head) => {
        if (head.visible === true) {
          postColData.push(head._id);
        }
      });
      // console.log(postColData);
      this.setState({ postColData: postColData }, () => {
        // console.log(this.state.postColData);
      });
    });
  };

  handleSelectCheck = (event) => {
    this.setState(
      {
        heading: event.target.value,
      },
      () => {
        // console.log(this.state.heading);
      }
    );
  };

  isActive = (key) => {
    const found_obj = this.state.heading.find((head) => head.value === key);
    return found_obj.visible;
  };

  dateFormate = (date) => {
    let d = (new Date(date) + '').split(' ');
    return [d[2], d[1], d[3]].join(' ');
  };
  render() {
    return (
      <Container className='containerSpacingdashBoard' maxWidth={'lg'}>
        <Grid container spacing={2} className='userDashboard'>
          <Grid item sm={2} className='sidebar'>
            <Sidebar />
          </Grid>
          <Grid item sm={10} className=''>
            {/* <Grid item sm={12} className="iconContainer">
              <div className="iconDivWrapper" onClick={() => this.setState({ showModel2: true })}>
                <img src={calImg} alt="img"   />
              </div>
              <div className="iconDivWrapper">
              <img src={notImg} alt="img" />
              </div>
              <Link
                    to="/userprofile"
                    style={{ textDecoration: "none", color: "#666666" }}
                  >
              <div className="iconDivWrapper">
                <img src={userImg} alt="img"  onClick={this.handleProfile} />
              </div>
              </Link>
            </Grid> */}
            <Grid item sm={12} className='GraphContainer'>
              <ProductViewsGraph
                handleChange={this.handleChange}
                propState={this.state}
                showModel={() => this.setState({ showModel: true })}
              />

              <div className='graphDivWrapper fontStyleLeg'>
                <div className='divAlignItem'>
                  <h2>Number of Clicks</h2>
                  <Box className=''>
                    <FormControl style={{ m: 1, minWidth: 120 }}>
                      <Select
                        value={this.state.set1}
                        style={{
                          paddingLeft: 8,
                          height: 40,
                          border: '2px solid #dcdada',
                          marginTop: '10px',
                          borderRadius: 10,
                        }}
                        variant='outlined'
                        id='filled-age-native-simple'
                        className='selectStyle'
                        displayEmpty
                        onChange={this.handleChange1}
                        defaultValue={this.state.set1}
                        inputProps={{
                          'aria-label': 'Without label',
                        }}
                      >
                        <MenuItem value={'this_week'}>
                          {' '}
                          {/* <Checkbox /> */}
                          This Week{' '}
                        </MenuItem>
                        <MenuItem value={'this_month'}>
                          {' '}
                          {/* <Checkbox /> */}
                          This Month
                        </MenuItem>
                        <MenuItem value={'this_year'}>
                          {' '}
                          {/* <Checkbox /> */}
                          This Year
                        </MenuItem>
                        <MenuItem value={'last_week'}>
                          {' '}
                          {/* <Checkbox /> */}
                          Last Week
                        </MenuItem>
                        <MenuItem value={'last_month'}>
                          {' '}
                          {/* <Checkbox /> */}
                          Last Month
                        </MenuItem>
                        <MenuItem value={'last_year'}>
                          {' '}
                          {/* <Checkbox /> */}
                          Last Year
                        </MenuItem>
                        <MenuItem value={'last_60_days'}>
                          {' '}
                          {/* <Checkbox /> */}
                          Last 60 Days
                        </MenuItem>
                        <MenuItem value={'last_90_days'}>
                          {' '}
                          {/* <Checkbox /> */}
                          Last 90 days
                        </MenuItem>
                        <MenuItem
                          className='customDiv'
                          onClick={() => this.setState({ showModel1: true })}
                          value={'custom'}
                        >
                          <img
                            src={customImg}
                            alt='Img'
                            style={{ cursor: 'pointer' }}
                          />
                          <span style={{ paddingLeft: 12 }}>Custom</span>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  {/* <Button className="btnGraph">
                    This Week <ExpandMoreIcon fontSize="medium" />
                  </Button> */}
                </div>
                {this.state.clickData.length > 0 ? (
                  <div
                    style={{
                      width: 480,
                      marginBottom: 10,
                    }}
                  >
                    {/* <Compo clickData={this.state.clickData} /> */}
                    <ResponsiveContainer width={480} height={350}>
                      <AreaChart
                        data={this.state.clickData}
                        margin={{ top: 20, right: 16, left: 0 }}
                        className='fontGraph'
                      >
                        <XAxis
                          dataKey='_id'
                          reversed={
                            this.state.set1 === 'this_year' ||
                              this.state.set1 === 'last_year'
                              ? false
                              : true
                          }
                        />
                        <YAxis />
                        <CartesianGrid strokeDasharray='3 3' />
                        <Tooltip />
                        <Legend
                          verticalAlign='top'
                          height={12}
                          iconType='circle'
                          iconSize={20}
                          align='right'
                          // content={<CustomLegend propState={this.state} />}
                          content={(props) =>
                            customLegend(props, this.state.clickData)
                          }
                        />
                        <Area
                          type='monotone'
                          dataKey={`Call to Action`}
                          // dataKey={`Call to Action - ${
                          //   this?.state?.clickData.reduce(
                          //     (total, row) => total + row["Call to Action"],
                          //     0
                          //   ) || 0
                          // }`}
                          stroke='#43464d'
                          fill='#a7dafd'
                        />
                        <Area
                          type='monotone'
                          // dataKey="View in your space"
                          dataKey={`View in your space`}
                          // dataKey={`View in your space - ${
                          //   this?.state?.clickData.reduce(
                          //     (total, row) => total + row["View in your space"],
                          //     0
                          //   ) || 0
                          // }`}
                          stroke='#8884d8'
                          fill='#8884d8'
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                ) : (
                  <h2
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      margin: '20%',
                    }}
                  >
                    {' '}
                    Data Not Available
                  </h2>
                )}
              </div>

              <div className='graphDivWrapper1'>
                <div
                  className='divAlignItem'
                  onClick={this.Hiden1}
                  style={{ cursor: 'pointer' }}
                >
                  <h2>Views By Top Countries</h2>
                  {this.state.show1 ? (
                    <ExpandLessIcon
                      fontSize='large'
                      onClick={this.Hiden1}
                      style={{ marginTop: 12 }}
                    />
                  ) : (
                    <ExpandMoreIcon
                      fontSize='large'
                      onClick={this.Hiden1}
                      style={{ marginTop: 12 }}
                    />
                  )}
                </div>
                {this.state.show1 ? (
                  <div className='border-div'>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              className='tableCountriesFonts'
                              style={{ color: ' #828282' }}
                            >
                              Sr.
                            </TableCell>
                            <TableCell
                              className='tableCountriesFonts'
                              style={{ color: ' #828282' }}
                            >
                              Name
                            </TableCell>
                            <TableCell
                              align='center'
                              className='tableCountriesFonts'
                              style={{ color: ' #828282' }}
                            >
                              Views
                            </TableCell>
                            <TableCell
                              className='tableCountriesFonts'
                              style={{ color: ' #828282' }}
                            >
                              {' '}
                              %
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.countryData.length > 0 ? (
                            this.state.countryData.map((data, i) => (
                              <TableRow
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  component='th'
                                  scope='row'
                                  className='tableCountriesFonts'
                                >
                                  {i + 1}
                                </TableCell>
                                <TableCell className='tableCountriesFonts'>
                                  {data._id == '' ? 'N/A' : data._id}
                                </TableCell>
                                <TableCell
                                  align='center'
                                  className='tableCountriesFonts'
                                  style={{ color: '#4EADFF' }}
                                >
                                  {data.value}
                                </TableCell>
                                <TableCell className='tableCountriesFonts'>
                                  {Math.round(
                                    (data.value / this.state.totalcountry) * 100
                                  )}{' '}
                                  %
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell style={{ paddingLeft: 80 }}>
                                <h2>Data Not Found !</h2>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                ) : null}
              </div>

              <div className='graphDivWrapper2'>
                <div
                  className='divAlignItem'
                  onClick={this.Hiden2}
                  style={{ cursor: 'pointer' }}
                >
                  <h2>Views By Top City</h2>
                  {this.state.show2 ? (
                    <ExpandLessIcon
                      fontSize='large'
                      onClick={this.Hiden2}
                      style={{ marginTop: 12 }}
                    />
                  ) : (
                    <ExpandMoreIcon
                      fontSize='large'
                      onClick={this.Hiden2}
                      style={{ marginTop: 12 }}
                    />
                  )}
                </div>
                {this.state.show2 ? (
                  <div className='border-div2'>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              className='tableCityFonts'
                              style={{ color: ' #828282' }}
                            >
                              Sr.{' '}
                            </TableCell>
                            <TableCell
                              className='tableCityFonts'
                              style={{ color: ' #828282' }}
                            >
                              Name
                            </TableCell>
                            <TableCell
                              className='tableCityFonts'
                              style={{ color: ' #828282' }}
                            >
                              Views
                            </TableCell>
                            <TableCell
                              className='tableCityFonts'
                              style={{ color: ' #828282' }}
                            >
                              {' '}
                              %
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.cityData.length > 0 ? (
                            this.state.cityData.map((data, i) => (
                              <TableRow
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  component='th'
                                  scope='row'
                                  className='tableCityFonts'
                                >
                                  {i + 1}
                                </TableCell>
                                <TableCell className='tableCityFonts'>
                                  {data._id == '' ? 'N/A' : data._id}
                                </TableCell>
                                <TableCell
                                  className='tableCityFonts'
                                  style={{ color: '#4EADFF' }}
                                >
                                  {data.value}
                                </TableCell>
                                <TableCell className='tableCityFonts'>
                                  {Math.round(
                                    (data.value / this.state.totalcity) * 100
                                  )}{' '}
                                  %
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell style={{ paddingLeft: 80 }}>
                                <h2>Data Not Found !</h2>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                ) : null}
              </div>

              <div className='graphDivWrapper' style={{ height: 72 }}>
                <div className='divAlignItem'>
                  <h2>
                    Average session time:{' '}
                    <span style={{ color: '#64b7fe' }}>
                      {this.state.sessionTime == ''
                        ? '00 00 00'
                        : this.state.sessionTime}
                    </span>
                  </h2>
                </div>
              </div>

              <div className='graphDivWrapper fontStylePi'>
                <div
                  className='divAlignItem'
                  onClick={this.Hiden}
                  style={{ cursor: 'pointer' }}
                >
                  <h2>Views By Operating System</h2>
                  {this.state.show ? (
                    <ExpandLessIcon
                      fontSize='large'
                      onClick={this.Hiden}
                      style={{ marginTop: 12 }}
                    />
                  ) : (
                    <ExpandMoreIcon
                      fontSize='large'
                      onClick={this.Hiden}
                      style={{ marginTop: 12 }}
                    />
                  )}
                </div>
                {this.state.show ? (
                  <div
                    style={{
                      width: 480,
                      marginBottom: 10,
                    }}
                  >
                    <RadialBarChart
                      width={500}
                      height={
                        this.state.osData.length > 6
                          ? this.state.osData.length * 35
                          : 350
                      }
                      cx={150}
                      cy={150}
                      innerRadius={40}
                      outerRadius={140}
                      barSize={10}
                      data={this.state.osData}
                    >
                      <RadialBar
                        minAngle={0}
                        // label={{ position: "insideBottom", fill: "#777" }}
                        background
                        dataKey='value'
                        legendType='circle'
                        clockWise={true}
                      />
                      <Legend
                        iconSize={10}
                        width={140}
                        height={140}
                        layout='vertical'
                        verticalAlign='bottom'
                        wrapperStyle={style}
                      />
                    </RadialBarChart>
                  </div>
                ) : null}
              </div>
            </Grid>
            <Grid item sm={12} className='GraphContainer1'>
              <div className='graphDivWrapper fontStylePi'>
                <div className='divAlignItem'>
                  <div
                    style={{
                      width: '100px',
                      height: '50px',
                      // border: "1px solid red",
                      display: 'grid',
                      placeItems: 'center',
                      // margin: "auto",
                    }}
                    onDoubleClick={() => {
                      // this.setState({ __ON_SERIAL_NO: true });
                      this.getAllAnalysis(true);
                    }}
                  >
                    {this.state.Show_Length_Of_Data
                      ? this.state.Show_Length_Of_Data
                      : null}
                  </div>
                  <div className='fileImg' style={{ cursor: 'pointer' }}>
                    <img
                      src={file}
                      alt='img'
                      onClick={() => {
                        if (this.state.getAllData.length)
                          this.postExportAnalytics();
                        else alert('No data present');
                      }}
                    />
                  </div>
                  <Button className='btnGraph' onClick={this.display}>
                    Edit Columns
                    <ExpandMoreIcon fontSize='medium' />
                  </Button>
                  {this.state.display ? (
                    <div
                      className='divForAddCol'
                      onClickAway={() => this.setState({ display: false })}
                    >
                      {this.state.heading.map((head, i) => (
                        <div className='fontAddCol'>
                          <label>
                            <input
                              type='checkbox'
                              name={head._id}
                              value={head.value}
                              checked={head.visible}
                              onChange={this.handleHeadingSelect}
                              style={{ marginRight: 8 }}
                            />
                            {head.value}
                          </label>
                        </div>
                      ))}
                    </div>
                  ) : null}
                  {/* {this.state.display ? (
                    <div className="divForAddCol">
                      <FormControl>
                        <InputLabel id="demo-mutiple-checkbox-label" style={{fontSize:"24px"}}>
                          Edit Columns
                        </InputLabel>
                        <Select
                          labelId="demo-mutiple-checkbox-label"
                          id="demo-mutiple-checkbox"
                          multiple
                          displayEmpty={true}
                          value={this.state.heading}
                          onChange={this.handleSelectCheck}
                          input={<Input />}
                          // open={this.display}
                          renderValue={(selected) => selected.join(", ")}
                          // MenuProps={MenuProps}
                        >
                          {column_heading.map((head, i) => (
                            <MenuItem key={i} value={head.value}>
                              <Checkbox
                                checked={
                                  this.state.heading.indexOf(head.value) > -1
                                }
                              />
                              <ListItemText primary={head.value} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    ) : null}  */}
                </div>
                <div>
                  {/* <button onClick={()=>{this.getAllAnalysis()}}>{this.state.getAllData.length}</button> */}
                  <TableContainer component={Paper}>
                    <InfiniteScroll
                      // ref={scrollRef}
                      dataLength={this.state.getAllData.length}
                      next={this.fetchNext}
                      hasMore={this.state.hasMore}
                      loader={<h4>Loading...</h4>}
                      endMessage={
                        <p style={{ textAlign: 'center' }}>
                          <b>Yay! You have seen it all</b>
                        </p>
                      }
                      height={"64vh"}
                    >
                      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            {__ON_SERIAL_NO && (
                              <TableCell className='fontForGraphTable'>
                                Sr. no
                              </TableCell>
                            )}
                            {/* <TableCell className="fontForGraphTable" >Country</TableCell>
                          <TableCell className="fontForGraphTable" >Views</TableCell>
                           <TableCell>%</TableCell> 
                          <TableCell className="fontForGraphTable" >City</TableCell>
                          <TableCell className="fontForGraphTable" >Sessions</TableCell>
                          <TableCell className="fontForGraphTable" >Device Type</TableCell> */}
                            {this.state.heading.map(
                              (head, i) =>
                                head.visible && (
                                  <TableCell className='fontForGraphTable'>
                                    {head.value}
                                  </TableCell>
                                )
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.getAllData.length > 0 ? (
                            this.state.getAllData.map((data, i) => (
                              <TableRow
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                              >
                                {__ON_SERIAL_NO && (
                                  <TableCell
                                    component='th'
                                    scope='row'
                                    className='fontForGraphTableRows'
                                  >
                                    {this.state.getAllData.length - (i + 1)}
                                  </TableCell>
                                )}
                                {this.isActive('Date') && (
                                  <TableCell className='fontForGraphTableRows'>
                                    {data.createdAt
                                      ? this.dateFormate(data.createdAt)
                                      : null}
                                  </TableCell>
                                )}
                                {this.isActive('Product Name') && (
                                  <TableCell className='fontForGraphTableRows'>
                                    {data.product
                                      ? data.product.title
                                        ? data.product.title
                                        : null
                                      : null}
                                  </TableCell>
                                )}
                                {this.isActive('Status') && (
                                  <TableCell className='fontForGraphTableRows'>
                                    {data.product
                                      ? data.product.status
                                        ? data.product.status
                                        : null
                                      : null}
                                  </TableCell>
                                )}
                                {this.isActive('Country') && (
                                  <TableCell className='fontForGraphTableRows'>
                                    {data.country == '' ? 'N/A' : data.country}
                                  </TableCell>
                                )}
                                {this.isActive('Views') && (
                                  <TableCell
                                    className='fontForGraphTableRows'
                                    style={{ color: '#4EADFF' }}
                                  >
                                    {/* {data.views ? data.views : "0"} */}
                                    {/* {data.views ? 1 : 0} */}1
                                  </TableCell>
                                )}
                                {this.isActive('Unique Views') && (
                                  <TableCell
                                    className='fontForGraphTableRows'
                                    style={{ color: '#4EADFF' }}
                                  >
                                    {data.unique_views ? data.unique_views : '0'}
                                  </TableCell>
                                )}
                                {/* <TableCell>%</TableCell> */}
                                {this.isActive('City') && (
                                  <TableCell className='fontForGraphTableRows'>
                                    {data.city == '' ? 'N/A' : data.city}
                                  </TableCell>
                                )}
                                {this.isActive('Sessions') && (
                                  <TableCell className='fontForGraphTableRows'>
                                    {this.secondsToHms(data.session) == ''
                                      ? '0 sec'
                                      : this.secondsToHms(data.session)}
                                  </TableCell>
                                )}
                                {this.isActive('Device Type') && (
                                  <TableCell className='fontForGraphTableRows'>
                                    {data.platform ? data.platform : 'N/A'}
                                  </TableCell>
                                )}
                                {this.isActive(
                                  'Clicks (Call to action button)'
                                ) && (
                                    <TableCell className='fontForGraphTableRows'>
                                      {data.actionClicks == ''
                                        ? '0'
                                        : data.actionClicks}
                                    </TableCell>
                                  )}
                                {this.isActive('Clicks (View in your space)') && (
                                  <TableCell className='fontForGraphTableRows'>
                                    {data.spaceClicks == ''
                                      ? '0'
                                      : data.spaceClicks}
                                  </TableCell>
                                )}
                                {this.isActive('Referrer By Session') && (
                                  <TableCell className='fontForGraphTableRows1'>
                                    <a
                                      href={
                                        data.referrerBySession
                                          ? data.referrerBySession
                                          : null
                                      }
                                    >
                                      {data.referrerBySession
                                        ? data.referrerBySession
                                        : 'N/A'}
                                    </a>
                                  </TableCell>
                                )}
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  width: 220,
                                  alignItems: 'center',
                                }}
                              >
                                <h2>Data Not Found !</h2>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </InfiniteScroll>
                  </TableContainer>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        {/* modal for show datepicker in viewgraph start */}
        <Modal
          visible={this.state.showModel}
          // width="400"
          // height="300"
          effect='fadeInUp'
          onClickAway={() => this.setState({ showModel: false })}
        >
          <DateRangePicker
            // ranges={[selectionRange]}
            onChange={this.handleSelect}
            moveRangeOnFirstSelection={false}
            ranges={this.state.dateState}
            months={2}
            direction='horizontal'
          />
        </Modal>
        {/* modal for show datepicker in viewgraph end */}

        {/* modal for show datepicker in  clickgraph start */}
        <Modal
          visible={this.state.showModel1}
          // width="400"
          // height="300"
          effect='fadeInUp'
          onClickAway={() => this.setState({ showModel1: false })}
        >
          <DateRangePicker
            // ranges={[selectionRange]}
            onChange={this.handleSelect1}
            moveRangeOnFirstSelection={false}
            ranges={this.state.dateState1}
            months={2}
            direction='horizontal'
          />
        </Modal>
        {/* modal for show datepicker in  clickgraph start */}

        {/* modal for show datepicker in  for allcustom start */}
        <Modal
          visible={this.state.showModel2}
          // width="400"
          // height="300"
          effect='fadeInUp'
          onClickAway={() => this.setState({ showModel2: false })}
        >
          <DateRangePicker
            // ranges={[selectionRange]}
            onChange={this.handleSelect3}
            moveRangeOnFirstSelection={false}
            ranges={this.state.dateState1}
            months={2}
            direction='horizontal'
          />
        </Modal>
        {/* modal for show datepicker in for allcustom  start */}
      </Container>
    );
  }
}
