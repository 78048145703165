import React, { useEffect, useState } from 'react';
import HStack from '../../TSEUI/HStack';
import actualityBrandLogo from '../../../Assets/newIcons/actualityBrandLogo.png';
import hamburgerMobile from '../../../Assets/newIcons/hamburgerMobile.png';
import { spacing } from '../UIConsts';
import { useMetaCtx } from './MetaCtxProvider';
import { IconButton } from '@material-ui/core';
import MobileDrawer from '../../MobileDrawer';
import CloseIcon from '@material-ui/icons/Close';
import useBodyScrollLock from '../../../Helpers/useBodyScrollLock';

import edit from '../../../Assets/Images/edit.png';
import { Link } from 'react-router-dom';
import confetti from '../../../Assets/newIcons/confetti.png';
import axios from '../../RecommendProduct/axios';
import { API } from '../../../Constants/APIs';
import AddNewProduct from '../../AddNewProduct';
import ShareUser from '../../ShareUser';
const Navbar = () => {
  const { lockScroll, unLockScroll } = useBodyScrollLock();
  const { metaData } = useMetaCtx();
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    if (openDrawer) lockScroll();
    else unLockScroll();
  }, [openDrawer]);
  const [userDetails, setUserDetails] = useState();
  useEffect(() => {
    axios
      .get(API.getUrls.getUser)
      .then((res) => {
        setUserDetails(res.data.user);
      })
      .catch((err) => {});
  }, []);
  const logout = () => {
    localStorage.clear();
    window.location.href = '/account/logout';
  };
  const [showMessage, setShowMessage] = useState(false);
  return (
    <>
      <HStack
        style={{
          justifyContent: 'space-between',
          padding: `${spacing * 2}px ${spacing * 1}px`,
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.07)',
        }}
      >
        <img src={actualityBrandLogo} width='170px' height='40px' />
        <img
          src={hamburgerMobile}
          width='30px'
          height='15px'
          onClick={() => {
            setOpenDrawer(true);
          }}
        />
      </HStack>
      <MobileDrawer open={true && openDrawer}>
        <HStack
          style={{
            justifyContent: 'flex-end',
          }}
        >
          <IconButton size='large' onClick={() => setOpenDrawer(false)}>
            <CloseIcon fontSize='large' />
          </IconButton>
        </HStack>
        <div
          style={{
            width: 120,
            height: 120,
            borderRadius: 10,
            overflow: 'hidden',
            position: 'relative',
            border: '1px solid #ddd',
            margin: 'auto',
            marginBottom: spacing,
            background: '#ddd',
          }}
        >
          <div
            style={{
              position: 'absolute',
              right: 0,
              top: 0,
              // border: '1px solid red',
            }}
          >
            <img
              src={edit}
              width='20px'
              height='20px'
              alt=''
              className='prodLogo'
              style={{
                cursor: 'pointer',
                margin: spacing / 2,
              }}
              onClick={() => {
                setShowMessage(true);
                setTimeout(() => {
                  setShowMessage(false);
                }, 2000);
              }}
            />
          </div>
          {!userDetails?.company_logo ? null : (
            <img
              src={API.imgUrl + userDetails?.company_logo}
              width={120}
              height={120}
            />
          )}
        </div>
        <div
          style={{
            margin: 'auto',
            fontSize: '14px',
            fontWeight: 400,
            textAlign: 'center',
            maxWidth: '75%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            color: 'pink',
          }}
        >
          {showMessage
            ? 'To change image go to desktop'
            : `                   `}
        </div>
        <div
          style={{
            margin: 'auto',
            fontSize: '1.1rem',
            fontWeight: 600,
            textAlign: 'center',
            maxWidth: '75%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {userDetails?.company_name || `            `}
        </div>
        <Divider />
        <MetaStrip val1='Performance' bold={true} />
        <MetaStrip
          val1='Total Views'
          val2={
            <>
              {metaData?.totalViews}
              <img
                src={confetti}
                alt=''
                width='20px'
                height='25px'
                style={{
                  marginLeft: 4,
                }}
              />
            </>
          }
        />
        <Divider />
        <MetaStrip val1='Products' style={{}} bold={true} />
        <MetaStrip val1='All Products' val2={metaData?.all} bold={true} />
        <MetaStrip val1='Live Products' val2={metaData?.published} />
        <MetaStrip val1='Draft Products' val2={metaData?.drafted} />
        <TSEButton text='Overall Analytics' to='analytics' />
        <Divider />
        <AddNewProduct
          style={{
            width: '63%',
            border: 0,
            outline: 0,
            background: '#D8D8D8',
            borderRadius: '5px',
            color: '#2D3436',
            margin: spacing + 'px ' + 'auto',
            padding: `${spacing * 0.75}px ${spacing * 2}px `,
            paddingLeft: spacing * 2,
            paddingRight: spacing * 2,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            fontSize: '1rem',
            fontWeight: 600,
            display: 'block',
            textTransform: 'none',
            textDecoration: 'none',
          }}
        />{' '}
        {/* <ShareUser /> */}
        <ShareUser is_share_some={true} />
        <TSEButton
          to='/settings'
          text='Settings'
          style={{
            width: '63%',
          }}
        />
        <TSEButton
          text='Logout'
          to='/account/logout'
          onClick={() => {
            logout();
          }}
          style={{
            width: '63%',
          }}
        />
      </MobileDrawer>
    </>
  );
};
const TSEButton = ({ style = {}, text, to, onClick }) => (
  <Link
    to={to}
    style={{
      textDecoration: 'none',
    }}
  >
    <button
      onClick={() => onClick && onClick?.()}
      style={{
        border: 0,
        outline: 0,
        background: '#D8D8D8',
        borderRadius: '5px',
        color: '#2D3436',
        margin: spacing + 'px ' + 'auto',
        padding: spacing,
        paddingLeft: spacing * 2,
        paddingRight: spacing * 2,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontSize: '1rem',
        fontWeight: 600,
        display: 'block',
        textTransform: 'none',
        textDecoration: 'none',
        ...style,
        // margin: spacing,
      }}
    >
      {text}{' '}
    </button>
  </Link>
);

const MetaStrip = ({ bold = false, val1, val2 }) => {
  return (
    <HStack
      style={{
        justifyContent: 'space-between',
        // border: '1px solid red',
        paddingTop: spacing / 1,
        paddingLeft: spacing,
        paddingRight: spacing,
      }}
    >
      <div
        style={{
          fontSize: '1rem',
          fontWeight: bold ? 700 : 'normal',
        }}
      >
        {val1}
      </div>
      {val2 && <HStack>{val2}</HStack>}
    </HStack>
  );
};

const Divider = () => (
  <div
    style={{
      width: '100%',
      borderBottom: '1px solid #ddd',
      marginTop: spacing,
      marginBottom: spacing,
    }}
  />
);

export default Navbar;
