import { Button, Container, Grid, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Toggle from "react-toggle";
import Modal from "react-awesome-modal";
import right from "../Assets/Images/Right.png";
import wrong from "../Assets/Images/Wrong.png";
import cross from "../Assets/Images/cross.png";
import axios from "axios";
import { API } from "../Constants/APIs";
import init from "../Helpers/Windotoken";
// import crossIconImg from "../Assets/newIcons/crossIcon.png";
import crossIconImg from "../Assets/newIcons/crossIcon3.png";
import checkIconImg from "../Assets/newIcons/checkIcon.png";
const CrossIcon = () => (
  <div
    style={{
      height: 12,
      width: 12,
      border: "1px solid rgba(0,0,0,0.5)",
      borderRadius: "50%",
      display: "inline-grid",
      placeItems: "center",
    }}
  >
    <img src={crossIconImg} height="6" width="6" />
  </div>
);

const customPlanDetails = [
  "Custom Hosted Models",
  "Custom Monthly Website Views",
  "First Model Optimization Free",
  "Installation Support",
  "Custom Analytics",
  "Animated Models",
  "Dedicated Account Manager",
  "Custom UI/UX",
];

const customExtraPoints = [
  { light: "AR Model Optimization starts from", bold: "$100 + $20/Variant" },
  { light: "VR starts from", bold: "$200" },
  { light: "Gamified Walkthrough starts from", bold: "$3000 " },
  { light: "Photo Realistic Renderings starts from", bold: "$80" },
];
class Pricing extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    plans: [],
    monthlyPlans: [],
    annualPlans: [],
    itemId: "",
    itemPrice: "",
    loadingBeg: false,
    loadingGrow: false,
    loadingEnte: false,
    alreadypurchased: false,
    modalIdAvailable: false,
    toggleStatus: false,
    purchasedPlanPrice: "",
  };
  componentDidMount() {
    const isAnnualPlanSelected = window.location.search.includes("plan=year");

    if (isAnnualPlanSelected && !this.state.toggleStatus)
      this.setState({
        toggleStatus: true,
      });
    window.scrollTo(0, 0);
    let pId = document.location.href;
    var url = new URL(pId);
    var modalId = url.searchParams.get("modalId");
    // console.log(modalId);
    if (modalId == null) {
      this.setState({
        modalIdAvailable: false,
      });
    } else {
      this.setState({
        modalIdAvailable: true,
      });
    }

    axios
      .get(
        API.getUrls.getPlansPricing +
          "?limit=3&flag=all&status=monthly&plan_status=active"
      )
      .then((res) => {
        // console.log(res);
        if (res.data.data.docs.length > 0) {
          console.clear();
          // console.log("monthlyPlans ", res.data.data.docs);
          this.setState({
            monthlyPlans: res.data.data.docs,
          });
        }
      });

    axios
      .get(
        API.getUrls.getPlansPricing +
          "?limit=3&flag=all&status=annual&plan_status=active"
      )
      .then((resp) => {
        // console.log("annualPlans ", resp.data.data.docs);
        if (resp.data.data.docs.length > 0) {
          this.setState({
            annualPlans: resp.data.data.docs,
          });
        }
      });

    axios.get(API.getUrls.getPlans).then((resp) => {
      // console.log(resp);
      if (resp.data.status === "failed") {
        // console.log("else");
      } else {
        this.setState(
          {
            purchasedPlanPrice:
              resp.data.data.subscription.subscription_items[0].amount,
          },
          () => {
            // console.log(this.state.purchasedPlanPrice);
          }
        );
      }
    });
  }

  gotoPortal = () => {
    let payload = {
      redirect_url: "https://portal.actuality.live/userprofile",
    };
    axios.post(API.postUrls.chargebeePortal, payload).then((resp) => {
      // console.log(resp);
      this.setState(
        {
          portalUrl: resp.data.portal_session.access_url,
        },
        () => {
          window.open(this.state.portalUrl, "_blank").focus();
        }
      );
    });
  };

  payment = (pricingId) => {
    // console.log(pricingId);
    // console.log(this.state.modalIdAvailable);
    // console.log(this.state.itemId,this.state.itemPrice);
    // if (init() === "success")
    // {
    let pId = document.location.href;
    var url = new URL(pId);

    var modalId = url.searchParams.get("modalId");

    localStorage.setItem("productId", modalId);
    localStorage.setItem("pricingId", pricingId);
    let authToken = localStorage.getItem("token");

    axios
      .get(API.getUrls.getPlansNew, {
        headers: { Authorization: "Bearer " + authToken },
      })
      .then((resp) => {
        // console.log("resp from getPamsNew ", resp.data);

        const payload = {
          pricing_id: pricingId,
          redirectUrl: "",
        };

        axios
          .post(API.postUrls.chargebeePaymentNew, payload, {
            headers: { Authorization: "Bearer " + authToken },
          })
          .then((res) => {
            // console.log(res);
            if (res.data.status === "success") {
              window.open(res.data.result.hosted_page.url, "_blank").focus();
              this.setState({
                loadingBeg: false,
                loadingGrow: false,
                loadingEnte: false,
              });
            } else {
              window.location.href = "/account/login";
              this.setState({
                loadingBeg: false,
                loadingGrow: false,
                loadingEnte: false,
              });
            }
          })
          .catch((err) => {
            // console.log("error from chargebeePaymentNew ", err);
          });
        // }
      })
      .catch((err) => {
        // console.log("error from getPlansNew ", err);
      });
    // }
    // else
    // {
    //   window.location.href = "/sign-in";
    // }
  };

  handleToggleChange = () => {
    if (this.state.toggleStatus === true) {
      this.setState({
        toggleStatus: false,
      });
    } else {
      this.setState({
        toggleStatus: true,
      });
    }

    // console.log(this.state.toggleStatus);
  };

  render() {
    return (
      <div>
        <section className="sectionInnerSpacingPricing">
          <Container>
            <section>
              <div>
                <div>
                  <h1 className="plans">Plans And Pricing</h1>
                  <p className="subTitlePlans">
                    30-day free trial, cancel any time.
                  </p>
                  <span
                    className={
                      window.innerWidth < 1100 ? "toggleArea1100" : "toggleArea"
                    }
                  >
                    <span className="annualPlan">Monthly Plans</span>
                    <span style={{ position: "relative", top: "6px" }}>
                      <Toggle
                        checked={this.state.toggleStatus ? true : false}
                        onChange={(e) => this.handleToggleChange(e)}
                        value={this.state.toggleStatus}
                        icons={false}
                      />
                    </span>
                    {/* <Switch
                        color="primary"
                        name="checkedB"
                        inputProps={{ "aria-label": "primary checkbox" }}
                        onChange={this.handleChange} checked={this.state.checked}
                    /> */}
                    <span className="monthlyPlan">Annual Plans</span>
                  </span>
                </div>
              </div>
            </section>

            <section
              className="sectionInnerSpacingAbout2"
              style={{
                marginLeft: window.innerWidth < 1100 ? "-18px" : "0",
                marginTop: "40px",
              }}
            >
              <Grid container spacing={4}>
                {this.state.toggleStatus
                  ? this.state.annualPlans.map((plan, index) => (
                      <Grid item sm={3}>
                        <div
                          className={
                            window.innerWidth < 1100
                              ? "pricingCard1100"
                              : "pricingCard"
                          }
                        >
                          <div
                            className="imgWrapper"
                            style={{
                              cursor: "auto",
                            }}
                          >
                            <h1
                              className="beginner"
                              style={{
                                fontWight: 500,
                                fontSize: 18,
                              }}
                            >
                              {plan.title}
                            </h1>
                            <h1 className="price">
                              ${parseInt(plan.item_price)}
                            </h1>
                            <div
                              className="month"
                              style={{
                                fontSize: 18,
                                fontWeight: 500,
                              }}
                            >
                              /year
                            </div>
                            <p className="modelTitle">{plan.sub_title}</p>
                            <Button
                              size={"large"}
                              variant="contained"
                              className="themeBtn txtShadow roundedPlansPricing buyNow"
                              onClick={() => {
                                this.setState(
                                  {
                                    itemId: plan.item_id,
                                    itemPrice: plan.item_price * 12,
                                    loadingBeg: true,
                                    loadingButBtn: index,
                                  },
                                  () => {
                                    this.payment(plan._id);
                                  }
                                );
                              }}
                            >
                              {this.state.loadingBeg &&
                              this.state.loadingButBtn == index
                                ? "Loading..."
                                : this.state.purchasedPlanPrice
                                ? this.state.purchasedPlanPrice === 299 ||
                                  this.state.purchasedPlanPrice === 249 * 12
                                  ? "Purchased"
                                  : "Upgrade"
                                : "Buy Now"}
                            </Button>

                            <div className="bottomBorder"></div>
                            <div className="vectorArea">
                              {plan.forUI.points.map((item) => {
                                return (
                                  <div
                                    className="paddingTop"
                                    style={{
                                      border: "0px solid red",
                                      padding: "13px 0 0 0",
                                    }}
                                  >
                                    <span
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {item.show ? (
                                        <img
                                          src={
                                            item.show
                                              ? checkIconImg
                                              : crossIconImg
                                          }
                                          alt=""
                                          width="12px"
                                          height="12px"
                                        />
                                      ) : (
                                        <CrossIcon />
                                      )}
                                      {/* <img
                                  src={item.show ? checkIconImg : crossIconImg}
                                  alt=""
                                  width="12px"
                                  height="12px"
                                /> */}
                                      <span className="hostedModels">
                                        {item.value}
                                      </span>
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                            <div className="bottomBorder2"></div>
                            <ExtraPointsDisplayer
                              points={plan.forUI.extraPoints}
                            />
                            {/* <div className="vectorArea2">
                              <div className="paddingTop2">
                                <div className="hostedModels">
                                  AR Model Optimization starts from
                                </div>
                                <span
                                  className="varient"
                                  style={{
                                    border: "0px solid aqua",
                                  }}
                                >
                                  {`$${
                                    plan.title.toLowerCase() == "beginner"
                                      ? 150
                                      : plan.title.toLowerCase() == "growth"
                                      ? 125
                                      : 100
                                  } + $20/Variant`}
                                </span>
                              </div>
                              <div className="paddingTop2">
                                <span className="hostedModels">
                                  VR starts from{" "}
                                </span>
                                <span className="varient2">$300</span>
                              </div>
                              <div className="paddingTop2">
                                <span className="gamefied">
                                  Gamified Walkthrough starts <br />{" "}
                                  <span style={{ paddingLeft: "10px" }}>
                                    from <span className="varient2">$4000</span>{" "}
                                  </span>
                                </span>
                              </div>
                              <div className="paddingTop2">
                                <span className="gamefied">
                                  Photo Realistic Renderings starts <br />{" "}
                                  <span style={{ paddingLeft: "10px" }}>
                                    from <span className="varient2">$150</span>{" "}
                                  </span>
                                </span>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </Grid>
                    ))
                  : this.state.monthlyPlans.map((plan, index) => (
                      <Grid item sm={3}>
                        <div
                          className={
                            window.innerWidth < 1100
                              ? "pricingCard1100"
                              : "pricingCard"
                          }
                        >
                          <div
                            className="imgWrapper"
                            style={{
                              cursor: "auto",
                            }}
                          >
                            <h1
                              className="beginner"
                              style={{
                                fontWight: 500,
                                fontSize: 18,
                              }}
                            >
                              {plan.title}
                            </h1>
                            <h1 className="price">
                              ${parseInt(plan.item_price)}
                            </h1>
                            <div
                              className="month"
                              style={{
                                fontSize: 18,
                                fontWeight: 500,
                              }}
                            >
                              /month
                            </div>
                            <p className="modelTitle">{plan.sub_title}</p>
                            <Button
                              size={"large"}
                              variant="contained"
                              className="themeBtn txtShadow roundedPlansPricing buyNow"
                              onClick={() => {
                                this.setState(
                                  {
                                    itemId: plan.item_id,
                                    itemPrice: plan.item_price,
                                    loadingBeg: true,
                                    loadingButBtn: index,
                                  },
                                  () => {
                                    this.payment(plan._id);
                                  }
                                );
                              }}
                            >
                              {this.state.loadingBeg &&
                              this.state.loadingButBtn == index
                                ? "Loading..."
                                : this.state.purchasedPlanPrice
                                ? this.state.purchasedPlanPrice === 299 ||
                                  this.state.purchasedPlanPrice === 249 * 12
                                  ? "Purchased"
                                  : "Upgrade"
                                : "Buy Now"}
                            </Button>

                            <div className="bottomBorder"></div>

                            <div className="vectorArea">
                              {plan.forUI.points.map((item) => {
                                return (
                                  <div
                                    className="paddingTop"
                                    style={{
                                      border: "0px solid red",
                                      padding: "13px 0 0 0",
                                    }}
                                  >
                                    <span
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {item.show ? (
                                        <img
                                          src={
                                            item.show
                                              ? checkIconImg
                                              : crossIconImg
                                          }
                                          alt=""
                                          width="12px"
                                          height="12px"
                                        />
                                      ) : (
                                        <CrossIcon />
                                      )}
                                      {/* <img
                                  src={item.show ? checkIconImg : crossIconImg}
                                  alt=""
                                  width="12px"
                                  height="12px"
                                /> */}
                                      <span className="hostedModels">
                                        {item.value}
                                      </span>
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                            <div
                              className="bottomBorder2"
                              style={
                                {
                                  // border: "1px solid aqua",
                                }
                              }
                            />
                            <ExtraPointsDisplayer
                              points={plan.forUI.extraPoints}
                            />
                          </div>
                        </div>
                      </Grid>
                    ))}
                <Grid item sm={3}>
                  <div
                    className={
                      window.innerWidth < 1100
                        ? "pricingCard1100"
                        : "pricingCard"
                    }
                  >
                    <div
                      className="imgWrapper"
                      style={{
                        cursor: "auto",
                      }}
                    >
                      <h1 className="beginner">Build Your Plan</h1>
                      <h1 className="price">Flexible</h1>
                      <div
                        className="month"
                        style={{
                          fontSize: 18,
                          fontWeight: 500,
                        }}
                      >
                        /month
                      </div>
                      <p className="modelTitle">
                        All the basics for businesses that are just getting
                        started.
                      </p>
                      <Link to="/contact-us" style={{ textDecoration: "none" }}>
                        <Button
                          size={"large"}
                          variant="contained"
                          className="themeBtn txtShadow roundedPlansPricing buyNow"
                        >
                          Contact Seller
                        </Button>
                      </Link>

                      <div className="bottomBorder"></div>

                      <div className="vectorArea">
                        {customPlanDetails.map((item) => (
                          <div
                            className="paddingTop"
                            style={{
                              border: "0px solid red",
                              padding: "13px 0 0 0",
                            }}
                          >
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={checkIconImg}
                                alt=""
                                width="12px"
                                height="12px"
                              />
                              <span className="hostedModels">{item}</span>
                            </span>
                          </div>
                        ))}
                      </div>
                      <div className="bottomBorder2"></div>
                      <ExtraPointsDisplayer points={customExtraPoints} />
                      {/* <div className="vectorArea2">
                        <div className="paddingTop2">
                          <div className="hostedModels">
                            AR Model Optimization starts from
                          </div>
                          <span
                            className="varient"
                            style={{
                              border: "0px solid aqua",
                            }}
                          >
                            $100 + $20/Variant
                          </span>
                        </div>
                        <div className="paddingTop2">
                          <span className="hostedModels">VR starts from </span>
                          <span className="varient2">$200</span>
                        </div>
                        <div className="paddingTop2">
                          <span className="gamefied">
                            Gamified Walkthrough starts <br />{" "}
                            <span style={{ paddingLeft: "10px" }}>
                              from <span className="varient2">$3000</span>{" "}
                            </span>
                          </span>
                        </div>
                        <div className="paddingTop2">
                          <span className="gamefied">
                            Photo Realistic Renderings starts <br />{" "}
                            <span style={{ paddingLeft: "10px" }}>
                              from <span className="varient2">$80</span>{" "}
                            </span>
                          </span>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </section>
          </Container>
        </section>

        {/* Purchase plan modal */}
        <Modal
          visible={this.state.alreadypurchased}
          width="350"
          height="230"
          effect="fadeInUp"
          onClickAway={() => this.setState({ alreadypurchased: false })}
        >
          <div
            className="confirmationModalPublish"
            style={{ textAlign: "center" }}
          >
            <Typography>You already have purchased plan.</Typography>
            <Button
              onClick={() => this.setState({ alreadypurchased: false })}
              className="DeleteBtnPublish"
              style={{ background: "#bdbdbd", marginTop: "30px" }}
            >
              Ok
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Pricing;
const ExtraPointsDisplayer = ({ points }) => {
  return (
    <div
      className="vectorArea2"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        border: "1px solid white",
        margin: 0,
        // paddingRight: 20,
      }}
    >
      {points.map((point) => (
        <div
          className="paddingTop2"
          key={point.id}
          style={
            {
              // border: "1px solid red",
              // padding: 0,
            }
          }
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              fontSize: 13,
              color: "#000000",
              alignItems: "center",
              // lineHeight: 1.5,
              boxSizing: "border-box",
            }}
          >
            <p
              style={{
                // lineHeight: 2,
                margin: 0,
                padding: 0,
                fontSize: 13,
                fontWeight: 400,
                paddingBottom: 4,
                color: "#000000",
                // maxWidth: "70%",
                paddingRight: 10,
                // display:'il'
              }}
            >
              {" "}
              {point.light}
            </p>
            {/* <div
              style={
                {
                  // width: 40,
                }
              }
            /> */}
            <b
              style={{
                // fontSize: 12,
                fontWeight: 600,
                color: "#000000",
              }}
            >
              {point.bold}
            </b>{" "}
          </div>

          {/* <div className="hostedModels">
      {point.light}
    </div>
    <span
      className="varient"
      style={{
        border: "0px solid aqua",
      }}
    >
      {point.bold}
      
    </span> */}
        </div>
      ))}
      {/* <div className="paddingTop2">
  <div className="hostedModels">
    AR Model Optimization starts from
  </div>
  <span
    className="varient"
    style={{
      border: "0px solid aqua",
    }}
  >
    {`$${
      plan.title.toLowerCase() == "beginner"
        ? 150
        : plan.title.toLowerCase() == "growth"
        ? 125
        : 100
    } + $20/Variant`}
  </span>
</div>
<div className="paddingTop2">
  <span className="hostedModels">
    VR starts from{" "}
  </span>
  <span className="varient2">$300</span>
</div>
<div className="paddingTop2">
  <span className="gamefied">
    Gamified Walkthrough starts <br />{" "}
    <span style={{ paddingLeft: "10px" }}>
      from <span className="varient2">$4000</span>{" "}
    </span>
  </span>
</div>
<div className="paddingTop2">
  <span className="gamefied">
    Photo Realistic Renderings starts <br />{" "}
    <span style={{ paddingLeft: "10px" }}>
      from <span className="varient2">$150</span>{" "}
    </span>
  </span>
</div> */}
    </div>
  );
};
