import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";

import { FronteggProvider } from "@frontegg/react";
import { BrowserRouter } from "react-router-dom";

const isDevEnv = false,
  isTestEnv = !false;

const contextOptions = {
  baseUrl: isDevEnv
    ? "https://app-5zm2xm0s46xc.frontegg.com"
    : `https://account.actuality.live` ||
    "https://app-tsgq004hcy7v.frontegg.com",
};

ReactDOM.render(
  <BrowserRouter>
    <FronteggProvider contextOptions={contextOptions}>
      <App isDevEnv={isDevEnv} isTestEnv={isTestEnv} />
    </FronteggProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

export { isDevEnv };
