import { Checkbox, Grid } from '@material-ui/core';
import React from 'react';
import { spacing } from './const';
import { API } from '../../Constants/APIs';

import eyes from '../../Assets/Images/eyes.png';
import TemplateBtn from './TemplateBtn';
import { RemoveRedEyeRounded } from '@material-ui/icons';
const ProductCard = ({ product, onCheck, selectedProducts }) => {
  return (
    <Grid
      item
      sm={4}
      style={{
        // overflow: 'hidden',
      }}
    >
      <div
        style={{
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',

          borderRadius: 10,
          paddingTop: spacing * 2,
          cursor: 'pointer',
          marginRight: 2,
        }}
        onClick={onCheck}
      >
        <img
          style={{
            height: 200,
            width: 200,
            margin: 'auto',
            display: 'block',
          }}
          src={API.imgUrl + product.poster_image_url}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid #ddd',
            paddingBottom: spacing * 0,
          }}
        >
          <div
            style={{
              flex: 1,
              fontSize: 18,
              fontWeight: 400,
              padding: spacing * 1,
              color: '#2e2e2e',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {product.title}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            // justifyContent: 'center',
            padding: spacing * 2,
            paddingLeft: 18,
            paddingRight: 18,
            color: '#858585'
          }}
        >
          <a
            target='_blank'
            href={`https://actuality.live/modelDynamicviewer/${product._id}`}
            style={{
              textDecoration: 'none',
              color: 'inherit',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'white',
              outline: 'none',
              border: 0,
              height: 35,
              boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
              flex: 1,
              borderRadius: 7,
              marginRight: spacing,
            }}
          >
            <RemoveRedEyeRounded />
            {/* <img src={eyes} height='15' width='25' /> */}
            <div
              style={{
                paddingLeft: spacing,
                fontSize: 13,
                fontWeight: 400,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              Preview
            </div>
          </a>
          <TemplateBtn product_id={product._id} />
        </div>
      </div>
    </Grid>
  );
};

export default ProductCard;
