import { Typography } from "@material-ui/core";
import React, { Component } from "react";
import Lottie from "react-lottie";
import success from "../Assets/lottie/lf30_editor_rxhvowbd.json";
import tick from "../Assets/Images/tick_circle.png";
import Modal from "react-awesome-modal";
// import Button from '@restart/ui/esm/Button';
// import Button from "@restart/ui/esm/Button";
import { Button } from "@restart/ui";

import axios from "axios";
import { Link } from "react-router-dom";
import { API } from "../Constants/APIs";
class PaymentSucces extends Component {
  state = {
    isStopped: true,
    showTick: true,
    openModal: true.valueOf,
    userFirstName: "",
    userLastName: "",
    item: "",
    price: "",
  };
  componentDidMount() {
    // console.log(window.location.href);
    let authToken = localStorage.getItem("token");
    var url_string = window.location.href; //window.location.href
    var url = new URL(url_string);
    var payId = url.searchParams.get("id");
    let productId = localStorage.getItem("productId");
    let pricingId = localStorage.getItem("pricingId");
    // console.log(payId);
    // console.log(productId);
    const payload = {
      page_id: payId,
      pricing_id: pricingId,
    };
    // console.log(payload);
    axios
      .get(API.getUrls.getPlansNew, {
        headers: { Authorization: "Bearer " + authToken },
      })
      .then((respPlan) => {
        // console.log(respPlan);
        if (respPlan.data.status === "failed") {
          axios
            .post(API.postUrls.orderNew, payload, {
              headers: { Authorization: "Bearer " + authToken },
            })
            .then((resp) => {
              // console.log(resp);
              if (resp.data.status === "success") {
                axios
                  .get(API.getUrls.getPlansNew, {
                    headers: { Authorization: "Bearer " + authToken },
                  })
                  .then((res) => {
                    // console.log(res);
                    if (res.data.status === "success") {
                      this.setState({
                        userFirstName: res.data.updatedOrders.user.name,
                        item: res.data.updatedOrders.order_summary.content
                          .subscription.subscription_items[0].item_price_id,
                        price:
                          res.data.updatedOrders.order_summary.content
                            .subscription.subscription_items[0].amount,
                        showTick: true,
                        isStopped: false,
                      });

                      if (productId) {
                        const payload2 = {
                          status: "published",
                        };
                        // console.log(payload2);
                        axios
                          .post(
                            API.postUrls.changeStatus +
                              productId +
                              "?type=product",
                            payload2,
                            {
                              headers: { Authorization: "Bearer " + authToken },
                            }
                          )
                          .then((statusResp) => {
                            // console.log(statusResp);
                            // if (statusResp.data.status === "success") {
                            //   window.location.href = "/modelDynamicviewer/" + payId
                            // }
                          });
                      }
                    }
                  });
              }
            });
        } else {
          if (respPlan.data.updatedOrders.page_id === payId) {
            setTimeout(() => {
              window.location.href = "/allProducts";
            }, 0);
          } else {
            axios
              .post(API.postUrls.orderNew, payload, {
                headers: { Authorization: "Bearer " + authToken },
              })
              .then((resp) => {
                // console.log(resp);
                if (resp.data.status === "success") {
                  axios
                    .get(API.getUrls.getPlansNew, {
                      headers: { Authorization: "Bearer " + authToken },
                    })
                    .then((res) => {
                      // console.log(res);
                      if (res.data.status === "success") {
                        this.setState({
                          userFirstName: res.data.updatedOrders.user.name,
                          item: res.data.updatedOrders.order_summary.content
                            .subscription.subscription_items[0].item_price_id,
                          price:
                            res.data.updatedOrders.order_summary.content
                              .subscription.subscription_items[0].amount,
                          showTick: true,
                          isStopped: false,
                        });

                        if (productId) {
                          const payload2 = {
                            status: "published",
                          };
                          // console.log(payload2);
                          axios
                            .post(
                              API.postUrls.changeStatus +
                                productId +
                                "?type=product",
                              payload2,
                              {
                                headers: {
                                  Authorization: "Bearer " + authToken,
                                },
                              }
                            )
                            .then((statusResp) => {
                              // console.log(statusResp);
                              // if (statusResp.data.status === "success") {
                              //   window.location.href = "/modelDynamicviewer/" + payId
                              // }
                            });
                        }
                      }
                    });
                }
              });
          }
        }
      });
  }

  render() {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: success,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <div className="Lottiewrapper">
        {
          this.state.showTick ? (
            <img src={tick} width="200px" height="200px" alt="" />
          ) : null
          // <Lottie
          //   options={defaultOptions}
          //   height={300}
          //   width={300}
          //   isStopped={this.state.isStopped}
          // />
        }
        <Typography variant={"h5"} className="typo">
          {" "}
          Hello{" "}
          <span style={{ textTransform: "capitalize" }}>
            {this.state.userFirstName}
          </span>
          , You have successfully purchased {this.state.item} plan of amount $
          {this.state.price.toString().slice(0, -2)}.{" "}
          {/* Hello <span style={{textTransform:"capitalize"}}>{this.state.userFirstName}</span> {this.state.userLastName}, You have successfully purchased {this.state.item} plan of amount ${this.state.price.toString().slice(0, -2)}. {" "} */}
          {/* Hello Shivani Game, You have successfully purchased Beginner-USD-Monthly plan of amount Rs.299. */}
        </Typography>

        {/* <Link to="/productlist/allProducts" style={{ textDecoration: "none" }}> */}
        <Link to="/allProducts" style={{ textDecoration: "none" }}>
          <Button
            size={"large"}
            variant="contained"
            className="themeBtnSuccess txtShadow rounded"
            onClick={() => {
              let productId = localStorage.setItem("productId", "");
              let pricingId = localStorage.setItem("pricingId", "");
            }}
          >
            Go To Dashboard
          </Button>
        </Link>

        {/* <Modal
          visible={this.state.openModal}
          width="400"
          height="300"
          effect="fadeInUp"
          onClickAway={() => this.setState({ openModal: false })}
        >
          <div className="confirmationModal" style={{ textAlign: "center" }}>
            <Typography>
              Hello User, You have successfully purchased Beginner Plan.
            </Typography>
            
            <Button
              onClick={() => this.setState({ openModal: false })}
              className="DeleteBtn"
              style={{ background: "#bdbdbd", border: "none", marginTop: "50px" }}
            >
              Close
            </Button>
            <p style={{ color: "green", textTransform: "capitalize" }}>
              {this.state.notification ? this.state.successMsg : ""}{" "}
            </p>
          </div>
        </Modal> */}
      </div>
    );
  }
}

export default PaymentSucces;
