import { useState } from "react";
import Categories from "./Categories";
import MetaCtxProvider from "./MetaCtxProvider";
import Navbar from "./Navbar";
import Products from "./Products";
import Search from "./Search";
import "./style.css";
const MobileHomePage = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [productStatus, setProductStatus] = useState("");
  return (
    <MetaCtxProvider>
      <div
        style={{
          maxWidth: "100%",
          minWidth: "100%",
          minHeight: "100%",
          overflowX: "hidden",
          overflowY: "auto",
        }}
        className="MobileHomePage __NO__SCROLLBAR__"
      >
        <Navbar />
        <Search value={searchQuery} setValue={(q) => setSearchQuery(q)} />
        <Categories
          productStatus={productStatus}
          setProductStatus={setProductStatus}
        />
        <Products productStatus={productStatus} searchQuery={searchQuery} />
      </div>
    </MetaCtxProvider>
  );
};
export default MobileHomePage;
