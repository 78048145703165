import React, { useState } from "react";
import {
  spacing,
  boxShadow,
  borderRadius,
  view_in_3d_btn_short,
  view_in_ar_btn_short,
  view_both_btn_short,
} from "./const";
import viewIn3D from "../../Assets/newIcons/viewIn3D.png";
import viewInAR from "../../Assets/newIcons/viewInAR.png";
import Tab from "./Tabs";
import { copyDataToClipboard } from "../../Helpers/utils";

// import modal_Viewer, { deviceSupported, open_in_ar } from '../Constant';
const ViewInButton = ({ productId }) => {
  const [selected, setSeleted] = useState([true, true]);
  const copyToClipboard = () => {
    if (selected.every((p) => !p)) return;
    let copyVal = "";
    if (selected[0] && selected[1]) copyVal += view_both_btn_short(productId);
    else if (selected[0]) copyVal += view_in_3d_btn_short(productId);
    else if (selected[1]) copyVal += view_in_ar_btn_short(productId);

    if (copyVal) copyDataToClipboard(copyVal);
  };
  return (
    <Tab text="Please select which buttons you’d like to embed on your platform">
      {" "}
      <Trips
        text1={`View in 3D Button`}
        text2={`Allows your clients to view and manipulate this`}
        text3={`3D model in a virtual web environment`}
        btnText={`View in 3D`}
        btnIcon={viewIn3D}
        selected={selected[0]}
        onChange={() => setSeleted((p) => [!p[0], p[1]])}
      />
      <Trips
        text1={`View in AR Button`}
        text2={`Allows your clients to view the model in AR on`}
        text3={`their mobile platform`}
        btnText={`View in AR`}
        btnIcon={viewInAR}
        selected={selected[1]}
        onChange={() => setSeleted((p) => [p[0], !p[1]])}
      />
      <button
        style={{
          border: 0,
          outline: 0,
          background: "#A7D4FE",
          borderRadius,
          boxShadow,
          padding: `${spacing * 2}px ${spacing * 3}px`,
          marginLeft: "50%",
          transform: "translateX(-50%)",
          marginBottom: spacing * 4,
          marginTop: spacing * 3,
          display: "inline-block",
        }}
        onClick={() => copyToClipboard()}
      >
        Copy Button Embed Code
      </button>
    </Tab>
  );
};

const Trips = ({
  text1,
  text2,
  text3,
  btnText,
  btnIcon,
  selected,
  onChange,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "stretch",
        padding: spacing * 3,
        paddingLeft: 0,
        paddingRight: 0,
        boxShadow,
        borderRadius: borderRadius / 2,
        marginTop: spacing,
        marginBottom: spacing * 2,
      }}
    >
      <div
        style={{
          border: "0px solid green",
          padding: spacing * 3,
          borderRight: "1px solid rgba(0,0,0,0.7)",
        }}
      >
        <input
          type="checkbox"
          checked={selected}
          onChange={(e) => onChange()}
          style={{
            width: 50,
            height: 50,
            borderRadius: borderRadius * 2,
          }}
        />
      </div>
      <div
        style={{
          textAlign: "center",
          flex: 1,
          border: "0px solid red",
          borderRight: "1px solid rgba(0,0,0,0.7)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: spacing,
        }}
      >
        <p
          style={{
            fontSize: 24,
            fontWeight: 500,
            color: "#2E2E2E",
          }}
        >
          {text1}
        </p>
        <p
          style={{
            fontSize: 18,
            fontWeight: 500,
            color: "#4B4B4B",
            marginTop: spacing / 2,
            marginBottom: spacing / 2,
          }}
        >
          {text2}{" "}
        </p>
        <p
          style={{
            fontSize: 18,
            fontWeight: 500,
            color: "#4B4B4B",
          }}
        >
          {text3}
        </p>
      </div>
      <div style={{ padding: spacing * 3 }}>
        <button
          style={{
            border: 0,
            outline: 0,
            borderRadius: borderRadius / 2,
            padding: `${spacing * 1.5}px ${spacing * 4}px`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "0px solid red",
          }}
        >
          <img src={btnIcon} height={30} width={30} />
          <span
            style={{
              marginLeft: spacing,
              fontSize: 23,
              fontWeight: 500,
              color: "#2e2e2e",
            }}
          >
            {btnText}
          </span>
        </button>
      </div>
    </div>
  );
};

export default ViewInButton;
