import { API } from "../../Constants/APIs";
import React, { useRef, useState } from "react";

import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { useEffect } from "react";
import { getTextWidth } from "../../Helpers/utils";
import BreakFormattedStrings from "../BreakFormattedStrings";
const ColorMetarial = ({
  material,
  handleEdit,
  onMaterialClick,
  isSelected,
}) => {
  // console.log("material ");

  return (
    <div
      style={{
        marginRight: 20,
        marginBottom: 10,
        maxWidth: 50,
        // border: "1px solid red",
      }}
      key={material._id}
    >
      <div
        style={{
          position: "relative",
          width: 50,
          height: 50,

          marginBottom: 10,
        }}
      >
        <DeleteComb handleEdit={handleEdit} />
        <div
          className="smallBox"
          style={{
            padding: 0,
            margin: 0,
            background: material.material_value,
            cursor: "pointer",
            border: `2px solid rgba(255,255,255,${isSelected() ? "1" : 0})`,
            outline: `2px solid rgba(0,0,0,${isSelected() ? "0.8" : 0})`,
          }}
          onClick={() => onMaterialClick()}
        >
          {/* {material.material_type == "color" ? (
          <div
            className="smallBox"
            style={{
              padding: 0,
              margin: 0,
              background: material.material_value,
              //   height: 50,
              //   width: 50,
            }}
          />
        ) : ( */}
          {material.material_type == "color" ? null : (
            <img
              className="smallBox"
              height="50"
              width="50"
              src={API.imgUrl + material.material_value}
              style={{
                borderRadius: 5,
                marginLeft: 0,
                // marginRight: 10,
                // marginBottom: 8,
                // border: "1px solid blue",
              }}
            />
          )}
          {/* )} */}
        </div>
      </div>
      <p
        style={{
          margin: 0,
          padding: 0,
          fontSize: "14px",
          fontFamily: "Inter",
          textAlign: "center",
          cursor: "pointer",
          textTransform: "capitalize",
          // border: "1px solid red",
          lineHeight: "noraml",
          color: "#565656",
        }}
      >
        <BreakFormattedStrings
          material_name={material.material_name}
          fontSize="14px"
          maxWidth={45}
        />
      </p>
    </div>
  );
};
const DeleteComb = ({ handleEdit }) => {
  return (
    <div
      onClick={handleEdit}
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        transform: "translate(50%,-40%)",
        // padding: 5,
        // border: "1px solid red",
        display: "grid",
        placeItems: "center",
        borderRadius: "50%",
        outline: "1px solid #ddd",
      }}
    >
      <EditOutlinedIcon
        style={{
          color: "gray",
          fontSize: "11px",
          // position: "absolute",
          // right: "-7px",
          // top: "-8px",
          // right: 0,
          // top: 0,
          // transform: "translate(50%,-30%)",
          cursor: "pointer",
          padding: 2,
          //   background: "gray",
          borderRadius: "10px",
          background: "white",
        }}
      />
      {/* <IconButton
      size="small"
      style={{
        background: "#ddd",
        color: "white",
      }}
    >
      <HighlightOffIcon fontSize="small" />
    </IconButton> */}
    </div>
  );
};

const SpanTag = ({ value, index }) => {
  const [isOverflow, setIsOverflow] = useState("csdj");
  const spanRef = useRef();
  useEffect(() => {
    setIsOverflow(spanRef.current.offsetWidth > 45);
  }, []);
  return (
    <>
      {isOverflow && index > 0 ? <br /> : null}
      <span
        ref={spanRef}
        style={{
          wordBreak: "break-all",
          // border: "1px solid blue",
        }}
      >
        {value}
        {getTextWidth("14px", value)} {` `}
      </span>
      {isOverflow ? <br /> : null}

      {/* {isOverflow} */}
    </>
  );
};
export default ColorMetarial;
