import React, { useEffect, useRef, useState } from 'react';
import image from "../Assets/Images/pattern.jpg";
import * as THREE from 'three';
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter";
import { SVGLoader } from 'three/addons/loaders/SVGLoader.js';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { Container, Grid } from '@material-ui/core';
import axios from 'axios';
import { API } from '../Constants/APIs';

const width = window.screen.width
const ModelTemplate = () => {
    const location = useLocation();
    const navigate = useNavigate()

    const [data, setData] = useState(() => queryString.parse(location.search))
    const [file, setFile] = useState(image);
    // const [depth, setDepth] = useState(0.01);
    const [modelDimensions, setModelDimensions] = useState({
        height: data.type === "rugs" ? 0.01 : 0,
        width: 0,
        depth: 0.01
    });
    const [fileName, setFileName] = useState("scene");
    const [scene, setScene] = useState(new THREE.Scene())
    const [isLoading, setIsLoading] = useState(false)
    const [isFileGenerating, setIsFileGenerating] = useState(false)


    const renderModelWithTextture = () => {
        scene.clear();
        setIsLoading(true)
        console.log(modelDimensions)
        document.getElementsByTagName("canvas")[0]?.remove();
        const canvasWrapper = document.getElementById("canvasWrapper");

        const camera = new THREE.PerspectiveCamera();
        camera.position.z = 2;
        // camera.position.z = 5;

        const renderer = new THREE.WebGLRenderer({
            // alpha: true,
            // antialias: true,
        });
        renderer.setClearColor(0x000000, 0);
        // const ambientLight = new THREE.AmbientLight(0x404040);
        // scene.add(ambientLight)
        // document.body?.appendChild(renderer.domElement);
        // renderer.setSize(window.innerWidth, window.innerWidth);
        renderer.setSize(canvasWrapper.offsetWidth, canvasWrapper.offsetHeight)
        canvasWrapper.appendChild(renderer.domElement)
        const controls = new OrbitControls(camera, renderer.domElement);
        controls.update();

        new THREE.TextureLoader().load(file, (texture) => {
            texture.wrapS = THREE.ClampToEdgeWrapping;
            const objForFront = {
                color: "#ddd",
                map: texture,
                side: THREE.DoubleSide
            }

            const objForTop = {
                color: "#ddd",
                map: texture,
                side: THREE.DoubleSide
            }

            if (data.type === "rugs") {
                delete objForFront.map
                delete objForTop.color
            } else {
                delete objForFront.color
                delete objForTop.map
            }

            const material = [
                new THREE.MeshBasicMaterial({ color: "#ddd" }),
                new THREE.MeshBasicMaterial({ color: "#ddd" }),
                new THREE.MeshBasicMaterial(objForTop),
                new THREE.MeshBasicMaterial({ color: "#ddd" }),
                new THREE.MeshBasicMaterial(objForFront),
                new THREE.MeshBasicMaterial({ color: "#ddd", }),
            ];
            const geometry = new THREE.BoxGeometry(modelDimensions.width, modelDimensions.height, modelDimensions.depth);
            const cube = new THREE.Mesh(geometry, material);
            if (data.type === "rugs") {
                scene.rotation.x = 0.2;
                // cube.geometry.rotateX(-20)
            }
            // cube.castShadow = true
            scene.add(cube);
            // white spotlight shining from the side, modulated by a texture, casting a shadow

            // const spotLight = new THREE.SpotLight(0xffffff);
            // spotLight.position.set(100, 1000, 100);
            // // spotLight.map = new THREE.TextureLoader().load(url);

            // spotLight.castShadow = true;

            // spotLight.shadow.mapSize.width = 1024;
            // spotLight.shadow.mapSize.height = 1024;

            // spotLight.shadow.camera.near = 500;
            // spotLight.shadow.camera.far = 4000;
            // spotLight.shadow.camera.fov = 30;
            // scene.add(spotLight);
            setIsLoading(false)
            const animate = () => {
                requestAnimationFrame(animate);
                // cube.rotation.x += 0.01;
                // cube.rotation.y += 0.01;
                renderer.render(scene, camera);
            };
            animate();
        });

    }



    const setCubeSizeFromImage = (imgUrl) => {
        const img = new Image();
        img.src = imgUrl;
        console.log(img)
        img.onload = () => {
            const imgHeight = img.height;
            const imgWeight = img.width;
            const cubeHeight =
                imgHeight * 0.000265;
            const cubeWidth =
                imgWeight * 0.000265;
            console.log(imgHeight, cubeHeight, imgWeight, cubeWidth);
            setModelDimensions({ width: cubeWidth, height: data.type === "rugs" ? modelDimensions.height : cubeHeight, depth: data.type === "rugs" ? cubeHeight : modelDimensions.depth })
        };
    }


    useEffect(() => {
        setCubeSizeFromImage(image)
    }, [])

    useEffect(() => {
        renderModelWithTextture()
    }, [modelDimensions])

    const updateModel = (e) => {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onloadend = () => {
            setFile(reader.result);
            setCubeSizeFromImage(reader.result)
        };
    }

    const submit = () => {
        setIsFileGenerating(true)
        const exporter = new GLTFExporter();
        // scene.rotation.x = 0
        exporter.parse(scene, (gltf) => {
            console.log(gltf)
            saveArrayBuffer(gltf, fileName + ".glb")
        }, (err) => {
            console.log(err)
        }, { binary: true })
    }

    function saveArrayBuffer(buffer, filename) {
        save(new Blob([buffer], { type: 'application/octet-stream' }), filename);
    }
    // const link = document.createElement('a');
    // link.style.display = 'none';
    // document.body.appendChild(link);

    async function save(blob, filename) {
        // link.href = URL.createObjectURL(blob);
        // link.download = filename;
        // link.click();
        // setIsFileGenerating(false)
        try {
            console.log(data)

            const formData = new FormData();
            formData.append('model_file', blob);
            formData.append('product', data.product);
            if (typeof data.materials === "object") {
                data.materials.forEach((m) => {
                    formData.append('materials[]', m);
                });
            }
            if (typeof data.materials === "string") {
                formData.append('materials[]', data.materials);
            }

            let axiosRef = axios;

            let url = API.postUrls.addVariants;
            if (data.variant != "undefined") {
                axiosRef = axiosRef.put;
                url += '/' + data.variant;
            } else {
                axiosRef = axiosRef.post;
                url += '/' + data.product;
            }
            const resp = await axiosRef(url, formData);
            if (resp.data.status === "success") {
                navigate(-1);
            }
        } catch (err) {
            if (err) console.log(err)
            // console.log("error from catych block ", err);
        } finally {
            // navigate(-1)
            setIsFileGenerating(false);
        }
    }



    return (
        <>
            <div className='modelTemplateWrapper' style={{ marginTop: "24px" }}>
                <Container className="containerSpacingdashBoard" maxWidth={"lg"}>
                    <Grid direction='row' container spacing={3} style={{ minHeight: "70vh" }}>
                        <Grid item md={8}>
                            <div className="newShadow" style={{ height: "100%", position: 'relative' }}>

                                <div id='canvasWrapper'></div>
                                <div style={{ display: "flex", justifyContent: "center", position: "absolute", bottom: 20, width: '100%' }}>
                                    <div className='newShadow uploadImageBtn' style={{
                                        width: "360px", display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        minHeight: "48px",
                                        padding: 12,
                                        textAlign: 'center',
                                        position: 'relative',
                                        background: "#fff"
                                    }}>
                                        <input type="file" id="file" style={{ opacity: 0, height: "48px", width: "100%", cursor: 'pointer', position: 'absolute', zIndex: 2 }} onChange={updateModel} />
                                        <span style={{ position: 'absolute', zIndex: 1 }}>Upload Image of your artwork</span>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item md={4}>
                            <div className="newShadow dimensionWrapper" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <div>
                                    <h4 style={{ textAlign: "center", marginBottom: "35px", fontSize: "18px", fontWeight: '500' }}>Adjust the dimensions of your artwork</h4>
                                    <div>
                                        <label>Width</label>
                                        <input className={"formEle"} style={{ border: 'none', outline: 'none' }} value={modelDimensions.width} onChange={(e) => setModelDimensions({ ...modelDimensions, width: e.target.value })} />
                                        {data.type === "rugs" ? <><div>
                                            <label>{"Length"}</label>
                                            <input className={"formEle"} style={{ border: 'none', outline: 'none' }} value={modelDimensions.depth} onChange={(e) => setModelDimensions({ ...modelDimensions, depth: e.target.value })} />
                                        </div>
                                        </> : <>
                                            <label>{"Height"}</label>
                                            <div>
                                                <input className={"formEle"} style={{ border: 'none', outline: 'none' }} value={modelDimensions.height} onChange={(e) => setModelDimensions({ ...modelDimensions, height: e.target.value })} />
                                            </div>
                                        </>}
                                        {data.type === "rugs" ? <>
                                            <label>{"Thickness"}</label>
                                            <div>
                                                <input className={"formEle"} style={{ border: 'none', outline: 'none' }} value={modelDimensions.height} onChange={(e) => setModelDimensions({ ...modelDimensions, height: e.target.value })} />
                                            </div>
                                        </> : <><div>
                                            <label>{"Length"}</label>
                                            <input className={"formEle"} style={{ border: 'none', outline: 'none' }} value={modelDimensions.depth} onChange={(e) => setModelDimensions({ ...modelDimensions, depth: e.target.value })} />
                                        </div>
                                        </>
                                        }
                                        {/* <div>
                                            <label>{data.type === "rugs" ? "Length" : "Depth"}</label>
                                            <input className={"formEle"} style={{ border: 'none', outline: 'none' }} value={modelDimensions.depth} onChange={(e) => setModelDimensions({ ...modelDimensions, depth: e.target.value })} />
                                        </div> */}
                                    </div>
                                </div>
                                <div>
                                    <p style={{ textAlign: 'center', fontSize: "16px", fontWeight: '400', color: "rgba(46, 46, 46, 1)" }}>* Dimension units are in meter *</p>
                                </div>
                                {/* <input style={{ border: 'none', outline: 'none' }} value={fileName} onChange={(e) => setFileName(e.target.value)} /> */}
                            </div>
                            <button disabled={isFileGenerating} onClick={submit} className='uploadBtn'>{isFileGenerating ? "Uploading..." : "Save"}</button>
                        </Grid>
                    </Grid>
                </Container>
            </div >

        </>
    )

}

export default ModelTemplate;