import "./style.css";
const ContentStyleWrapper = ({ content }) => (
  <div
    className="__ACTUALITY_SUN_EDITOR_CONTENT"
    dangerouslySetInnerHTML={{
      __html: content,
    }}
  />
);
export default ContentStyleWrapper;
