import { Container, Typography } from '@material-ui/core';
import axios from 'axios';
import React, { Component } from 'react';
import renderHTML from 'react-render-html';
import { API } from '../Constants/APIs';
class PrivacyPolicy extends Component {
  state = {
    privacy: '',
    description: '',
  };
  componentDidMount() {
    this.getPrivacy();
  }

  getPrivacy = () => {
    axios.get(API.getUrls.getPrivacy).then((res) => {
      this.setState({
        privacy: res.data.privacy.content,
      });
    });
  };
  render() {
    return (
      <Container className="containerSpacingdashBoard" maxWidth={'lg'}>
        <div className="privacyPolicy">
          <Typography align={'center'} variant={'h3'} className="descripttag">
            {' '}
            Privacy Policy
          </Typography>
          <Typography> {renderHTML(this.state.privacy)} </Typography>
        </div>
      </Container>
    );
  }
}

export default PrivacyPolicy;
