import { Button } from '@material-ui/core';
import React, { Component } from 'react';
export default class Payments extends Component {
  render() {
    return (
      <div style={{ position: 'relative', width: '100%' }}>
        <div style={{ width: '80%', margin: 'auto 100px auto' }}>
          <div className="container signIn">
            <h3 className="mainHeading">
              {' '}
              You need to setup a payment method
              <br />
              before proceeding
            </h3>
            <form onSubmit={this.login} id="myForm">
              <div className="inputWrapper">
                <span className="spanTag emailTag">Select Bank</span>

                <input
                  className="Input"
                  name="email"
                  required
                  onChange={this.handlechange}
                />
              </div>
              <br />
              <div className="inputWrapper">
                <span
                  className="spanTag passTag"
                  style={{ marginLeft: '-76px' }}
                >
                  Enter Card Number
                </span>
                <input
                  className="Input"
                  name="password"
                  required
                  type="password"
                  onChange={this.handlechange}
                />
              </div>
              <p
                className="forgotPass"
                style={{ marginLeft: '27%', textAlign: 'center' }}
              >
                Need Help ?
              </p>
              <Button
                variant="contained"
                type="submit"
                className="submitBtn"
                style={{ marginLeft: '94px', width: '16%' }}
              >
                Add
              </Button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
