import React from 'react';
import TSEDialog from '../TSEDialog';
import ProductSharePopUpCompo from './ProductSharePopUpCompo';

const ProductSharePopUp = ({
  openShareDialog,
  handleClose,
  productId,
  language,
  modelSrc,
}) => {
  return (
    <TSEDialog
      minHeight="40vh"
      minWidth="70vw"
      maxWidth="70vw"
      open={openShareDialog}
      onClickOverlay={handleClose}
    >
      {!productId ? null : (
        <ProductSharePopUpCompo
          modelSrc={modelSrc}
          handleClose={handleClose}
          productId={productId}
          language={language}
        />
      )}
    </TSEDialog>
  );
};

export default ProductSharePopUp;
