import "@google/model-viewer";
import { Button, Grid, Typography } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import axios from "axios";
import QRCode from "qrcode.react";
import qs from "query-string";
import React, { Component, createRef } from "react";
import Modal from "react-awesome-modal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Lottie from "react-lottie";
import "../../App.scss";
import arimage from "../../Assets/Images/ar_icon.png";
import rotate from "../../Assets/Images/rotate.png";
import remove from "../../Assets/Images/delete.png";
// import share from "../../Assets/Images/share.png";
import share from "../../Assets/Images/sharebtn.png";
import zoom from "../../Assets/Images/zoom-in.png";
import loader from "../../Assets/lottie/3dloader.json";
import MobileModelDynamicViewer from "./MobileDynamic";
import { API } from "../../Constants/APIs";
import logo from "../../Assets/Images/actualityLogo.png";
import init from "../../Helpers/Windotoken";
import AnnotationBtns from "./AnnotationBtns";
import HotspotContentDrawer from "../HotspotContentDrawer";
import { deviceSupported } from "../Constants";
// import Drawer from "../Drawer";
// import ContentStyleWrapper from "./ContentStyleWrapper";

const screen = window.screen.width <= 1024 ? "mobile" : "desktop";

export default class ModalDynamicCopy extends Component {
  constructor(props) {
    super(props);
    this.modelRef = createRef();
    this.annotBtnCloseCB = createRef();

    // console.log(this.props);
    this.state = {
      model: {},
      product: {},
      variantIndex: 0,
      revealModel: false,
      mesh: [],
      Components: [],
      frames: [],
      selected_material_id: 0,
      selected_component_id: "",
      showModalBtn: screen === "desktop",
      showVariant: false,
      selectedVariant: {},
      meshes: [],
      Variants: [],
      model_file_url: "",
      ModalImage: "",
      productId: window.location.pathname.split("/")[2],
      variantsCombo: [],
      Component1: [],
      Component2: [],
      visible: false,
      value: "",
      embedCode: "",
      QRCode: "",
      copied: false,
      qrCopy: false,
      embedCopy: false,
      visiblity: true,
      selectedMaterials: [],
      language: "",
      screen: qs.parse(window.location.search).screen
        ? qs.parse(window.location.search).screen
        : screen,
      productStatus: "",
      showHotspot: {
        isTrue: false,
        data: {},
      },
    };
  }

  componentDidMount() {
    // console.log(this.state.productId);
    var payId = window.location.pathname.split("/")[2];
    // console.log(payId);
    axios.get(API.getUrls.getSingleProduct + payId).then((resp) => {
      // console.log(resp);
      // console.log(resp.data.product);
      if (resp.data.status === "success") {
        this.setState({
          visiblity: resp.data.product.visibility,
          productStatus: resp.data.product.status,
          language: resp.data.product.language,
        });
        if (this.state.productStatus === "published") {
          // console.log("if");
          this.setState({
            embedCode:
              "<iframe src='" +
              "https://actuality.live/modelDynamicviewer/" +
              this.props.matchparams.id +
              "' title='some' scrolling='No' height='750px' width='100%' frameborder='0'></iframe>",
            value:
              "https://actuality.live/modelDynamicviewer/" +
              this.props.matchparams.id,
            QRCode:
              "https://actuality.live/modelDynamicviewer/" +
              this.props.matchparams.id,
          });

          this.setModel();
          this.getComponents();
          this.getVariants();
          this.postViews();
        } else {
          // console.log("else");
          this.setState({
            embedCode:
              "<iframe src='" +
              "https://portal.actuality.live/modelDynamicviewer/" +
              this.props.matchparams.id +
              "' title='some' scrolling='No' height='750px' width='100%' frameborder='0'></iframe>",
            value:
              "https://portal.actuality.live/modelDynamicviewer/" +
              this.props.matchparams.id,
            QRCode:
              "https://portal.actuality.live/modelDynamicviewer/" +
              this.props.matchparams.id,
          });
          this.setModel();
          this.getComponents();
          this.getVariants();
          this.postViews();
        }
      }
    });
  }
  postViews = () => {
    // if (init() === "success") {
    //   axios
    //     .post(API.postUrls.postViews + "/" + this.props.matchparams.id)
    //     .then((res) => {
    //     });
    // const payload = {
    //   prodId : this.state.productId,
    // }
    // axios
    // .put(API.postUrls.websiteViews ,payload)
    // .then((res) => {
    // console.log(res);
    // });
    // }
  };
  openModal = () => {
    this.setState({
      visible: true,
    });
  };
  closeModal = () => {
    this.setState({
      visible: false,
    });
  };
  setUpModal = () => {
    var modal = document.getElementById("myModal");
    var modalContent = document.querySelector(".modal-content");

    var btn = document.getElementById("qrtext");

    var span = document.getElementsByClassName("closeBtn")[0];

    if (btn) {
      btn.onclick = function () {
        modal.style.display = "block";
        modalContent.classList.add("slideIn");
      };
    }

    span.onclick = function () {
      modalContent.classList.remove("slideIn");
      modal.style.display = "none";
    };

    window.onclick = function (event) {
      if (event.target === modal) {
        modalContent.classList.remove("slideIn");
        modal.style.display = "none";
      }
    };
  };

  setModel = () => {
    axios
      .get(API.getUrls.getProducts + "/" + this.props.matchparams.id)
      .then((res) => {
        // console.log(res);
        this.setState(
          {
            product: res.data.product,
            revealModel: true,
          },
          () => {
            this.setUpModal();
          }
        );
      });
  };
  getComponents = () => {
    axios
      .get(
        API.getUrls.getComponents +
          this.props.matchparams.id +
          "?page=1&limit=10"
      )
      .then((res) => {
        // console.log(res);
        this.setState(
          {
            Components: res.data.components.docs,
          },
          () => {
            let selectedMaterials = [];
            this.state.Components.forEach((component) => {
              selectedMaterials.push({
                material: component.materials[0]._id,
                component: component._id,
              });
            });
            this.setState({ selectedMaterials });
          }
        );
      });
  };
  getVariants = () => {
    axios
      .get(
        API.getUrls.variants +
          "?page=1&limit=10&product=" +
          this.props.matchparams.id
      )
      .then((res) => {
        // console.log(res);
        if (res.data.docs.length > 0) {
          this.setState({
            Variants: res.data.docs,
            selectedVariant: res.data.docs[0],
            ModalImage: res.data.docs[0].model_file_url,
          });
        }
      });
  };

  selectMaterial = (matId, compId) => {
    const selectedMaterials = [];
    this.state.selectedMaterials.forEach((item) => {
      selectedMaterials.push({
        material: compId === item.component ? matId : item.material,
        component: item.component,
      });
    });
    this.setState(
      {
        selectedMaterials,
      },
      () => {
        this.applyChanges();
      }
    );
  };

  checkSelected = (matId, comId) => {
    const material = this.state.selectedMaterials.find(
      (item) => item.component === comId && item.material === matId
    );

    return material ? true : false;
  };

  selectVariant = (variant) => {
    this.setState({ selectedVariant: variant });
  };
  applyChanges = () => {
    let filteredVariant = {};
    let totalSelected = this.state.selectedMaterials.length;
    this.state.Variants.forEach((variant) => {
      const materials = variant.materials.filter((material) =>
        this.state.selectedMaterials.some(
          (item) =>
            item.material === material._id &&
            material.component === item.component
        )
      );
      if (materials.length === totalSelected) {
        filteredVariant = variant;
      }
    });
    this.setState({
      selectedVariant: filteredVariant,
      showVariant: true,
    });
  };
  downloadQR = () => {
    const canvas = document.getElementById("QRCode");
    const pngUrl = canvas
      .toDataURL(window.location.href)
      .replace(window.location.href, window.location.href);
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "QRCode";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  redirectToUrl = () => {
    if (this.state.product.link_url) {
      window.open(this.state.product.link_url);
    }
  };

  copyUrl = (e) => {
    this.setState({
      copied: true,
    });
    setTimeout(() => {
      this.setState({
        copied: false,
      });
    }, 2000);
  };

  copyEmbed = (e) => {
    this.setState({
      embedCopy: true,
    });
    setTimeout(() => {
      this.setState({
        embedCopy: false,
      });
    }, 2000);
  };

  render() {
    const model = this.state.model;
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: loader,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <div
        className="modelViewer"
        style={{
          padding: this.state.screen === "desktop" ? "0 24px" : 0,
          border: "0px solid aqua",
          display: "flex",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {this.state.screen === "desktop" ? (
          <>
            {this.state.revealModel ? (
              <Grid
                container
                spacing={4}
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  height: "95vh",

                  border: "0px solid red",
                  position: "relative",
                }}
              >
                <HotspotContentDrawer
                  open={this.state.showHotspot.isTrue}
                  onClose={() => {
                    this.setState({ showHotspot: { isTrue: false, data: {} } });
                    this.annotBtnCloseCB.current();
                  }}
                  title={this.state.showHotspot.data.title}
                  description={this.state.showHotspot.data.description}
                />

                <Grid
                  item
                  sm={9}
                  style={{
                    height: "95vh",
                    position: "relative",
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                >
                  <model-viewer
                    ref={(elem) => {
                      this.modelRef.current = elem;
                    }}
                    exposure={model.exposure}
                    scale={1}
                    src={
                      this.state.showVariant
                        ? API.imgUrl + this.state.selectedVariant.model_file_url
                        : API.imgUrl + this.state.ModalImage
                    }
                    shadow-intensity={model.shadow_intensity}
                    quick-look-browsers="safari chrome Firefox"
                    camera-orbit="45deg 55deg 2.5m"
                    field-of-view="60deg"
                    min-field-of-view={
                      window.innerWidth < 600 ? "90deg" : "55deg"
                    }
                    max-field-of-view={
                      window.innerWidth < 600 ? "90deg" : "55deg"
                    }
                    camera-controls
                    ar
                    ar-modes="webxr scene-viewer quick-look"
                    ar-placement={"floor"}
                    autoplay
                    style={{
                      background: "#fff",
                      boxShadow: "1px 2px 5px rgba(0, 0, 0, 0.1)",
                      borderRadius: "15px",
                      height: "95vh",
                      width: "100%",
                      position: "relative",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    <AnnotationBtns
                      annots={this.state?.selectedVariant?.hotspots}
                      openHotspotDrawer={(annot, cb) => {
                        this.annotBtnCloseCB.current = cb;
                        this.modelRef.current.setAttribute(
                          "camera-target",
                          annot.cameraTarget
                        );
                        this.modelRef.current.setAttribute(
                          "camera-orbit",
                          annot.cameraOrbit
                        );
                        this.modelRef.current.setAttribute(
                          "zoom",
                          annot?.zoom || 12
                        );
                        this.setState({
                          showHotspot: {
                            isTrue: true,
                            data: {
                              title: annot.title,
                              description: annot.description,
                            },
                          },
                        });
                      }}
                    />
                    <Typography
                      className="brand"
                      style={{
                        marginTop: 15,
                        border: "0px solid aqua",
                        width: 112,
                        height: 112,
                      }}
                    >
                      <div
                        className="modelCompanyLogo"
                        style={{
                          border: "0px solid red",
                          width: 112,
                          height: 112,
                        }}
                      >
                        <img
                          src={API.imgUrl + this.state.product.product_logo}
                          alt=""
                          style={{ borderRadius: "16%" }}
                          width="112"
                          height="112"
                        />
                      </div>
                    </Typography>

                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                      slot="poster"
                    >
                      <Lottie
                        options={defaultOptions}
                        height={100}
                        width={100}
                        autoplay
                        loop
                      />
                      <h2 style={{ textAlign: "center" }}>Loading...</h2>
                    </div>
                    <button
                      slot="ar-button"
                      style={{
                        backgroundColor: "transparent",
                        borderRadius: "4px",
                        border: "none",
                        boxShadow:
                          "0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                        position: "absolute",
                        top: 12,
                        right: "16px",
                        background: "#fff",
                      }}
                    >
                      <div>
                        <img
                          src={arimage}
                          width="20px"
                          alt=""
                          style={{
                            marginRight: "4px",
                            position: "relative",
                            top: 5,
                          }}
                        />
                        <p
                          style={{
                            fontWeight: "bold",
                            fontSize: 12,
                            display: "inline-block",
                          }}
                        >
                          {this.state.language === "French"
                            ? "Voir dans votre espace"
                            : "View in your space"}
                        </p>
                      </div>
                    </button>
                    <div className="iconWrapper">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                          <a
                            href="https://actuality.live"
                            style={{ textDecoration: "none", color: "#000000" }}
                          >
                            <h5
                              style={{
                                marginLeft: "15px",
                                color: "#565656",
                                fontWeight: "400",
                                cursor: "pointer",
                                fontSize: "15px",
                                marginBottom: 21,
                              }}
                            >
                              {this.state.language === "French"
                                ? "Alimenté par"
                                : "Powered By"}
                              <b>actuality.live</b>
                            </h5>
                          </a>
                        </div>
                      </div>
                      <span>
                        <img
                          src={rotate}
                          alt=""
                          style={{ width: "40px", height: "40px" }}
                        />
                        <img src={zoom} width={"32px"} alt="" />

                        <img
                          src={share}
                          style={{ cursor: "pointer" }}
                          height={"24px"}
                          alt=""
                          onClick={() => {
                            this.openModal();
                          }}
                        />
                      </span>
                    </div>
                  </model-viewer>
                </Grid>
                <Grid
                  sm={3}
                  style={{
                    position: "relative",
                    height: "95vh",
                    boxSizing: "border-box",
                    paddingLeft: 10,
                    border: "0px solid red",
                  }}
                >
                  <>
                    <div className="cardFirstPreview">
                      <Typography // className="modelTitlePreview"
                        style={{ fontWeight: 500, fontSize: 18 }}
                        variant="h5"
                      >
                        {this.state.product.title}
                      </Typography>
                      {/* <p className="subtitlePreview">Scroll to zoom</p> */}
                      <p className="subtitlePreview">
                        {this.state.product
                          ? this.state.product.sub_title === "undefined"
                            ? ""
                            : this.state.product.sub_title
                          : null}
                      </p>
                      {/* <p className="subtitlePreview">{this.state.product.user.company_name}</p> */}
                    </div>

                    {this.state.visiblity ? (
                      <div
                        className="cardPreview"
                        onClick={() => {
                          this.redirectToUrl();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <Typography
                          gutterBottom
                          className="buyNowPreview"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.redirectToUrl();
                          }}
                        >
                          {this.state.product.link_title
                            ? this.state.product.link_title
                            : "Buy Now"}
                        </Typography>
                        <p
                          className="buy_subPreview"
                          style={{ cursor: "pointer" }}
                        >
                          {this.state.product.link_description
                            ? this.state.product.link_description
                            : "From Someweb.com"}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                  <div

                  // className="OverFlowComponents"
                  >
                    {this.state.Components.map((single, index1) => (
                      <div className="card" key={single?._id || index1}>
                        <div className="variant_head_wrapper">
                          <Typography
                            gutterBottom
                            className="buyNowPreview"
                            style={{ lineHeight: "40px", fontWeight: 500 }}
                          >
                            {single.component_name}
                          </Typography>
                        </div>
                        <div className="fixBox">
                          {single.materials[0]._id
                            ? single.materials.map((material, index) => (
                                <div key={material._id || index}>
                                  {material.material_type === "color" ? (
                                    <>
                                      <div
                                        onClick={() => {
                                          this.selectMaterial(
                                            material._id,
                                            single._id
                                          );
                                        }}
                                        className="paletteBox"
                                        style={{
                                          backgroundColor:
                                            material.material_value,
                                          margin: "16px 20px 16px 9px",
                                        }}
                                        key={index}
                                      >
                                        {this.checkSelected(
                                          material._id,
                                          single._id
                                        ) ? (
                                          <div className="selected">
                                            <Check style={{ color: "#000" }} />
                                          </div>
                                        ) : null}
                                      </div>
                                      <div
                                        style={{
                                          inlineSize: "58px",
                                          overflowWrap: "break-word",
                                        }}
                                      >
                                        <p
                                          style={{
                                            textAlign: "center",
                                            fontSize: "14px",
                                            fontFamily: "Inter",
                                            width: "78px",
                                            marginTop: "-6px",
                                            marginLeft: "-4px",
                                          }}
                                        >
                                          {material.material_name}
                                        </p>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        className="paletteBox"
                                        style={{
                                          margin: "16px 20px 16px 9px",
                                        }}
                                      >
                                        <img
                                          onClick={() => {
                                            this.selectMaterial(
                                              material._id,
                                              single._id
                                            );
                                          }}
                                          key={index}
                                          src={
                                            API.imgUrl + material.material_value
                                          }
                                          className="paletteBox"
                                          style={{
                                            marginTop: "0px",
                                            marginLeft: "0px",
                                          }}
                                          alt=""
                                        />
                                        {this.checkSelected(
                                          material._id,
                                          single._id
                                        ) ? (
                                          <div className="selected">
                                            <Check style={{ color: "#000" }} />
                                          </div>
                                        ) : null}
                                      </div>
                                      <div
                                        style={{
                                          inlineSize: "58px",
                                          overflowWrap: "break-word",
                                        }}
                                      >
                                        <p
                                          style={{
                                            textAlign: "center",
                                            fontSize: "14px",
                                            fontFamily: "Inter",
                                            width: "78px",

                                            marginTop: "-6px",
                                            marginLeft: "-4px",
                                          }}
                                        >
                                          {material.material_name}
                                        </p>
                                      </div>
                                    </>
                                  )}
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                    ))}
                  </div>

                  {this.state.showModalBtn ? (
                    <div id="qrtext" style={{ bottom: 0 }}>
                      <img
                        src={arimage}
                        width="24px"
                        alt=""
                        style={{ marginRight: "4px" }}
                      />
                      <p className="view_space_btn">
                        {" "}
                        {this.state.language === "French"
                          ? "Voir dans votre espace"
                          : "View in your space"}{" "}
                      </p>
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            ) : null}
          </>
        ) : (
          <MobileModelDynamicViewer
            model={this.state.ModalImage}
            visibility={this.state.visiblity}
            product={this.state.product.title}
            Component={this.state.Components}
            selectMaterial={this.selectMaterial}
            checkSelected={this.checkSelected}
            showVariant={this.state.showVariant}
            selectedVariant={this.state.selectedVariant}
            ed={this.ed}
            visibleModal={this.openModal}
            ViewModal={this.setUpModal}
            openLink={this.redirectToUrl}
            UrlTitle={this.state.product.link_title}
            UrlDescription={this.state.product.link_description}
            language={this.state.language}
            productLogo={this.state.product.product_logo}
          />
        )}
        <div id="myModal" className="modal"></div>
        <div className="modal-content">
          <div className="contentArea">
            <h1>
              {this.state.language === "French"
                ? "Comment afficher en réalité augmentée"
                : "How to View in Augmented Reality"}{" "}
            </h1>
            <p>
              {this.state.language === "French"
                ? `Scannez ce QR code avec votre téléphone pour visualiser l'objet dans votre espace. L'expérience se lance directement depuis votre navigateur - aucune application requise ! `
                : `Scan this QR code with your phone to view the object in your
              space. The experience launches directly from your browser - no app
              required !`}
            </p>
            <p>
              {deviceSupported(this.state.language)}
              {/* {this.state.language === "French"
                ? `fonctionne mieux avec l'iPhone 12 et supérieur`
                : `*works best with iPhone 12 & above`}{" "} */}
            </p>
          </div>
          <div id="qrCodeWrapper">
            {this.state.productStatus === "draft" ? (
              <QRCode
                value={
                  "https://portal.actuality.live/modelDynamicviewer/" +
                  this.props.matchparams.id
                }
                style={{ height: 200, width: 200, marginTop: 12 }}
              />
            ) : (
              <QRCode
                value={
                  "https://actuality.live/modelDynamicviewer/" +
                  this.props.matchparams.id
                }
                style={{ height: 200, width: 200, marginTop: 12 }}
              />
            )}
          </div>
          <span className="closeBtn">
            {this.state.language === "French" ? "Proche" : "Close"}
          </span>
        </div>
        <Modal
          visible={this.state.visible}
          width="550"
          height="450"
          effect="fadeInUp"
          onClickAway={() => this.closeModal()}
        >
          <div className="shareModal web">
            <span
              className="textMain"
              style={{ display: "block", textAlign: "center" }}
            >
              Share
            </span>
            <img
              src={remove}
              width="15px"
              height="15px"
              alt=""
              className="colseModelbtn"
              onClick={() => this.closeModal()}
            />
            {/* <span
              className="textMain"
              style={{ marginLeft: "84px", fontWeight: 400 }}
            >
              For internal ing only
            </span> */}
            <Grid container spacing={2} style={{ position: "relative" }}>
              <Grid item sm={3}>
                {" "}
                <Typography>URL</Typography>{" "}
              </Grid>
              <Grid item sm={7}>
                <input
                  className="input"
                  value={this.state.value}
                  onChange={({ target: { value } }) =>
                    this.setState({ value, copied: false })
                  }
                  style={{
                    width: window.innerWidth < 1100 ? "81%" : "",
                    marginLeft: window.innerWidth < 1100 ? "10px" : "",
                  }}
                />
              </Grid>
              <Grid item sm={2}>
                <CopyToClipboard text={this.state.value} onCopy={this.copyUrl}>
                  <Button className="CopyBtn">Copy</Button>
                </CopyToClipboard>
                {this.state.copied ? (
                  <Typography
                    style={{
                      color: "red",
                      marginTop: window.innerWidth < 1100 ? "" : "40px",
                      marginLeft: window.innerWidth < 1100 ? "" : "22px",
                    }}
                  >
                    Copied.
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ position: "relative" }}>
              <Grid item sm={3}>
                {window.innerWidth < 1100 ? (
                  <Typography>Code</Typography>
                ) : (
                  <Typography>Embed Code</Typography>
                )}
              </Grid>
              <Grid item sm={7}>
                <input
                  type="text"
                  className="input"
                  value={this.state.embedCode}
                  style={{ width: window.innerWidth < 1100 ? "84%" : "" }}
                  onChange={({ target: { embedCode } }) =>
                    this.setState({ embedCopy: false })
                  }
                />
              </Grid>
              <Grid item sm={2}>
                <CopyToClipboard
                  text={this.state.embedCode}
                  onCopy={this.copyEmbed}
                >
                  <Button
                    className="CopyBtn"
                    style={{
                      marginLeft: window.innerWidth < 1100 ? "-45px" : "",
                    }}
                  >
                    Copy
                  </Button>
                </CopyToClipboard>
                {this.state.embedCopy ? (
                  <Typography
                    style={{
                      color: "red",
                      marginTop: window.innerWidth < 1100 ? "" : "40px",
                      marginLeft: window.innerWidth < 1100 ? "" : "22px",
                    }}
                  >
                    Copied.
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ position: "relative" }}>
              <Grid item sm={3}>
                {" "}
                <Typography>QR code</Typography>{" "}
              </Grid>
              <Grid item sm={4}>
                {this.state.productStatus === "draft" ? (
                  <QRCode
                    id="QRCode"
                    value={
                      "https://portal.actuality.live/modelDynamicviewer/" +
                      this.state.productId
                    }
                    style={{ height: 150, width: 150, marginTop: 12 }}
                  />
                ) : (
                  <QRCode
                    id="QRCode"
                    value={
                      "https://actuality.live/modelDynamicviewer/" +
                      this.state.productId
                    }
                    style={{ height: 150, width: 150, marginTop: 12 }}
                  />
                )}
              </Grid>
              <Grid item sm={5}>
                <Button
                  className="CopyBtn"
                  onClick={this.downloadQR}
                  style={{
                    marginTop: window.innerWidth < 1100 ? "122px" : "120px",
                    marginLeft: window.innerWidth < 1100 ? "78px" : "",
                    position:
                      window.innerWidth < 1100 ? "absolute" : "relative",
                  }}
                >
                  Download
                </Button>
              </Grid>
            </Grid>
          </div>
        </Modal>
      </div>
    );
  }
}
