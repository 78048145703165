import { Button, CircularProgress, Snackbar } from '@material-ui/core';
import React, { Component } from 'react';
export default class ContactFromProduct extends Component {
  state = {
    name: '',
    email: '',
    message: '',
    loading: false,
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    // console.log(this.props);
  }
  handlechange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  contactUs = (e) => {
    e.preventDefault();
  };
  render() {
    return (
      <div className="getInTouch ">
        <div className="container">
          {this.state.successLoading ? (
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={true}
              style={{ top: '102px', textTransform: 'capitalize' }}
              color={'#bdbdbd'}
              autoHideDuration={6000}
              message={this.state.successMsg}
            ></Snackbar>
          ) : null}
          {this.state.errorLoading ? (
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
                textTransform: 'capitalize',
              }}
              open={true}
              style={{ top: '102px' }}
              color={'#bdbdbd'}
              autoHideDuration={6000}
              message={this.state.errorMsg}
            ></Snackbar>
          ) : null}
          <h3 className="mainHeading">Contact Us</h3>
          <form action="" id="myForm" onSubmit={this.contactUs}>
            <p className="spanTag emailTag" style={{ marginBottom: '40px' }}>
              Product Reference ID : ############
            </p>

            <p className="spanTag passTag">
              {' '}
              Tell us about your project and include and 2D/3D assets
            </p>
            <textarea
              className="Input"
              name="message"
              style={{ height: '150px' }}
              type="text"
              onChange={this.handlechange}
            />

            <div className="flexRow">
              <Button
                variant="outlined"
                size="medium"
                className="upload"
                component="label"
                style={{
                  padding: '7px 22px',
                  marginTop: '30px',
                  height: '51px',
                }}
              >
                Upload Image
                <input
                  type="file"
                  onChange={this.handleFile}
                  name="filename"
                  hidden
                />
              </Button>
              <Button
                variant="contained"
                type="submit"
                className="submitBtn regBtn"
                style={{
                  padding: '6px 29px',
                  marginTop: '30px',
                  marginLeft: '0px',
                  height: '51px',
                }}
              >
                {this.state.loading ? (
                  <CircularProgress size={35} color={'white'} />
                ) : (
                  'Submit'
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
