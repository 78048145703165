import React from 'react';
import { useParams } from 'react-router-dom';
import ModalDynamic from './ModalDynamicCopy';

export default function FunctionalModalDynamicTemp() {
  let id = useParams();

  return (
    <div>
      <ModalDynamic matchparams={id} />
    </div>
  );
}
