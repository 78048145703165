import axios from '../../RecommendProduct/axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { API } from '../../../Constants/APIs';

const MetaCtx = createContext({ meta: {} });

const MetaCtxProvider = ({ children }) => {
  const [metaData, setMetaData] = useState();
  const getMetaData = () => {
    axios
      .get(API.getUrls.productMeta)
      .then((res) => {
        setMetaData(res.data);
      })
      .catch((err) => {});
    // }
  };
  useEffect(() => {
    getMetaData();
  }, []);
  return (
    <MetaCtx.Provider value={{ metaData, getMetaData }}>
      {children}
    </MetaCtx.Provider>
  );
};

export const useMetaCtx = () => useContext(MetaCtx);
export default MetaCtxProvider;
