import React from "react";

import UnPublishModalTemp from "./UnPublishModalTemp";

const ProductEditTemp = () => {
  return (
    <>
      <UnPublishModalTemp />
    </>
  );
};

export default ProductEditTemp;
