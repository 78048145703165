
import artwork from "./../Assets/Images/artwork.png"
import rugs from "./../Assets/Images/rugs.png"
import coming_soon from "./../Assets/Images/coming_soon.png"

export const templates = [
    {
        "id": 1,
        "title": "Artwork",
        "image": artwork,
        "disabled": false,
        "type": "artwork"

    },
    {
        "id": 2,
        "title": "Rugs",
        "image": rugs,
        "disabled": false,
        "type": "rugs"
    },
    {
        "id": 3,
        "title": "Coming Soon",
        "image": coming_soon,
        "disabled": true
    },

]