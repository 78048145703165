import React, { useEffect, useState } from 'react';

import AddCircleOutlineSharpIcon from '@material-ui/icons/AddCircleOutlineSharp';
import { Typography } from '@material-ui/core';
import CreateRecommendDialog from './CreateRecommendDialog';
import { getRecommendProducts } from './lib';
import RecommendBox from './RecommendBox';

const RecommendProduct = ({ productId, parentProduct }) => {
  const [open, setOpen] = useState(false);

  const [recommendProducts, setRecommendProducts] = useState([]);

  const fetchRecommends = () => {
    getRecommendProducts(productId)
      .then(({ recommendProducts }) => setRecommendProducts(recommendProducts))
      .catch(() => {});
  };

  useEffect(() => {
    fetchRecommends();
  }, []);
  useEffect(() => {
    // console.log("recommendProducts ", recommendProducts);
  }, [recommendProducts]);
  return (
    <>
      {' '}
      <MainAddBtn open={open} setOpen={setOpen} />
      {recommendProducts.map((pr) => (
        <RecommendBox
          key={pr._id}
          recommendProduct={pr}
          fetchRecommends={fetchRecommends}
          recommendProducts={recommendProducts}
          productId={productId}
          parentProduct={parentProduct}
        />
      ))}
      <CreateRecommendDialog
        open={open}
        handleClose={() => setOpen(false)}
        productId={productId}
        previousTitles={recommendProducts.map(({ title }) => title)}
        onSubmitCB={() => {
          fetchRecommends();
        }}
      />
    </>
  );
};

export default RecommendProduct;

const MainAddBtn = ({ open, setOpen }) => (
  <div
    className='addComp'
    onClick={() => {
      setOpen(true);
      // this.setState({
      //   confirmationComponentAdd: true,
      // })
    }}
    style={{ cursor: 'pointer' }}
  >
    <div className='inlineWrapper'>
      <Typography
        className='text'
        onClick={() => {
          // this.setState({
          //   confirmationComponentAdd: true,
          // })
        }}
      >
        Add Recommendation
      </Typography>

      <AddCircleOutlineSharpIcon
        style={{
          position: 'absolute',
          right: '23px',
          top: '15px',
        }}
      />
    </div>
  </div>
);
