import React, { useEffect, useRef, useState } from 'react';
import HStack from '../../TSEUI/HStack';
import Stack from '../../TSEUI/Stack';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { spacing } from '../UIConsts';

import modelImg1 from '../../../Assets/Images/modelImg1.png';
import analyticsImg from '../../../Assets/Images/data-analytics 1.png';
import eyes from '../../../Assets/Images/eyes.png';
import axios from '../../RecommendProduct/axios';
import { API } from '../../../Constants/APIs';
import { CircularProgress } from '@material-ui/core';
import { canPublishProduct } from '../../../Helpers/utils';
import CloneButton from '../../CloneButton';
import DeleteConfimationSecondModel, {
  DeleteConfirmation,
} from '../../RecommendProduct/DeleteConfirmation';
import { useMetaCtx } from './MetaCtxProvider';

const ProductTab = ({
  category,
  expand: expandProp,
  productStatus,
  searchQuery,
  index,
}) => {
  const [expand, setExpand] = useState(expandProp);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const getProducts = () => {
    setIsLoading(true);
    axios
      .get(
        `${API.getUrls.getProducts}?q=${searchQuery}&status=${productStatus}&catId=${category._id}&page=1&limit=10000`
      )
      .then((res) => {
        setProducts([]);
        setProducts(res.data.docs);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (expand) getProducts();
  }, [expand, searchQuery, productStatus]);
  useEffect(() => {
    if (expand || index != 0) return;
    setExpand(true);
    if (!products.length) getProducts();
  }, [productStatus]);

  return (
    <div
      style={{
        border: '0px solid aqua',
      }}
    >
      <Opener
        onClick={() => {
          setExpand((p) => !p);
        }}
        expand={expand}
        category={category}
      />
      {!expand ? null : isLoading ? (
        <div
          style={{
            border: '0px solid red',
            display: 'flex',
            justifyContent: 'center',
            padding: 20,
          }}
        >
          <CircularProgress size={40} color={'info'} />
        </div>
      ) : !products.length ? (
        <div
          style={{
            fontSize: '1.5rem',
            fontWeight: 600,
            textAlign: 'center',
            padding: 20,
          }}
        >
          No Products Found.
        </div>
      ) : (
        <div
          style={{
            display: 'flex' || 'grid',
            // gridTemplateColumns: '1fr 1fr',
            // gridColumnGap: spacing,
            flexWrap: 'wrap',
            // gridRowGap: spacing * (expand ? 1 : 0),
            // paddingTop: spacing * (expand ? 1 : 1),
            // paddingRight: spacing,
            // paddingLeft: spacing,
            border: '0px solid blue',
            alignItems: 'start',
            height: expand ? 'auto' : 0,
            opacity: expand ? 1 : 0,
            transition: 'all 300ms',
          }}
        >
          {products.map((product, index) => (
            <ProductCard
              key={product._id}
              product={product}
              index={index}
              getProducts={getProducts}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const ProductCard = ({ product, index, getProducts }) => {
  const outerRef = useRef();
  const [cardWidth, setCardWidth] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const { getMetaData } = useMetaCtx();
  useEffect(() => {
    setCardWidth(outerRef?.current?.offsetWidth || '100%');
  }, []);
  const openeditLink = async () => {
    console.clear();
    // console.log("this   ", this.props.id);
    const canPublish = await canPublishProduct(product._id);
    // console.log("canPublish ", canPublish);
    // return;
    window.open(
      canPublish
        ? `/viewer?id=${product._id}`
        : `/product-edit?id=${product._id}`,
      '_blank'
    );
  };
  const openAnalytics = () => {
    let URL = `/productanalytics/${product._id}`;
    window.open(URL, '_blank');
  };
  const openPreview = () => {
    const notPublished = product.status != 'published';

    let URL = `${
      notPublished ? '' : 'https://actuality.live'
    }/modelDynamicviewer/${product._id}`;

    window.open(URL, '_blank');
  };
  const deleteProduct = async () => {
    try {
      const { data } = await axios.delete(
        API.postUrls.addproduct + '/' + product._id
      );
      getMetaData();
      await getProducts();
    } catch (err) {}
  };
  return (
    <>
      {openDelete && (
        <DeleteConfirmation
          open={openDelete}
          handleClose={() => setOpenDelete(false)}
          handleSubmit={deleteProduct}
          title='Are you sure you want to delete this product ?'
        />
      )}
      <div
        style={{
          maxWidth: '50%',
          minWidth: '50%',
          padding: spacing / 2,
          // paddingLeft: (index + 1) % 2 == 0 ? 0 : spacing,
        }}
      >
        <div
          ref={outerRef}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            border: '0px solid red',
            overflow: 'hidden',
          }}
        >
          <div
            style={{
              minWidth: cardWidth,
              minHeight: cardWidth,
              maxWidth: cardWidth,
              maxHeight: cardWidth,
              background: '#ddd',
              borderRadius: 10,
              overflow: 'hidden',
              border: '0px solid aqua',
              cursor: 'pointer',
              position: 'relative',
              margin: 'auto',
            }}
            onClick={async () => {
              await openeditLink();
            }}
          >
            <CloneButton
              productId={product._id}
              openeditLink={async () => {
                await openeditLink();
              }}
              postDuplicateProduct={async () => {
                await getProducts();
                getMetaData();
              }}
              deleteProduct={() => setOpenDelete(true)}
              isPublished={product.status == 'published'}
              openAnalytics={openAnalytics}
              openPreview={openPreview}
            />
            <img
              src={
                product.poster_image_url
                  ? API.imgUrl + product.poster_image_url
                  : modelImg1
              }
              width={cardWidth}
              height={cardWidth}
            />
          </div>
          <div
            style={{
              paddingTop: spacing,
              paddingBottom: spacing,
              border: '0px solid aqua',
              padding: spacing / 2,
            }}
          >
            <HStack
              style={{
                justifyContent: 'space-between',
                border: '0px solid red',
              }}
            >
              <div
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: '#000000',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                title={product.title}
              >
                {product.title}
              </div>
              <div
                style={{
                  color: 'gray',
                  cursor: 'pointer',
                }}
                onClick={() => setOpenDelete(true)}
              >
                <DeleteOutlineIcon />
              </div>
            </HStack>{' '}
            <div
              style={{
                fontSize: 12,
                lineHeight: 'normal',
                fontWeight: 500,
                textAlign: 'left',
                color: '#828282',
              }}
            >
              Views :
              {product.views
                ? product.views > 1
                  ? product.views - 1
                  : product.views
                : 0}
            </div>
            <HStack
              style={{
                border: '0px solid red',
                marginTop: 4,
              }}
            >
              <div
                style={{
                  fontSize: 12,
                  lineHeight: 'normal',
                  fontWeight: 500,
                  textAlign: 'left',
                  color: '#828282',
                  flex: 1,
                }}
              >
                {product.status === 'published' ? 'Published' : 'Not Published'}
              </div>
              {product.status != 'published' ? null : (
                <img
                  src={eyes}
                  alt='img'
                  style={{
                    width: 13,
                    height: 13,
                    position: 'static',
                    top: 'auto',
                    left: 'auto',
                    transform: 'none',
                    marginRight: spacing,
                    cursor: 'pointer',
                  }}
                  onClick={openPreview}
                />
              )}
              <a href={`/productanalytics/${product._id}`} target='_blank'>
                {' '}
                <img
                  src={analyticsImg}
                  alt='img'
                  style={{
                    width: 13,
                    height: 13,
                    position: 'static',
                    top: 'auto',
                    left: 'auto',
                    transform: 'none',
                    marginRight: 4,
                  }}
                />
              </a>
            </HStack>
          </div>
        </div>
      </div>
    </>
  );
};

const Opener = ({ expand, onClick, category }) => {
  return (
    <HStack
      style={{
        padding: `${spacing * 2}px ${spacing * 2}px`,
        fontSize: 16,
        fontWeight: 700,
        color: '#2D3436',
        border: '0px solid red',
        boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.07)`,
        cursor: 'pointer',
        zIndex: 6666,
      }}
      onClick={onClick}
    >
      {!expand ? '+' : '-'} {category?.title}
    </HStack>
  );
};

export default ProductTab;
