import "@google/model-viewer";
import { Typography } from "@material-ui/core";
import { Check, LinearScaleTwoTone } from "@material-ui/icons";
import React, { Component, createRef } from "react";
import share from "../../Assets/Images/share.png";
import arimage from "../../Assets/Images/ar_icon.png";
import { API } from "../../Constants/APIs";
// import Drawer from "./Drawer";
// import ContentStyleWrapper from "./ContentStyleWrapper";
import AnnotationBtns from "./AnnotationBtns";
import HotspotContentDrawer from "../HotspotContentDrawer";

const variantControllerHeight = 100;
export default class MobileModelDynamicViewer extends Component {
  constructor(props) {
    super(props);

    this.modelRef = createRef();
    this.annotBtnCloseCB = createRef();

    this.state = {
      showHotspot: {
        isTrue: false,
        data: {},
      },
    };
  }
  render() {
    const props = this.props;
    return (
      <div
        className="mobileResponsiveViewer"
        style={{ height: window.innerHeight }}
      >
        <HotspotContentDrawer
          open={this.state.showHotspot.isTrue}
          onClose={() => {
            this.setState({ showHotspot: { isTrue: false, data: {} } });
            this.annotBtnCloseCB.current();
          }}
          title={this.state.showHotspot.data.title}
          description={this.state.showHotspot.data.description}
        />
        <div className="header">
          <div>
            <Typography className="brand">Actuality</Typography>
            <Typography className="proName">{props.product}</Typography>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "14px",
              }}
            >
              <div
                className="shareBtn"
                onClick={() => {
                  this.props.visibleModal();
                }}
              >
                <img src={share} height={"20px"} alt="" />
              </div>
              <div className="shareBtn">
                <LinearScaleTwoTone />
              </div>
            </div>
            <div>
              {this.props.visibility ? (
                <Typography
                  className="buyNow"
                  onClick={() => {
                    this.props.openLink();
                  }}
                >
                  {props.UrlTitle ? props.UrlTitle : "Buy Now"}
                  {/* <br /> 
                  <br /> 
                  <h5>{props.UrlDescription ? props.UrlDescription : "Buy Now"}</h5> */}
                </Typography>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <model-viewer
          ref={(elem) => {
            this.modelRef.current = elem;
          }}
          id="reveal"
          // src={model?.variants[this.state.variantIndex].glb_file_path}
          exposure="0.8"
          // ios-src={model?.variants[this.state.variantIndex].usdz_file_path}
          src={
            props.showVariant
              ? API.imgUrl + props.selectedVariant.model_file_url
              : API.imgUrl + props.model
          }
          quick-look-browsers="safari chrome"
          // ios-src={
          //   props.showVariant
          //     ? API.imgUrl + props.selectedVariant.model_file_url
          //     : API.imgUrl + props.model
          // }
          camera-orbit="45deg 55deg 2.5m"
          shadow-intensity="2"
          field-of-view="80deg"
          min-field-of-view={"80deg"}
          max-field-of-view={"80deg"}
          bounds="tight"
          camera-controls
          ar
          ar-modes="webxr scene-viewer quick-look"
          ar-scale={"auto"}
          ar-placement={"floor"}
          autoplay
          loading="lazy"
          style={{
            background: "#FFFFFF",
            height: window.innerHeight - variantControllerHeight,
            width: "-webkit-fill-available",
            position: "absolute",
            left: "50%",
            top: props.showVariant ? "0%" : "50%",
            transform: props.showVariant
              ? "translateX(-50%)"
              : "translate(-50%, -50%)",
          }}
        >
          <AnnotationBtns
            annots={this.props?.selectedVariant?.hotspots}
            openHotspotDrawer={(annot, cb) => {
              this.annotBtnCloseCB.current = cb;
              this.modelRef.current.setAttribute(
                "camera-target",
                annot.cameraTarget
              );
              this.modelRef.current.setAttribute(
                "camera-orbit",
                annot.cameraOrbit
              );
              this.modelRef.current.setAttribute("zoom", annot?.zoom || 12);
              this.setState({
                showHotspot: {
                  isTrue: true,
                  data: {
                    title: annot.title,
                    description: annot.description,
                  },
                },
              });
            }}
          />
          <button
            slot="ar-button"
            className="viewInYourSpace"
            // style={{
            //   backgroundColor: "transparent",
            //   background: "#bdbdbd",
            //   boxShadow: "1px 2px 5px rgba(0, 0, 0, 0.1)",
            //   borderRadius: "15px",
            //   position: "absolute",
            //   left: "50%",
            //   transform: "translateX(-50%)",
            //   bottom: "16px",
            //   border: "none",
            //   display: "flex",
            //   width: "74%",
            //   flexDirection: "row",
            //   alignItems: "center",
            //   padding: "0 12px",
            //   marginBottom: "48px",
            //   justifyContent: "center",
            // }}
            // onClick={() => {
            //   this.props.ViewModal();
            // }}
          >
            <img src={arimage} width="20px" alt="" />

            <p className="view_space_btn">
              {" "}
              {this.props.language === "French"
                ? "Voir dans votre espace"
                : "View in your space"}
            </p>
          </button>
        </model-viewer>
        <div
          className="variantControls"
          style={{
            height: variantControllerHeight,
            width: window.innerWidth,
          }}
        >
          <div className="framesWrapper">
            {props.Component.map((single, index1) => (
              <div key={single._id || index1}>
                <Typography style={{ marginLeft: 6 }}>
                  {single.component_name}
                </Typography>

                <div className="variantListing">
                  {single.materials[0]._id
                    ? single.materials.map((material, index) => (
                        <div key={material._id || index}>
                          {material.material_type === "color" ? (
                            <>
                              <div
                                onClick={() => {
                                  this.props.selectMaterial(
                                    material._id,
                                    single._id
                                  );
                                }}
                                className="paletteBox"
                                style={{
                                  backgroundColor: material.material_value,
                                }}
                                key={index}
                              >
                                {this.props.checkSelected(
                                  material._id,
                                  single._id
                                ) ? (
                                  <div className="selected">
                                    <Check style={{ color: "#000" }} />
                                  </div>
                                ) : null}
                              </div>
                              <div
                                style={{
                                  inlineSize: "58px",
                                  overflowWrap: "break-word",
                                }}
                              >
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontSize: "14px",
                                    fontFamily: "Inter",
                                  }}
                                >
                                  {material.material_name}
                                </p>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="paletteBox">
                                <img
                                  onClick={() => {
                                    this.props.selectMaterial(
                                      material._id,
                                      single._id
                                    );
                                  }}
                                  key={index}
                                  src={API.imgUrl + material.material_value}
                                  className="paletteBox"
                                  style={{
                                    marginTop: "0px",
                                    marginLeft: "0px",
                                  }}
                                  alt=""
                                />
                                {this.props.checkSelected(
                                  material._id,
                                  single._id
                                ) ? (
                                  <div className="selected">
                                    <Check style={{ color: "#000" }} />
                                  </div>
                                ) : null}
                              </div>
                              <div
                                style={{
                                  inlineSize: "58px",
                                  overflowWrap: "break-word",
                                }}
                              >
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontSize: "14px",
                                    fontFamily: "Inter",
                                  }}
                                >
                                  {material.material_name}
                                </p>
                              </div>
                            </>
                          )}
                        </div>
                      ))
                    : null}
                  {!props.Component.lenth - 1 === index1 ? (
                    <div className="separator"></div>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
