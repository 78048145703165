import React, { useState, useEffect } from 'react';
import { spacing } from './const';
import CategoryBox from './CategoryBox';
import RightContentBox from './RightContentBox';

import axios from 'axios';
import { API } from '../../Constants/APIs';
import { useLocation } from 'react-router-dom';
import { Container, Grid } from '@material-ui/core';

const AddProductToShare = ({ }) => {
  const [category, setCategory] = useState([]);

  const handleCategoryCheck = (id) => {
    // setCategory(id == category ? '' : id);
    if (category.includes(id)) {
      const cats = category.filter(ca => ca != id);
      setCategory(cats)
    } else {
      setCategory([...category, id])
    }
  };

  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [share, setShare] = useState(null);

  const location = useLocation();

  useEffect(() => {
    (async () => {
      const shareId = location.pathname?.split?.('/')?.pop?.();
      setIsLoading(true);

      if (!shareId) {
        setIsLoading(false);
        return;
      }
      try {
        const { data: shareData } = await axios.get(
          `${API.getUrls.share_products}/${shareId}`
        );
        setShare(shareData.shareProduct);
        const { data } = await axios.get(API.getUrls.Categories);
        setCategories(data.categories.docs);
        setCategory([data.categories.docs[0]._id]);
      } catch (err) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);
  if (isLoading) return null;

  if (!isLoading && !share)
    return (
      <div
        id="scrollableDiv"
        style={{
          fontSize: 20,
          fontWeight: 500,
          color: 'red',
          textAlign: 'center',
          fontStyle: 'italic',
          padding: 20,
          minHeight: '60vh',
        }}
      >
        No Data Found
      </div>
    );
  return (
    <Container className="containerSpacingdashBoard" maxWidth={'lg'}>
      <Grid
        container
        spacing={2}
        className="userDashboard"
      >
        <CategoryBox
          category={category}
          handleCategoryCheck={handleCategoryCheck}
          categories={categories}
        />
        <RightContentBox
          category={category}
          share={share}
          shareId={share._id}
        />
      </Grid>
    </Container>
  );
};

export default AddProductToShare;
