import React from 'react';
import HStack from '../../TSEUI/HStack';

import SearchIcon from '@material-ui/icons/Search';
import { spacing } from '../UIConsts';

const Search = ({ value, setValue }) => {
  return (
    <HStack
      style={{
        justifyContent: 'center',
        padding: `${spacing * 2}px ${spacing * 1}px`,
        border: '0px solid red',
      }}
    >
      <HStack
        style={{
          border: '0px solid blue',
          flex: 1,
          boxShadow: `inset 0px -2px 6px rgba(0, 0, 0, 0.25), inset 0px 4px 5px -1px rgba(0, 0, 0, 0.25)`,
          borderRadius: 20,
        }}
      >
        <input
          style={{
            // flex: 1,
            alignSelf: 'stretch',
            // border:
            height: 48,
            border: 0,
            outline: 'none',
            background: 'transparent',
            padding: 0,
            paddingLeft: 20,
            fontSize: '20px',
            margin: 0,
            width: '95%',
          }}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          type="search"
          placeholder="Search Product"
        />
        <div style={{ flex: 1 }} />
        <div
          style={{
            width: 48,
            height: 48,
            display: 'grid',
            placeItems: 'center',
          }}
        >
          <SearchIcon />
        </div>
      </HStack>
      {/* <img src={actualityBrandLogo} width="170px" height="50px" />
      <img src={hamburgerMobile} width="40" /> */}
    </HStack>
  );
};

export default Search;
