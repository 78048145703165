import React from 'react';
import { useParams } from 'react-router-dom';
import Modal from './Modal';

export default function ModalFunctional() {
  let id = useParams();
  // console.log(id);
  return (
    <div>
      <Modal matchparams={id} />
    </div>
  );
}
