import React, { useEffect, useState } from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { API as Constants } from '../../Constants/APIs';
import { createOrUpdateOrDeleteCombination } from './lib';
import { btnStyle } from '../../Helpers/utils';
const __DIM = 60;
const paddLeft = 20;
const BLANK = {
  style: {
    background: 'white',
    border: '0px solid red',
    // maxWidth: "max-content",
    padding: 8,

    // border: "1px solid #ddd",
    paddingLeft: 0,
  },
};

const CombinationDialog = ({
  productId,
  recommendId,
  combinations = [],
  goBack,
  onSubmitCb,
  selectedCombination: propSelectedCombination,
  handleClose: handleCloseCombinationBox,
  productTitle,
}) => {
  const [selectedCombinations, setSelectedCombinations] = useState(
    combinations
      .filter(
        ({ product }) =>
          product == productId || product == propSelectedCombination?.product
      )
      .map((combinations) => ({ checked: true, ...combinations }))
  );

  const [variants, setVariants] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [titleNotUnique, setTitleNotUnique] = useState(false);
  const [fillAllSelected, setFillAllSelected] = useState(false);
  const [requireToSelect, setRequireToSelect] = useState(false);
  const createCombinations = async () => {
    setIsSubmitting(true);
    console.clear();
    let titles = combinations
      .filter(
        ({ product }) =>
          product != productId && product != propSelectedCombination?.product
      )
      .map(({ title }) => title);

    let newCombs = [];
    let editCombs = [];
    let deleteCombs = [];
    let selectedCombinationsTemp = selectedCombinations.map((comb) => ({
      ...comb,
      product: productId,
    }));
    selectedCombinationsTemp.forEach((comb) => {
      let found = combinations.find((c) => c.variant == comb.variant);
      let title = comb?.title?.trim?.();
      title = title?.trimEnd?.();
      comb.title = title;
      if (found)
        if (comb.checked) editCombs.push(comb);
        else deleteCombs.push(comb);
      else {
        newCombs.push(comb);
      }
    });
    const isRemainEmpty = newCombs.find(
      ({ checked, title }) => checked && !title
    );

    if (isRemainEmpty) {
      setFillAllSelected(true);
      setIsSubmitting(false);
      setTimeout(() => {
        setFillAllSelected(false);
      }, 2000);
      return;
    }

    newCombs = newCombs.filter(({ checked, title }) => title && checked);

    newCombs.forEach(({ title }) => {
      titles.push(title);
    });
    editCombs
      .filter(({ checked }) => checked)
      .forEach(({ title }) => {
        titles.push(title);
      });
    // console.log("titles ", titles);
    titles = titles.filter((t) => t).map((t) => t.toLowerCase());
    // console.log("after titles ", titles);
    let setOfTitles = new Set(titles);
    // console.log("titles ", titles);
    if (setOfTitles.size != titles.length) {
      setIsLoading(false);
      setTitleNotUnique(true);
      setIsSubmitting(false);

      setTimeout(() => {
        setTitleNotUnique(false);
      }, 2000);
      return;
    }

    const bodyData = {};
    if (editCombs.length && deleteCombs.length && newCombs.length) {
      // setIsSubmitting(false);
      // return;
    }
    if (!newCombs.length && !editCombs.length && !deleteCombs.length) {
      setRequireToSelect(true);
      setIsSubmitting(false);
      return;
    }

    if (newCombs.length) bodyData.newCombs = newCombs;
    if (editCombs.length) bodyData.editCombs = editCombs;
    if (deleteCombs.length) bodyData.deleteCombs = deleteCombs;
    // console.log("bodyData ", bodyData);
    // setIsSubmitting(false);
    // return;
    try {
      const data = await createOrUpdateOrDeleteCombination(
        recommendId,
        bodyData
      );
      onSubmitCb();
    } catch (err) {
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSelectComb = (id, checked) => {
    if (requireToSelect) setRequireToSelect(false);
    // console.log("handleSelectComb ", id, checked);
    let temp = selectedCombinations;

    let exist = temp.findIndex((v) => v.variant == id);
    if (exist != -1) {
      // console.log("exist ", exist);
      temp[exist].checked = checked;
    } else temp.push({ variant: id, checked });

    setSelectedCombinations(temp);
    let tempVariants = [...variants];
    setVariants(tempVariants);
  };

  const handleCombName = (id, title) => {
    if (requireToSelect) setRequireToSelect(false);
    // title = title.trim();
    // title = title.trimEnd();
    // console.log("handleCombName ");
    let temp = selectedCombinations;
    let exist = temp.findIndex((v) => v.variant == id);
    if (exist != -1) {
      temp[exist].title = title;
    } else temp.push({ variant: id, title });

    setSelectedCombinations(temp);
    let tempVariants = [...variants];
    setVariants(tempVariants);
  };
  const [components, setComponents] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const resetSelection = () => {
    setVariants([]);
    setComponents([]);
    setIsLoading(false);
    goBack();
  };

  const getVariants = async () => {
    setIsLoading(true);

    if (!productId) return;
    try {
      let { data: compoData } = await axios.get(
        `${Constants.getUrls.getComponents}${productId}?limit=10`
      );

      compoData = compoData.components.docs;
      let limit = compoData.reduce(
        (total, row) => total * row?.materials?.length || 1,
        1
      );

      const { data: variantData } = await axios.get(
        `${Constants.getUrls.variants}?page=1&limit=${limit}&product=${productId}`
      );

      setVariants(variantData.docs);
      setComponents(compoData);
    } catch (err) {
      // console.log("error from get Variant ", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getVariants();
  }, [productId]);

  const [wasThatSelectedTimer, setWasThatSelectedTimer] = useState(true);
  const [wasScrolled, setWasScrolled] = useState(false);
  const scrollTo = (ref) => {
    // console.log(ref, "ref.offsetTop ", ref?.offsetTop);
    /**
     * start
center
end
nearest
     */
    if (ref && !wasScrolled) {
      setWasScrolled(true);
      ref.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setWasThatSelectedTimer(false);
    }, 5000);
  }, []);

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: 'white',
        zIndex: 20,
        maxWidth: '100%',
        overflow: 'auto',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {isLoading ? (
        <Typography
          variant='body1'
          style={{
            textAlign: 'center',
            marginTop: 6,
          }}
        >
          Fetching variants...
        </Typography>
      ) : !components.length || (!variants?.length && true) ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            // justifyContent: "space-between",
            padding: 20,
          }}
        >
          <Tooltip title='Go Back'>
            <IconButton
              onClick={goBack}
              style={{
                border: '1px solid rgba(0,0,255,0.7)',
              }}
            >
              <KeyboardBackspaceIcon color='primary' />
            </IconButton>
          </Tooltip>
          <Typography
            variant='body1'
            style={{
              textAlign: 'center',
              marginTop: 6,
            }}
          >
            No variants to select on this product.
          </Typography>
        </div>
      ) : (
        <>
          <div
            style={{
              fontSize: 24,
              fontWeight: 400,
              // padding: paddLeft,
              paddingLeft: paddLeft,
              height: 40,
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              paddingRight: paddLeft,
              cursor: 'pointer',
            }}
            title={productTitle}
          >
            {productTitle}
          </div>
          <div
            style={{
              flex: 1,
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                maxHeight: '100%',
                minHeight: '100%',
                overflow: 'auto',
                paddingLeft: paddLeft,
              }}
            >
              <TableContainer>
                <Table stickyHeader>
                  <TableHead {...BLANK}>
                    <TableRow>
                      {/* .filter(({materials})=>materials.length) */}
                      {components
                        .filter(({ materials }) => materials.length)
                        .map((component) => (
                          <TableCell
                            key={component._id}
                            {...BLANK}
                            align='center'
                          >
                            {component.component_name}
                          </TableCell>
                        ))}

                      <TableCell {...BLANK} align='center'>
                        Display Name
                      </TableCell>
                      <TableCell
                        style={{
                          ...BLANK.style,
                          // border: "1px solid red",
                          paddingRight: 10,
                          paddingLeft: 10,
                        }}
                        align='center'
                      >
                        Select{' '}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody {...BLANK}>
                    {variants?.map((variant, index) => {
                      let thisVariant = selectedCombinations?.find(
                        ({ variant: vID }) => vID == variant?._id
                      );

                      let isThisChecked = thisVariant?.checked;
                      let thisTitle = thisVariant?.title;

                      const wasThatSelected =
                        variant?._id == propSelectedCombination?.variant;
                      return (
                        <TableRow key={variant._id} {...BLANK}>
                          <LeftThreeCells
                            materials={variant.materials}
                            columnsLength={components.length}
                          />

                          <TableCell
                            style={{
                              ...BLANK.style,
                              // margin: "auto",
                              // display:'block'
                              // display: "flex",
                              // alignItems: "center",
                              // justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                // border: "1px solid red",
                                width: '100%',
                              }}
                            >
                              <TextField
                                value={thisTitle}
                                onChange={(e) => {
                                  handleCombName(variant._id, e.target.value);
                                }}
                                size='small'
                                variant='outlined'
                              />
                            </div>
                          </TableCell>
                          <TableCell
                            style={{
                              ...BLANK.style,
                              // display: "flex",
                              // alignItems: "center",
                              // justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                // border: "1px solid red",
                                width: '100%',
                              }}
                            >
                              <input
                                ref={(elem) => {
                                  if (wasThatSelected && !wasScrolled) {
                                    scrollTo(elem);
                                  }
                                }}
                                style={{
                                  padding: 5,
                                  cursor: 'pointer',
                                  width: 30,
                                  height: 30,

                                  margin: 'auto',
                                }}
                                type='checkbox'
                                checked={isThisChecked}
                                onChange={(e) => {
                                  handleSelectComb(
                                    variant._id,
                                    e.target.checked
                                  );
                                }}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <div
            style={{
              height: '100px',
              position: 'relative',
            }}
          >
            <div
              style={{
                position: 'absolute',
                right: 0,
                top: 0,
              }}
            >
              {titleNotUnique || requireToSelect || fillAllSelected ? (
                <Typography
                  color='error'
                  style={{
                    marginRight: paddLeft,
                  }}
                >
                  {requireToSelect
                    ? 'Select variant and give unique title'
                    : titleNotUnique
                    ? 'Titles Must be Unique'
                    : fillAllSelected
                    ? 'Give unique title to selected variants'
                    : ''}
                </Typography>
              ) : null}
            </div>
            {!isLoading && components.length && variants?.length ? (
              <ActionBtns
                onSubmit={createCombinations}
                handleClose={() => {
                  if (propSelectedCombination?.product)
                    handleCloseCombinationBox();
                  else goBack();
                }}
                isSubmitting={isSubmitting}
              />
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

export default CombinationDialog;

const ActionBtns = ({ onSubmit, handleClose, isSubmitting }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      justifyContent: 'space-around',
      margin: 'auto',
    }}
  >
    <button
      disabled={isSubmitting}
      onClick={handleClose}
      style={{
        ...btnStyle,
        width: '40%',
        background: 'white',
        color: 'black',
      }}
    >
      Cancel
    </button>
    <button
      onClick={onSubmit}
      disabled={isSubmitting}
      style={{
        ...btnStyle,
        width: '40%',
        background: '#BDBDBD',
        color: 'white',
      }}
    >
      {isSubmitting ? 'Submitting...' : 'Submit'}
    </button>
  </div>
);

const LeftThreeCells = ({ materials, columnsLength }) => {
  return (
    <>
      {materials.map((material) => (
        <TableCell align='left' key={material._id} {...BLANK}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {material.material_type == 'color' ? (
              <div
                style={{
                  objectFit: 'contain',
                  width: __DIM,
                  height: __DIM,
                  borderRadius: '5px',
                  background: material.material_value,
                  boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
                }}
              />
            ) : (
              <img
                src={`${Constants.imgUrl}${material.material_value}`}
                height={__DIM}
                width={__DIM}
                alt={material.material_name}
                style={{
                  borderRadius: '5px',

                  boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
                }}
              />
            )}{' '}
            <Typography
              style={{
                textAlign: 'center',
                fontSize: 16,
                marginTop: 6,
                textTransform: 'capitalize',
              }}
            >
              {material.material_name}
            </Typography>
          </div>
        </TableCell>
      ))}
      {/* {[...new Array(columnsLength - materials.length)].map((val) => (
        <TableCell align="left" key={val} {...BLANK} />
      ))} */}
    </>
  );
};
/**
 <div
        style={{
          display: "none",
          padding: titleNotUnique ? "5px 0" : 0,
          // borderBottom: "1px solid #ddd",
          border: "1px solid #ddd",
          height: titleNotUnique ? 50 : 0,
          display: "flex",
          alignItems: "center",
          // marginBottom: 10,
        }}
      >
        {" "}
        <div
          style={{
            flex: 1,
          }}
        />
        {titleNotUnique ? (
          <Typography
            color="error"
            style={{
              marginRight: 4,
            }}
          >
            Titles Must be Unique
          </Typography>
        ) : null}
        <IconButton size="medium" onClick={() => resetSelection()}>
          <KeyboardBackspaceIcon fontSize="medium" />
        </IconButton> 
        // </div>
 */
