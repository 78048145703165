import React, { useState, useEffect } from 'react';
import { spacing } from './const';
import CategoryBox from './CategoryBox';
import RightContentBox from './RightContentBox';

import axios from 'axios';
import { API } from '../../Constants/APIs';

const Templates = ({}) => {
  const [category, setCategoy] = useState('');

  const handleCategoryCheck = (id) => {
    setCategoy(id == category ? '' : id);
  };

  const [categories, setCategories] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get(API.getUrls.Categories);
        setCategories(data.categories.docs);
      } catch (err) {}
    })();
  }, []);
  return (
    <div
      style={{
        padding: spacing * 2,
        minHeight: '80vh',
        maxWidth: '1200px',
        margin: 'auto',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          // border: '1px solid red',
          overflow: 'hidden',
          justifyContent: 'center',
        }}
      >
        <CategoryBox
          category={category}
          handleCategoryCheck={handleCategoryCheck}
          categories={categories}
        />
        <RightContentBox category={category} />
      </div>
    </div>
  );
};

export default Templates;
