import React, { useState } from "react";
import { API } from "../../Constants/APIs";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/EditOutlined";
import { Link } from "react-router-dom";
import TSEDialog from "../TSEDialog";
import axios from "axios";
import { IoEyeOutline, IoEyeSharp } from "react-icons/io5";

const ProductCard = ({
  product: propProduct,
  addProductCard,
  shareId,
  deleteProduct,
  index, length,
  subTitleInputBlur
}) => {
  const [product, setProduct] = useState(propProduct);
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (e) => {
    const target = e.target;

    setProduct((p) => ({ ...p, [target.name]: target.value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) return;
    const { title, sub_title } = product;

    if (!title || !sub_title) return;
    setIsLoading(true);

    try {
      const { data } = await axios.put(
        `${API.getUrls.share_products}/products/${shareId}`,
        {
          product: product._id,
          title,
          sub_title,
        }
      );
      setShowDialog(false);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <TSEDialog
        open={showDialog}
        handleClose={() => setShowDialog((p) => !p)}
        minWidth="450px"
        maxWidth="450px"
      >
        <form onSubmit={handleSubmit} style={{ marginTop: '64px' }}>
          <div
            style={{
              border: "0px solid red",
              padding: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
              paddingBottom: 25,
            }}
          >
            <input
              style={{
                // boxShadow:
                //   "inset 0px -2px 6px rgba(0, 0, 0, 0.25), inset 0px 4px 5px -1px rgba(0, 0, 0, 0.25)",
                border: "1px solid #ddd",
                borderRadius: 10,
                background: "#fff",
                outline: 0,
                // border: 0,
                padding: 10,
                fontSize: 18,
              }}
              placeholder={"Title"}
              value={product.title}
              onChange={handleChange}
              name="title"
            />
            <input
              style={{
                // boxShadow:
                //   "inset 0px -2px 6px rgba(0, 0, 0, 0.25), inset 0px 4px 5px -1px rgba(0, 0, 0, 0.25)",
                border: "1px solid #ddd",
                borderRadius: 10,
                background: "#fff",
                outline: 0,
                // border: 0,
                padding: 10,
                marginTop: 15,
                fontSize: 18,
              }}
              placeholder={"Description"}
              value={product.sub_title}
              onChange={handleChange}
              name="sub_title"
            />
            <button
              type="submit"
              style={{
                alignSelf: "center",
                outline: 0,
                padding: "10px 20px",
                border: 0,
                marginRight: 0,
                background: " #E1E1E1",
                boxShadow: "1px 5px 5px rgba(0, 0, 0, 0.1)",
                borderRadius: 9,
                color: "#2e2e2e",
                fontSize: 16,
                cursor: "pointer",
                marginTop: 20,
              }}
            >
              Submit
            </button>
          </div>
        </form>
      </TSEDialog>
      <div
        style={{
          padding: "20px 0",
          width: 350,
          zIndex: subTitleInputBlur ? -1 : 1,
          margin: (index + 1) % 3 === 2 ? "0 89px" : (index + 1) % 3 === 1 && ((index) / length >= 3 / 4) ? "0 32px 0 0" : (index + 1) % 3 === 1 && (length) === 1 ? "0 32px 0 0" : "0",

        }}
      >
        <div
          style={{
            boxShadow: "0px 5px 9px 0px rgba(0, 0, 0, 0.12)",
            borderRadius: "20px",
            position: "relative",
            overflow: "hidden",
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {addProductCard ? <AddProductCard shareId={shareId} length={length} /> : null}{" "}
          {!addProductCard && <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                padding: 10,
                cursor: "pointer",
                boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
                background: '#fff',
                borderRadius: 22,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                right: 16,
                top: 16
              }}
              onClick={deleteProduct}
            >
              <CloseIcon />
            </div>
          </div>
          }
          <img
            style={{
              width: "100%",
              // height: ,
              height: '350px',
              alignSelf: 'center',
              // marginBottom: 20
            }}
            alt={product.title}
            src={API.imgUrl + product.product.poster_image_url}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 16,
              paddingBottom: 8,
              marginTop: 12
              // borderTop: '1px solid #ddd'
            }}
          >
            <span
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                fontSize: 18,
                fontWeight: 500,
                color: '#2e2e2e'
              }}
              title={product.title}
            >
              {product.title}
            </span>
            <div
              style={{
                cursor: "pointer",
                border: "0px solid red",
                paddingLeft: 8,
              }}
              onClick={() => {
                setShowDialog(true);
              }}
            >
              <EditIcon fontSize="small" />
            </div>
          </div>
          <div
            style={{
              // whiteSpace: "nowrap",
              overflow: "hidden",
              fontSize: 15,
              fontWeight: 'normal',
              padding: 16,
              paddingTop: 6,
              paddingBottom: 20,
              // height: 35,
              color: '#828282',
              minHeight: 62,
              lineHeight: "24px"
            }}
          >
            {product.sub_title?.length > 80 ? product.sub_title?.substring(0, 80) + "..." : product.sub_title}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 16px 35px' }}>
            <button onClick={() => window.open(`https://actuality.live/modelDynamicviewer/${product.product._id}`, "_blank")} style={{ background: '#000', borderRadius: 10, width: "55%", color: '#fff', textAlign: 'center', padding: "12px 0", display: 'flex', alignItems: 'center', padding: '0 12px', fontSize: 14, height: '43px', border: 'none' }}><IoEyeSharp size="20" color="#fff" style={{ marginRight: 4 }} /> View in your space</button>
            <button onClick={() => window.open(`${product.product.link_url}`, "_blank")} style={{
              background: "#D8D8D8"
              , borderRadius: 10, width: "40%", color: '#2e2e2e', textAlign: 'center', padding: "12px 0", display: 'flex', alignItems: 'center', padding: '0 12px', fontSize: 14, height: '43px', border: 'none', justifyContent: 'center'
            }}>Call to action</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCard;

const AddProductCard = ({ shareId, length }) => (
  <Link to={`/addToShare/${shareId}`} style={{ textDecoration: "none" }}>
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        // borderRadius: 15,
      }}
    >
      {" "}
      <span
        style={{
          fontSize: 64,
          fontWeight: 200,
          color: "#828282",
          marginBottom: 5,
          // border: '1px solid red',
        }}
      >
        {" "}
        +
      </span>
      <span
        style={{
          fontSize: 30,
          fontWeight: 400,
          color: "#828282",
          marginTop: 5,
        }}
      >
        Add Products
      </span>
    </div>
  </Link>
);
