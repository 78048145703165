import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Grid,
  Snackbar,
  Typography,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { Edit, Visibility, VisibilityOff } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import AddCircleOutlineSharpIcon from "@material-ui/icons/AddCircleOutlineSharp";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import axios from "axios";
import React, { Component } from "react";
import Modal from "react-awesome-modal";
import { SketchPicker } from "react-color";
import { Navigate } from "react-router";
import edit from "../Assets/Images/edit.png";
import rectangle from "../Assets/Images/Rectangle 54.png";
import verticalLine from "../Assets/Images/Vector 12.png";
import vector from "../Assets/Images/Vector.png";
import { API } from "../Constants/APIs";
// for corp part
import Resizer from "react-image-file-resizer";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import language from "../Assets/Images/language.png";
import Passcode from "../Components/Passcode";
import { validateUrl } from "../Helpers/os";
import MaterialEditModel from "./MaterialEditModel";
import PasscodeChange from "../Components/PasscodeChange";
import { MultiSelect } from "react-multi-select-component";
import "../App.scss";
import TopRightBoxes from "../Components/TopRightBoxes";
import VariantsCreatorTable from "../Components/VariantsCreater/indexTable";
import VariantsCreator from "../Components/VariantsCreater";
import { createRef } from "react";
import VariantsDeleteConfirmation from "../Components/VariantsDeleteConfirmation";
import { firstLetterCapital } from "../Helpers/utils";
import ComponentEdit from "../Components/ComponentEdit";
import PlacementRadio from "../Components/PlacementRadio";
import TSEDialog from "../Components/TSEDialog";
import CategoryBox from "../Components/AddProductToShare/CategoryBox";
import { spacing } from "../Components/AddProductToShare/const";
import { templates } from "../Constants/modelTemplates";
import { Link } from "react-router-dom";
import queryString from 'query-string';


function gcd(a, b) {
  // return (b == 0) ? a : gcd (b, a%b);
  return 100 * Math.abs((a - b) / ((a + b) / 2));
}

function calculateRatio(num_1, num_2) {
  let num = 0;
  for (num = num_2; num > 1; num--) {
    if (num_1 % num == 0 && num_2 % num == 0) {
      num_1 = num_1 / num;
      num_2 = num_2 / num;
    }
  }
  var ratio = num_1 + ":" + num_2;
  return ratio;
}

const resizeFile = (file) =>
  new Promise((resolve) => {
    // console.log(file);
    Resizer.imageFileResizer(
      file,
      800,
      600,
      "JPEG",
      80,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

const pId = document.location.href;
var url = new URL(pId);
var productId = url.searchParams.get("id");
let fd = new FormData();
// end crop part

const MATS_COMPO = "mats_compo";

export default class ProductEdit extends Component {
  constructor(props) {
    super(props);
    this.handleSaveRef = createRef();
  }
  state = {
    visible: false,
    showFirst: false,
    showSecond: false,
    showThird: false,
    showFourth: false,
    compname: "",
    productId: 0,
    Components: [],
    addColor: true,
    type: "color",
    material_value: "",
    compId: 0,
    file: "",
    showMaterials: true,
    variantsCombo: [],
    variantData: [],
    value: 0,
    setValue: 0,
    array1: [],
    array2: [],
    emptyVariants: false,
    emptyComponents: false,
    model_file: "",
    processing: false,
    userdetail: {},
    product: {},
    title: "",
    uploadCount: 0,
    posterImage: "",
    Variants: [],
    deleteConfirmation: false,
    confirmationComponentDelete: false,
    deleteMatId: 0,
    deleteCompId: 0,
    loading: false,
    confirmationComponentAdd: false,
    selectedMaterials: [],
    colorPicker: false,
    link_description: "",
    link_title: "",
    link_url: "",
    active: 0,
    activeComponents: [],
    showAllText: false,
    visiblity: true,
    visiblityoff: false,
    // corp state
    filename: {},
    uploadJPG: false,
    visibleCropProduct: false,
    src: null,
    language: "",
    crop: {
      aspect: 1,
      width: 100,
      // width: 80,
      unit: "%",
    },
    categories: [],
    noOfGlbsToUpload: 0,
    suggestions: [],
    suggestionModelModal: false,
    suggestionSelected: {},
    selectedVariant: {},
    productId: "",
    selectedCategories: [],
    templateStringParams: null
    // crop state end
  };
  componentDidMount() {
    this.getSingleProduct();
    this.getUser();
    this.getVariants();
    this.fetchCategories();
    this.fetchSuggestions();
    let pId = document.location.href;
    var url = new URL(pId);
    var productId = url.searchParams.get("id");
    axios.get(API.getUrls.getSingleProduct + productId).then((resp) => {
      // console.log(resp);
      if (resp.data.status === "success") {
        this.setState({
          visiblity: resp.data.product.visibility,
          language: resp.data.product.language,
          productId
        });
      }
    });
  }
  handleCategoryCheck = (id) => {
    if (this.state.selectedCategories.includes(id)) {
      const cats = this.state.selectedCategories.filter(ca => ca != id);
      this.setState({ selectedCategories: cats }, () => {
        this.fetchSuggestions()
      })
    } else {
      this.setState({ selectedCategories: [...this.state.selectedCategories, id] }, () => {
        this.fetchSuggestions()
      })
    }
  }

  fetchSuggestions = () => {
    let url = API.getUrls.model_suggestions + "?";
    if (this.state.selectedCategories.length > 0) {
      this.state.selectedCategories.forEach(cat_id => {
        url += "categories[]=" + cat_id + "&"
      })
    }
    axios.get(url).then((res) => {
      this.setState({ suggestions: res.data.models.docs });
    });
  };

  onImageChange = (event) => {
    const target = event.target;
    if (!target.files.length) return;
    this.setState({
      file: target.files[0],
    });
    if (target.files && target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ swatchFile: e.target.result });
      };
      reader.readAsDataURL(target.files[0]);
    }
  };
  selectedComponents = (CompId) => {
    if (this.state.activeComponents.includes(CompId)) {
      let index = this.state.activeComponents.indexOf(CompId);
      this.state.activeComponents.splice(index, 1);
    } else {
      this.state.activeComponents.push(CompId);
    }
  };
  getComponents = () => {
    let pId = document.location.href;
    var url = new URL(pId);
    var productId = url.searchParams.get("id");
    this.setState({ productId });
    axios
      .get(API.getUrls.getComponents + productId + "?page=1&limit=10")
      .then((res) => {
        this.setState({ Components: [] });
        this.setState(
          {
            Components: res.data.components.docs,
            allComponentTitles: res.data.components.docs.map(
              ({ component_name, _id: compId }) => ({ component_name, compId })
            ),
          },

          () => {
            this.state.Components.forEach((item) => {
              this.state.activeComponents.push(item._id);
            });
            let temp = this.getCombinations(this.state.Components);
            // console.log("variantsCombo ", temp);
            this.setState({
              variantsCombo: temp,

              emptyComponents:
                this.state.Components.length === 0 ? true : false,
            });
          }
        );
      });
  };
  isVariantMade = (data, log = false) => {
    let i = 0;
    data.forEach((item) => {
      if (item.is_variant_made) {
        i++;
      }
    });
    // if (log) console.log(i, " == ", data.length);
    return i === data.length;
  };
  getUser = () => {
    // if (init() === "success") {
    let authToken = localStorage.getItem("token");
    axios
      .get(API.getUrls.getUser, {
        headers: { Authorization: "Bearer " + authToken },
      })
      .then((res) => {
        // console.log(res);
        this.setState({ userdetail: res.data.user });
      });
    // }
  };

  openModal() {
    this.setState({
      showFirst: true,
      showSecond: false,
      showThird: false,
      showFourth: false,
      visible: true,
    });
  }
  secondModal() {
    this.setState({
      showFirst: false,
      showSecond: true,
      showThird: false,
      showFourth: false,
      visible: true,
    });
  }
  thirdModal() {
    this.setState({
      showFirst: false,
      showSecond: false,
      showThird: true,
      showFourth: false,
      visible: true,
    });
  }
  fourthModal() {
    this.setState({
      showFirst: false,
      showSecond: false,
      showThird: false,
      showFourth: true,
      visible: true,
    });
  }
  closeModal() {
    this.setState({
      visible: false,
      openEdit: false,
      compnentEdit: false,
      materialEdit: false,
    });
    if (this.state.ShowComponent) {
      document.getElementById("componentForm")?.reset?.();
    } else {
      document.getElementById("materialForm")?.reset?.();
    }
  }
  handleInput = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        // console.log(this.state.language);
      }
    );
  };
  addComponent = (e) => {
    e.preventDefault();
    let authToken = localStorage.getItem("token");
    this.setState({ loading: true, showFirst: !true });
    let rawData = {
      component_name: this.state.compname,
      product: this.state.productId,
    };
    axios
      .post(API.postUrls.addComponents, rawData, {
        headers: { Authorization: "Bearer " + authToken },
      })
      .then((resp) => {
        if (resp.data.status === "success") {
          this.setState({
            notification: true,
            successMsg: resp.data.message,
            loading: false,
            visible: false,
            ShowComponent: false,
          });
          setTimeout(() => {
            this.setState({ notification: false });
            document.getElementById("componentForm").reset();

            // this.getComponents();
          }, 2000);
        } else {
          this.setState({
            notification: true,
            successMsg: resp.data.message,
            loading: false,
          });
          setTimeout(() => {
            this.setState({ notification: false });
          }, 2000);
        }
      })
      .finally(() => {
        this.getVariants();
        this.getComponents();
      });
  };

  addMaterial = (e) => {
    e.preventDefault();

    this.setState({ loading: true });

    let data = new FormData();
    if (this.state.material_name)
      data.append("material_name", this.state.material_name);
    data.append("material_type", this.state.type);
    if (this.state.type === "color") {
      data.append(
        "material_value",
        this.state.material_value ? this.state.material_value : "#ff0000"
      );
    }
    data.append("component", this.state.compId);
    if (this.state.type === "swatch") {
      data.append("swatch_image", this.state.file);
    }
    // if (init() === "success") {
    axios
      .post(API.postUrls.addMaterial, data)
      .then((resp) => {
        console.clear();
        // const errors = Object.keys(resp.data.errors);
        if (resp.data?.errors?.msg) {
          this.setState({
            notification: true,
            successMsg: resp.data.errors.msg,
            // visible: false,
            loading: false,
            // swatchFile: "",
            // file: "",
          });
          setTimeout(() => {
            this.setState({ notification: false });
          }, 2000);
          return;
        }
        // console.log("resp ", resp.data.errors);
        if (resp.data.status === "success") {
          this.setState({
            notification: true,
            successMsg: resp.data.message,
            visible: false,
            loading: false,
            swatchFile: "",
            file: "",
          });
          setTimeout(() => {
            this.setState({ notification: false });
            document.getElementById("materialForm").reset();
          }, 2000);
        } else {
          this.setState({
            notification: true,
            successMsg: resp.data.message,
          });
          setTimeout(() => {
            this.setState({ notification: false });
          }, 2000);
        }
      })
      .finally(() => {
        this.getComponents();
        this.getVariants();
      });
    // }
  };
  handleFile = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };
  handleGlbFile = (e) => {
    let target = e.target;
    // console.log(target.files[0].name, "target.files[0] ", target.name);

    const incr = this.state[target.name] ? 0 : 1;

    const noOfGlbsToUpload = !this.state.noOfGlbsToUpload
      ? 1
      : this.state.noOfGlbsToUpload + incr;
    // return;
    this.setState({ [target.name]: target.files[0], noOfGlbsToUpload }, () => {
      if (
        !!!target?.files?.[0] ||
        !!!target?.files?.[0]?.name.endsWith(".glb")
      ) {
        // alert("Please upload .glb file only.");
        // return false;
      } else {
        this.setState({
          uploadGlb: true,
          // noOfGlbsToUpload: (this.state.noOfGlbsToUpload ?? 0) + 1,
        });
      }
    });
  };
  handlevariantModal = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] }, () => {
      var fileName = document
        .getElementById("uploadVariant")
        .value.toLowerCase();
      if (!fileName.endsWith(".glb")) {
        alert("Please upload .glb file only.");
        return false;
      }
    });
  };

  getCombinations(arr) {
    if (arr.length === 0) return [[]];
    let [current, ...rest] = arr;
    let combinations = this.getCombinations(rest);
    return current.materials.reduce(
      (a, string) => [...a, ...combinations.map((c) => [string, ...c])],
      []
    );
  }

  handleChangeComplete = (color) =>
    this.setState({ material_value: color.hex });

  addVariants = (e) => {
    e.preventDefault();
    if (!this.state.Components.length) {
      alert("Add components");
    } else this.handleSaveRef.current.save();
    return;
    // console.log(this.state.variantsCombo);
    let noOfGlbsToUpload = this.state.noOfGlbsToUpload;

    // return;
    if (!noOfGlbsToUpload) {
      alert("Upload models");
      return;
    }

    let noOfGlbsUploaded = 0;
    let count = 0;
    this.state.variantsCombo.forEach((isVariant, index) => {
      if (!this.isVariantMade(isVariant)) {
        if (!this.state["model_file" + index]) {
          count++;
        }
      }
    });

    this.state.variantsCombo.forEach((singleVariant, index) => {
      if (!this.state["model_file" + index]) return;
      let data = new FormData();

      singleVariant.forEach((some) => {
        data.append("materials[]", some._id);
      });
      data.append("model_file", this.state["model_file" + index]);
      this.setState({ processing: true });
      axios
        .post(API.postUrls.addVariants + "/" + this.state.productId, data)
        .then((res) => {
          if (res.data.status === "success") {
            if (this.state.uploadCount + 1 == this.state.noOfGlbsToUpload) {
              window.location.href = "/viewer?id=" + this.state.productId;
            }
            this.setState({
              uploadCount: this.state.uploadCount + 1,
            });
            // console.log("this.state.uploadCount ", this.state.uploadCount);
          }
        })
        .catch(() => { });
    });

    if (count === this.state.variantsCombo.length) {
      this.setState({
        // processing: false,
        notification: true,
        successMsg: "Variant Added Successfully",
      });
      setTimeout(() => {
        this.setState({ notification: false });
      }, 2000);
    }
    // }
  };
  getSingleProduct = () => {
    let pId = document.location.href;
    var url = new URL(pId);
    var productId = url.searchParams.get("id");
    // if (init() === "success") {
    axios.get(API.getUrls.getProducts + "/" + productId).then((res) => {
      // console.log(`res `, res);
      this.setState({
        product: res.data.product,
        placement: res.data.product.placement,
        catIds: res.data.product.categories.map(
          ({ title: label, _id: value }) => ({ value, label })
        ),
      });
    });
    // }
  };
  fetchCategories = () => {
    axios
      .get(API.getUrls.Categories + "?page=1&limit=10&status=active")
      .then((res) => {
        this.setState({
          categories: res.data.categories.docs.map(
            ({ title: label, _id: value }) => ({ value, label })
          ),
        });
      })
      .catch((err) => { });
  };
  updateProduct = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    let data = new FormData();
    if (!this.state.catIds.length) {
      this.setState({
        notification: true,
        successMsg: "Category is required",
        loading: false,
      });
      setTimeout(() => {
        this.setState({ notification: false });
      }, 2000);
      return;
    }
    if (this.state.title) data.append("title", this.state.title);
    if (this.state.placement) data.append("placement", this.state.placement);
    if (this.state.sub_title) data.append("sub_title", this.state.sub_title);
    if (this.state.filename) {
      data.append("posterImage", this.state.filename);
    }
    this.state.catIds.forEach((cat) => {
      data.append("categories[]", cat.value);
    });
    data.append("link_url", this.state.link_url);
    data.append("link_title", this.state.link_title);
    data.append("link_description", this.state.link_description);
    data.append("language", this.state.language);

    if (this.state.product.is_protected) {
      if (this.state.deletePasscode) {
        data.append("delete_passcode", true);
        data.append("passcode", this.state.passcode);
      } else if (this.state.changePasscode) {
        data.append("change_passcode", true);
        data.append("prevPasscode", this.state.prevPasscode);
        data.append("passcode", this.state.passcode);
      }
    } else if (this.state.is_protected && this.state.passcode.trim().length) {
      data.append("is_protected", true);
      data.append("passcode", this.state.passcode);
    }

    axios
      .put(API.postUrls.addproduct + "/" + this.state.product._id, data)
      .then((resp) => {
        if (resp.data.status === "success") {
          this.setState({
            notification: true,
            loading: false,
            successMsg: resp.data.message,
            productadded: true,
            openEdit: false,
            openBuyNow: false,
            passcode: "",
            prevPasscode: "",
            changePasscode: false,
            deletePasscode: false,
          });
          setTimeout(() => {
            this.setState({ notification: false });
            this.getSingleProduct();
          }, 2000);
        } else {
          this.setState({
            notification: true,
            successMsg: resp.data.message,
            loading: false,
            // passcodeMsg: resp.data.passcodeMsg || "",
          });
          setTimeout(() => {
            this.setState({ notification: false });
          }, 2000);
        }
      })
      .catch((err) => {
        this.setState({
          notification: true,
          successMsg: "Server is busy. Please try again!",
          loading: false,
        });
        setTimeout(() => {
          this.setState({ notification: false });
        }, 2000);
      });
    // }
  };
  updateComponent = (e) => {
    e.preventDefault();
    let authToken = localStorage.getItem("token");
    this.setState({ loading: true });
    let rawData = {
      component_name: this.state.compname,
      product: this.state.productId,
    };
    axios
      .put(API.postUrls.addComponents + "/" + this.state.compId, rawData, {
        headers: { Authorization: "Bearer " + authToken },
      })
      .then((resp) => {
        if (resp.data.status === "success") {
          this.setState({
            notification: true,
            successMsg: resp.data.message,
            loading: false,
            compnentEdit: false,
          });
          setTimeout(() => {
            this.setState({ notification: false });

            this.getComponents();
          }, 2000);
        } else {
          this.setState({
            notification: true,
            successMsg: resp.data.message,
          });
          setTimeout(() => {
            this.setState({ notification: false });
          }, 2000);
        }
      });
  };
  updateMaterial = (e, data) => {
    e.preventDefault();

    this.setState({ loading: true });

    let formData = new FormData();
    formData.append("material_name", data.material_name);
    formData.append("material_type", data.material_type);
    if (data.material_type === "color") {
      formData.append("material_value", data.material_value);
    }
    formData.append("component", data.compId);
    if (data.material_type === "swatch" && data.file) {
      formData.append("swatch_image", data.file);
    }
    // if (init() === "success") {
    axios
      .put(API.postUrls.addMaterial + "/" + data.material_id, formData)
      .then((resp) => {
        if (resp.data.status === "success") {
          this.setState({
            notification: true,
            successMsg: resp.data.message,
            visible: false,
            loading: false,
            materialEdit: false,
          });
          setTimeout(() => {
            this.setState({ notification: false });
            this.getComponents();
          }, 2000);
        } else {
          this.setState({
            notification: true,
            successMsg: resp.data.message,
          });
          setTimeout(() => {
            this.setState({ notification: false });
          }, 2000);
        }
      });
    // }
  };
  openEditModal = () => {
    this.setState({
      openEdit: true,
    });
  };
  canIgnoreDeleteConfirmation = (compId) => {
    let isSecondCompoEmpty = false;
    if (compId && this.state.Components.length == 2) {
      isSecondCompoEmpty = this.state.Components.find(
        ({ _id }) => _id != compId
      );

      isSecondCompoEmpty = isSecondCompoEmpty?.materials.length == 0;
    }
    return (
      !this.state?.Variants?.length ||
      this.state.Components.length <= 1 ||
      !!isSecondCompoEmpty
    );
  };
  getVariants = () => {
    let pId = document.location.href;
    var url = new URL(pId);
    var productId = url.searchParams.get("id");
    axios
      .get(API.getUrls.variants + "?page=1&limit=10&product=" + productId)
      .then((res) => {
        this.setState(
          {
            Variants: res.data.docs,
          },
          () => {
            this.getComponents();
          }
        );
      });
  };

  variantDelete = () => {
    // console.log("variantDeletevariantDelete");
    let pId = document.location.href;
    var url = new URL(pId);
    var productId = url.searchParams.get("id");
    if (this.state.Variants.length > 0) {
      // if (init() === "success") {
      axios
        .delete(API.postUrls.deleteAllVariants + "/" + productId)
        .then((res) => {
          if (res.data.status === "success") {
            this.setState({
              notification: true,
              successMsg: res.data.message,
              confirmationComponentAdd: false,
            });
            setTimeout(() => {
              this.setState({
                notification: false,
              });
              window.location.reload();
            }, 5000);
          }
        });
      // }
    }
  };

  deleteMaterial = (id, isValid) => {
    // if (init() === "success") {
    axios.delete(API.postUrls.addMaterial + "/" + id).then((res) => {
      if (res.data.status === "failed") {
        this.setState(
          {
            notification: true,
            successMsg: res.data.message,
            deleteConfirmation: false,
          },
          () => { }
        );
        setTimeout(() => {
          this.setState({
            notification: false,
          });
        }, 5000);

        this.getComponents();
        this.getVariants();
      }
    });
    // }
  };
  deleteComponent = (id) => {
    let authToken = localStorage.getItem("token");
    this.setState({ loading: true });

    axios
      .delete(API.postUrls.addComponents + "/" + id, {
        headers: { Authorization: "Bearer " + authToken },
      })
      .then((resp) => {
        if (resp.data.status === "failed") {
          this.setState({
            notification: true,
            successMsg: resp.data.message,
            confirmationComponentDelete: false,
          });
          setTimeout(() => {
            this.setState({
              notification: false,
            });
          }, 5000);
          // this.variantDelete();
          this.getComponents();
        } else {
          this.setState({
            notification: true,
            successMsg: resp.data.message,
            confirmationComponentDelete: false,
          });
          setTimeout(() => {
            this.setState({
              notification: false,
            });
          }, 2000);
          // this.variantDelete();
          this.getComponents();
        }
      })
      .finally(() => {
        this.setState({ confirmationComponentDelete: false });
        this.getComponents();
        this.getVariants();
      });
  };

  variant_update = (id) => {
    // if (init() === "success") {
    this.setState({ loading: true });
    let data = new FormData();

    data.append("model_file", this.state.model_file);
    axios.put(API.postUrls.addVariants + "/" + id, data).then((res) => {
      if (res.data.status === "success") {
        this.setState({
          notification: true,
          successMsg: res.data.message,
          confirmationComponentAdd: false,
        });
        setTimeout(() => {
          this.setState({
            notification: false,
            variantUpdate: false,
          });
        }, 3000);
      }
    });
    // }
  };

  addVisibilty = (e) => {
    // console.log(e);
    let pId = document.location.href;
    var url = new URL(pId);
    var productId = url.searchParams.get("id");
    let data = new FormData();
    data.append("visibility", e);
    axios.put(API.postUrls.updateProduct + productId, data).then((resp) => {
      // console.log(resp);
    });
  };

  // crop function start
  handleFile = (e) => {
    // console.log(e.target.files[0]);
    this.setState({ [e.target.name]: e.target.files[0] }, () => {
      var fileUpload = document.getElementById("uploadFile");

      var regex = /(\.jpg|\.jpeg|\.png|\.PNG)$/i;
      if (regex.test(fileUpload.value.toLowerCase())) {
        if (typeof fileUpload.files != "undefined") {
          var reader = new FileReader();
          var scope = this;
          reader.readAsDataURL(fileUpload.files[0]);
          reader.onload = function (e) {
            var image = new Image();

            image.src = e.target.result;

            image.onload = function () {
              var height = this.height;
              var width = this.width;
              scope.setState({
                visibleCrop: true,
              });

              // if (height > 600 || width > 800) {
              //   alert("Height and Width must not exceed 800px and 600px.");
              //   return false;
              // }
              scope.setState({ uploadJPG: true });
              return true;
            };
          };
        } else {
          alert("This browser does not support HTML5.");
          return false;
        }
      } else {
        var thisvar = this;
        alert("Please upload file having extensions .jpeg/.jpg/.png/ only.");
        thisvar.setState({ uploadJPG: false });
        return false;
      }
    });
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
      this.convertUrlToImageData(croppedImageUrl);
      // console.log(this.imageRef);
      // console.log(crop);
      // console.log(croppedImageUrl);
      // this.blobToFile(croppedImageUrl, "my-image.png");
    }
  }

  getBlobFromUrl = (myImageUrl) => {
    return new Promise((resolve, reject) => {
      let request = new XMLHttpRequest();
      request.open("GET", myImageUrl, true);
      request.responseType = "blob";
      request.onload = () => {
        resolve(request.response);
      };
      request.onerror = reject;
      request.send();
    });
  };

  getDataFromBlob = (myBlob) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(myBlob);
    });
  };

  convertUrlToImageData = async (myImageUrl) => {
    try {
      let myBlob = await this.getBlobFromUrl(myImageUrl);
      // console.log(myBlob)
      let myImageData = await this.getDataFromBlob(myBlob);
      // console.log(myImageData)
      this.b64toBlob(myImageData);
      this.dataURLtoFile(myImageData, "newfile.png");
    } catch (err) {
      // console.log(err);
      return null;
    }
  };

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    var blob = new File([u8arr], filename, { type: mime });
    // console.log(blob);
    this.setState({
      filename: blob,
    });
  }

  blobToFile(theBlob, fileName) {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
  }

  convertedFile(theBlob) {
    var myBlob = new Blob();

    var myFile = this.blobToFile(theBlob, "my-image.png");
  }

  b64toBlob(dataURI) {
    var byteString = atob(dataURI.split(",")[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: "image/jpeg" });
    // console.log(blob);
    this.blobToFile(blob, "my-image.png");
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      // console.log(canvas);
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error("Canvas is empty");
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve(this.fileUrl);
        },
        "image/jpeg",
        1
      );
    });
  }

  onSelectFile = (e) => {
    this.setState({
      filename: "",
      src: "",
    });
    // console.log(this.state.visibleCropProduct);
    // console.log(e.target.files);
    // console.log(e.target.files.length);
    if (e.target.files && e.target.files.length > 0) {
      this.setState(
        {
          visibleCropProduct: true,
        },
        () => {
          // console.log(this.state.visibleCropProduct);
        }
      );
      const reader = new FileReader();
      // console.log(reader);
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result }, () => {
          // console.log(this.state.src);
        })
      );
      // console.log(this.state.src);
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  selectSuggestion = (model) => {
    this.setState({ suggestionSelected: model });
  }
  showSuggestion = (variant, isSaved, variantId) => {
    console.log(variant, isSaved, variantId)
    this.setState({ suggestionModelModal: true, selectedVariant: { ...variant, isSaved, variantId: variantId?.variant } })
  }
  addVariantWithSuggestedModels = () => {
    const materials = []
    this.state.selectedVariant.materials.forEach((m) => {
      materials.push(m)
    });
    const payload = {
      materials,
      product: this.state.productId,
      suggestedModelId: this.state.suggestionSelected?._id,
      isSaved: this.state.selectedVariant.isSaved,
      variantId: this.state.selectedVariant.variantId
    }
    console.log(payload)
    axios.post(API.postUrls.variantFromSuggestion + this.state.productId, payload).then(res => {
      console.log(res.data);
      this.setState({
        suggestionModelModal: false,
        selectedVariant: {},
        notification: true,
        successMsg: res.data.message
      }, () => {
        setTimeout(() => {
          this.setState({ notification: false })
        }, 3000);
      });
      this.getVariants();
    }).catch(err => {
      console.log(err)
    })
  }

  showTemplateModal = (variant, variantId) => {
    console.log(variantId)
    const __material = variant.materials;
    const __materials = __material.map(mat => mat._id)

    let templateStringParams = queryString.stringify({ ["materials"]: __materials });
    templateStringParams += "&variant=" + variantId?.variant;
    console.log(variant, templateStringParams)
    this.setState({ showTemplateModal: !this.state.showTemplateModal, templateStringParams })
  }
  // end corp funtion
  render() {
    let pId = document.location.href;
    var url = new URL(pId);
    var productId = url.searchParams.get("id");
    if (
      this.state.uploadCount === this.state.variantsCombo.length &&
      this.state.variantsCombo.length > 0
    ) {
      return <Navigate to={"/viewer/" + "?id=" + this.state.productId} />;
    } else {
      // crop for function
      const { crop, croppedImageUrl, src } = this.state;
      //  crop for funtion
      return (
        <Container className="containerSpacingdashBoard" maxWidth={"lg"}>
          {this.state.notification ? (
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              open={true}
              style={{ bottom: "40px", textTransform: "capitalize" }}
              color={"#bdbdbd"}
              autoHideDuration={6000}
              message={this.state.successMsg}
            ></Snackbar>
          ) : null}
          {/* <form action="" onSubmit={this.addVariants}> */}
          <Grid
            container
            spacing={2}
            className="editWrapper"
            style={{ marginTop: "25px" }}
          >
            <Grid item sm={9}>
              <div className="editLeftWrapper">
                <Grid container spacing={2}>
                  <Grid item sm={12}>
                    <Typography className="heading">
                      Please upload the right .glb files for each material
                      combination listed below. You can add more components and
                      variants in the component tab on the right.
                      <br />
                      <br />
                      If you need any assistance with making the .glb files,
                      please{" "}
                      <a
                        href="/contact-us"
                        style={{
                          textDecoration: "none",
                          display: "contents",
                        }}
                      >
                        contact us
                      </a>
                      .
                    </Typography>
                  </Grid>
                </Grid>
                {this.state.emptyComponents ? (
                  <div>
                    <Grid
                      container
                      spacing={2}
                      style={{
                        borderBottom: "1px solid rgb(229 225 225)",
                        width: " 99%",
                        marginLeft: "1px",
                      }}
                    ></Grid>
                    <Typography
                      style={{ marginTop: "50px", textAlign: "center" }}
                    >
                      Components not available.
                    </Typography>
                  </div>
                ) : (
                  <div
                    style={{
                      overflow: "hidden",
                    }}
                  >
                    {true ? (
                      <VariantsCreatorTable
                        ref={this.handleSaveRef}
                        productId={productId}
                        variants={this.state.Variants}
                        components={this.state.Components}
                        showSuggestion={this.showSuggestion}
                        showTemplateModal={this.showTemplateModal}
                      />
                    ) : (
                      <>
                        <Grid
                          container
                          spacing={2}
                          style={{
                            borderBottom: "1px solid rgb(229 225 225)",
                            width: " 99%",
                            marginLeft: "1px",
                          }}
                        >
                          {this.state.Components.map((component) => (
                            <Grid
                              item
                              sm={3}
                              className="rowHead"
                              key={component._id}
                            >
                              {component.component_name}
                            </Grid>
                          ))}
                          <Grid item sm={3} className="rowHead">
                            Action
                          </Grid>
                        </Grid>
                        <div>
                          <VariantsCreator
                            ref={this.handleSaveRef}
                            productId={productId}
                            variants={this.state.Variants}
                            components={this.state.Components}
                          />
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </Grid>
            <Grid item sm={3}>
              <div className="editRightWrapper">
                <TopRightBoxes
                  product={this.state.product}
                  openEditModal={() => {
                    this.openEditModal();
                  }}
                  enableBuyNow={() => {
                    this.setState({ openBuyNow: true });
                  }}
                  handleVisiblity={() => {
                    this.setState(
                      {
                        visiblity: !!!this.state.visiblity,
                        visiblityoff: !!!this.state.visiblityoff,
                      },
                      () => {
                        this.addVisibilty(this.state.visiblity);
                      }
                    );
                  }}
                  propState={this.state}
                />
                {this.state.Variants.length > 0 && false ? (
                  <div
                    className="addComp"
                    style={{
                      // border: "1px solid red",
                      position: "relative",
                      overflow: "hidden",
                    }}
                    onClick={() =>
                      this.setState({
                        confirmationComponentAdd: true,
                        loading: false,
                      })
                    }
                  >
                    <div
                      className="inlineWrapper"
                      style={{
                        zIndex: 10,
                      }}
                    >
                      <Typography className="text"> Add Component</Typography>

                      <AddCircleOutlineSharpIcon
                        style={{
                          position: "absolute",
                          right: "23px",
                          top: "15px",
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    className="addComp"
                    onClick={() => {
                      this.setState({
                        ShowComponent: true,
                        showFirst: false,
                        showSecond: false,
                        showThird: false,
                        showFourth: false,
                        visible: true,
                        loading: false,
                      });
                    }}
                    style={{
                      position: "relative",
                      overflow: "hidden",
                      marginBottom: 10,
                    }}
                  >
                    <div className="inlineWrapper">
                      <Typography className="text">Add Component</Typography>

                      <AddCircleOutlineSharpIcon
                        style={{
                          position: "absolute",
                          right: "23px",
                          top: "15px",
                        }}
                      />
                    </div>
                  </div>
                )}
                {/* Components Mapping */}
                {this.state.Components
                  ? this.state.Components.map((comp, index) => (
                    <div className="parentDiv">
                      <div
                        className="box1"
                        style={{
                          width: "100%",
                          height: this.state.activeComponents.includes(
                            comp._id
                          )
                            ? "226px"
                            : "38px",
                          overflowY: this.state.activeComponents.includes(
                            comp._id
                          )
                            ? "auto"
                            : "",
                          position: "relative",
                          transitionDuration: "222ms",
                          paddingTop: 15,
                        }}
                      >
                        <ExpandMoreRoundedIcon
                          className="downArrow"
                          style={{
                            cursor: "pointer",
                            transform: `rotate(${this.state.activeComponents.includes(comp._id)
                              ? "180deg"
                              : "0deg"
                              })`,
                          }}
                          onClick={() => {
                            // alert("ok");
                            this.selectedComponents(comp._id);

                            this.setState({
                              showMaterials:
                                this.state.activeComponents.includes(comp._id)
                                  ? true
                                  : false,
                            });
                          }}
                        />

                        <CloseIcon
                          onClick={() => {
                            // if (
                            //   this.canIgnoreDeleteConfirmation() ||
                            //   !comp.materials.length
                            // ) {
                            //   this.deleteComponent(comp._id);
                            // } else
                            this.setState({
                              confirmationComponentDelete: true,
                              deleteCompId: comp._id,
                              isThisHaveMultipleMaterials:
                                comp.materials.length,
                            });
                          }}
                          style={{
                            color: "#bdbdbd",
                            fontSize: "18px",
                            position: "absolute",
                            right: "40px",
                            top: "17px",
                            cursor: "pointer",
                            marginLeft: 8,
                          }}
                        />
                        <div
                          className="inlineWrapper"
                          style={{ marginLeft: "0", cursor: "pointer" }}
                        >
                          <Typography
                            className="buyNow"
                            style={{
                              cursor: "pointer",
                              fontWeight: "600",
                              // border: "1px solid black",
                              fontSize: 16 || 18,
                              textTransform: "capitalize",
                              color: "#2E2E2E",
                            }}
                          >
                            {comp.component_name}
                          </Typography>
                          <ComponentEdit
                            component_name={comp.component_name}
                            compId={comp._id}
                            product={this.state.productId}
                            allComponentTitles={this.state.allComponentTitles}
                            postUpdateCB={() => this.getComponents()}
                          />
                          {/* <EditOutlinedIcon
                              style={{
                                marginLeft: "10px",
                                marginTop: "16px",
                                cursor: "pointer",
                                position: "absolute",
                                right:
                                  window.innerWidth < 1000 ? "56px" : "64px",
                                top: "2px",
                                fontSize: "16px",
                                color: "#bdbdbd",
                              }}
                              onClick={() => {
                                this.setState({
                                  compId: comp._id,
                                  compnentEdit: true,
                                  component_name: comp.component_name,
                                });
                              }}
                            /> */}
                        </div>
                        <br />
                        {this.state.activeComponents.includes(comp._id) ? (
                          <div
                            className="inlineWrapper"
                            style={{
                              marginLeft: "0px",
                              marginTop: 5,
                              // border: "1px solid black",
                            }}
                          >
                            {comp
                              ? comp.materials.length > 0
                                ? comp.materials.map((singleMat, index) => (
                                  <div
                                    style={{
                                      // border: "1px solid black",
                                      width: "max-content",
                                    }}
                                  >
                                    {singleMat?.material_type ? (
                                      singleMat.material_type ===
                                        "color" ? (
                                        <div
                                          style={{
                                            position: "relative",
                                            width: "50px",
                                            height: "53px",
                                            // border: "1px solid aqua  ",
                                            padding: 0,
                                            boxSizing: "border-box",
                                          }}
                                        >
                                          <div
                                            className="smallBox"
                                            onClick={() => {
                                              this.setState({
                                                material_value:
                                                  singleMat.material_value,
                                                material_name:
                                                  singleMat.material_name,
                                                compId: comp._id,
                                                material_id: singleMat._id,
                                              });
                                            }}
                                            style={{
                                              backgroundColor:
                                                singleMat.material_value,
                                              position: "relative",
                                              cursor: "pointer",
                                              // border: "1px solid blue",
                                              // margin: 0,
                                              boxSizing: "border-box",
                                            }}
                                          >
                                            <CloseIcon
                                              onClick={() => {
                                                this.setState({
                                                  deleteConfirmation: true,
                                                  deleteMatId:
                                                    singleMat._id,
                                                  isAvailable:
                                                    singleMat.is_variant_made,
                                                  deleteMatIdCompId:
                                                    comp._id,
                                                });
                                              }}
                                              style={{
                                                color: "#fff",
                                                fontSize: "13px",
                                                position: "absolute",
                                                right: "-7px",
                                                top: "-8px",
                                                cursor: "pointer",
                                                background: "gray",
                                                borderRadius: "10px",
                                              }}
                                            />
                                            <Edit
                                              onClick={() => {
                                                this.setState({
                                                  materialEdit: true,
                                                  materialEditObject: {
                                                    material_value:
                                                      singleMat.material_value,
                                                    material_name:
                                                      singleMat.material_name,
                                                    material_id:
                                                      singleMat._id,
                                                    compId: comp._id,
                                                    material_type:
                                                      singleMat.material_type,
                                                  },
                                                });
                                              }}
                                              style={{
                                                color: "#fff",
                                                fontSize: "22px",
                                                position: "absolute",
                                                right: "14px",
                                                top: "14px",
                                                cursor: "pointer",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            position: "relative",
                                            width: "70px",
                                            height: "53px",
                                            border: "0px solid aqua  ",
                                          }}
                                        >
                                          <img
                                            className="smallBox"
                                            style={{
                                              background: "transparent",
                                              position: "relative",
                                              cursor: "pointer",
                                            }}
                                            src={
                                              API.imgUrl +
                                              singleMat.material_value
                                            }
                                            alt=""
                                            onClick={() => {
                                              this.setState({
                                                materialEdit: true,
                                                material_value:
                                                  singleMat.material_value,
                                                material_name:
                                                  singleMat.material_name,
                                                material_id: singleMat._id,
                                                compId: comp._id,
                                                addSwatch:
                                                  this.state.type ===
                                                    "swatch"
                                                    ? true
                                                    : false,
                                                addColor:
                                                  this.state.type ===
                                                    "swatch"
                                                    ? false
                                                    : true,
                                              });
                                            }}
                                          />
                                          <CloseIcon
                                            onClick={() => {
                                              this.setState({
                                                deleteConfirmation: true,
                                                deleteMatId: singleMat._id,
                                                isAvailable:
                                                  singleMat.is_variant_made,
                                              });
                                            }}
                                            style={{
                                              color: "#fff",
                                              fontSize: "13px",
                                              position: "absolute",
                                              right: "-1px",
                                              top: "-8px",
                                              cursor: "pointer",
                                              background: "gray",
                                              borderRadius: "10px",
                                            }}
                                          />
                                          <Edit
                                            onClick={() => {
                                              this.setState({
                                                materialEdit: true,
                                                materialEditObject: {
                                                  material_value:
                                                    singleMat.material_value,
                                                  material_name:
                                                    singleMat.material_name,
                                                  material_id:
                                                    singleMat._id,
                                                  compId: comp._id,
                                                  material_type:
                                                    singleMat.material_type,
                                                },
                                              });
                                            }}
                                            style={{
                                              color: "#fff",
                                              fontSize: "22px",
                                              position: "absolute",
                                              right: "22px",
                                              top: "14px",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </div>
                                      )
                                    ) : null}
                                    <div
                                      style={{
                                        // paddingRight: "10px",
                                        width: "70%",
                                        paddingLeft: "6px",
                                        marginTop: "-7px",
                                        inlineSize: "60px",
                                        overflowWrap: "break-word",
                                        // border: "1px solid blue",
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontFamily: "Inter",
                                          textAlign: "center",
                                          cursor: "pointer",
                                          // padding: 0,
                                          // margin: 0,
                                          paddingRight: 0,
                                          marginRight: 0,
                                          fontSize: 14,
                                          textTransform: "capitalize",
                                          color: "#565656",
                                          // border: "1px solid red",
                                        }}
                                        onClick={() =>
                                          this.state.showAllText
                                            ? this.setState({
                                              showAllText: false,
                                            })
                                            : this.setState({
                                              showAllText: true,
                                            })
                                        }
                                      >
                                        {singleMat
                                          ? singleMat.material_name
                                            .length <= 25
                                            ? singleMat.material_name
                                            : singleMat.material_name.substring(
                                              0,
                                              10
                                            ) + "..."
                                          : null}
                                      </p>
                                    </div>
                                  </div>
                                ))
                                : null
                              : null}

                            <br />

                            <div
                              className="secondBox"
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                // alert("hello");
                                this.setState(
                                  {
                                    compId: comp._id,
                                    material_value: "",
                                    material_name: "",
                                    OPEN_ADD_MAT_CONFIRM_MODAL:
                                      this.state.Variants.length &&
                                      comp.materials.length == 0,
                                    // this.canIgnoreDeleteConfirmation(
                                    //   comp._id
                                    // )
                                    //   ? false
                                    //   : true,
                                    ShowComponent: false,
                                  },
                                  () => {
                                    if (
                                      !this.state.Variants.length ||
                                      comp.materials.length
                                      // this.canIgnoreDeleteConfirmation(
                                      //   comp._id
                                      // )
                                    )
                                      this.openModal();
                                  }
                                );
                              }}
                            >
                              <AddIcon
                                style={{
                                  marginTop: "14px",
                                  color: "gray",
                                  cursor: "pointer",
                                }}
                              />

                              <Typography
                                className="default"
                                style={{
                                  marginLeft: "0px",
                                  width: "55px",
                                  lineHeight: "19px",
                                  marginTop: 15,
                                }}
                              >
                                Add Material
                              </Typography>
                            </div>
                          </div>
                        ) : null}

                        <br />
                      </div>
                    </div>
                  ))
                  : null}

                <div className="addComp">
                  <Typography className="text"> Status : Draft</Typography>
                </div>
                <div className="addComp" style={{ display: "inline-flex" }}>
                  <img
                    src={language}
                    width={"20px"}
                    alt=""
                    style={{
                      width: "20px",
                      height: "20px",
                      marginTop: "18px",
                    }}
                  />
                  <select
                    name="language"
                    id=""
                    className="languageBtn"
                    value={this.state.language}
                    onChange={(e) => {
                      this.setState({ language: e.target.value }, () => {
                        // console.log(this.state.language);
                        this.updateProduct(e);
                      });
                    }}
                  // onChange={this.handleInput}
                  >
                    <option value="English" className="langOption">
                      English
                    </option>
                    <option value="French" className="langOption">
                      French
                    </option>
                  </select>
                </div>

                <Button
                  className="saveBtn"
                  // type="submit"
                  style={{ width: "109%" }}
                  onClick={this.addVariants}
                >
                  Save
                </Button>

                <Typography style={{ color: "red" }}>
                  {this.state.warn1 ? "* Modal file is required." : ""}
                </Typography>
              </div>
            </Grid>
          </Grid>
          {/* </form> */}
          <VariantsDeleteConfirmation
            visible={this.state.OPEN_ADD_MAT_CONFIRM_MODAL}
            onClickAway={() => {
              this.setState({ OPEN_ADD_MAT_CONFIRM_MODAL: false });
            }}
            handleDelete={() => {
              this.setState({ OPEN_ADD_MAT_CONFIRM_MODAL: false });
              this.openModal();
            }}
          />
          <Modal
            visible={this.state.visible}
            width="300"
            height="470"
            effect="fadeInUp"
            onClickAway={() => this.closeModal()}
          >
            {this.state.showFirst ? (
              <div className="modalWrapper">
                <CloseSharpIcon
                  style={{ position: "absolute", right: "10px", top: "5px" }}
                  onClick={() => this.closeModal()}
                />
                <form action="" id="materialForm" onSubmit={this.addMaterial}>
                  <h1 className="modalHead">Preview</h1>
                  {this.state.type === "color" ? (
                    <div
                      className="modalBox"
                      style={{
                        backgroundColor: this.state.material_value
                          ? this.state.material_value
                          : "red",
                      }}
                    ></div>
                  ) : (
                    <div>
                      {this.state.swatchFile ? (
                        <img
                          src={this.state.swatchFile}
                          width="105px"
                          height="105px"
                          style={{ marginBottom: "10px" }}
                          alt=""
                        />
                      ) : (
                        <img
                          src={rectangle}
                          width="105px"
                          height="105px"
                          style={{ marginBottom: "10px" }}
                          alt=""
                        />
                      )}
                    </div>
                  )}
                  <div className="inputTextWrap">
                    <span className="modalText">Name</span>
                    <input
                      type="text"
                      className="modalInput"
                      // required
                      name="material_name"
                      onChange={this.handleInput}
                    />
                  </div>

                  <Typography className="modalMaterialHead">
                    {" "}
                    Material
                  </Typography>

                  <div style={{ marginBottom: "20px" }}>
                    <span
                      className="modalMaterialHead"
                      style={{
                        marginRight: "80px",
                        borderBottom: "none",
                        fontWeight: this.state.type === "color" ? "bold" : 400,
                      }}
                      onClick={() =>
                        this.setState({
                          addColor: true,
                          addSwatch: false,
                          type: "color",
                        })
                      }
                    >
                      Solid Color
                    </span>
                    <img src={verticalLine} className="varticalLine" alt="" />

                    <span
                      className="modalText"
                      style={{
                        fontWeight: this.state.type === "swatch" ? "bold" : 400,
                      }}
                      onClick={() =>
                        this.setState({
                          addSwatch: true,
                          addColor: false,
                          type: "swatch",
                        })
                      }
                    >
                      Swatch
                    </span>
                  </div>

                  {this.state.addColor ? (
                    <div>
                      <div
                        className={
                          this.state.colorPicker
                            ? "inputTextWrap2"
                            : "inputTextWrap"
                        }
                      >
                        <span
                          className="modalText"
                          style={{ marginLeft: " -8px" }}
                        >
                          Hexcode
                        </span>
                        <input
                          type="text"
                          className="modalInput"
                          name="material_value"
                          value={
                            this.state.material_value
                              ? this.state.material_value
                              : "#ff0000"
                          }
                          onClick={() =>
                            this.setState({
                              colorPicker: !this.state.colorPicker,
                              ShowComponent: false,
                            })
                          }
                        />
                      </div>
                      <div
                        style={{
                          position: "relative",
                          bottom: "210px",
                          left: "300px",
                        }}
                      >
                        {this.state.colorPicker ? (
                          <SketchPicker
                            name="material_value"
                            color={this.state.material_value}
                            onChangeComplete={this.handleChangeComplete}
                            width="175px"
                          />
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                  {this.state.addSwatch ? (
                    <div>
                      <Button
                        variant="outlined"
                        size="medium"
                        className="uploadbtnswatch"
                        component="label"
                        style={{ margin: "11px auto" }}
                      >
                        Upload Swatch
                        <input
                          type="file"
                          onChange={this.onImageChange}
                          name="file"
                          hidden
                        />
                      </Button>
                      <Typography className="modalHead">
                        {this.state.file
                          ? "Swatch Uploaded"
                          : "accepted file types : .jpeg, .png"}
                      </Typography>
                    </div>
                  ) : null}
                  <Button
                    className={
                      this.state.colorPicker
                        ? this.state.addSwatch
                          ? "uploadbtnswatchPicker1"
                          : "uploadbtnswatchPicker"
                        : "uploadbtnswatch"
                    }
                    type="submit"
                  >
                    Add Material
                  </Button>
                </form>
              </div>
            ) : null}

            {this.state.ShowComponent ? (
              <div
                className="modalWrapper"
                style={{ marginTop: "115px", border: "0px solid red" }}
              >
                <form action="" id="componentForm" onSubmit={this.addComponent}>
                  <h1 className="modalHead" style={{ fontSize: "18px" }}>
                    Add Component
                  </h1>
                  {/* <img
                    src={yes}
                    alt=""
                    style={{ position: "absolute", right: "44px", top: "10px" }}
                  /> */}
                  <CloseSharpIcon
                    style={{ position: "absolute", right: "10px", top: "5px" }}
                    onClick={() => this.closeModal()}
                  />

                  <div className="inputTextWrap">
                    <span className="modalText">Name</span>
                    <input
                      type="text"
                      className="modalInput"
                      name="compname"
                      required
                      onChange={this.handleInput}
                    />
                  </div>
                  <br />
                  <Button className="addBtn" type="submit">
                    {this.state.loading ? (
                      <CircularProgress color={"white"} size={25} />
                    ) : (
                      "Add Component"
                    )}
                  </Button>
                </form>
              </div>
            ) : null}
          </Modal>
          <Modal
            visible={this.state.processing && true}
            width="450"
            height="200"
            effect="fadeInUp"
          >
            <div style={{ textAlign: "center", marginTop: "66px" }}>
              <p>Please wait...</p>
              <Box sx={{ position: "relative", display: "inline-flex" }}>
                <CircularProgress
                  color={"info"}
                  variant="determinate"
                  value={Math.round(
                    (100 / this.state.noOfGlbsToUpload) * this.state.uploadCount
                  )}
                />

                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                  >
                    {`${Math.round(
                      (100 / this.state.noOfGlbsToUpload) *
                      this.state.uploadCount
                    )}%`}
                  </Typography>
                </Box>
              </Box>

              <p>
                {this.state.uploadCount +
                  "/" +
                  this.state.noOfGlbsToUpload +
                  " models are uploaded"}
              </p>
            </div>
          </Modal>
          <TSEDialog
            open={this.state.openEdit}
            handleClose={() => {
              this.setState({ openEdit: !this.state.openEdit });
            }}
          >
            {" "}
            <div
              style={{
                minWidth: 350,
                // border: "2px solid red",
              }}
            >
              <div
                className="modalWrapper"
                style={{ border: "0px solid red", paddingBottom: "1rem" }}
              >
                {" "}
                <h4 style={{ margin: "20px 0", marginTop: 10, fontSize: 20 }}>
                  Edit product details
                </h4>
                <form
                  action=""
                  onSubmit={(e) => {
                    this.updateProduct(e);
                  }}
                >
                  {/* <Typography
                  style={{
                    margin: 0,
                  }}
                >
                  {this.state.filename ? "File updated" : null}
                </Typography> */}
                  <div
                    className="contentWrap"
                    style={{
                      margin: "0 auto",
                    }}
                  >
                    <TSEInputLabel label="Product Name" />
                    <input
                      type="text"
                      name="title"
                      className="modalInput"
                      style={{
                        marginBottom: "5px",
                        width: "100%",
                      }}
                      defaultValue={
                        this.state.product ? this.state.product.title : ""
                      }
                      onChange={this.handleInput}
                      required
                    />
                    <TSEInputLabel label="Product Sub Title" />
                    <input
                      type="text"
                      name="sub_title"
                      className="modalInput"
                      style={{
                        marginBottom: "5px",
                        width: "100%",
                      }}
                      defaultValue={
                        this.state.product ? this.state.product.sub_title : ""
                      }
                      onChange={this.handleInput}
                      required
                    />
                    <TSEInputLabel label="Categories" />
                    {this.state.openEdit && (
                      <div
                        style={{
                          textAlign: "left",
                        }}
                      >
                        <MultiSelect
                          options={this.state.categories}
                          value={this.state.catIds}
                          onChange={(data) => {
                            this.setState({ catIds: data });
                          }}
                          className="InputSelect fullWidth"
                          labelledBy={"Categories"}
                        />
                      </div>
                    )}
                    <TSEInputLabel label="Select Language" />
                    <select
                      name="language"
                      id=""
                      className="modalInput"
                      style={{ width: "100%" }}
                      value={this.state.language}
                      onChange={this.handleInput}
                    >
                      <option value="English">English</option>
                      <option value="French">French</option>
                    </select>
                    <TSEInputLabel label="Upload Document" />
                    <Button
                      variant="outlined"
                      size="medium"
                      className="uploadbtn"
                      component="label"
                      style={{
                        border: "1px solid #bdbdbd",
                        width: "100%",
                        textTransform: "capitalize",
                        background: "#bdbdbd73",
                        marginBottom: "5px",
                      }}
                    >
                      Upload Image
                      <input
                        type="file"
                        onChange={this.onSelectFile}
                        name="filename"
                        id="uploadFile"
                        hidden
                      />
                    </Button>
                    <PlacementRadio
                      propState={this.state}
                      alignItems="flex-end"
                      onChange={(placement) => this.setState({ placement })}
                    />
                    {this.state.product.is_protected ? (
                      <PasscodeChange
                        passcodeMsg={this.state.passcodeMsg}
                        passcode={this.state?.passcode || ""}
                        setPasscode={(passcode) => this.setState({ passcode })}
                        prevPasscode={this.state?.prevPasscode || ""}
                        setPrevPasscode={(prevPasscode) =>
                          this.setState({ prevPasscode })
                        }
                        className="modalInput"
                        setDeletePasscode={() => {
                          this.setState((pre) => ({
                            ...pre,
                            deletePasscode: true,
                            changePasscode: false,
                          }));
                        }}
                        setChangePasscode={() => {
                          this.setState((pre) => ({
                            ...pre,
                            changePasscode: true,
                            deletePasscode: false,
                          }));
                        }}
                        setNonePasscode={() => {
                          this.setState((pre) => ({
                            ...pre,
                            deletePasscode: false,
                            changePasscode: false,
                          }));
                        }}
                      />
                    ) : (
                      <Passcode
                        isProtected={this.state.is_protected}
                        toggleIsProtected={() => {
                          this.setState((pre) => {
                            // console.log("is ", !!!pre?.is_protected);
                            return {
                              ...pre,
                              is_protected: !!!pre?.is_protected,
                            };
                          });
                        }}
                        passcode={this.state?.passcode || ""}
                        setPasscode={(passcode) => this.setState({ passcode })}
                        className="modalInput"
                      />
                    )}
                    <Button
                      className="addBtn"
                      type="submit"
                      style={{
                        width: "100%",
                        marginLeft: "0px",
                        // border: "1px solid red",
                        marginTop: 10,
                        marginBottom: 20,
                      }}
                      disabled={this.state.loading}
                    >
                      {this.state.loading ? (
                        <CircularProgress color={"white"} size={25} />
                      ) : (
                        "Update"
                      )}
                    </Button>
                  </div>
                </form>
              </div>{" "}
            </div>
          </TSEDialog>

          <Modal
            visible={false && this.state.openEdit}
            width="350"
            height="720"
            effect="fadeInUp"
            onClickAway={() => this.closeModal()}
          >
            <div
              className="modalWrapper"
              style={{ border: "0px solid red", paddingBottom: "1rem" }}
            >
              <h4>Edit product details</h4>
              <form
                action=""
                onSubmit={(e) => {
                  this.updateProduct(e);
                }}
              >
                {/* <Typography
                  style={{
                    margin: 0,
                  }}
                >
                  {this.state.filename ? "File updated" : null}
                </Typography> */}
                <div
                  className="contentWrap"
                  style={{
                    margin: "0 auto",
                  }}
                >
                  <TSEInputLabel label="Product Name" />
                  <input
                    type="text"
                    name="title"
                    className="modalInput"
                    style={{ marginBottom: "5px" }}
                    defaultValue={
                      this.state.product ? this.state.product.title : ""
                    }
                    onChange={this.handleInput}
                    required
                  />
                  <TSEInputLabel label="Product Sub Title" />
                  <input
                    type="text"
                    name="sub_title"
                    className="modalInput"
                    style={{ marginBottom: "5px" }}
                    defaultValue={
                      this.state.product ? this.state.product.sub_title : ""
                    }
                    onChange={this.handleInput}
                    required
                  />
                  <TSEInputLabel label="Categories" />
                  {this.state.openEdit && (
                    <div
                      style={{
                        textAlign: "left",
                      }}
                    >
                      <MultiSelect
                        options={this.state.categories}
                        value={this.state.catIds}
                        onChange={(data) => {
                          this.setState({ catIds: data });
                        }}
                        className="InputSelect fullWidth"
                        labelledBy={"Categories"}
                      />
                    </div>
                  )}
                  <TSEInputLabel label="Select Language" />
                  <select
                    name="language"
                    id=""
                    className="modalInput"
                    style={{ width: "100%" }}
                    value={this.state.language}
                    onChange={this.handleInput}
                  >
                    <option value="English">English</option>
                    <option value="French">French</option>
                  </select>
                  <TSEInputLabel label="Upload Document" />
                  <Button
                    variant="outlined"
                    size="medium"
                    className="uploadbtn"
                    component="label"
                    style={{
                      border: "1px solid #bdbdbd",
                      width: "100%",
                      textTransform: "capitalize",
                      background: "#bdbdbd73",
                      marginBottom: "5px",
                    }}
                  >
                    Upload Image
                    <input
                      type="file"
                      onChange={this.onSelectFile}
                      name="filename"
                      id="uploadFile"
                      hidden
                    />
                  </Button>
                  <PlacementRadio
                    propState={this.state}
                    alignItems="flex-end"
                    onChange={(placement) => this.setState({ placement })}
                  />
                  {this.state.product.is_protected ? (
                    <PasscodeChange
                      passcodeMsg={this.state.passcodeMsg}
                      passcode={this.state?.passcode || ""}
                      setPasscode={(passcode) => this.setState({ passcode })}
                      prevPasscode={this.state?.prevPasscode || ""}
                      setPrevPasscode={(prevPasscode) =>
                        this.setState({ prevPasscode })
                      }
                      className="modalInput"
                      setDeletePasscode={() => {
                        this.setState((pre) => ({
                          ...pre,
                          deletePasscode: true,
                          changePasscode: false,
                        }));
                      }}
                      setChangePasscode={() => {
                        this.setState((pre) => ({
                          ...pre,
                          changePasscode: true,
                          deletePasscode: false,
                        }));
                      }}
                      setNonePasscode={() => {
                        this.setState((pre) => ({
                          ...pre,
                          deletePasscode: false,
                          changePasscode: false,
                        }));
                      }}
                    />
                  ) : (
                    <Passcode
                      isProtected={this.state.is_protected}
                      toggleIsProtected={() => {
                        this.setState((pre) => {
                          // console.log("is ", !!!pre?.is_protected);
                          return {
                            ...pre,
                            is_protected: !!!pre?.is_protected,
                          };
                        });
                      }}
                      passcode={this.state?.passcode || ""}
                      setPasscode={(passcode) => this.setState({ passcode })}
                      className="modalInput"
                    />
                  )}
                  <Button
                    className="addBtn"
                    type="submit"
                    style={{
                      width: "100%",
                      marginLeft: "0px",
                      // border: "1px solid red",
                      marginTop: 10,
                    }}
                    disabled={this.state.loading}
                  >
                    {this.state.loading ? (
                      <CircularProgress color={"white"} size={25} />
                    ) : (
                      "Update"
                    )}
                  </Button>
                </div>
              </form>
            </div>
          </Modal>
          <Modal
            visible={this.state.compnentEdit}
            width="300"
            height="350"
            effect="fadeInUp"
            onClickAway={() => this.closeModal()}
          >
            <div className="modalWrapper" style={{ marginTop: "80px" }}>
              <form
                action=""
                id="componentForm"
                onSubmit={this.updateComponent}
              >
                <h1 className="modalHead" style={{ fontSize: "18px" }}>
                  Edit component details
                </h1>

                <CloseSharpIcon
                  style={{ position: "absolute", right: "10px", top: "5px" }}
                  onClick={() => this.closeModal()}
                />

                <div className="inputTextWrap">
                  <span className="modalText">Name</span>
                  <input
                    type="text"
                    className="modalInput"
                    name="compname"
                    defaultValue={this.state.component_name}
                    required
                    onChange={this.handleInput}
                  />
                </div>
                <br />
                <Button className="addBtn" type="submit">
                  {this.state.loading ? (
                    <CircularProgress color={"white"} size={25} />
                  ) : (
                    "Update Component"
                  )}
                </Button>
              </form>
            </div>
          </Modal>
          {this.state.materialEdit && (
            <MaterialEditModel
              open={this.state.materialEdit}
              closeModal={() => this.closeModal()}
              data={this.state.materialEditObject}
              updateMaterial={this.updateMaterial}
            />
          )}

          <Modal
            visible={this.state.deleteConfirmation}
            width="400"
            height="300"
            effect="fadeInUp"
            onClickAway={() =>
              this.setState({
                deleteConfirmation: false,
                deleteMatIdCompId: "",
              })
            }
          >
            <div className="confirmationModal" style={{ textAlign: "center" }}>
              <Typography>
                {!this.canIgnoreDeleteConfirmation(this.state.deleteMatIdCompId)
                  ? "All variants belongs to this material will be deleted.Are you sure you want to delete this material?"
                  : " Are you sure you want to delete this material ?"}
              </Typography>
              <Button
                onClick={() => {
                  this.deleteMaterial(
                    this.state.deleteMatId,
                    this.state.isAvailable
                  );
                }}
                className="DeleteBtn"
              >
                Delete
              </Button>
              <Button
                onClick={() => this.setState({ deleteConfirmation: false })}
                className="DeleteBtn"
                style={{ background: "#bdbdbd" }}
              >
                Cancel
              </Button>
            </div>
          </Modal>
          <Modal
            visible={this.state.confirmationComponentDelete}
            width="400"
            height="300"
            effect="fadeInUp"
            onClickAway={() =>
              this.setState({ confirmationComponentDelete: false })
            }
          >
            <div className="confirmationModal" style={{ textAlign: "center" }}>
              {this.state.Variants.length > 0 &&
                this.state.isThisHaveMultipleMaterials ? (
                <Typography>
                  All Variants of this product will be deleted. Are you sure you
                  want to delete this component ?
                </Typography>
              ) : (
                <Typography>
                  Are you sure you want to delete this component ?
                </Typography>
              )}

              <Button
                onClick={() => {
                  this.deleteComponent(this.state.deleteCompId);
                }}
                className="DeleteBtn"
              >
                Delete
              </Button>
              <Button
                onClick={() =>
                  this.setState({ confirmationComponentDelete: false })
                }
                className="DeleteBtn"
                style={{ background: "#bdbdbd" }}
              >
                Cancel
              </Button>
              <Typography style={{ color: "green" }}>
                {this.state.notification ? this.setState.successMsg : ""}
              </Typography>
            </div>
          </Modal>
          <Modal
            visible={this.state.confirmationComponentAdd}
            width="400"
            height="300"
            effect="fadeInUp"
            onClickAway={() =>
              this.setState({ confirmationComponentAdd: false })
            }
          >
            <div className="confirmationModal" style={{ textAlign: "center" }}>
              <Typography>
                All Variants of this product will be deleted. Are you sure you
                want to delete Variants ?
              </Typography>

              <Button
                onClick={() => {
                  this.variantDelete();
                  // this.setState({ confirmationComponentAdd: false });
                }}
                className="DeleteBtn"
              >
                Delete
              </Button>
              <Button
                onClick={() =>
                  this.setState({ confirmationComponentAdd: false })
                }
                className="DeleteBtn"
                style={{ background: "#bdbdbd" }}
              >
                Cancel
              </Button>
              <Typography style={{ color: "green" }}>
                {this.state.notification ? this.state.successMsg : ""}
              </Typography>
            </div>
          </Modal>
          <Modal
            visible={this.state.variantUpdate}
            width="400"
            height="300"
            effect="fadeInUp"
            onClickAway={() => this.setState({ variantUpdate: false })}
          >
            <div
              className="inputWrapper"
              style={{
                padding: "20px",
                textAlign: "center",
                marginTop: "30px",
              }}
            >
              <form action="">
                <Typography>Upload model file</Typography>
                <Button
                  variant="outlined"
                  size="medium"
                  className="uploadbtnswatch"
                  component="label"
                  style={{
                    margin: "11px auto",
                    textTransform: "capitalize",
                    width: "33%",
                  }}
                >
                  Upload
                  <input
                    type="file"
                    onChange={this.handlevariantModal}
                    name="model_file"
                    id="uploadVariant"
                    hidden
                    className="imageUpload"
                  />
                </Button>
                <p> {this.state.model_file ? "File Uploaded" : ""}</p>
                <br />
                <Button
                  onClick={() => this.variant_update(this.state.variantId)}
                  className="DeleteBtn"
                  style={{
                    background: "#bdbdbd",
                    textTransform: "capitalize",
                    color: "#fff",
                  }}
                >
                  {this.state.loading ? (
                    <CircularProgress color={"white"} size={25} />
                  ) : (
                    "Update Variant"
                  )}
                </Button>
                <p style={{ color: "green" }}>
                  {this.state.notification ? this.state.successMsg : ""}
                </p>
              </form>
            </div>
          </Modal>
          <Modal
            visible={this.state.openBuyNow}
            width="400"
            height="450"
            effect="fadeInUp"
            onClickAway={() => this.setState({ openBuyNow: false })}
          >
            <div
              className="inputWrapper"
              style={{
                padding: "20px",
                textAlign: "center",
                marginTop: "30px",
              }}
            >
              <form
                action=""
                onSubmit={(e) => {
                  if (this.state.invalidUrl) return;

                  this.updateProduct(e);
                }}
              >
                <div className="modalWrapper">
                  <h4 style={{ marginTop: "-10px" }}>Edit product details</h4>

                  <div className="contentWrap">
                    <p>Link URL</p>
                    <input
                      type="text"
                      name="link_url"
                      className="modalInput"
                      style={{ marginBottom: "10px" }}
                      defaultValue={
                        this.state.product ? this.state.product.link_url : ""
                      }
                      onChange={(e) => {
                        const isValidUrl = validateUrl(e.target.value);
                        // console.log("isValidUrl ", isValidUrl);
                        if (!isValidUrl) this.setState({ invalidUrl: true });
                        else if (this.state.invalidUrl)
                          this.setState({ invalidUrl: false });
                        this.handleInput(e);
                      }}
                      required
                    />
                    {this.state.invalidUrl && (
                      <p
                        style={{
                          color: "rgba(255,0,0,0.8)",
                          fontStyle: "italic",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        Link is invalid
                      </p>
                    )}
                    <p>Link Title</p>
                    <input
                      type="text"
                      name="link_title"
                      className="modalInput"
                      style={{ marginBottom: "10px" }}
                      defaultValue={
                        this.state.product ? this.state.product.link_title : ""
                      }
                      onChange={this.handleInput}
                      required
                    />{" "}
                    <p>Link Description</p>
                    <input
                      type="text"
                      name="link_description"
                      className="modalInput"
                      style={{ marginBottom: "10px" }}
                      defaultValue={
                        this.state.product
                          ? this.state.product.link_description
                          : ""
                      }
                      onChange={this.handleInput}
                      required
                    />
                    <br />
                    <Button
                      className="addBtn"
                      type="submit"
                      disabled={this.state.invalidUrl}
                      style={{ width: "100%", marginLeft: "0px" }}
                    >
                      {this.state.loading ? (
                        <CircularProgress color={"white"} size={25} />
                      ) : (
                        "Update"
                      )}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </Modal>
          {/* model for corp product image */}
          <Modal
            visible={this.state.visibleCropProduct}
            // width="700"
            width="1100"
            height="650"
            effect="fadeInUp"
            onClickAway={() => this.setState({ visibleCropProduct: false })}
          >
            <Grid
              container
              spacing={2}
              className="PreeditWrapper"
              style={{ marginTop: "25px" }}
            >
              <Grid
                item
                sm={12}
                style={{
                  padding: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {src && (
                  <ReactCrop
                    src={src}
                    crop={crop}
                    ruleOfThirds
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                  />
                )}
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              className="PreeditWrapper"
              style={{ marginTop: "25px" }}
            >
              <Grid
                item
                sm={12}
                style={{
                  padding: "20px",
                  textAlign: "center",
                  marginRight: "170px",
                }}
              >
                <span>
                  <button
                    className="saveCroppedImg"
                    onClick={() => {
                      this.setState(
                        {
                          visibleCropProduct: false,
                          imgPreview: true,
                        },
                        () => { }
                      );
                    }}
                  >
                    Save
                  </button>
                </span>
                <span>
                  <button
                    className="cancelCroppedImg"
                    onClick={() => {
                      this.setState(
                        {
                          visibleCropProduct: false,
                          imgPreview: false,
                        },
                        () => { }
                      );
                    }}
                  >
                    Cancel
                  </button>
                </span>
              </Grid>
            </Grid>
          </Modal>

          <Modal
            visible={this.state.suggestionModelModal}
            // width="700"
            width="1200"
            height="750"
            effect="fadeInUp"
            onClickAway={() => this.setState({ suggestionModelModal: false })}
          >
            <h3 style={{ textAlign: 'center', padding: '12px 0', borderBottom: '1px solid #ddd', marginTop: 0 }}>You can choose any of the given suggested models for your product.</h3>
            <Grid
              container
              direction="row"
              justifyContent="center"
              className="PreeditWrapper"
              spacing={3}
              style={{ overflow: 'scroll', height: 600, padding: '0 12px' }}
            >
              <Grid item sm={3}>
                <div
                  style={{
                    border: "1px solid white",
                    borderRadius: 14,
                    boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.25)`,
                    paddingBottom: spacing,
                    position: 'sticky',
                    top: 12
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      padding: spacing * 2,
                      fontSize: 20,
                      fontWeight: 500,
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    Categories
                  </div>
                  {this.state.categories
                    .map((category) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          // padding: spacing,
                          paddingRight: spacing * 2,
                          paddingLeft: spacing * 2,
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 18,
                            fontWeight: 400,
                            color: "#565656",
                          }}
                        >
                          {category.label}
                        </div>

                        <Checkbox
                          id="category"
                          color="primary"
                          onChange={() => {
                            this.handleCategoryCheck(category.value);
                          }}
                          checked={this.state.selectedCategories.includes(category.value)}
                        />
                      </div>
                    ))}
                </div>
              </Grid>
              <Grid item container sm={9} spacing={3}>
                {this.state.suggestions.map((model) => (
                  <Grid
                    style={{ cursor: "pointer", borderRadius: 3 }}
                    onClick={() => this.selectSuggestion(model)}
                    item
                    sm={3}
                  >
                    <img
                      src={API.imgUrl + model.poster_image_url}
                      style={{
                        width: "100%", boxShadow: this.state.suggestionSelected._id === model._id ? "none" : "rgba(0, 0, 0, 0.25) 0px 4px 4px",
                        borderRadius: "15px",
                        border: this.state.suggestionSelected._id === model._id ? "2px solid #A5C0D7" : "2px solid transparent",
                      }}
                    />
                    <h5 style={{ margin: '4px 0 ', fontWeight: 'normal', fontSize: 16, color: this.state.suggestionSelected._id === model._id ? "#A5C0D7" : "#828282", textAlign: 'center' }}>{model.title}</h5>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              className="PreeditWrapper"
              style={{ marginTop: "25px" }}
            >
              <Grid
                item
                sm={12}
                style={{
                  padding: "20px",
                  textAlign: "center",
                  marginRight: "170px",
                }}
              >
                <span>
                  <button
                    className="saveCroppedImg"
                    onClick={this.addVariantWithSuggestedModels}
                  >
                    Save
                  </button>
                </span>
                <span>
                  <button
                    className="cancelCroppedImg"
                    onClick={() => {
                      this.setState(
                        {
                          suggestionModelModal: false,
                          imgPreview: false,
                        },
                        () => {
                          this.setState({ selectedCategories: [] })
                        }
                      );
                    }}
                  >
                    Cancel
                  </button>
                </span>
              </Grid>
            </Grid>
          </Modal>

          <Modal
            visible={this.state.showTemplateModal}
            // width="700"
            width="548"
            height="550"
            effect="fadeInUp"
            onClickAway={() => this.setState({ showTemplateModal: false })}
          >
            <h3 style={{ padding: '24px 26px', fontSize: 24, marginTop: '18px', fontWeight: 'normal' }}>Select your Template</h3>
            <Grid
              container
              direction="row"
              justifyContent="center"
              className="PreeditWrapper"
              spacing={3}
              style={{ padding: '0 12px' }}
            >
              <Grid item container sm={12} spacing={3}>
                {templates.map((template) => (
                  <Grid
                    style={{ cursor: "pointer", borderRadius: 3, position: 'relative' }}
                    item
                    sm={4}
                  >
                    <Link to={"/modeltemplate?product=" + this.state.productId + "&" + this.state.templateStringParams + "&type=" + template.type} onClick={(e) => {
                      if (template.disabled) {
                        e.preventDefault();
                      }
                    }} style={{ textDecoration: 'none' }}>

                      <div style={{ position: "relative" }}>  <img
                        src={template.image}
                        style={{
                          width: "100%",
                          borderRadius: "15px",
                        }}
                      />
                        {
                          template.disabled && <h3 style={{ position: 'absolute', top: "50%", left: '50%', transform: "translate(-50%, -50%)", color: "#fff", margin: 0, textAlign: 'center' }}>Coming Soon</h3>

                        }
                      </div>
                      <h5 style={{ margin: '4px 0 ', fontWeight: 'normal', fontSize: 16, textAlign: 'center' }}>{template.title}</h5>
                    </Link>
                  </Grid>
                ))}
              </Grid>
              <Grid
                sm={12}
                style={{
                  padding: "20px",
                  textAlign: "center",
                }}
              >
                <button
                  className="cancelCroppedImg"
                  style={{
                    position: "absolute",
                    bottom: "18px", width: "230.99px", borderRadius: '5px'
                  }}
                  onClick={() => {
                    this.setState(
                      {
                        showTemplateModal: false,
                        imgPreview: false,
                      },
                      () => {
                        this.setState({ selectedCategories: [] })
                      }
                    );
                  }}
                >
                  Cancel
                </button>
              </Grid>
            </Grid>

          </Modal>
        </Container >
      );
    }
  }
}
const TSEInputLabel = ({ label }) => (
  <p
    style={{
      padding: 0,
      marginTop: 15,
      marginBottom: 10,
    }}
  >
    {label}
  </p>
);
