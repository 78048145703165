import { Button, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import TSEDialog from '../TSEDialog';
import custom_icon from '../../Assets/newIcons/custom_icon.png';
import template_icon from '../../Assets/newIcons/template_icon.png';
import { Link } from 'react-router-dom';
import { useMediaHook } from '../useMediaHook';
import { scrollToTop } from '../../Helpers/utils';

const AddNewProduct = ({ style }) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const isMdOrDown = useMediaHook();
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        className='productBtn'
        style={{
          width: '100%',
          fontSize: window.innerWidth < 1100 ? '14px' : '18px',
          marginTop: '35px',
          ...style,
        }}
      >
        Add New Product +
      </Button>
      <TSEDialog open={true && open} handleClose={handleClose}>
        <div
          style={{
            border: '0px solid red',
            padding: 15,
            textAlign: 'left',
            paddingRight: 25,
            paddingLeft: 25,
          }}
        >
          <div
            style={{
              fontSize: 24,
              fontWeight: 5500,
              color: '#2e2e2e',
              padding: 15,
              // paddingTop: 0,
              // paddingLeft: 0,
              textAlign: 'left',
              marginTop: 44,
              paddingTop: 0,
              marginBottom: 12
              // border: "1px solid green",
              // marginBottom: 10,
            }}
          >
            Select any of them
          </div>
          {/* <Grid container> */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: 0,
              paddingTop: 0,
              paddingBottom: 30,
              flexWrap: 'wrap',
              justifyContent: 'center',
              // borderBottom: "1px solid #ddd",
            }}
          >
            <Box
              img={custom_icon}
              href='/product-create'
              text1='Custom'
              text2={`Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ea, odio porro. Nobis adipisci quas ratione necessitatibus dolore et culpa provident`}
            />
            {isMdOrDown ? null : (
              <div
                style={{
                  width: '30px',
                }}
              />
            )}
            <Box
              img={template_icon}
              href='/templates'
              text1='Template'
              text2={`Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ea, odio porro. Nobis adipisci quas ratione necessitatibus dolore et culpa provident`}
            />
          </div>
          {/* </Grid> */}
        </div>
      </TSEDialog>
    </>
  );
};

const Box = ({ img, text1, text2, href }) => {
  const isMdOrDown = useMediaHook();
  return (
    <Link
      to={href}
      style={{
        textDecoration: 'none',
      }}
      onClick={() => {
        scrollToTop();
        document.body.style.overflow = 'auto';
      }}
    >
      <div
        style={{
          cursor: 'pointer',
          minWidth: '350px',
          width: isMdOrDown ? '100%' : '350px',

          borderRadius: 8,
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
          padding: 20,
          paddingBottom: 20,
          display: 'flex',
          alignItems: 'center',
          marginBottom: isMdOrDown ? 10 : 0,
          // border: '1px solid blue',
        }}
      >
        {/* <img src={img} width='60' height='60' /> */}
        {isMdOrDown ? null : (
          <img src={img} width='60' height='60' style={{}} />
        )}
        <div
          style={{
            paddingLeft: isMdOrDown ? 0 : 20,
          }}
        >
          <div
            style={{
              fontSize: 20,
              fontWeight: 400,
              color: '#000',
              marginBottom: 5,
            }}
          >
            {text1}
          </div>
          <div style={{ color: '#646464' }}>{text2}</div>
        </div>
      </div>
    </Link>
  );
};
export default AddNewProduct;
