import { Typography } from '@material-ui/core';
import React, { Component } from 'react';
import Lottie from 'react-lottie';
import notfound from '../Assets/lottie/36395-lonely-404.json';
class NotFoundPage extends Component {
  state = {
    isStopped: true,
  };
  render() {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: notfound,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
    return (
      <div
        style={{
          textAlign: 'center',
          marginTop: '120px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Lottie
          options={defaultOptions}
          height={300}
          width={600}
          isStopped={this.state.isStopped}
        />
        <Typography variant={'h3'} style={{ textAlign: 'center' }}>
          Page not found
        </Typography>
      </div>
    );
  }
}

export default NotFoundPage;
