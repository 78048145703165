import React, { forwardRef, useEffect, useState } from 'react';
import useBodyScrollLock from '../../Helpers/useBodyScrollLock';
import { IconButton } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
const TSEDialog = forwardRef(
  (
    {
      open,
      children,
      maxWidth = '85vw',
      minWidth = 'auto',
      maxHeight = '85vh',
      minHeight = 'auto',
      onClickOverlay,
      handleClose,
    },
    shareButtonRef
  ) => {
    const { lockScroll, unLockScroll } = useBodyScrollLock();

    useEffect(() => {
      if (open) {
        lockScroll();
      } else {
        unLockScroll();
      }
    }, [open]);
    return (
      <div
        className='__TSE_DIALOG'
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'rgba(0,0,0,0.7)',
          display: 'grid',
          placeItems: 'center',
          zIndex: 99999,
          transform: `scale(${open ? 1 : 0})`,
          transition: 'all 300ms',
          boxSizing: 'border-box',
          cursor: 'pointer',
          transition: 'all 300ms',
        }}
        onClick={() => {
          unLockScroll();
          onClickOverlay && onClickOverlay?.();
        }}
      >
        <div
          style={{
            cursor: 'auto',
            maxWidth: maxWidth,
            minWidth: minWidth,
            maxHeight: maxHeight,
            minHeight: minHeight,
            background: 'white',
            zIndex: 99999,
            overflow: 'hidden',
            borderRadius: 14,
            boxShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
            transition: 'all 300ms',
            position: 'relative'
          }}
        >
          <div
            onClick={(e) => {
              // console.clear();
              e.stopPropagation();
            }}
            style={{
              cursor: 'auto',
              maxWidth: maxWidth,
              minWidth: minWidth,
              maxHeight: maxHeight,
              minHeight: minHeight,
              background: 'white',
              // zIndex: 99999,
              overflowX: 'hidden',
              overflowY: 'auto',
              borderRadius: 14,
              boxShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
              transition: 'all 300ms',
            }}
          >
            {!handleClose ? null : (
              <div
                style={{
                  position: 'absolute',
                  top: 10,
                  right: 10,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  // padding: 15,
                }}
              >
                <IconButton
                  size='31px'
                  onClick={() => {
                    unLockScroll();
                    handleClose?.();
                  }}
                >
                  <HighlightOffIcon style={{ height: '45px', width: '45px', color: '#2e2e2e' }} />
                </IconButton>
              </div>
            )}
            {children}
          </div>
        </div>
      </div>
    );
  }
);

export default TSEDialog;
