import { Button, IconButton, Tooltip, Typography } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import axios from 'axios';
import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import { API } from '../Constants/APIs';
import init from '../Helpers/Windotoken';
import { Link } from 'react-router-dom';
import analyticsImg from '../../src/Assets/Images/data-analytics 1.png';
import eyes from '../../src/Assets/Images/eyes.png';
// import square from "../../src/Assets/Images/eyes.png";
import square from '../../src/Assets/Images/square.png';
import CloneButton from './CloneButton';
import { canPublishProduct } from '../Helpers/utils';

export default class ProductCard extends Component {
  state = {
    product0: [],
    some: '',
    Variants: [],
    productId: 0,
    query: '',
  };
  componentDidMount() {
    this.getVariants();
  }
  getVariants = () => {
    {
    }
  };

  postDuplicateProduct = () => {
    this.props.onDelete(this.props.catId, this.props.CardIndex);
    this.props.getMetaData();
  };
  deleteProduct = (pId) => {
    // this.props.onDelete(this.props.catId);
    // return;
    // if (init() === "success") {
    axios.delete(API.postUrls.addproduct + '/' + pId).then((res) => {
      if (res.data.status === 'success') {
        this.setState({
          notification: true,
          successMsg: res.data.message,
        });
        this.props.getMetaData();
        setTimeout(() => {
          this.setState({
            notification: false,
            openModal: false,
          });
          this.props.onDelete(this.props.catId, this.props.CardIndex);
        }, 3000);
      } else {
        this.setState({
          notification: true,
          successMsg: res.data.message,
        });
        setTimeout(() => {
          this.setState({
            notification: false,
            openModal: false,
          });
        }, 2000);
      }
    });
    // }
  };

  fetchModels = () => {
    if (this.props.catId) {
      // if (init() === "success") {
      axios
        .get(
          API.getUrls.getProducts +
            '?page=' +
            this.props.page +
            '&limit=' +
            this.props.limit +
            '&catId=' +
            this.props.catId +
            '&q=' +
            this.props.query +
            '&status=' +
            this.props.status
        )
        .then((res) => {
          this.setState({
            loadingProducts: false,

            ['products' + this.props.CardIndex]: res.data.docs,
            totalpage: res.data.totalPages,
            hasNextPage: res.data.hasNextPage === false ? false : true,
          });
        });
      // }
    }
  };
  previewProduct = () => {
    if (this.state.ModalImage === null) {
      alert('Please add components and upload .glb file');
    } else {
      let token = localStorage.getItem('token');
      var url_string = window.location.href; //window.location.href
      var url = new URL(url_string);
      var payId = url.searchParams.get('id');
      if (this.state.productStatus === 'draft') {
        window.open(
          'https://portal.actuality.live/modelDynamicviewer/' +
            this.state.productId,
          '_blank'
        );
      } else {
        window.open(
          'https://actuality.live/modelDynamicviewer/' + this.state.productId,
          '_blank'
        );
      }
    }
  };
  openeditLink = async (productId) => {
    console.clear();
    const canPublish = await canPublishProduct(this.props.id);

    window.open(
      canPublish
        ? `/viewer?id=${this.props.id}`
        : `/product-edit?id=${this.props.id}`,
      '_blank'
    );

    return;
    if (this.props.moveToScreenUrl) {
      window.open(this.props.moveToScreenUrl, '_blank');
      return;
    }
    let URL = '';
    if (this.state.Variants.length > 0) URL = `/viewer?id=`;
    else URL = `/product-edit?id=`;

    URL += `${productId ? productId : this.props.id}`;
    window.open(URL, '_blank');
  };

  openPreview = async (productId) => {
    // console.log("first ", productId ? productId : this.props.id);

    const notPublished = this.props.publishStatus == 'draft';

    let URL = `${
      notPublished ? '' : 'https://actuality.live'
    }/modelDynamicviewer/${productId ? productId : this.props.id}`;

    window.open(URL, '_blank');
  };

  openAnalytics = (productId) => {
    // console.log("first ", productId ? productId : this.props.id);
    let URL = `/productanalytics/${productId ? productId : this.props.id}`;
    window.open(URL, '_blank');
  };

  render() {
    const props = this.props;

    return (
      <div
        className='productCard'
        style={{
          position: 'relative',
          // padding: 25,
          maxWidth: 192,
          margin: 'auto',
          marginBottom: 20,
          overflow: 'hidden',
          // border: "1px solid blue",

          height: 'auto',
          // padding: 5,
        }}
      >
        <div className='imgBackground' onClick={() => this.openeditLink()}>
          <div
            className='imgWrapper'
            style={{
              width: 192,
              height: 192,
              position: 'relative',
              overflow: 'hidden',

              // border: "1px solid red",
            }}
          >
            <CloneButton
              productId={this.props.id}
              openeditLink={this.openeditLink}
              postDuplicateProduct={this.postDuplicateProduct}
              previewProduct={this.previewProduct}
              openAnalytics={this.openAnalytics}
              openPreview={this.openPreview}
              getMeta={this.props.getMetaData}
              deleteProduct={() => {
                this.setState({
                  openModal: true,
                  productId: props.id,
                });
                // this.deleteProduct()
              }}
              isPublished={props.publishStatus === 'published'}
            />
            <img
              src={this.props.isSeatcategory && false ? square : props.imgSrc}
              alt={props.title}
              style={{
                width: '100%',
                height: '100%',
                zIndex: 10,
                border: '0px solid red',
              }}
            />
          </div>
        </div>

        <div
          style={{
            padding: 0,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: 0,
            marginTop: 10,
          }}
        >
          {/* Product Title Goes Here */}
          <p
            style={{
              fontSize: 18,
              fontWeight: 600,
              textAlign: 'left',
              margin: 0,
              padding: 0,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            title={props.title}
          >
            {props.title}
          </p>

          <DeleteOutlineIcon
            className='deleteIcon'
            onClick={() => {
              this.setState({
                openModal: true,
                productId: props.id,
              });
            }}
          />
        </div>
        <p
          style={{
            fontSize: 14,
            lineHeight: 'normal',
            fontWeight: 500,

            padding: 0,
            textAlign: 'left',
            color: '#999999',
            // border: "1px solid blue",
            // margin: "4px 0",
            margin: 0,
            marginTop: 6,
          }}
        >
          Views :
          {props.views ? (props.views > 1 ? props.views - 1 : props.views) : 0}
          {/* {props.views ? props.views : 0} */}
        </p>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: 0,
            position: 'static',
            top: 'auto',
            left: 'auto',
            // border: "1px solid aqua",
            // marginTop: 7,
          }}
        >
          <p
            style={{
              fontSize: 14,
              fontWeight: 500,
              margin: 0,
              padding: 0,
              textAlign: 'left',
              color: '#999999',
              flex: 1,
            }}
          >
            {props.publishStatus === 'published'
              ? 'Published'
              : 'Not Published'}
          </p>
          {props?.publishStatus != 'published' ? null : (
            <Tooltip title='Preview'>
              <div
                style={{
                  marginLeft: 8,
                  padding: 5,
                  background: '#e3e3e3',
                  display: 'grid',
                  placeItems: 'center',
                  borderRadius: '4px',
                  // border: "1px solid red",
                }}
                onClick={() => this.openPreview()}
              >
                <img
                  src={eyes}
                  alt='img'
                  style={{
                    width: 15,
                    height: 15,
                    position: 'static',
                    top: 'auto',
                    left: 'auto',

                    // border: "1px solid red",
                    transform: 'none',
                  }}
                />
              </div>
            </Tooltip>
          )}
          <Tooltip title='Analytics'>
            <div
              style={{
                marginLeft: 8,
                padding: 5,
                background: '#e3e3e3',
                display: 'grid',
                placeItems: 'center',
                borderRadius: '4px',
                // border: "1px solid blue",
              }}
              onClick={() => this.openAnalytics()}
            >
              <img
                src={analyticsImg}
                alt='img'
                style={{
                  width: 15,
                  height: 15,
                  position: 'static',
                  top: 'auto',
                  left: 'auto',
                  // border: "1px solid red",
                  transform: 'none',
                }}
              />
            </div>
          </Tooltip>
        </div>
        <Modal
          visible={this.state.openModal}
          width='400'
          height='300'
          effect='fadeInUp'
          onClickAway={() => this.setState({ openModal: false })}
        >
          <div className='confirmationModal' style={{ textAlign: 'center' }}>
            <Typography>
              Are you sure you want to delete this product ?
            </Typography>
            <Button
              onClick={() => {
                this.deleteProduct(this.state.productId);
              }}
              className='DeleteBtn'
            >
              Delete{' '}
            </Button>
            <Button
              onClick={() => this.setState({ openModal: false })}
              className='DeleteBtn'
              style={{ background: '#bdbdbd' }}
            >
              Cancel
            </Button>
            <p style={{ color: 'green', textTransform: 'capitalize' }}>
              {this.state.notification ? this.state.successMsg : ''}{' '}
            </p>
          </div>
        </Modal>
      </div>
    );
  }
}
