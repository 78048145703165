import { Box, Checkbox, Radio, Typography } from '@material-ui/core';
import React, { useState } from 'react';

import PasscodeField from '../PasscodeField';
import LockIcon from '@material-ui/icons/Lock';
const PasscodeChange = ({
  passcode,
  setPasscode,
  className,
  setPrevPasscode,
  prevPasscode,
  setDeletePasscode,
  setChangePasscode,
  setNonePasscode,
  passcodeMsg,
}) => {
  const [type, setType] = useState('none');
  const handleChange = (val) => {
    if (val == 'delete') {
      setDeletePasscode();
      setType('delete');
    } else if (val == 'change') {
      setChangePasscode();
      setType('change');
    } else {
      setNonePasscode();
      setType('none');
    }
  };
  return (
    <div
      style={{
        paddingRight: 10,
      }}
    >
      <div
        style={{
          border: '0px solid red',
          // padding: 4,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          className='inlineWrapper'
          style={{
            border: '0px solid aqua',
            // padding: 0,
            // margin: 0,
          }}
        >
          <Typography
            className='buyNow'
            style={{
              padding: 0,
              margin: 0,
              fontWeight: 450,
              fontSize: '0.9rem',
              display: 'flex',
              alignItems: 'center',
              marginBottom: 5,
            }}
          >
            <LockIcon color='primary' fontSize='small' />
            <span
              style={{
                marginLeft: 5,
              }}
            >
              Product already protected
            </span>
          </Typography>
        </div>
      </div>
      <div
        className='inlineWrapper'
        style={{
          border: '0px solid aqua',
          // padding: 0,
          // margin: 0,
        }}
      >
        <Typography className='buyNow' style={{ padding: 0, margin: 0 }}>
          Passcode
        </Typography>
      </div>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {' '}
        <TSERadio
          label='Delete'
          checked={type == 'delete'}
          onChange={() => handleChange('delete')}
        />
        <TSERadio
          label='Change'
          checked={type == 'change'}
          onChange={() => handleChange('change')}
        />
        <TSERadio
          label='None'
          checked={type == 'none'}
          onChange={() => handleChange('none')}
        />
      </Box>
      {/* <div
        className="inlineWrapper"
        style={{
          border: "0px solid aqua",
          // padding: 0,
          // margin: 0,
        }}
      >
        <Typography className="buyNow" style={{ padding: 0, margin: 0 }}>
          Enter your previous passcode
        </Typography>
      </div> */}
      {type == 'none' ? null : (
        <>
          {type == 'change' && (
            <PasscodeField
              className={className}
              placeholder='Enter Previous Passcode'
              onChange={(e) => setPrevPasscode(e.target.value)}
              value={prevPasscode}
            />
          )}
          <PasscodeField
            className={className}
            placeholder={`Enter ${type == 'delete' ? '' : 'New'} Passcode`}
            onChange={(e) => setPasscode(e.target.value)}
            value={passcode}
          />
        </>
      )}
      {passcodeMsg && (
        <Typography
          style={{
            marginTop: 5,
          }}
          color='error'
        >
          {passcodeMsg}
        </Typography>
      )}
      {/* 
      <div
        className="inlineWrapper"
        style={{
          border: "0px solid aqua",
          // padding: 0,
          // margin: 0,
        }}
      >
        <Typography className="buyNow" style={{ padding: 0, margin: 0 }}>
          Enter your new passcode
        </Typography>
      </div>
      <PasscodeField
        className={className}
        onChange={(e) => setPasscode(e.target.value)}
        value={passcode}
      /> */}
    </div>
  );
};

export default PasscodeChange;

const TSERadio = ({ label, checked, onChange }) => (
  <Box
    style={{
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    }}
  >
    <Radio
      checked={checked}
      onChange={onChange}
      value='a'
      name='radio-button-demo'
      inputProps={{ 'aria-label': 'A' }}
      id={label}
      size='small'
    />
    <label
      htmlFor={label}
      style={{
        cursor: 'pointer',
      }}
    >
      {label}
    </label>
  </Box>
);
