import { Container, Typography, Grid, Button } from '@material-ui/core';
import axios from 'axios';
import React, { Component } from 'react';
import renderHTML from 'react-render-html';
import { API } from '../Constants/APIs';
import image31 from '../Assets/Images/image_31.png';
import modelImg1 from '../Assets/Images/modelImg1.png';
import modelImg2 from '../Assets/Images/modelImg2.png';
import modelImg3 from '../Assets/Images/modelImg3.png';
import right from '../Assets/Images/Right.png';
import wrong from '../Assets/Images/Wrong.png';
import ModelCard from '../Components/ModelCard';
import image32 from '../Assets/Images/image_32.png';
import { Link } from 'react-router-dom';
import PricingCard from '../Components/PricingCard';
import { RemoveRedEyeSharp } from '@material-ui/icons';
import 'react-toggle/style.css'; // for ES6 modules
import Switch from 'react-switch';
import Toggle from 'react-toggle';
class Plans_and_pricing extends Component {
  state = {
    aboutUs: [],
    description: '',
    blogs: [],
    models: [
      {
        title: 'Retro Television from Herman Miller',
        img_src: modelImg1,
      },
      {
        title: 'Kitchen Furniture by ',
        img_src: modelImg2,
      },
      {
        title: 'Gold Lamp from Herman Miller',
        img_src: modelImg3,
      },

      {
        title: 'Kitchen Furniture by ',
        img_src: modelImg2,
      },
      {
        title: 'Retro Television from Herman Miller',
        img_src: modelImg1,
      },
      {
        title: 'Gold Lamp from Herman Miller',
        img_src: modelImg3,
      },
      {
        title: 'Gold Lamp from Herman Miller',
        img_src: modelImg3,
      },

      {
        title: 'Kitchen Furniture by ',
        img_src: modelImg2,
      },
      {
        title: 'Retro Television from Herman Miller',
        img_src: modelImg1,
      },
    ],
    checked: false,
  };

  handleChange(checked) {
    this.setState({ checked: true });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener('resize', () => {});
    this.getAbout();
    this.fetchModels();
    axios
      .get(API.getUrls.blogs + '?page=1&limit=10&status=active')
      .then((res) => {
        this.setState({
          blogs: res.data.docs,
        });
      });
  }

  fetchModels = () => {
    axios.get('/SingleModel.json').then((res) => {
      this.setState({ models: res.data.models });
    });
  };

  gotoblog_detail = (id) => {
    window.location.href = '/blog-detail' + '?id=' + id;
  };

  getAbout = () => {
    axios.get(API.getUrls.getAboutUs).then((res) => {
      this.setState({
        aboutUs: res.data.docs,
      });
    });
  };
  render() {
    return (
      <>
        {/* <Container className="containerSpacingdashBoard" maxWidth={"lg"}>
          {this.state.aboutUs.map((about) => (
            <Typography> {renderHTML(about.description)} </Typography>
          ))}

        </Container> */}
        <Container>
          <section>
            <div>
              <div>
                <h1 className='plans'>Plans And Pricing</h1>
                <p className='subTitlePlans'>
                  30-day free trial, cancel any time.{' '}
                </p>
                <span
                  className={
                    window.innerWidth < 1100 ? 'toggleArea1100' : 'toggleArea'
                  }
                >
                  <span className='annualPlan'>Annual Plans</span>
                  <span style={{ position: 'relative', top: '6px' }}>
                    <Toggle
                      defaultChecked={this.state.tofuIsReady}
                      icons={false}
                      onChange={this.handleTofuChange}
                    />
                  </span>
                  {/* <Switch
                        color="primary"
                        name="checkedB"
                        inputProps={{ "aria-label": "primary checkbox" }}
                        onChange={this.handleChange} checked={this.state.checked}
                    /> */}
                  <span className='monthlyPlan'>Monthly Plans</span>
                </span>
              </div>
            </div>
          </section>

          <section
            className='sectionInnerSpacingAbout2'
            style={{
              marginLeft: window.innerWidth < 1100 ? '-18px' : '0',
              marginTop: '40px',
            }}
          >
            <Grid container spacing={4}>
              <Grid item sm={3}>
                <div
                  className={
                    window.innerWidth < 1100 ? 'pricingCard1100' : 'pricingCard'
                  }
                >
                  <div className='imgWrapper'>
                    <h1 className='beginner'>Beginner</h1>
                    <h1 className='price'>$299</h1>
                    <div className='month'>/month</div>
                    <p className='modelTitle'>
                      All the basics for businesses that are just getting
                      started.
                    </p>
                    {/* <div className="buyNow"><span className="buyBtn">Buy Now</span></div> */}
                    <Link to='/contact-us' style={{ textDecoration: 'none' }}>
                      <Button
                        size={'large'}
                        variant='contained'
                        className='themeBtn txtShadow roundedPlansPricing buyNow'
                      >
                        Buy Now
                      </Button>
                    </Link>

                    <div className='bottomBorder'></div>

                    <div className='vectorArea'>
                      <div className='paddingTop'>
                        <span>
                          <img src={right} alt='' width='12px' height='12px' />
                          <span className='hostedModels'>20 Hosted Models</span>
                        </span>
                      </div>
                      <div className='paddingTop'>
                        <span>
                          <img src={right} alt='' width='12px' height='12px' />
                          <span className='hostedModels'>
                            20,000 Monthly Website Views
                          </span>
                        </span>
                      </div>
                      <div className='paddingTop'>
                        <span>
                          <img src={right} alt='' width='12px' height='12px' />
                          <span className='hostedModels'>
                            First Model Optimization Free
                          </span>
                        </span>
                      </div>
                      <div className='paddingTop'>
                        <span>
                          <img src={right} alt='' width='12px' height='12px' />
                          <span className='hostedModels'>
                            Installation Support
                          </span>
                        </span>
                      </div>
                      <div className='paddingTop'>
                        <span>
                          <img src={right} alt='' width='12px' height='12px' />
                          <span className='hostedModels'>Analytics</span>
                        </span>
                      </div>
                      <div className='paddingTop'>
                        <span>
                          <img src={wrong} alt='' width='12px' height='12px' />
                          <span className='hostedModels'>
                            Dedicated Account Manager
                          </span>
                        </span>
                      </div>
                      <div className='paddingTop'>
                        <span>
                          <img src={wrong} alt='' width='12px' height='12px' />
                          <span className='hostedModels'>Animated Models</span>
                        </span>
                      </div>
                      <div className='paddingTop'>
                        <span>
                          <img src={wrong} alt='' width='12px' height='12px' />
                          <span className='hostedModels'>White Labeled</span>
                        </span>
                      </div>
                    </div>
                    <div className='bottomBorder2'></div>
                    <div className='vectorArea2'>
                      <div className='paddingTop2'>
                        <div className='hostedModels'>
                          AR Model Optamization starts from
                        </div>
                        <span className='varient'>$150 + $20/Varient</span>
                      </div>
                      <div className='paddingTop2'>
                        <span className='hostedModels'>VR starts from </span>
                        <span className='varient2'>$300</span>
                      </div>
                      <div className='paddingTop2'>
                        <span className='gamefied'>
                          Gamefied Walkthrough starts <br />{' '}
                          <span style={{ paddingLeft: '10px' }}>
                            from <span className='varient2'>$4000</span>{' '}
                          </span>
                        </span>
                      </div>
                      <div className='paddingTop2'>
                        <span className='gamefied'>
                          Photo Realistic Renderings starts <br />{' '}
                          <span style={{ paddingLeft: '10px' }}>
                            from <span className='varient2'>$150</span>{' '}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid item sm={3}>
                <div
                  className={
                    window.innerWidth < 1100 ? 'pricingCard1100' : 'pricingCard'
                  }
                >
                  <div className='imgWrapper'>
                    <h1 className='beginner'>Growth</h1>
                    <h1 className='price'>$699</h1>
                    <div className='month'>/month</div>
                    <p className='modelTitle'>
                      All the basics for businesses that are just getting
                      started.
                    </p>
                    {/* <div className="buyNow2"><span className="buyBtn">Buy Now</span></div> */}
                    <Link to='/contact-us' style={{ textDecoration: 'none' }}>
                      <Button
                        size={'large'}
                        variant='contained'
                        className='themeBtnSelected txtShadow roundedPlansPricing buyNow'
                      >
                        Buy Now
                      </Button>
                    </Link>

                    <div className='bottomBorder'></div>

                    <div className='vectorArea'>
                      <div className='paddingTop'>
                        <span>
                          <img src={right} alt='' width='12px' height='12px' />
                          <span className='hostedModels'>50 Hosted Models</span>
                        </span>
                      </div>
                      <div className='paddingTop'>
                        <span>
                          <img src={right} alt='' width='12px' height='12px' />
                          <span className='hostedModels'>
                            80,000 Monthly Website Views
                          </span>
                        </span>
                      </div>
                      <div className='paddingTop'>
                        <span>
                          <img src={right} alt='' width='12px' height='12px' />
                          <span className='hostedModels'>
                            First Model Optimization Free
                          </span>
                        </span>
                      </div>
                      <div className='paddingTop'>
                        <span>
                          <img src={right} alt='' width='12px' height='12px' />
                          <span className='hostedModels'>
                            Installation Support
                          </span>
                        </span>
                      </div>
                      <div className='paddingTop'>
                        <span>
                          <img src={right} alt='' width='12px' height='12px' />
                          <span className='hostedModels'>Analytics</span>
                        </span>
                      </div>
                      <div className='paddingTop'>
                        <span>
                          <img src={right} alt='' width='12px' height='12px' />
                          <span className='hostedModels'>Animated Models</span>
                        </span>
                      </div>
                      <div className='paddingTop'>
                        <span>
                          <img src={wrong} alt='' width='12px' height='12px' />
                          <span className='hostedModels'>White Labeled</span>
                        </span>
                      </div>
                      <div className='paddingTop'>
                        <span>
                          <img src={wrong} alt='' width='12px' height='12px' />
                          <span className='hostedModels'>
                            Dedicated Account Manager
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className='bottomBorder2'></div>
                    <div className='vectorArea2'>
                      <div className='paddingTop2'>
                        <div className='hostedModels'>
                          AR Model Optamization starts from
                        </div>
                        <span className='varient'>$125 + $20/Varient</span>
                      </div>
                      <div className='paddingTop2'>
                        <span className='hostedModels'>VR starts from </span>
                        <span className='varient2'>$250</span>
                      </div>
                      <div className='paddingTop2'>
                        <span className='gamefied'>
                          Gamefied Walkthrough starts <br />{' '}
                          <span style={{ paddingLeft: '10px' }}>
                            from <span className='varient2'>$3500</span>{' '}
                          </span>
                        </span>
                      </div>
                      <div className='paddingTop2'>
                        <span className='gamefied'>
                          Photo Realistic Renderings starts <br />{' '}
                          <span style={{ paddingLeft: '10px' }}>
                            from <span className='varient2'>$100</span>{' '}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid item sm={3}>
                <div
                  className={
                    window.innerWidth < 1100 ? 'pricingCard1100' : 'pricingCard'
                  }
                >
                  <div className='imgWrapper'>
                    <h1 className='beginner'>Enterprise</h1>
                    <h1 className='price'>$999</h1>
                    <div className='month'>/month</div>
                    <p className='modelTitle'>
                      All the basics for businesses that are just getting
                      started.
                    </p>
                    {/* <div className="buyNow"><span className="buyBtn">Buy Now</span></div> */}
                    <Link to='/contact-us' style={{ textDecoration: 'none' }}>
                      <Button
                        size={'large'}
                        variant='contained'
                        className='themeBtn txtShadow roundedPlansPricing buyNow'
                      >
                        Buy Now
                      </Button>
                    </Link>

                    <div className='bottomBorder'></div>

                    <div className='vectorArea'>
                      <div className='paddingTop'>
                        <span>
                          <img src={right} alt='' width='12px' height='12px' />
                          <span className='hostedModels'>
                            100 Hosted Models
                          </span>
                        </span>
                      </div>
                      <div className='paddingTop'>
                        <span>
                          <img src={right} alt='' width='12px' height='12px' />
                          <span className='hostedModels'>
                            150,000 Monthly Website Views
                          </span>
                        </span>
                      </div>
                      <div className='paddingTop'>
                        <span>
                          <img src={right} alt='' width='12px' height='12px' />
                          <span className='hostedModels'>
                            First Model Optimization Free
                          </span>
                        </span>
                      </div>
                      <div className='paddingTop'>
                        <span>
                          <img src={right} alt='' width='12px' height='12px' />
                          <span className='hostedModels'>
                            Installation Support
                          </span>
                        </span>
                      </div>
                      <div className='paddingTop'>
                        <span>
                          <img src={right} alt='' width='12px' height='12px' />
                          <span className='hostedModels'>Analytics</span>
                        </span>
                      </div>
                      <div className='paddingTop'>
                        <span>
                          <img src={right} alt='' width='12px' height='12px' />
                          <span className='hostedModels'>
                            Dedicated Account Manager
                          </span>
                        </span>
                      </div>
                      <div className='paddingTop'>
                        <span>
                          <img src={right} alt='' width='12px' height='12px' />
                          <span className='hostedModels'>Animated Models</span>
                        </span>
                      </div>
                      <div className='paddingTop'>
                        <span>
                          <img src={right} alt='' width='12px' height='12px' />
                          <span className='hostedModels'>White Labeled</span>
                        </span>
                      </div>
                    </div>
                    <div className='bottomBorder2'></div>
                    <div className='vectorArea2'>
                      <div className='paddingTop2'>
                        <div className='hostedModels'>
                          AR Model Optamization starts from
                        </div>
                        <span className='varient'>$100 + $20/Varient</span>
                      </div>
                      <div className='paddingTop2'>
                        <span className='hostedModels'>VR starts from </span>
                        <span className='varient2'>$200</span>
                      </div>
                      <div className='paddingTop2'>
                        <span className='gamefied'>
                          Gamefied Walkthrough starts <br />{' '}
                          <span style={{ paddingLeft: '10px' }}>
                            from <span className='varient2'>$3000</span>{' '}
                          </span>
                        </span>
                      </div>
                      <div className='paddingTop2'>
                        <span className='gamefied'>
                          Photo Realistic Renderings starts <br />{' '}
                          <span style={{ paddingLeft: '10px' }}>
                            from <span className='varient2'>$80</span>{' '}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid item sm={3}>
                <div
                  className={
                    window.innerWidth < 1100 ? 'pricingCard1100' : 'pricingCard'
                  }
                >
                  <div className='imgWrapper'>
                    <h1 className='beginner'>Build Your Plan</h1>
                    <h1 className='price'>Flexible</h1>
                    <div className='month'>/month</div>
                    <p className='modelTitle'>
                      All the basics for businesses that are just getting
                      started.
                    </p>
                    {/* <div className="buyNow"><span className="buyBtn">Contact Seller</span></div> */}
                    <Link to='/contact-us' style={{ textDecoration: 'none' }}>
                      <Button
                        size={'large'}
                        variant='contained'
                        className='themeBtn txtShadow roundedPlansPricing buyNow'
                      >
                        Contact Seller
                      </Button>
                    </Link>

                    <div className='bottomBorder'></div>

                    <div className='vectorArea'>
                      <div className='paddingTop'>
                        <span>
                          <img src={right} alt='' width='12px' height='12px' />
                          <span className='hostedModels'>
                            Custom Hosted Models
                          </span>
                        </span>
                      </div>
                      <div className='paddingTop'>
                        <span>
                          <img src={right} alt='' width='12px' height='12px' />
                          <span className='hostedModels'>
                            Custom Monthly Website Views
                          </span>
                        </span>
                      </div>
                      <div className='paddingTop'>
                        <span>
                          <img src={right} alt='' width='12px' height='12px' />
                          <span className='hostedModels'>
                            First Model Optimization Free
                          </span>
                        </span>
                      </div>
                      <div className='paddingTop'>
                        <span>
                          <img src={right} alt='' width='12px' height='12px' />
                          <span className='hostedModels'>
                            Installation Support
                          </span>
                        </span>
                      </div>
                      <div className='paddingTop'>
                        <span>
                          <img src={right} alt='' width='12px' height='12px' />
                          <span className='hostedModels'>Custom Analytics</span>
                        </span>
                      </div>
                      <div className='paddingTop'>
                        <span>
                          <img src={right} alt='' width='12px' height='12px' />
                          <span className='hostedModels'>
                            Dedicated Account Manager
                          </span>
                        </span>
                      </div>
                      <div className='paddingTop'>
                        <span>
                          <img src={right} alt='' width='12px' height='12px' />
                          <span className='hostedModels'>Animated Models</span>
                        </span>
                      </div>
                      <div className='paddingTop'>
                        <span>
                          <img src={right} alt='' width='12px' height='12px' />
                          <span className='hostedModels'>Custom UI/UX</span>
                        </span>
                      </div>
                    </div>
                    <div className='bottomBorder2'></div>
                    <div className='vectorArea2'>
                      <div className='paddingTop2'>
                        <div className='hostedModels'>
                          AR Model Optamization starts from
                        </div>
                        <span className='varient'>$100 + $20/Varient</span>
                      </div>
                      <div className='paddingTop2'>
                        <span className='hostedModels'>VR starts from </span>
                        <span className='varient2'>$200</span>
                      </div>
                      <div className='paddingTop2'>
                        <span className='gamefied'>
                          Gamefied Walkthrough starts <br />{' '}
                          <span style={{ paddingLeft: '10px' }}>
                            from <span className='varient2'>$3000</span>{' '}
                          </span>
                        </span>
                      </div>
                      <div className='paddingTop2'>
                        <span className='gamefied'>
                          Photo Realistic Renderings starts <br />{' '}
                          <span style={{ paddingLeft: '10px' }}>
                            from <span className='varient2'>$80</span>{' '}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </section>
        </Container>
      </>
    );
  }
}

export default Plans_and_pricing;
