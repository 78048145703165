import React, { useState } from 'react';
import { spacing } from './const';
import axios from './axios';
import { API } from '../../Constants/APIs';
import TouchAppIcon from '@material-ui/icons/TouchApp';
const TemplateBtn = ({ product_id }) => {
  const [isLoading, setIsLoading] = useState(false);
  const duplicate_template = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.post(
        `${API.postUrls.templateProducts}${product_id}`
      );
      if (data.newProductId) {
        window.location.href = `/viewer?id=${data.newProductId}`;
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <button
      disabled={isLoading}
      onClick={duplicate_template}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'white',
        outline: 'none',
        border: 0,
        height: 35,
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
        flex: 1,
        borderRadius: 7,
        marginLeft: spacing,
        background: '#e3e3e3',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        color: '#444'
        // color: '#444444',
      }}
    >
      <span
        style={{
          // paddingLeft: spacing,
          paddingRight: spacing,
          fontSize: 13,
          fontWeight: 400,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
      >
        Use this Template{isLoading ? ` ...` : ''}
      </span>
      {/* <TouchAppIcon fontSize='small' /> */}
      {/* {"this Template"}  */}
    </button>
  );
};

export default TemplateBtn;
