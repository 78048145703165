// Home Page
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import axios from 'axios';
import React, { Component } from 'react';
import { Route, Routes } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { API } from '../Constants/APIs';
import init from '../Helpers/Windotoken';
import AllProducts from './AllProducts';
import DraftProducts from './DraftProducts';
import LiveProducts from './LiveProducts';
import PendingProducts from './PendingProducts';
import WIPproducts from './WIPproducts';
import edit from '../Assets/Images/edit.png';
import remove from '../Assets/Images/delete.png';
import Modal from 'react-awesome-modal';
import Logo from '../Assets/Images/actualityLogo.png';
import Sidebar from '../Components/Sidebar';
import { scrollToTop } from '../Helpers/utils';
import confetti from '../Assets/newIcons/confetti.png';
import AddNewProduct from '../Components/AddNewProduct';
import ShareUser from '../Components/ShareUser';
export default class ProductList extends Component {
  state = {
    products0: [],
    categories: [],
    expandMore: false,
    expand: 0,
    scrollIndex: 1,
    pageCount: 1,
    hasNextPage: true,
    page: 1,
    totalpage: 1,
    metaData: {},
    selected: 0,
    showSearchSuggestions: false,
    productStatus: '',
    query: '',
    perPage: '8',
    searching: false,
    userdetail: {},
    openEdit: false,
    removeCompanyLogo: false,
    currentModal: false,
    currentStatusMessage: '',
    // userInactiveModal : false,
  };
  componentDidMount() {
    // if(init() === "success")
    // {
    this.getMetaData();
    this.fetchCategories();
    this.getUser();
    this.checkProductStatus();

    // }
    // else
    // {
    //   window.location.href="/account/login";
    // }
  }

  checkProductStatus = () => {
    let userId = localStorage.getItem('userId');
    axios
      .get(API.getUrls.checkProductStatus + '?user_id=' + userId)
      .then((resp) => {
        // console.log(resp);
        if (resp.data.status === 'failed') {
          this.setState({
            currentModal: true,
            currentStatusMessage: resp.data.message,
          });
        }
      });
  };

  fetchCategories = () => {
    this.setState({ loading: true });
    // if (init() === "success") {
    let authToken = localStorage.getItem('token');
    // console.log(authToken);
    axios
      .get(
        API.getUrls.getUserCat +
        '?status=' +
        this.state.productStatus +
        '&q=' +
        this.state.query,
        { headers: { Authorization: 'Bearer ' + authToken } }
      )
      .then((res) => {
        this.setState({
          categories: res.data.categories,
          CategoryLength: res.data.categories.length === 0 ? true : false,
          loading: false,
        });
      });

  };
  serachProducts = (e) => {
    this.setState({ searching: true, visible: true, showAll: false });
    if (e.target.value.length > 3) {
      this.setState(
        {
          query: e.target.value,
          showSearchSuggestions: true,
        },
        () => {
          this.fetchCategories();
        }
      );
    } else if (e.target.value.length === 0) {
      this.setState(
        {
          searching: false,
          query: '',
        },
        () => {
          this.fetchCategories();
        }
      );
    }
  };

  getMetaData = () => {
    let authToken = localStorage.getItem('token');
    axios
      .get(API.getUrls.productMeta, {
        headers: { Authorization: 'Bearer ' + authToken },
      })
      .then((res) => {
        this.setState({ metaData: res.data });
      });

  };
  getUser = () => {
    this.setState({ loader: true });
    let authToken = localStorage.getItem('token');
    axios
      .get(API.getUrls.getUser, {
        headers: { Authorization: 'Bearer ' + authToken },
      })
      .then((res) => {
        this.setState({ userdetail: res.data.user, loader: false });
      });
  };


  onImageChangeLogo = (event) => {
    let data = new FormData();
    if (event.target.files[0]) {
      data.append('company_logo', event.target.files[0]);
      let userToken = localStorage.getItem('token');
      this.setState({ loading: true });
      axios.put(API.postUrls.updateProfile, data).then((resp) => {
        if (resp.data.status === 'success') {
          this.setState({
            loading: false,
          });
          this.getUser();
        }
      });
    }
  };

  changeCompanyName = (e) => {
    this.setState({
      openEdit: true,
    });
  };

  closeModal = (e) => {
    this.setState({
      openEdit: false,
    });
  };

  updateCompanyName = (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    let data = new FormData();
    data.append('company_name', this.state.company_name);
    axios.put(API.postUrls.updateProfile, data).then((resp) => {
      if (resp.data.status === 'success') {
        this.setState({
          notification: true,
          loading: false,
          successMsg: resp.data.message,
          openEdit: false,
        });
        setTimeout(() => {
          this.setState({ notification: false });
          this.getUser();
        }, 2000);
      } else {
        this.setState({
          notification: true,
          successMsg: resp.data.message,
          loading: false,
        });
        setTimeout(() => {
          this.setState({ notification: false });
        }, 2000);
      }
    });
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  logout = () => {
    localStorage.clear();
    window.location.href = '/account/logout';
  };

  onRemoveFile = () => {
    this.setState({
      removeCompanyLogo: true,
    });
  };

  removeLogo = () => {
    axios.put(API.postUrls.removeCompanyLogo).then((resp) => {
      // console.log(resp);
      if (resp.data.status === 'success') {
        this.setState({
          removeCompanyLogo: false,
        });
        this.getUser();
      }
    });
  };

  render() {
    return (
      <Container
        className='containerSpacingdashBoard'
        maxWidth={'lg'}
        style={
          {
            // border: "1px solid red",
          }
        }
      >
        {/* {
          this.state.pageLoad ? window.location.reload(true) : window.location.reload(false)
        } */}
        <Grid container spacing={2} className='userDashboard'>
          <Grid
            item
            sm={2}
            className='sidebar'
            style={{
              overflow: 'hidden',
              // height: "max-content",
              height: '100%',
            }}
          >
            {/* <Sidebar /> */}
            <div className='' style={{ position: 'relative' }}>
              <Typography className='logoArea'>
                <div className='uploadLogoPlaceSidebar'>
                  {this.state.userdetail ? (
                    this.state.userdetail.company_logo ? (
                      <img
                        src={
                          // this.state.userdetail
                          //   ? this.state.userdetail.company_logo
                          //     ? API.imgUrl + this.state.userdetail
                          //       ? this.state.userdetail.company_logo
                          //       : ""
                          //     : ""
                          //   : ""
                          this.state.userdetail.company_logo
                            ? API.imgUrl + this.state.userdetail.company_logo
                            : ''
                        }
                        alt=''
                        style={{
                          width: '100%',
                          height: '100%',
                          borderRadius: '15px',
                        }}
                      />
                    ) : (
                      ''
                    )
                  ) : (
                    ''
                  )}
                  {this.state.userdetail ? (
                    <Button
                      variant='outlined'
                      size='medium'
                      className='profilePic'
                      component='label'
                      style={{
                        border: 'none',
                        width: '50px',
                        position: 'absolute',
                        right: '-17%',
                        top: '-2%',
                      }}
                    >
                      {this.state.userdetail.company_logo ? (
                        <>
                          <img
                            src={remove}
                            width='15px'
                            height='15px'
                            alt=''
                            className='prodLogo'
                            onClick={this.onRemoveFile}
                          />
                        </>
                      ) : (
                        <>
                          <img
                            src={edit}
                            width='15px'
                            height='15px'
                            alt=''
                            className='prodLogo'
                          />
                          <input
                            type='file'
                            onChange={this.onImageChangeLogo}
                            name='profile_pic'
                            hidden
                          />
                        </>
                      )}
                    </Button>
                  ) : (
                    ''
                  )}
                </div>

                <Typography style={{ position: 'relative', top: '62%' }}>
                  <img
                    src={edit}
                    width='15px'
                    height='15px'
                    alt=''
                    className='compLogo'
                    onClick={this.changeCompanyName}
                  />
                  <Button
                    variant='outlined'
                    size='medium'
                    className='compnyName'
                    component='label'
                    style={{
                      border: 'none',
                      width: '100%',
                      position: 'relative',
                      maxWidth: '100%',
                      top: '-7px',
                      padding: 0,
                    }}
                  >
                    {this.state.userdetail ? (
                      this.state.userdetail.company_name ? (
                        <>
                          <p
                            title={
                              this.state.userdetail.company_name ??
                              'Company Name ...'
                            }
                            style={{
                              padding: 0,
                              margin: 0,
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              // border: "1px solid aqua",
                              maxWidth: '100%',
                              width: '100%',
                              paddingRight: 20,
                              // wordBreak: "break-all",
                            }}
                          >
                            {this.state.userdetail
                              ? this.state.userdetail.company_name
                              : ''}
                          </p>
                        </>
                      ) : (
                        <>
                          <div>Company Name</div>
                        </>
                      )
                    ) : (
                      ''
                    )}
                  </Button>
                </Typography>
              </Typography>
              <Typography className='performance'> Performance </Typography>
              <Grid container spacing={2} style={{ textAlign: 'left' }}>
                <Grid
                  item
                  sm={6}
                  style={{
                    cursor: 'auto',
                  }}
                >
                  <span
                    className='spanTag'
                    style={{
                      cursor: 'auto',
                    }}
                  >
                    Total Views
                  </span>
                </Grid>
                <Grid item sm={6} style={{ textAlign: 'center' }}>
                  {/* <span className="spanTag">
                    {this.state.metaData.totalViews
                      ? this.state.metaData.totalViews
                      : 0}
                  </span> */}
                  <span
                    className='spanTag'
                    style={{
                      position: 'relative',
                    }}
                  >
                    {this.state.metaData.totalViews
                      ? this.state.metaData.totalViews
                      : 0}
                    <div
                      style={{
                        position: 'absolute',
                        top: '-2px',
                        left: '100%',
                        maxHidth: 20,
                        maxHeight: 25,
                        display: 'grid',
                        placeItems: 'center',
                      }}
                    >
                      {' '}
                      <img
                        src={confetti}
                        alt=''
                        width='20px'
                        height='25px'
                        style={{
                          marginLeft: 4,
                        }}
                      />
                    </div>
                  </span>
                </Grid>
              </Grid>
              <hr />
              <Typography className='performance'> Products </Typography>
              <Grid container spacing={2} style={{ textAlign: 'left' }}>
                <Grid item sm={6}>
                  <NavLink exact to='/allProducts' className='onHoverLink'>
                    <span
                      className='spanTag'
                      style={{
                        fontWeight:
                          this.state.productStatus === '' ? 'bold' : 'normal',
                        color: '#2D3436',
                      }}
                      onClick={() =>
                        this.setState({ productStatus: '' }, () => {
                          this.fetchCategories();
                        })
                      }
                    >
                      All Products
                    </span>
                  </NavLink>
                </Grid>
                <Grid item sm={6} style={{ textAlign: 'center' }}>
                  <span className='spanTag'>
                    {this.state.metaData.all ? this.state.metaData.all : 0}
                  </span>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ textAlign: 'left' }}>
                <Grid item sm={6}>
                  <NavLink exact to='/live' className='onHoverLink'>
                    <span
                      className='spanTag'
                      style={{
                        fontWeight:
                          this.state.productStatus === 'published'
                            ? 'bold'
                            : 'normal',
                        color: '#2D3436',
                      }}
                      onClick={() =>
                        this.setState({ productStatus: 'published' }, () => {
                          this.fetchCategories();
                        })
                      }
                    >
                      Live Products
                    </span>
                  </NavLink>
                </Grid>
                <Grid item sm={6} style={{ textAlign: 'center' }}>
                  <span className='spanTag'>
                    {' '}
                    {this.state.metaData.published
                      ? this.state.metaData.published
                      : 0}{' '}
                  </span>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ textAlign: 'left' }}>
                <Grid item sm={6}>
                  <NavLink exact to='/draft' className='onHoverLink'>
                    <span
                      className='spanTag'
                      style={{
                        fontWeight:
                          this.state.productStatus === 'draft'
                            ? 'bold'
                            : 'normal',
                        color: '#2D3436',
                      }}
                      onClick={() =>
                        this.setState(
                          {
                            productStatus: 'draft',
                            categories: this.state.categories,
                          },
                          () => {
                            this.fetchCategories();
                          }
                        )
                      }
                    >
                      Draft Products
                    </span>
                  </NavLink>
                </Grid>
                <Grid item sm={6} style={{ textAlign: 'center' }}>
                  <span className='spanTag'>
                    {' '}
                    {this.state.metaData.drafted
                      ? this.state.metaData.drafted
                      : 0}{' '}
                  </span>
                </Grid>
              </Grid>
              {/* button for overallanalytics start */}
              <div
                style={{
                  // border: "0px solid red",
                  // padding: "0 20px",
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Link
                  to='/analytics'
                  style={{
                    textDecoration: 'none',
                    color: '#666666',
                    // border: "1px solid aqua",
                    marginTop: '35px',
                    marginBottom: 20,
                  }}
                >
                  <Button
                    className='productBtn'
                    style={{
                      width: '100%',
                      fontSize: window.innerWidth < 1100 ? '8px' : '10px',

                      borderRadius: 4,
                      padding: '2px 20px',
                      // border: "1px solid blue",
                      height: 36 || 'min-content',
                    }}
                  >
                    Overall Analytics
                  </Button>
                </Link>
              </div>
              <hr />
              {/* button for overallanalytics  end */}
              <AddNewProduct />
              {/* <ShareUser /> */}
              <ShareUser is_share_some={true} />
              {/* <Link
                to="/product-create"
                style={{ textDecoration: 'none', color: '#666666' }}
              >
                <Button
                  className="productBtn"
                  style={{
                    width: '100%',
                    fontSize: window.innerWidth < 1100 ? '14px' : '18px',
                    marginTop: '35px',
                  }}
                >
                  Add New Product +
                </Button>
              </Link> */}
              <Link
                to='/settings'
                style={{ textDecoration: 'none', color: '#666666' }}
              >
                <Button
                  className='productBtn'
                  onClick={() => {
                    scrollToTop();
                  }}
                  style={{
                    width: '100%',
                    fontSize: window.innerWidth < 1100 ? '14px' : '18px',

                    marginTop: '26px',
                  }}
                >
                  Settings
                </Button>
              </Link>
              <Button
                className='productBtn'
                onClick={this.logout}
                style={{
                  marginTop: '26px',
                  width: '100%',
                  fontSize: window.innerWidth < 1100 ? '14px' : '18px',
                }}
              >
                Logout
              </Button>
            </div>
          </Grid>
          <Grid item sm={10} className='productList'>
            <div>
              <div className='flexWrapper'>
                <NavLink
                  exact
                  to='/allProducts'
                  activeClassName='selectedLink bold'
                  style={{ textDecoration: 'none', marginBottom: '12px' }}
                >
                  <Typography
                    className='rowheads'
                    onClick={() =>
                      this.setState({ productStatus: '' }, () => {
                        this.fetchCategories();
                      })
                    }
                    style={{
                      fontSize: window.innerWidth < 1100 ? '18px' : '18px',
                      fontWeight:
                        this.state.productStatus === '' ? 'bold' : 'normal',
                      color: '#2D3436',
                    }}
                  >
                    All Products
                  </Typography>
                </NavLink>
                <NavLink
                  to='/draft'
                  className='active  '
                  activeClassName='selectedLink bold'
                  style={{ textDecoration: 'none', marginBottom: '12px' }}
                >
                  <Typography
                    className='rowheads'
                    onClick={() =>
                      this.setState({ productStatus: 'draft' }, () => {
                        this.fetchCategories();
                      })
                    }
                    style={{
                      fontSize: window.innerWidth < 1100 ? '18px' : '18px',
                      fontWeight:
                        this.state.productStatus === 'draft'
                          ? 'bold'
                          : 'normal',
                      color: '#2D3436',
                    }}
                  >
                    Draft
                  </Typography>
                </NavLink>
                <NavLink
                  to='/live'
                  activeClassName='selectedLink bold'
                  style={{ textDecoration: 'none', marginBottom: '12px' }}
                >
                  <Typography
                    className='rowheads'
                    onClick={() =>
                      this.setState({ productStatus: 'published' }, () => {
                        this.fetchCategories();
                      })
                    }
                    style={{
                      fontSize: window.innerWidth < 1100 ? '18px' : '18px',
                      fontWeight:
                        this.state.productStatus === 'published'
                          ? 'bold'
                          : 'normal',
                      color: '#2D3436',
                    }}
                  >
                    Live Products
                  </Typography>
                </NavLink>
                <div style={{ position: 'relative' }}>
                  <input
                    placeholder='Search Products'
                    name=''
                    onChange={this.serachProducts}
                    className='searchinput'
                    style={{
                      width: window.innerWidth < 1100 ? '270px' : '394px',
                      marginTop: '-12px',
                    }}
                  />
                  <SearchIcon className='serachIcon' />
                </div>
              </div>
              {this.state.searching ? (
                <div>
                  <p>{`Showing results for "` + this.state.query + `"`}</p>
                </div>
              ) : null}

              <Routes>
                <Route
                  path='/'
                  element={
                    <AllProducts
                      categories={this.state.categories}
                      query={this.state.query}
                      productStatus={this.state.productStatus}
                      CategoryLength={this.state.CategoryLength}
                      loading={this.state.loading}
                      searching={this.state.searching}
                      getMetaData={this.getMetaData}
                    />
                  }
                />
                <Route
                  path='allProducts'
                  element={
                    <AllProducts
                      categories={this.state.categories}
                      query={this.state.query}
                      productStatus={this.state.productStatus}
                      CategoryLength={this.state.CategoryLength}
                      loading={this.state.loading}
                      searching={this.state.searching}
                      getMetaData={this.getMetaData}
                    />
                  }
                />
                <Route
                  path='draft'
                  element={
                    <DraftProducts
                      categories={this.state.categories}
                      query={this.state.query}
                      productStatus={this.state.productStatus}
                      CategoryLength={this.state.CategoryLength}
                      loading={this.state.loading}
                      searching={this.state.searching}
                      getMetaData={this.getMetaData}
                    />
                  }
                />
                <Route
                  path='pending'
                  element={
                    <PendingProducts
                      categories={this.state.categories}
                      query={this.state.query}
                      productStatus={this.state.productStatus}
                      CategoryLength={this.state.CategoryLength}
                      loading={this.state.loading}
                      getMetaData={this.getMetaData}
                    />
                  }
                />
                <Route
                  path='live'
                  element={
                    <LiveProducts
                      categories={this.state.categories}
                      query={this.state.query}
                      productStatus={this.state.productStatus}
                      CategoryLength={this.state.CategoryLength}
                      loading={this.state.loading}
                      getMetaData={this.getMetaData}
                    />
                  }
                />
                <Route
                  path='Wip'
                  element={
                    <WIPproducts
                      categories={this.state.categories}
                      query={this.state.query}
                      productStatus={this.state.productStatus}
                      CategoryLength={this.state.CategoryLength}
                      loading={this.state.loading}
                    />
                  }
                />
              </Routes>

              {/* {this.state.showSearchSuggestions ? (
                <div>
                  {this.state.categories.map((singleCate, index) => (
                    <Accordion>
                      <AccordionSummary
                        aria-controls={"panel" + index + 1 + "a-content"}
                        id={"panel" + index + 1 + "a-header"}
                      >
                        <div className="CategoryDiv">
                          <Typography
                            className="Heading"
                            onClick={() => {
                              this.fetchModels(singleCate._id, index);
                            }}
                            key={index}
                          >
                            <span
                              style={{
                                marginRight: "30px",
                                marginLeft: "10px",
                              }}
                            >
                              {this.state.selected === index ? (
                                <RemoveIcon />
                              ) : (
                                <AddIcon />
                              )}
                            </span>
                            {singleCate.title}
                          </Typography>
                        </div>
                      </AccordionSummary>

                      <AccordionDetails>
                      <div className="cardWrapper">
                        {this.state.loadingProducts ? (
                          <div
                            style={{ textAlign: "center", marginTop: "80px" }}
                          >
                            <CircularProgress />
                          </div>
                        ) : (
                          <Grid container spacing={3}>
                            {this.state["products" + index]
                              ? this.state["products" + index].map(
                                  (ele, ind) => (
                                    <Grid item sm={3} key={ind}>
                                      <ProductCard
                                        imgSrc={
                                          ele.poster_image_url
                                            ? API.imgUrl + ele.poster_image_url
                                            : modelImg1
                                        }
                                        id={ele._id}
                                        title={ele.title}
                                        views={ele.views}
                                      />
                                    </Grid>
                                  )
                                )
                              : null}
                          </Grid>
                        )}
                      </div>
                    </AccordionDetails>
                
                    </Accordion>
                  ))}
                </div>
              ) : null} */}
            </div>

            {/* Purchase plan modal */}
            <Modal
              visible={this.state.currentModal}
              width='400'
              height='300'
              effect='fadeInUp'
              onClickAway={() => this.setState({ currentModal: false })}
            >
              <div
                className='confirmationModalPublish'
                style={{ textAlign: 'center' }}
              >
                <Typography>{this.state.currentStatusMessage}</Typography>
                <Button
                  onClick={() => (window.location.href = '/pricing')}
                  className='DeleteBtnPublish'
                  style={{ background: '#bdbdbd' }}
                >
                  Click To Purchase Plan
                </Button>
                <Button
                  onClick={() => this.setState({ currentModal: false })}
                  className='DeleteBtnPublish'
                  style={{ background: '#bdbdbd' }}
                >
                  Cancel
                </Button>
              </div>
            </Modal>

            {/* Remove Company logo */}
            <Modal
              visible={this.state.removeCompanyLogo}
              width='400'
              height='300'
              effect='fadeInUp'
              onClickAway={() => this.setState({ removeCompanyLogo: false })}
            >
              <div
                className='confirmationModal'
                style={{ textAlign: 'center' }}
              >
                <Typography>
                  Are you sure you want to remove company logo ?
                </Typography>
                <Button
                  onClick={() => {
                    this.removeLogo();
                    // this.deleteMaterial(
                    //   this.state.deleteMatId,
                    //   this.state.isAvailable
                    // );
                  }}
                  className='DeleteBtn'
                >
                  Remove{' '}
                </Button>
                <Button
                  onClick={() => this.setState({ removeCompanyLogo: false })}
                  className='DeleteBtn'
                  style={{ background: '#bdbdbd' }}
                >
                  Cancel
                </Button>
              </div>
            </Modal>

            <Modal
              visible={this.state.openEdit}
              width='350'
              height='230'
              effect='fadeInUp'
              onClickAway={() => this.closeModal()}
            >
              <div className='modalWrapper'>
                {/* <h4>Update Company name</h4> */}
                <form action='' onSubmit={this.updateCompanyName}>
                  <Typography>
                    {this.state.filename ? 'File updated' : null}
                  </Typography>
                  <br />
                  <div className='contentWrap'>
                    <p>Company Name</p>
                    <input
                      type='text'
                      name='company_name'
                      className='modalInput'
                      style={{ marginBottom: '10px' }}
                      // defaultValue={
                      //   this.state.product ? this.state.product.title : ""
                      // }
                      onChange={this.handleInput}
                      required
                    />
                    <br />
                    <Button
                      className='addBtn'
                      type='submit'
                      style={{ width: '100%', marginLeft: '0px' }}
                    >
                      {this.state.loading ? (
                        <CircularProgress color={'white'} size={25} />
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </div>
                </form>
              </div>
            </Modal>
            {/* Modal for show user in inactive */}
            {/* <Modal
              visible={this.state.userInactiveModal}
              width="400"
              height="300"
              effect="fadeInUp"
              onClickAway={() => this.setState({ userInactiveModal: false })}
            >
              <div
                className="confirmationModalPublish"
                style={{ textAlign: "center" }}
              >
                <img src={Logo} alt="logo Image" height={70} width={200}/>
               <h2>Your account has been locked by admin !</h2>
              </div>
            </Modal> */}
          </Grid>
        </Grid>
      </Container>
    );
  }
}
