import React from 'react';
import { useParams } from 'react-router-dom';
import Static from './Static';

export default function StaticFunctional() {
  let id = useParams();
  return (
    <div>
      <Static matchparams={id} />
    </div>
  );
}
