import {
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Person, Settings } from '@material-ui/icons';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import PaymentIcon from '@material-ui/icons/Payment';
import ClearIcon from '@material-ui/icons/Clear';
import axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../Components/Sidebar';
import { API } from '../Constants/APIs';
import { AdminPortal } from '@frontegg/react';
import usericon from '../Assets/Images/userjpeg.jpeg';
import { dateAhead } from '../Helpers/utils';
export default class Setting extends Component {
  state = {
    plans: [
      {
        item_id: 'Beginner-USD-Monthly',
        title: 'Beginner Plan',
        no_of_models: 20,
        is_first_model_free: true,
        ar_360_view_fee: '$300',
        platform_fee: '$1200',
      },
      {
        item_id: 'Growth-USD-Monthly',
        title: 'Growth Plan',
        no_of_models: 50,
        is_first_model_free: true,
        ar_360_view_fee: '$300',
        platform_fee: '$1200',
      },
      {
        item_id: 'Enterprise-USD-Monthly',
        title: 'Enterprise Plan',
        no_of_models: 100,
        is_first_model_free: true,
        ar_360_view_fee: '$300',
        platform_fee: '$1200',
      },
    ],
    planName: '',
    purchasedDate: '',
    nextBillingDate: '',
    amount: '',
    nodatafound: false,
    cardNumber: '',
    cardNumber2: '',
    gateway: '',
    card_type: '',
    customer_id: '',
    paymentSetting: false,
    portalUrl: '',
    modalId: '',
    planId: '',
    page: '1',
    perPage: '20',
    usedModels: '',
    remainingModels: '',
    totalModels: '',
    subscriptionStatus: 'Active',
    noOfDaysLeft: '',
    noOfViewsLeft: '',
    monthlyViews: '',
    prdStatus: '',
  };
  componentDidMount() {
    const el = document.createElement('script');

    axios.get(API.getUrls.getPlansNew).then((resp) => {
      console.clear();

      if (resp?.data?.status === 'success') {
        var date = new Date(
          resp?.data?.updatedOrders?.order_summary?.content?.subscription?.next_billing_at
        );
        var nextBillingDate = date.toString();
        this.setState({
          planId:
            resp?.data?.data?.subscription?.subscription_items[0]
              ?.item_price_id,
          planName:
            resp?.data?.data?.subscription?.subscription_items[0]
              ?.item_price_id,
          purchasedDate: resp?.data?.updatedOrders?.createdAt?.slice(0, 10),
          nextBillingDate: nextBillingDate,
          amount: resp?.data?.data?.subscription?.subscription_items[0]?.amount,
          cardNumber: resp?.data?.data?.card?.masked_number,
          cardNumber2: resp?.data?.data?.card?.last4,
          gateway: resp?.data?.data?.card?.gateway,
          card_type: resp?.data?.data?.card?.card_type,
          customer_id: resp?.data?.data?.card?.customer_id,
          subscriptionStatus: resp?.data?.data?.subscription?.status,
          totalModels:
            resp?.data?.updatedOrders?.pricing_id?.no_of_hosted_models,
          remainingModels: resp?.data?.updatedOrders?.no_of_models_left,
          noOfDaysLeft: resp?.data?.updatedOrders?.no_of_days_left,
          noOfViewsLeft: resp?.data?.updatedOrders?.no_of_views_left,
          monthlyViews: resp?.data?.updatedOrders?.pricing_id?.monthly_views,
          usedModels:
            resp?.data?.updatedOrders?.pricing_id?.no_of_hosted_models -
            resp?.data?.updatedOrders?.no_of_models_left,
          prdStatus: resp?.data?.updatedOrders?.pricing_id?.status,
        });
      } else {
        this.setState({
          nodatafound: true,
        });
      }
    });
  }

  logout = () => {
    localStorage.clear();
    window.location.href = '/account/logout';
  };

  handleProfile = () => {
    AdminPortal.show();
  };

  gotoPortal = () => {
    let payload = {
      redirect_url: 'https://portal.actuality.live/userprofile',
    };
    axios
      .post(API.postUrls.chargebeePortal, payload)
      .then((resp) => {
        console.clear();
        this.setState(
          {
            portalUrl: resp.data.portal_session.access_url,
          },
          () => {
            window.open(this.state.portalUrl, '_blank').focus();
          }
        );
      })
      .catch((err) => {
        if (err) {
          console.clear();
        }
      });
  };

  showPaymentSetting = (toggle = true) => {
    this.setState({
      paymentSetting: toggle,
    });
  };
  render() {
    return (
      <Container className='containerSpacingdashBoard' maxWidth={'lg'}>
        <Grid container spacing={2} className='userDashboard'>
          <Grid item sm={2} className='sidebar'>
            <Sidebar />
          </Grid>
          <Grid
            item
            sm={10}
            style={{
              paddingLeft: window.innerWidth < 1000 ? '75px' : '105px',
              marginTop: '35px',
            }}
          >
            <Link
              to='/userprofile'
              style={{ textDecoration: 'none', color: '#666666' }}
            >
              <Button
                className='profileBtn'
                style={{ width: window.innerWidth < 1000 ? '330px' : '405px' }}
                onClick={this.handleProfile}
              >
                <Person
                  style={{
                    marginRight: window.innerWidth < 1000 ? '8px' : '15px',
                    marginLeft: window.innerWidth < 1000 ? '-2px' : '-13px',
                    width: '40px',
                    height: '39px',
                  }}
                />
                Profile
              </Button>
            </Link>

            <Button
              className='profileBtn'
              style={{ width: window.innerWidth < 1000 ? '330px' : '405px' }}
              onClick={() => this.showPaymentSetting(true)}
            >
              <span style={{ marginRight: '30px' }}>
                <Settings className='personIcon' />
              </span>
              Payment Settings
            </Button>

            {this.state.paymentSetting ? (
              <>
                <Grid
                  item
                  sm={10}
                  style={{
                    marginLeft: window.innerWidth < 1000 ? '75px' : '15px',
                    marginTop: '35px',
                  }}
                >
                  {this.state.nodatafound ? (
                    <div className='purchaseSetting'>
                      Currently you don't have any subscription plan.
                      <br />
                      <a
                        className='subscribe'
                        href={'/pricing?modalId=' + this.state.modalId}
                        style={{ textDecoration: 'none' }}
                      >
                        <Button
                          className='profileBtn'
                          style={{
                            width: window.innerWidth < 1000 ? '330px' : '405px',
                            fontSize: 20,
                            marginTop: 20,
                          }}
                        >
                          Click here for Subscription plan.
                        </Button>{' '}
                      </a>
                    </div>
                  ) : (
                    <div className='detailBack'>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          paddingLeft: 10,
                        }}
                      >
                        <Grid
                          item
                          sm={12}
                          style={{
                            border: '0px solid red',
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <IconButton
                            aria-label='delete'
                            size='large'
                            onClick={() => {
                              this.showPaymentSetting(false);
                            }}
                          >
                            <ClearIcon fontSize='medium' />
                          </IconButton>
                        </Grid>
                        <Grid
                          item
                          sm={
                            this.state.subscriptionStatus === 'active' ? 4 : 6
                          }
                        >
                          <h4>Plan Details</h4>
                        </Grid>
                        {this.state.subscriptionStatus === 'active' ? (
                          <Grid item sm={4}>
                            <Button
                              className='cancelPlanBtn'
                              style={{
                                width:
                                  window.innerWidth < 1000 ? '200px' : '200px',
                                position: 'relative',
                                left: '70px',
                                top: '10px',
                              }}
                              onClick={() =>
                                (window.location.href = '/pricing')
                              }
                            >
                              Upgrade plan
                            </Button>
                          </Grid>
                        ) : null}

                        {this.state.subscriptionStatus === 'active' ? (
                          <Grid item sm={4}>
                            <Button
                              className='cancelPlanBtn'
                              style={{
                                width:
                                  window.innerWidth < 1000 ? '200px' : '200px',
                                position: 'relative',
                                left: '25px',
                                top: '10px',
                              }}
                              onClick={this.gotoPortal}
                            >
                              Manage Subscription
                            </Button>
                          </Grid>
                        ) : (
                          <Grid item sm={6}>
                            <Button
                              className='cancelPlanBtn'
                              style={{
                                width:
                                  window.innerWidth < 1000 ? '200px' : '200px',
                                position: 'relative',
                                left: '165px',
                                top: '10px',
                              }}
                              onClick={this.gotoPortal}
                            >
                              Manage Subscription
                            </Button>
                          </Grid>
                        )}

                        <Grid item sm={4} className='colmds'>
                          <h5 className='infoItemPlan'>Plan name</h5>
                          <Typography className='infoItemPlanDetail'>
                            {this.state.planName}
                          </Typography>
                        </Grid>
                        <Grid item sm={4} className='colmds'>
                          <h5 className='infoItemPlan'>Purchased Date</h5>
                          <Typography className='infoItemPlanDetail'>
                            {this.state.purchasedDate}
                          </Typography>
                        </Grid>
                        <Grid item sm={4} className='colmds'>
                          <h5 className='infoItemPlan'>Total Amount</h5>
                          <Typography className='infoItemPlanDetail'>
                            ${parseInt(this.state.amount) / 100}
                          </Typography>
                        </Grid>

                        <Grid item sm={4} className='colmds'>
                          <h5 className='infoItemPlan'>Total Hosted Models</h5>
                          <Typography className='infoItemPlanDetail'>
                            {this.state.totalModels}
                          </Typography>
                        </Grid>
                        <Grid item sm={4} className='colmds'>
                          <h5 className='infoItemPlan'>
                            Uploaded Hosted Models
                          </h5>
                          <Typography className='infoItemPlanDetail'>
                            {this.state.usedModels}
                          </Typography>
                        </Grid>
                        <Grid item sm={4} className='colmds'>
                          <h5 className='infoItemPlan'>
                            Remaining Hosted Models
                          </h5>
                          <Typography className='infoItemPlanDetail'>
                            {this.state.remainingModels}
                          </Typography>
                        </Grid>

                        <Grid item sm={4} className='colmds'>
                          <h5 className='infoItemPlan'>Subscription Status</h5>
                          {this.state.subscriptionStatus === 'active' ? (
                            <Typography
                              className='infoItemPlanDetail'
                              style={{ color: 'rgb(4 21 234 / 86%)' }}
                            >
                              Active
                            </Typography>
                          ) : (
                            <>
                              <Typography
                                className='infoItemPlanDetail'
                                style={{ color: 'red' }}
                              >
                                Cancelled
                              </Typography>
                            </>
                          )}
                        </Grid>

                        <Grid item sm={4} className='colmds'>
                          <h5 className='infoItemPlan'>Renewal Date</h5>
                          <Typography className='infoItemPlanDetail'>
                            {dateAhead(this.state.noOfDaysLeft)}
                          </Typography>
                        </Grid>

                        <Grid item sm={4} className='colmds'>
                          <h5 className='infoItemPlan'>
                            Total{' '}
                            {this.state.prdStatus === 'monthly'
                              ? 'Monthly'
                              : 'Annual'}{' '}
                            Views Left
                          </h5>
                          <Typography className='infoItemPlanDetail'>
                            {this.state.noOfViewsLeft}
                          </Typography>
                        </Grid>

                        <Grid item sm={12}>
                          <h4>Card Details</h4>
                        </Grid>

                        <Grid item sm={4}>
                          <h5 className='infoItemPlan'>Card Number</h5>
                          <Typography className='infoItemPlanDetail'>
                            {this.state.cardNumber}
                          </Typography>
                        </Grid>
                        <Grid item sm={4}>
                          <h5 className='infoItemPlan'>Card Type</h5>
                          <Typography className='infoItemPlanDetail'>
                            {this.state.card_type}
                          </Typography>
                        </Grid>
                        <Grid item sm={4}>
                          <h5 className='infoItemPlan'>Customer Id</h5>
                          <Typography className='infoItemPlanDetail'>
                            {this.state.customer_id}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </Grid>
              </>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </Container>
    );
  }
}
