import React from 'react';

const MobileDrawer = ({ open, children, maxWidth = '85%' }) => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'rgba(0,0,0,0.5)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        zIndex: 99999999,
        transform: `translateX(${open ? 0 : '200vw'})`,
        transition: 'all 300ms',
      }}
    >
      <div
        style={{
          maxWidth: maxWidth,
          minWidth: maxWidth,
          flex: 1,
          // maxHeight: '100vh',
          // minHeight: '100vh',
          // position: 'fixed',
          // top: 0,
          // right: 0,
          // bottom: 0,
          background: 'white',
          zIndex: 99999999,
          overflowX: 'hidden',
          overflowY: 'auto',
          borderTopLeftRadius: 10,
          borderBottomLeftRadius: 10,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default MobileDrawer;
