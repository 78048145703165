import { useRef } from 'react';
import React from 'react';

import './annotBtn.css';
import icons8 from './icons8.png';
const AnnotationBtns = ({ annots, openHotspotDrawer }) => {
  return !annots?.length
    ? null
    : annots?.map(({ description, ...rest }, index) => (
        <AnnotBtn
          hotspot={rest}
          key={rest?._id || index}
          openHotspotDrawer={(cb) =>
            openHotspotDrawer && openHotspotDrawer({ description, ...rest }, cb)
          }
        />
      ));
};

const AnnotBtn = ({
  openHotspotDrawer,
  hotspot: { title, dataPosition, dataNormal, zoom = 12 },
}) => {
  const ref = useRef();

  const hideTitle = () => {
    ref.current.classList.remove('clicked');
  };

  const onClick = (e) => {
    ref.current.classList.add('clicked');
    openHotspotDrawer(hideTitle);
    e.stopPropagation();
  };
  return (
    <div
      ref={ref}
      data-position={dataPosition}
      data-normal={dataNormal}
      slot={`hotspot-${title}`}
      onClick={onClick}
      onMouseEnter={() => {
        ref.current.classList.add('show');
      }}
      onMouseLeave={() => {
        ref.current.classList.remove('show');
      }}
      className='annotBtnWrapper__XX'
      style={{
        position: 'absolute',
        transform: 'translate(-15px,-15px)',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        background: '#fff',
        borderRadius: '15.5px',
        boxShadow: `1px 2px 5px rgba(0, 0, 0, 0.1)`,
        maxHeight: 28,
      }}
    >
      <div
        style={{
          width: 28,
          height: 28,
          border: '1px solid #646464',
          borderRadius: '15.5px',
          display: 'inline-grid',
          placeItems: 'center',
          background: '#fff',
        }}
      >
        <div
          style={{
            width: 11,
            height: 11,
            borderRadius: '50%',
            backgroundColor: '#646464',
          }}
        />
      </div>
      <div className='annotBtnWrapper__XX_TITLE'>
        <div
          style={{
            lineHeight: 1,
          }}
        >
          {title}
        </div>
      </div>
    </div>
  );
};
export default AnnotationBtns;

export const NewAnnotPositionPlus = ({ dataPosition, dataNormal, title }) => {
  return (
    <img
      data-position={dataPosition}
      src={icons8}
      width='28'
      height='28'
      data-normal={dataNormal}
      slot={`hotspot-${title}`}
      style={{
        position: 'absolute',
        transform: 'translate(-15px,-15px)',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#fff',
        borderRadius: '50%',
        boxShadow: `1px 2px 5px rgba(0, 0, 0, 0.1)`,
        height: 28,
        width: 28,
        border: '0px solid red',
        fontSize: 20,
        fontWeight: 700,

        // lineHeight: 1,
        margin: 0,
        padding: 0,
      }}
    />
  );
};
