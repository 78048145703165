import { useAuth } from '@frontegg/react';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import AddProductToShare from './Components/AddProductToShare';
import CreateShare from './Components/CreateShare';
import Footer from './Components/Footer';
import ForgotPassword from './Components/ForgotPassword';
import FunctionalModalDynamicTemp from './Components/FunctionalModalDynamicTemp';
import Header from './Components/Header';
import MobileHomePage from './Components/MobileUI/MobileHomePage';
import ProductEditTemp from './Components/ProductEditTemp';
import ShareListing from './Components/ShareListing';
import Templates from './Components/Templates';
import { useMediaHook } from './Components/useMediaHook';
import { API } from './Constants/APIs';
import './global.css';
import About from './Screens/About';
import AllAnalytics from './Screens/AllAnalytics';
import BlogDetail from './Screens/BlogDetail';
import BlogSection from './Screens/BlogSection';
import Contact from './Screens/Contact';
import ContactFromProduct from './Screens/ContactFromProduct';
import Landing from './Screens/Landing';
import ModalFunctional from './Screens/ModalFunctional';
import ModelTemplate from './Screens/ModelTemplate';
import NotFoundPage from './Screens/NotFound';
import Payments from './Screens/Payments';
import PaymentSettings from './Screens/PaymentSettings';
import PaymentSucces from './Screens/PaymentSucces';
import Plans_and_pricing from './Screens/Plans_and_pricing';
import Pricing from './Screens/Pricing';
import PrivacyPolicy from './Screens/PrivacyPolicy';
import ProductAnalytics from './Screens/ProductAnalytics';
import ProductCreate from './Screens/ProductCreate';
import ProductEdit from './Screens/ProductEdit';
import ProductList from './Screens/ProductList';
import ResetPassword from './Screens/ResetPassword';
import Setting from './Screens/Setting';
import SignIn from './Screens/SignIn';
import SignUp from './Screens/SignUp';
import StaticFunctional from './Screens/StaticFunctional';
import TeknionFunctional from './Screens/TeknionFunctional';
import TermsOfService from './Screens/TermsOfService';

const theme = createTheme({
  typography: {
    fontFamily: "'Inter', sans-serif",
  },
});

export default function App({ isDevEnv, isTestEnv }) {
  const HOME_URL = isDevEnv
    ? `http://localhost:3000`
    : isTestEnv
      ? 'https://portal.actuality.live'
      : 'https://portal.actuality.live';

  const { user, isAuthenticated } = useAuth();
  const [authentication, setAuthState] = useState(false);

  const getInfo = (userData) => {
    if (userData && Object.keys(userData).length > 0) {
      let token = localStorage.getItem('token');
      if (token) {
        setAuthState(true);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      } else {
        let payload = {
          email: userData.email,
          name: userData.name,
          token: userData.accessToken,
          company_name: 'Company',
          fe_user_id: userData.id,
        };

        axios.post(API.postUrls.silentLogin, payload).then((resp) => {
          localStorage.setItem('token', resp.data.user.accessToken);
          localStorage.setItem('frontegg_id', userData.id);
          localStorage.setItem('userId', resp.data.user._id);
          axios.defaults.headers.common['Authorization'] =
            'Bearer ' + resp.data.user.accessToken;
          window.location.reload();
        });
      }
    }
  };

  const checkUser = () => {
    axios.get(API.getUrls.getUser).then((resp) => {
      if (resp.data.status === 'success') {
        if (
          resp.data.user.is_delete === 'yes' ||
          resp.data.user.status === 'draft'
        ) {
          localStorage.clear();
          window.location.href = `${HOME_URL}/account/logout`;
        }
      }
    });
  };

  useEffect(() => {
    if (isAuthenticated) {
      getInfo(user);
      checkUser();
    } else {
      window.location.href = `${HOME_URL}/account/login`;
    }
  });
  const isMdOrDown = useMediaHook();

  return (
    <MuiThemeProvider theme={theme}>
      <Routes>
        <Route
          path='modelDynamicviewer'
          element={<FunctionalModalDynamicTemp />}
        >
          <Route
            path=':id'
            element={(props) => <FunctionalModalDynamicTemp />}
          />
        </Route>

        {authentication ? (
          <>
            <Route
              path='userprofile'
              element={
                <>
                  <Header isAuthenticated={isAuthenticated} userData={user} />
                  <Setting isAuthenticated={isAuthenticated} userData={user} />
                  <Footer />
                </>
              }
            />
            <Route
              path='analytics'
              element={
                <>
                  <Header isAuthenticated={isAuthenticated} userData={user} />
                  <AllAnalytics
                    isAuthenticated={isAuthenticated}
                    userData={user}
                  />
                  <Footer />
                </>
              }
            />
            <Route
              path='/templates'
              element={
                <>
                  <Header isAuthenticated={isAuthenticated} userData={user} />
                  <Templates />
                  <Footer />
                </>
              }
            />
            <Route
              path='/sharelist'
              element={
                <>
                  <Header isAuthenticated={isAuthenticated} userData={user} />
                  <ShareListing />
                  <Footer />
                </>
              }
            />
            <Route
              path='/addToShare/:share_id'
              element={
                <>
                  <Header isAuthenticated={isAuthenticated} userData={user} />
                  <AddProductToShare isShare={true} />
                  <Footer />
                </>
              }
            />
            <Route path='/share' element={<CreateShare />} />
            <Route path='/share/:share_id' element={<CreateShare />} />
            <Route
              path='productanalytics/*'
              element={
                <>
                  <Header isAuthenticated={isAuthenticated} userData={user} />
                  <ProductAnalytics
                    isAuthenticated={isAuthenticated}
                    userData={user}
                  />
                  <Footer />
                </>
              }
            />
            <Route
              path='product-create'
              element={
                <>
                  <Header isAuthenticated={isAuthenticated} userData={user} />
                  <ProductCreate />
                  <Footer />
                </>
              }
            />
            {/* <Route path="productlist/*" element={<> <Header isAuthenticated={isAuthenticated} userData={user} /> <ProductList /> <Footer /> </>} /> */}
            <Route
              path='product-edit'
              element={
                <>
                  <Header isAuthenticated={isAuthenticated} userData={user} />
                  {/* <ProductEditWithVerify isEdit={true} isViewer={false} /> */}
                  <ProductEdit />
                  <Footer />
                </>
              }
            />
            <Route
              path='viewer/*'
              element={
                <>
                  <Header isAuthenticated={isAuthenticated} userData={user} />
                  {/* <ProductEditWithVerify isEdit={false} isViewer={true} /> */}
                  <ProductEditTemp />
                  {/* <Header isAuthenticated={isAuthenticated} userData={user} />
                <UnPublishModal />
                <Footer /> */}
                </>
              }
            />
            <Route
              path='payments'
              element={
                <>
                  <Header isAuthenticated={isAuthenticated} userData={user} />
                  <Payments />
                  <Footer />
                </>
              }
            />
            <Route
              path='pricing'
              element={
                <>
                  <Header isAuthenticated={isAuthenticated} userData={user} />
                  <Pricing />
                  <Footer />
                </>
              }
            />
            <Route
              path='payment_success'
              element={
                <>
                  <Header isAuthenticated={isAuthenticated} userData={user} />
                  <PaymentSucces />
                  <Footer />
                </>
              }
            />
            <Route
              path='settings'
              element={
                <>
                  <Header isAuthenticated={isAuthenticated} userData={user} />{' '}
                  <Setting /> <Footer />
                </>
              }
            />
            <Route
              path='modelviewer'
              element={
                <ModalFunctional
                  isAuthenticated={isAuthenticated}
                  userData={user}
                />
              }
            >
              <Route
                path=':id'
                element={(props) => (
                  <ModalFunctional
                    {...props}
                    isAuthenticated={isAuthenticated}
                    userData={user}
                  />
                )}
              />
            </Route>
            <Route path='/teknion/EA/' element={<StaticFunctional />}>
              <Route
                path=':id'
                element={(props) => <StaticFunctional {...props} />}
              />
            </Route>
            <Route path='/teknion_upstage/EA/' element={<TeknionFunctional />}>
              <Route
                path=':id'
                element={(props) => <TeknionFunctional {...props} />}
              />
            </Route>
            <Route
              path={'/*'}
              exact
              element={
                <>
                  {isMdOrDown ? (
                    <MobileHomePage />
                  ) : (
                    <>
                      <Header
                        isAuthenticated={isAuthenticated}
                        userData={user}
                      />
                      <ProductList />
                      <Footer />
                    </>
                  )}
                </>
              }
            />
            <Route
              path={'/admin='}
              exact
              element={
                <>
                  <Header isAuthenticated={isAuthenticated} userData={user} />
                  <ProductList /> <Footer />
                </>
              }
            />
            <Route
              path={'/demo'}
              exact
              element={
                <>
                  <Header isAuthenticated={isAuthenticated} userData={user} />
                  <Landing /> <Footer />
                </>
              }
            />
            <Route
              path={'sign-in'}
              exact
              element={
                <>
                  <Header isAuthenticated={isAuthenticated} userData={user} />
                  <SignIn />
                  <Footer />
                </>
              }
            />
            <Route
              path={'sign-up'}
              exact
              element={
                <>
                  <Header isAuthenticated={isAuthenticated} userData={user} />
                  <SignUp />
                  <Footer />
                </>
              }
            />
            <Route
              path={'contact-us'}
              exact
              element={
                <>
                  <Header isAuthenticated={isAuthenticated} userData={user} />
                  <Contact />
                  <Footer />
                </>
              }
            />
            <Route
              path={'Contact'}
              exact
              element={
                <>
                  <Header isAuthenticated={isAuthenticated} userData={user} />
                  <ContactFromProduct />
                  <Footer />
                </>
              }
            />
            <Route
              path={'forgot-password'}
              exact
              element={
                <>
                  <Header isAuthenticated={isAuthenticated} userData={user} />
                  <ForgotPassword />
                  <Footer />
                </>
              }
            />
            <Route
              path={'reset-password'}
              exact
              element={
                <>
                  <Header isAuthenticated={isAuthenticated} userData={user} />
                  <ResetPassword />
                  <Footer />
                </>
              }
            />
            <Route
              path={'about-us'}
              exact
              element={
                <>
                  <Header isAuthenticated={isAuthenticated} userData={user} />
                  <About />
                  <Footer />
                </>
              }
            />
            <Route
              path={'plans_and_pricing'}
              exact
              element={
                <>
                  <Header isAuthenticated={isAuthenticated} userData={user} />
                  <Plans_and_pricing />
                  <Footer />
                </>
              }
            />
            <Route
              path={'privacy'}
              exact
              element={
                <>
                  <Header isAuthenticated={isAuthenticated} userData={user} />
                  <PrivacyPolicy />
                  <Footer />
                </>
              }
            />
            <Route
              path={'terms'}
              exact
              element={
                <>
                  <Header isAuthenticated={isAuthenticated} userData={user} />
                  <TermsOfService />
                  <Footer />
                </>
              }
            />
            <Route
              path={'blogs'}
              exact
              element={
                <>
                  <Header isAuthenticated={isAuthenticated} userData={user} />
                  <BlogSection />
                  <Footer />
                </>
              }
            />
            <Route
              path={'blog-detail'}
              exact
              element={
                <>
                  <Header isAuthenticated={isAuthenticated} userData={user} />
                  <BlogDetail />
                  <Footer />
                </>
              }
            />
            <Route
              path={'modeltemplate'}
              exact
              element={
                <>
                  <Header isAuthenticated={isAuthenticated} userData={user} />
                  <ModelTemplate />
                  {/* <Footer /> */}
                </>
              }
            />
            <Route
              path={'payment-methods'}
              exact
              element={
                <>
                  <Header isAuthenticated={isAuthenticated} userData={user} />
                  <PaymentSettings />
                  <Footer />
                </>
              }
            />
            <Route path='/pageNotFound' element={NotFoundPage} />
          </>
        ) : (
          ''
        )}
      </Routes>
    </MuiThemeProvider>
  );
}
