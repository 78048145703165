import React, { Component } from 'react';
import {
  Grid,
  Button,
  Typography,
  Snackbar,
  CircularProgress,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { API } from '../Constants/APIs';
import axios from 'axios';
import { MultiSelect } from 'react-multi-select-component';
import Modal from 'react-awesome-modal';
import Resizer from 'react-image-file-resizer';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Passcode from '../Components/Passcode';
import PlacementRadio from '../Components/PlacementRadio';
import { scrollToTop } from '../Helpers/utils';

const __ASPECT__ = 1;
function gcd(a, b) {
  // return (b == 0) ? a : gcd (b, a%b);
  return 100 * Math.abs((a - b) / ((a + b) / 2));
}

function calculateRatio(num_1, num_2) {
  let num = 0;
  for (num = num_2; num > 1; num--) {
    if (num_1 % num == 0 && num_2 % num == 0) {
      num_1 = num_1 / num;
      num_2 = num_2 / num;
    }
  }
  var ratio = num_1 + ':' + num_2;
  return ratio;
}

const resizeFile = (file) =>
  new Promise((resolve) => {
    // console.log(file);
    Resizer.imageFileResizer(
      file,
      800,
      600,
      'JPEG',
      80,
      0,
      (uri) => {
        resolve(uri);
      },
      'base64'
    );
  });

export default class ProductCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      urlId: '',
      placement: 'floor',
      searching: false,
      categories: [],
      catIds: [],
      image: '',
      title: '',
      productId: 0,
      filename: '',
      data: {},
      productadded: false,
      imgWidth: 0,
      imgHeight: 0,
      link_url: '',
      link_title: '',
      link_description: '',
      uploadJPG: false,
      visibleCrop: false,
      src: null,
      crop: {
        aspect: 1,
        width: 160,
        // width: 80,
        unit: '%',
      },
      imgPreview: false,
      uploadModel: false,
      language: 'English',
    };
  }

  componentDidMount() {
    // if (init() === "success") {
    axios
      .get(API.getUrls.Categories + '?page=1&limit=10&status=active')
      .then((res) => {
        // console.log(res);
        res.data.categories.docs.forEach((item) => {
          this.state.categories.push({
            label: item.title,
            value: item._id,
          });
        });
        this.setState({
          categories: this.state.categories,
        });
      });
    // }
  }

  createProduct = (e) => {
    // console.log(this.state.language);
    e.preventDefault();
    // console.log(this.state.filename);
    if (this.state.filename === '') {
      this.setState({
        warning: true,
      });
    } else {
      if (this.state.catIds.length === 0) {
        this.setState({ warn1: true });
        scrollToTop();
      } else {
        this.setState({ loading: true });
        let data = new FormData();
        data.append('title', this.state.title);
        data.append('placement', this.state.placement);
        if (this.state.is_protected && !this.state?.passcode?.trim()?.length) {
          this.setState({
            warning: true,
          });
          return;
        }

        data.append('is_protected', !!this.state.is_protected);
        data.append(
          'passcode',
          this.state.is_protected ? this.state.passcode : ''
        );

        if (this.state.filename) {
          data.append('posterImage', this.state.filename);
        }
        this.state.catIds.forEach((cat) => {
          data.append('categories[]', cat.value);
        });
        data.append('language', this.state.language);

        axios
          .post(API.postUrls.addproduct, data)
          .then((resp) => {
            if (resp.data.status === 'success') {
              this.setState({
                notification: true,
                loading: true,
                successMsg: resp.data.message,
                productId: resp.data.product._id,
                data: resp.data.product,
                productadded: true,
              });
              setTimeout(() => {
                this.setState({ notification: false });
                window.location.href =
                  '/product-edit?id=' + resp.data.product._id;
              }, 2000);
            } else {
              this.setState({
                notification: true,
                successMsg: resp.data.message,
                loading: false,
              });
              setTimeout(() => {
                this.setState({ notification: false });
              }, 2000);
            }
          })
          .catch((err) => {
            this.setState({
              notification: true,
              successMsg: 'Server is busy, please try again!',
              loading: false,
            });
          });
        // }
      }
    }
  };

  handleFile = (e) => {
    // console.log(e.target.files[0]);
    this.setState({ [e.target.name]: e.target.files[0] }, () => {
      var fileUpload = document.getElementById('uploadFile');

      var regex = /(\.jpg|\.jpeg|\.png|\.PNG)$/i;
      if (regex.test(fileUpload.value.toLowerCase())) {
        if (typeof fileUpload.files != 'undefined') {
          var reader = new FileReader();
          var scope = this;
          reader.readAsDataURL(fileUpload.files[0]);
          reader.onload = function (e) {
            var image = new Image();

            image.src = e.target.result;

            image.onload = function () {
              var height = this.height;
              var width = this.width;
              scope.setState({
                visibleCrop: true,
              });

              // if (height > 600 || width > 800) {
              //   alert("Height and Width must not exceed 800px and 600px.");
              //   return false;
              // }
              scope.setState({ uploadJPG: true });
              return true;
            };
          };
        } else {
          alert('This browser does not support HTML5.');
          return false;
        }
      } else {
        var thisvar = this;
        alert('Please upload file having extensions .jpeg/.jpg/.png/ only.');
        thisvar.setState({ uploadJPG: false });
        return false;
      }
    });
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl });
      this.convertUrlToImageData(croppedImageUrl);
      // console.log(this.imageRef);
      // console.log(crop);
      // console.log(croppedImageUrl);
      // this.blobToFile(croppedImageUrl, "my-image.png");
    }
  }

  getBlobFromUrl = (myImageUrl) => {
    return new Promise((resolve, reject) => {
      let request = new XMLHttpRequest();
      request.open('GET', myImageUrl, true);
      request.responseType = 'blob';
      request.onload = () => {
        resolve(request.response);
      };
      request.onerror = reject;
      request.send();
    });
  };

  getDataFromBlob = (myBlob) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(myBlob);
    });
  };

  convertUrlToImageData = async (myImageUrl) => {
    try {
      let myBlob = await this.getBlobFromUrl(myImageUrl);
      // console.log(myBlob)
      let myImageData = await this.getDataFromBlob(myBlob);
      // console.log(myImageData)
      this.b64toBlob(myImageData);
      this.dataURLtoFile(myImageData, 'newfile.png');
    } catch (err) {
      // console.log(err);
      return null;
    }
  };

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    var blob = new File([u8arr], filename, { type: mime });
    // console.log(blob);
    this.setState({
      filename: blob,
    });
  }

  //Usage example:
  // var file = dataURLtoFile('data:text/plain;base64,aGVsbG8gd29ybGQ=','hello.txt');
  // console.log(file);

  blobToFile(theBlob, fileName) {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    // console.log(theBlob);
    // this.convertedFile(theBlob)
  }

  convertedFile(theBlob) {
    var myBlob = new Blob();

    //do stuff here to give the blob some data...

    var myFile = this.blobToFile(theBlob, 'my-image.png');
    // console.log(myFile);
  }

  b64toBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: 'image/jpeg' });
    // console.log(blob);
    this.blobToFile(blob, 'my-image.png');
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            // console.error("Canvas is empty");
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve(this.fileUrl);
        },
        'image/png',
        1
      );
    });
  }

  onSelectFile = (e) => {
    // console.log("hiii");
    const target = e.target;
    if (!target.files?.length) return;
    this.setState({
      filename: '',
      src: '',
    });
    // console.log(this.state.visibleCrop);
    // console.log(target.files);
    // console.log(target.files.length);
    if (target.files && target.files.length > 0) {
      this.setState(
        {
          visibleCrop: true,
        },
        () => {
          // console.log(this.state.visibleCrop);
        }
      );
      const reader = new FileReader();
      // console.log(reader);
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result }, () => {
          // console.log(this.state.src);
        })
      );
      // console.log(this.state.src);
      reader.readAsDataURL(target.files[0]);
    }
  };

  handleInput = (e) => {
    // console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  toggleIsProtected = () =>
    this.setState((pre) => ({ ...pre, is_protected: !!!pre?.is_protected }));

  render() {
    const { crop, croppedImageUrl, src } = this.state;
    return (
      <div className='productUpload'>
        {this.state.notification ? (
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={true}
            style={{ bottom: '40px', textTransform: 'capitalize' }}
            color={'#bdbdbd'}
            autoHideDuration={6000}
            message={this.state.successMsg}
          ></Snackbar>
        ) : null}
        <Grid
          container
          spacing={2}
          className='PreeditWrapper'
          style={{ marginTop: '25px', border: '0px solid red' }}
        >
          {this.state.uploadModel ? (
            <>
              <Grid item sm={3}></Grid>
              <Grid item sm={6} style={{ padding: '50px' }}>
                <Typography className='addIcon'>+</Typography>
                {this.state.productadded ? (
                  <Link
                    to={{
                      pathname: '/product-edit' + '?id=' + this.state.productId,
                    }}
                    style={{ color: 'white', textDecoration: 'none' }}
                  >
                    <Button
                      onClick={() => {
                        this.go_ToEdit();
                      }}
                      className='uploadBtn'
                      disabled={this.state.productadded ? true : false}
                      style={{
                        cursor: this.state.productadded
                          ? 'pointer'
                          : 'not-allowed',
                      }}
                    >
                      Upload 3D files
                    </Button>
                  </Link>
                ) : (
                  <Button
                    className='uploadBtn'
                    disabled={this.state.productadded ? true : false}
                    style={{
                      cursor: this.state.productadded
                        ? 'pointer'
                        : 'not-allowed',
                    }}
                  >
                    Upload 3D files
                  </Button>
                )}

                <Typography className='acceptedText'>
                  Accepted file types:.glb
                </Typography>
                <p className='normalText'>
                  Use this option if you have 3D files in .glb format for all
                  the
                  <br /> varients.
                </p>

                <div style={{ position: 'relative', marginBottom: '35px' }}>
                  <hr className='hrLine' />
                  <Typography className='OrText'>OR</Typography>
                </div>
                {this.state.productadded ? (
                  <Link
                    to={{
                      pathname: '/Contact',
                      state: { id: this.state.productId },
                    }}
                    style={{ color: 'white', textDecoration: 'none' }}
                  >
                    {' '}
                    <Button
                      className='uploadBtn'
                      disabled={this.state.productadded ? true : false}
                      style={{
                        cursor: this.state.productadded
                          ? 'pointer'
                          : 'not-allowed',
                      }}
                    >
                      Contact Us
                    </Button>
                  </Link>
                ) : (
                  <Button
                    className='uploadBtn'
                    disabled={this.state.productadded ? true : false}
                    style={{
                      cursor: this.state.productadded
                        ? 'pointer'
                        : 'not-allowed',
                    }}
                  >
                    Contact Us
                  </Button>
                )}

                <Typography
                  className='normalText'
                  style={{ borderBottom: 'none' }}
                >
                  If you do not have 3D files in .glb format, please contact our
                  digital <br /> asset creation team and we will get back to you
                  within one <br /> business day
                </Typography>
              </Grid>
              <Grid item sm={3}></Grid>
            </>
          ) : (
            <>
              <Grid item sm={4}></Grid>
              <Grid item sm={4}>
                <div className='editRightWrapper'>
                  <form
                    action=''
                    id='ClearForm'
                    name='ClearForm'
                    onSubmit={this.createProduct}
                    style={{
                      // border: "1px solid black",
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <div
                      className='box1 '
                      style={{
                        // border: "1px solid red",
                        paddingRight: 10,
                      }}
                    >
                      <div
                        className='inlineWrapper'
                        style={{
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        <Typography
                          className='buyNow'
                          style={{
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          Product Name
                        </Typography>
                      </div>
                      <input
                        className='Input'
                        name='title'
                        required
                        style={{
                          margin: 0,
                          // paddingTop: 0,
                          // width: "100%",
                          marginTop: 10,
                        }}
                        onChange={this.handleInput}
                      />
                      <div
                        className='inlineWrapper'
                        style={{
                          margin: 0,
                          padding: 0,
                          marginTop: 10,
                          marginBottom: 10,
                          // border: "1px solid blue",
                        }}
                      >
                        <Typography
                          className='buyNow'
                          style={{
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          Categories
                        </Typography>
                      </div>
                      <MultiSelect
                        options={this.state.categories}
                        value={this.state.catIds}
                        onChange={(data) => {
                          this.setState({ catIds: data }, () => {});
                        }}
                        className='InputSelect fullWidth'
                        labelledBy={'Categories'}
                      />
                      {!this.state.catIds.length > 0 ? (
                        <div>
                          {this.state.warn1 ? (
                            <span style={{ color: 'red' }}>*required </span>
                          ) : null}
                        </div>
                      ) : null}
                      <div
                        className='inlineWrapper'
                        style={{
                          margin: 0,
                          padding: 0,
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      >
                        <Typography
                          className='buyNow'
                          style={{ padding: 0, margin: 0 }}
                        >
                          Select Language
                        </Typography>
                      </div>
                      <select
                        name='language'
                        id=''
                        className='Input'
                        style={{ width: '100%', margin: 0, paddingRight: 10 }}
                        onChange={this.handleInput}
                      >
                        <option value='English'>English</option>
                        <option value='French'>French</option>
                      </select>
                      <div
                        className='inlineWrapper'
                        style={{
                          padding: 0,
                          margin: 0,
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      >
                        <Typography
                          className='buyNow'
                          style={{ padding: 0, margin: 0 }}
                        >
                          Upload Documents
                        </Typography>
                      </div>
                      <Button
                        variant='outlined'
                        size='medium'
                        className='browseBtn'
                        component='label'
                        style={{
                          border: '1px solid #ddd',
                          width: '100%',
                          background: '#ebebeb',
                          textTransform: 'capitalize',
                          cursor: 'pointer',
                          // padding: 0,
                          margin: 0,
                        }}
                      >
                        Upload Image
                        {/* <AspectRatio ratio="4/3" style={{ maxWidth: '100px' }}> */}
                        <input
                          type='file'
                          // onChange={this.handleFile}
                          onChange={this.onSelectFile}
                          name='filename'
                          hidden
                          accept='image/*'
                          id='uploadFile'
                        />
                        {/* </AspectRatio> */}
                      </Button>
                      {!this.state.uploadJPG ? (
                        <p style={{ fontSize: '13px' }}>
                          {/* Accepted file types .jpg ,.jpeg, .png and size 182 x 137 */}
                          Accepted file types .jpg ,.jpeg, .png and size 800 x
                          600 px
                        </p>
                      ) : null}
                      {this.state.imgPreview
                        ? croppedImageUrl && (
                            <img
                              alt='Crop'
                              style={{ width: '100%', position: 'relative' }}
                              src={croppedImageUrl}
                            />
                          )
                        : ''}
                      <PlacementRadio
                        propState={this.state}
                        onChange={(placement) => this.setState({ placement })}
                      />
                      <Passcode
                        isProtected={!!this.state.is_protected}
                        toggleIsProtected={() =>
                          this.setState((pre) => ({
                            ...pre,
                            is_protected: !!!pre?.is_protected,
                          }))
                        }
                        passcode={this.state.passcode || ''}
                        setPasscode={(passcode) => this.setState({ passcode })}
                      />
                    </div>

                    {!this.state.filename ? (
                      <Typography
                        className='textbelowInput'
                        style={{ color: 'red' }}
                      >
                        {this.state.warning
                          ? '* Upload image for product'
                          : null}
                      </Typography>
                    ) : (
                      <Typography className='textbelowInput'>
                        {this.state.filename && this.state.uploadJPG
                          ? this.state.filename.name
                          : null}
                      </Typography>
                    )}
                    <div
                      style={{
                        // border: "1px solid red",
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {this.state.loading ? (
                        <Button
                          className='saveBtn'
                          type='submit'
                          style={{
                            // border: "1px solid aqua",
                            marginLeft: 0,
                            position: 'static',
                            top: 'auto',
                            left: 'auto',
                            right: 'auto',
                            bottom: 'auto',
                          }}
                        >
                          <CircularProgress size={32} color='white' />
                        </Button>
                      ) : (
                        <Button
                          className='saveBtn'
                          type='submit'
                          style={{
                            marginLeft: 0,
                            // border: "1px solid blue",
                            position: 'static',
                            top: 'auto',
                            left: 'auto',
                            right: 'auto',
                            bottom: 'auto',
                          }}
                        >
                          Continue
                        </Button>
                      )}
                    </div>
                  </form>
                  {window.innerWidth < 1100 ? (
                    <Modal
                      visible={this.state.visibleCrop}
                      width='700'
                      minHeight='1020'
                      effect='fadeInUp'
                      onClickAway={() => this.setState({ visibleCrop: false })}
                    >
                      <Grid
                        container
                        spacing={2}
                        className='PreeditWrapper'
                        style={{ marginTop: '25px' }}
                      >
                        <Grid item sm={12} style={{ padding: '20px' }}>
                          {src && (
                            <ReactCrop
                              aspect={__ASPECT__}
                              keepSelection={true}
                              src={src}
                              crop={crop}
                              ruleOfThirds
                              onImageLoaded={this.onImageLoaded}
                              onComplete={this.onCropComplete}
                              onChange={this.onCropChange}
                            />
                          )}
                        </Grid>
                        {/* <Grid item sm={6} style={{padding: "20px"}}>
                      {croppedImageUrl && (
                        <img alt="Crop" style={{ width: "100%", height:"430px", position: "relative", top: "60px" }} src={croppedImageUrl} />
                      )}
                    </Grid> */}
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        className='PreeditWrapper'
                        style={{ marginTop: '25px' }}
                      >
                        <Grid item sm={6} style={{ padding: '20px' }}></Grid>
                        <Grid item sm={6} style={{ padding: '20px' }}>
                          <span>
                            <button
                              className='saveBtnCrop'
                              onClick={() => {
                                this.setState(
                                  {
                                    visibleCrop: false,
                                    imgPreview: true,
                                  },
                                  () => {
                                    // console.log(this.state.visibleCrop);
                                  }
                                );
                              }}
                            >
                              Save
                            </button>
                          </span>

                          <span>
                            <button
                              className='cancelBtnCrop'
                              onClick={() => {
                                this.setState(
                                  {
                                    visibleCrop: false,
                                    imgPreview: false,
                                  },
                                  () => {
                                    // console.log(this.state.visibleCrop);
                                  }
                                );
                              }}
                            >
                              Cancel
                            </button>
                          </span>
                        </Grid>
                      </Grid>
                    </Modal>
                  ) : (
                    <Modal
                      visible={this.state.visibleCrop}
                      // width="700"
                      width='1100'
                      height='650'
                      effect='fadeInUp'
                      onClickAway={() => this.setState({ visibleCrop: false })}
                    >
                      <Grid
                        container
                        spacing={2}
                        className='PreeditWrapper'
                        style={{ marginTop: '25px' }}
                      >
                        <Grid
                          item
                          sm={12}
                          style={{
                            padding: '20px',
                            // border: "1px solid red",
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          {src && (
                            <ReactCrop
                              aspect={__ASPECT__}
                              keepSelection={true}
                              src={src}
                              crop={crop}
                              ruleOfThirds
                              onImageLoaded={this.onImageLoaded}
                              onComplete={this.onCropComplete}
                              onChange={this.onCropChange}
                            />
                          )}
                        </Grid>
                        {/* <Grid item sm={6} style={{padding: "20px"}}>
                      {croppedImageUrl && (
                        <img alt="Crop" style={{ width: "100%", height:"430px", position: "relative", top: "60px" }} src={croppedImageUrl} />
                      )}
                    </Grid> */}
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        className='PreeditWrapper'
                        style={{ marginTop: '25px' }}
                      >
                        {/* <Grid item sm={6} style={{padding: "20px"}}></Grid> */}
                        <Grid
                          item
                          sm={12}
                          style={{
                            padding: '20px',
                            textAlign: 'center',
                            marginRight: '170px',
                          }}
                        >
                          <span>
                            {/* <button style={{cursor:"pointer"}} className="saveBtnCrop" onClick={() => {
                          this.setState({
                            visibleCrop: false,
                            imgPreview: true
                          })
                        }}>Save</button> */}
                            <button
                              className='saveCroppedImg'
                              onClick={() => {
                                this.setState(
                                  {
                                    visibleCrop: false,
                                    imgPreview: true,
                                  },
                                  () => {
                                    // console.log(this.state.visibleCrop);
                                  }
                                );
                              }}
                            >
                              Save
                            </button>
                          </span>
                          <span>
                            {/* <button className="cancelBtnCrop" 
                        onClick={() => {
                          this.setState({
                            visibleCrop: false,
                            imgPreview: false
                          }, () => {
                            // console.log(this.state.visibleCrop);
                          })
                        }}>Cancel</button> */}
                            <button
                              className='cancelCroppedImg'
                              onClick={() => {
                                this.setState(
                                  {
                                    visibleCrop: false,
                                    imgPreview: false,
                                  },
                                  () => {
                                    // console.log(this.state.visibleCrop);
                                  }
                                );
                              }}
                            >
                              Cancel
                            </button>
                          </span>
                        </Grid>
                      </Grid>
                    </Modal>
                  )}
                </div>
              </Grid>
              <Grid item sm={4}></Grid>
            </>
          )}
        </Grid>
      </div>
    );
  }
}
