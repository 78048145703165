// export const domain = "http://192.168.1.26:3000/";
// export const domain = "https://core.actuality.live/";
export const domain = false
  ? 'http://localhost:4000/'
  : 'https://core.actuality.live/';
export const API = {
  // imgUrl: "http://192.168.1.26:3000",
  // imgUrls: "http://192.168.1.26:3000/",
  // imgUrl: "https://core.actuality.live",
  // imgUrls: "https://core.actuality.live/",
  imgUrls: 'https://core.actuality.live/',
  imgUrl: 'https://core.actuality.live',
  getUrls: {
    models_for_actuality: domain + 'api/products/admin/models_for_actuality',
    getPlans: domain + 'api/order/user',
    getPlansNew: domain + 'api/subscribe/user',
    blogs: domain + 'api/blogs',
    services: domain + 'api/services',
    Categories: domain + 'api/categories',
    getComponents: domain + 'api/components/',
    getMaterials: domain + 'api/materials',
    getUser: domain + 'api/auth/user',
    getProducts: domain + 'api/products',
    getSingleProduct: domain + 'api/products/',
    canPublishProduct: domain + 'api/products/canPublishProduct',
    productMeta: domain + 'api/auth/product_meta',
    variants: domain + 'api/variants',
    getUserCat: domain + 'api/products/user/categories',
    getSocials: domain + 'api/socials',
    getAboutUs: domain + 'api/aboutus',
    getPrivacy: domain + 'api/privacy',
    getTerms: domain + 'api/terms',
    getPlansPricing: domain + 'api/pricing',
    checkProductStatus: domain + 'api/subscribe/currentPlanStatus',

    // usre analytics apis
    user_analysis_views: domain + 'api/analytics/user_analysis/views',
    click_user_analysis: domain + 'api/analytics/user_analysis/clicks',
    view_by_top_user_analysis:
      domain + 'api/analytics/user_analysis/view_by_top',
    view_by_platform_user_analysis:
      domain + 'api/analytics/user_analysis/view_by_platform',
    getAllAnalysis: domain + 'api/analytics',

    // for product analysis
    product_analysis_for_views:
      domain + 'api/analytics/product_analysis/views/',
    product_analysis_for_clicks:
      domain + 'api/analytics/product_analysis/clicks/',
    product_analysis_view_top:
      domain + 'api/analytics/product_analysis/view_by_top/',
    product_analysis_view_platform:
      domain + 'api/analytics/product_analysis/view_by_platform/',
    getAll_product_analysis: domain + 'api/analytics/product_analysis/',
    share_products: domain + 'api/share_products',
    model_suggestions: domain + 'api/model_suggestion',
  },
  postUrls: {
    share_products: domain + 'api/share_products',
    duplicateproduct: domain + 'api/duplicateproduct/',
    templateProducts: domain + 'api/templateProducts/',
    removeProductLogo: domain + 'api/products/removeProductLogo/',
    removeCompanyLogo: domain + 'api/auth/removeCompanyLogo',
    chargebeePortal: domain + 'api/portal',
    changeStatus: domain + 'api/status/',
    contact: domain + 'api/contacts',
    login: domain + 'api/auth/signin',
    signUp: domain + 'api/auth/signup',
    addproduct: domain + 'api/products',
    addComponents: domain + 'api/components',
    addMaterial: domain + 'api/materials',
    addVariants: domain + 'api/variants',
    addHotspot: domain + 'api/variants/hotspots/',
    googleLogin: domain + 'api/auth/social_login',
    updateProfile: domain + 'api/auth/update_profile',
    postViews: domain + 'api/products/product_views',
    deleteAllVariants: domain + 'api/products/delete_all_variant',
    updateProduct: domain + 'api/products/',
    chargebeePayment: domain + 'api/payment',
    order: domain + 'api/order',
    chargebeePaymentNew: domain + 'api/chargebeePayment',
    orderNew: domain + 'api/subscribe',
    silentLogin: domain + 'api/auth/silent_login',
    websiteViews: domain + 'api/subscribe/websiteViews',
    exportAnalytics: domain + 'api/analytics/exportExcel',
    productExportAnalytics: domain + 'api/analytics/exportExcelByProd/',
    saveFile: domain + 'api/saveFile',
    variantFromSuggestion: domain + 'api/variants/suggestedModel/'
  },
};
