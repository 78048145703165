import React, { useEffect, useState } from "react";

import { getTextWidth } from "../../Helpers/utils";

const BreakFormattedStrings = ({ material_name, fontSize, maxWidth }) => {
  const [widthsOfWords, setWidthOfWords] = useState([]);
  useEffect(() => {
    if (material_name)
      setWidthOfWords(
        material_name
          .split(" ")
          .map((v) => ({ width: getTextWidth(fontSize, v), value: v }))
      );
  }, [material_name]);
  return (
    <>
      {widthsOfWords?.map((v, i) => (
        <React.Fragment key={v.value}>
          {i != 0 && v.width > maxWidth ? <br /> : null}
          <span
            style={{
              wordBreak: v.width > maxWidth ? "break-all" : "normal",
            }}
          >
            {v.value} {` `}
          </span>
          {i != 0 &&
          v.width > maxWidth &&
          widthsOfWords[i + 1]?.width > maxWidth ? null : v.width > maxWidth ? (
            <br />
          ) : null}
        </React.Fragment>
      ))}
    </>
  );
};

export default BreakFormattedStrings;
