import { AppBar, Button, Toolbar, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import UserAuth from '../Helpers/UserAuth';
import init from '../Helpers/Windotoken';
import logo from '../Assets/Images/actualityLogo.png';
import axios from 'axios';
import { API } from '../Constants/APIs';
import { AdminPortal } from '@frontegg/react';
import ShareUser from './ShareUser';
class Header extends Component {
  constructor(props) {
    super();

    this.state = {
      userToken: '',
      userDetail: props.userData,
      checkAuth: props.checkAuth,
    };
  }

  componentDidMount() {
    let Token = localStorage.getItem('token');
    this.setState(
      {
        userToken: Token,
      },
      () => { }
    );
  }

  gotoHomeLink = () => {
    window.location.href = 'https://actuality.live/';
  };
  gotoContact = () => {
    window.location.href = '/contact-us';
  };
  gotoBottom = () => {
    let body = document.body,
      html = document.documentElement;

    const height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    window.scroll({ top: height, left: 0, behavior: 'smooth' });
  };

  handleProfile = () => {
    AdminPortal.show();
  };

  render() {
    return (
      <div>
        {/* {
          this.props.isAuthenticated ? 
          <UserAuth getInfo={this.getInfo}/>
          : ""
        } */}
        <AppBar position='static' className='bgTheme navbar' elevation={0}>
          <Toolbar style={{ justifyContent: 'space-between' }}>
            {/* <a href="http://localhost:3001/" target="_blank" style={{textDecoration:"none"}}> */}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography
                variant='h3'
                className='bolder'
                style={{}}
              // onClick={() => this.gotoHomeLink()}
              >
                <span>
                  <a
                    href='https://actuality.live/'
                    target='_blank'
                    style={{ textDecoration: 'none' }}
                  >
                    {' '}
                    <img
                      src={logo}
                      alt=''
                      width='33%'
                      style={
                        {
                          // border: "1px solid red",
                        }
                      }
                    />
                  </a>
                </span>
                {/* Actuality */}
              </Typography>
            </div>
            <ul
              className={
                window.innerWidth > 1100 ? 'listItems' : 'listItemsResp'
              }
            >
              <li style={{ paddingRight: '30px' }}>
                {this.props.isAuthenticated ? (
                  <a
                    href='https://calendly.com/rishabh_gupta/30min'
                    target='_blank'
                    style={{
                      textDecoration: 'none',
                      color: '#666666',
                      cursor: 'pointer',
                    }}
                  >
                    <Typography
                      variant='h6'
                      className='DemoText'
                    // onClick={() => this.gotoHomeLink()}
                    >
                      Demo
                    </Typography>
                  </a>
                ) : (
                  <Typography
                    variant='h6'
                    className='DemoText'
                    onClick={() => this.gotoContact()}
                  >
                    Contact
                  </Typography>
                )}
              </li>
              {this.props.isAuthenticated ? (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {/* <ShareUser /> */}
                  <NavLink
                    to='/userprofile'
                    activeClassName='selected'
                    style={{ textDecoration: 'none' }}
                  >
                    <li style={{ paddingRight: '30px' }}>
                      <Typography
                        variant='h6'
                        className='DemoText'
                        onClick={this.handleProfile}
                      >
                        Profile
                      </Typography>
                    </li>
                  </NavLink>
                  {/* <li>
                  <Typography variant="h6" className="DemoText" onClick={this.handleProfile}>
                    Profile
                  </Typography>
                </li> */}
                  {/* <ShareUser /> */}

                  <NavLink
                    // to="/productlist/allProducts"
                    to='/'
                    activeClassName='selected'
                    style={{ textDecoration: 'none' }}
                  >
                    <li style={{ paddingRight: '30px' }}>
                      <Typography variant='h6' className='DemoText'>
                        Dashboard
                      </Typography>
                    </li>
                  </NavLink>
                </div>
              ) : (
                <NavLink
                  to='/account/login'
                  // to="/sign-in"
                  activeClassName='selected'
                  style={{ textDecoration: 'none' }}
                >
                  <li style={{ paddingRight: '30px' }}>
                    <Typography variant='h6' className='DemoText'>
                      Sign In
                    </Typography>
                  </li>
                </NavLink>
              )}
            </ul>
            {this.props.isAuthenticated ? (
              <Link to='/contact-us' style={{ textDecoration: 'none' }}>
                <Button
                  size={'large'}
                  variant='contained'
                  className='themeBtn txtShadow rounded'
                >
                  Contact
                </Button>
              </Link>
            ) : (
              <a
                href='https://calendly.com/rishabh_gupta/30min'
                target='_blank'
                style={{ textDecoration: 'none' }}
              >
                <Button
                  size={'large'}
                  variant='contained'
                  className='themeBtn txtShadow rounded'
                >
                  Book Demo
                </Button>
              </a>
            )}
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default Header;
