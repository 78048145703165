import React from 'react';
import Links from './Links';
import { Container } from '@material-ui/core';

const Footer = ({ shareProduct, setShareProduct, handleSubmit }) => {
  return (
    <Container
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 2,
        // padding: 20,
        border: '0px solid red',
        // padding: "3rem 11rem 0px",
        background: '#fff',
      }}
      maxWidth={'lg'}
    >
      <Links
        shareProduct={shareProduct}
        setShareProduct={setShareProduct}
        handleSubmit={handleSubmit}
      />
      <Logo />
    </Container>
  );
};

const Logo = ({ }) => (
  <div
    style={{
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <a
      style={{
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textDecoration: 'none',
        color: '#646464',
        fontSize: 20,
        fontWeight: 400,
      }}
      href='https://actuality.live'
      target='_blank'
    >
      <span>powered by </span>
      <span
        style={{
          fontWeight: 500,
          color: '#000',
          marginLeft: 5,
        }}
      >
        actuality.live{' '}
      </span>
    </a>
  </div>
);
export default Footer;
