import React, { Component } from 'react';
import axios from 'axios';
import { API } from '../Constants/APIs';
import { Container, Grid, Typography } from '@material-ui/core';
import renderHTML from 'react-render-html';
import ModelCard from '../Components/ModelCard';
import modelImg1 from '../Assets/Images/modelImg1.png';
import modelImg2 from '../Assets/Images/modelImg2.png';
import modelImg3 from '../Assets/Images/modelImg3.png';
import { Link } from 'react-router-dom';

class BlogDetail extends Component {
  state = {
    blogs: {},
    description: '',
    short_description: '',
    embeded_link: '',
    blogss: [],
    proId: '',
    models: [
      {
        title: 'Retro Television from Herman Miller',
        img_src: modelImg1,
      },
      {
        title: 'Kitchen Furniture by ',
        img_src: modelImg2,
      },
      {
        title: 'Gold Lamp from Herman Miller',
        img_src: modelImg3,
      },

      {
        title: 'Kitchen Furniture by ',
        img_src: modelImg2,
      },
      {
        title: 'Retro Television from Herman Miller',
        img_src: modelImg1,
      },
      {
        title: 'Gold Lamp from Herman Miller',
        img_src: modelImg3,
      },
      {
        title: 'Gold Lamp from Herman Miller',
        img_src: modelImg3,
      },

      {
        title: 'Kitchen Furniture by ',
        img_src: modelImg2,
      },
      {
        title: 'Retro Television from Herman Miller',
        img_src: modelImg1,
      },
    ],
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    const blogId = document.location.href;
    this.setState({
      proId: blogId,
    });
    var url = new URL(blogId);
    var blogIds = url.searchParams.get('id');
    axios.get(API.getUrls.blogs + '/' + blogIds).then((res) => {
      // console.log(res);
      this.setState({
        blogs: res.data.blog,
        description: res.data.blog.description,
        short_description: res.data.blog.short_description,
        embeded_link: res.data.blog.embeded_link,
      });
    });
    this.fetchModels();
    axios
      .get(API.getUrls.blogs + '?page=1&limit=10&status=active')
      .then((res) => {
        // console.log(res);
        this.setState({
          blogss: res.data.docs,
        });
      });
  }

  gotoblog_detail = () => {
    window.location.href = '/blog-detail?id=' + this.state.proId;
  };

  fetchModels = () => {
    axios.get('/SingleModel.json').then((res) => {
      this.setState({ models: res.data.models });
    });
  };

  render() {
    return (
      <>
        <Container
          className='containerSpacingdashBoard blog_detail'
          maxWidth={'lg'}
        >
          <Typography
            variant={'h4'}
            className={window.innerWidth < 1100 ? 'blogHeadResp' : 'blogHead'}
          >
            <span style={{ fontWeight: '300' }}>{this.state.blogs.title}</span>{' '}
            <span>{this.state.blogs.sub_title}</span>{' '}
          </Typography>
          <p className={window.innerWidth < 1100 ? 'paraBlog1100' : 'paraBlog'}>
            {renderHTML(this.state.short_description)}
          </p>

          {/* <iframe src={this.state.blogs.embeded_link} frameborder="0"></iframe> */}
          {/* <Typography variant={"p"} className="blogHead">
          {this.state.blogs.short_description}
        </Typography> */}
          {/* <Grid container spacing={2}>
          <Grid item sm={4}>
            <img
              src={API.imgUrl + this.state.blogs.blog_img_url}
            //   width="200px"
            //   height="200px"
              alt=""
              className="blogImaage"
            />
          </Grid>
          <Grid item sm={8}>
            <Typography className="descript">{renderHTML(this.state.description)}</Typography>
          </Grid>
        </Grid> */}
        </Container>
        <Container className='containerSpacingdashBoard'>
          <iframe
            src={this.state.embeded_link}
            // src="https://unsplash.com/photos/2L-0vnCnzcU"
            title='some'
            frameborder='0'
            style={{
              minHeight: '728px',
              width: '100%',
              alignSelf: 'center',
              // marginLeft: "5%",
              borderRadius: '20px',
            }}
          ></iframe>
        </Container>
        <Container
          className='containerSpacingdashBoard blog_detail'
          maxWidth={'lg'}
        >
          <p
            className={
              window.innerWidth < 1100
                ? 'paraBlogDetailDesc1100'
                : 'paraBlogDetailDesc'
            }
          >
            {renderHTML(this.state.description)}
          </p>

          <section
            className='sectionInnerSpacingAbout1'
            style={{ marginLeft: window.innerWidth < 1100 ? '4%' : '0' }}
          >
            <Typography
              align={'center'}
              variant={'h5'}
              className={'bolder verticalSpacing browseHead'}
            >
              Learn more about XR on our blog.
            </Typography>

            {/* <Container className="containerSpacing" maxWidth={"lg"}> */}
            <Grid container spacing={3} style={{ marginTop: '25px' }}>
              {this.state.blogss.slice(0, 3).map((blog) => (
                <Grid
                  item
                  sm={4}
                  className='blogCard'
                  onClick={() =>
                    (window.location.href = '/blog-detail?id=' + blog._id)
                  }
                >
                  {/* <Link
                    to={{
                      pathname: "/blog-detail?id=" + blog._id,
                      state: { proId: blog._id },
                    }}> */}
                  <div className='imgBackground'>
                    <div
                      className={
                        window.innerWidth < 1100
                          ? 'imgWrapperOneResp'
                          : 'imgWrapperOne'
                      }
                    >
                      <img
                        src={API.imgUrl + blog.blog_img_url}
                        style={{
                          height: '275px',
                          width: '383px',
                          borderRadius: '26px',
                        }}
                        // width="100%"
                        alt=''
                      />
                    </div>
                  </div>
                  {/* </Link> */}
                  <Typography
                    className='modelTitleAbout bolder'
                    style={{
                      paddingTop: '20px',
                      textAlign: 'left',
                      paddingLeft: '5px',
                      fontSize: '24px',
                    }}
                  >
                    {blog.title + ' ' + blog.sub_title}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            {/* </Container> */}
          </section>

          <section
            className='sectionInnerSpacingAbout2'
            style={{ marginLeft: window.innerWidth < 1100 ? '5%' : '0' }}
          >
            <Typography
              align={'center'}
              variant={'h5'}
              className={'bolder verticalSpacing browseHead'}
            >
              Browse some of our models in your space
            </Typography>
            {/* <Container className="containerSpacing" maxWidth={"lg"}> */}
            <Grid container spacing={3}>
              {this.state.models.slice(0, 3).map((ele, index) =>
                ele.is_visible_in_landing ? (
                  <Grid item sm={4} key={index}>
                    <ModelCard
                      imgSrc={ele.poster_path ? ele.poster_path : modelImg1}
                      id={ele.id}
                      title={ele.title}
                    />
                  </Grid>
                ) : null
              )}
            </Grid>

            {/* </Container> */}
          </section>
        </Container>
      </>
    );
  }
}

export default BlogDetail;
