import {
  Button,
  CircularProgress,
  Snackbar,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import React, { Component } from 'react';
import GoogleLogin from 'react-google-login';
import MicrosoftLogin from 'react-microsoft-login';
import { Link } from 'react-router-dom';
import google from '../Assets/Images/search.png';
import { API } from '../Constants/APIs';

export default class SignUp extends Component {
  state = {
    email: '',
    password: '',
    name: '',
    loading: false,
    errorMsg: '',
  };
  handlechange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  register = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    let payload = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
    };
    axios.post(API.postUrls.signUp, payload).then((resp) => {
      if (resp.data.status === 'success') {
        this.setState({
          loading: false,
          successLoading: true,
          successMsg: resp.data.message,
        });
        setTimeout(() => {
          this.setState({
            successLoading: false,
          });
          // window.location.href = "/sign-in";
          window.location.href = '/account/login';
        }, 2000);
      } else {
        this.setState({
          loading: false,

          errorLoading: true,
          errorMsg: resp.data.message,
        });
        setTimeout(() => {
          this.setState({
            errorLoading: false,
          });
          document.getElementById('myForm').reset();
        }, 2000);
      }
    });
  };
  responseGoogle = (response) => {
    window.email = response.profileObj ? response.profileObj.email : null;
    window.name = response.profileObj ? response.profileObj.name : null;
    window.social_token = response.profileObj
      ? response.profileObj.googleId
      : null;
    let LoginLoad = {
      email: response.profileObj ? response.profileObj.email : null,
      name: response.profileObj ? response.profileObj.name : null,
    };
    axios.post(API.postUrls.googleLogin, LoginLoad).then((res) => {
      if (res.data.status === 'success') {
        this.setState({
          successMsg: 'Log In Successfully.',
          successLoading: true,
        });
        localStorage.setItem('token', res.data.token);

        setTimeout(() => {
          this.setState({
            successLoading: false,
          });
          window.location.href = '/';
        }, 3000);
      } else {
        this.setState({
          loading: false,

          errorLoading: true,
          errorMsg: 'Login Failed',
        });
        setTimeout(() => {
          this.setState({
            errorLoading: false,
          });
          document.getElementById('myForm').reset();
        }, 3000);
      }
    });
  };
  authHandler = (data, err) => {
    if (data) {
      window.email = data.account ? data.account.userName : null;
      window.name = data.account ? data.account.name : null;
      let LoginLoad = {
        email: data.account ? data.account.userName : null,
        name: data.account ? data.account.name : null,
      };
      axios.post(API.postUrls.googleLogin, LoginLoad).then((res) => {
        if (res.data.status === 'success') {
          this.setState({
            successMsg: 'Log In Successfully.',
            successLoading: true,
          });
          localStorage.setItem('token', res.data.token);
        } else {
          this.setState({
            loading: false,

            errorLoading: true,
            errorMsg: 'Login Failed',
          });
        }
      });
    }
  };
  render() {
    return (
      <div className="SignInWrapper">
        <div className="container signIn">
          {this.state.successLoading ? (
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              open={true}
              style={{ bottom: '40px' }}
              color={'#bdbdbd'}
              autoHideDuration={6000}
              message={this.state.successMsg}
            ></Snackbar>
          ) : null}
          {this.state.errorLoading ? (
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              open={true}
              style={{ bottom: '40px' }}
              color={'#bdbdbd'}
              autoHideDuration={6000}
              message={this.state.errorMsg}
            ></Snackbar>
          ) : null}
          <p className="newHere">New here ?</p>
          <h3 className="mainHeading">Create Account</h3>
          <form action="" onSubmit={this.register} id="myForm">
            <div className="inputWrapper">
              <span className="spanTag emailTag" style={{ marginLeft: '-6px' }}>
                Name
              </span>

              <input
                className="Input"
                name="name"
                required
                onChange={this.handlechange}
              />
            </div>

            <div className="inputWrapper">
              <span className="spanTag emailTag">Email</span>

              <input
                className="Input"
                name="email"
                required
                onChange={this.handlechange}
              />
            </div>

            <div className="inputWrapper">
              <span className="spanTag passTag" style={{ marginLeft: '-99px' }}>
                {' '}
                Create Password
              </span>
              <input
                className="Input"
                name="password"
                required
                type="password"
                onChange={this.handlechange}
              />
            </div>

            <Button variant="contained" type="submit" className="submitBtn">
              {this.state.loading ? (
                <CircularProgress size={30} color={'white'} />
              ) : (
                'Submit'
              )}
            </Button>
            <Typography className="signInor">Or </Typography>

            <GoogleLogin
              render={(renderProps) => (
                <button
                  onClick={renderProps.onClick}
                  className="signwithgoogle"
                >
                  <img
                    src={google}
                    alt=""
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    width="10%"
                    style={{
                      position: 'absolute',
                      left: '11px',
                      top: '12px',
                    }}
                  />{' '}
                  <span style={{ marginLeft: '30px' }}>
                    {' '}
                    Sign in with Google
                  </span>
                </button>
              )}
              clientSecret="VnOX2Dit1xi-UWDvdoMh9GyI"
              clientId="325586537681-tpbbmikbr3ut3p0v9buk40h5ujg9ttfj.apps.googleusercontent.com"
              onSuccess={this.responseGoogle}
              onFailure={this.responseGoogle}
            />
            <br />
            <MicrosoftLogin
              clientId="1a75bfc2-fa8f-4c18-bac2-cd5fba23602a"
              authCallback={this.authHandler}
              redirectUri="https://portal.actuality.live/"
              debug={true}
              className="microsoftLogin"
            />
            <Typography className="newUser">Returning User ?</Typography>
            <Link to="/account/login" className="linkStyle">
              <Button variant="contained" type="submit" className="submitBtn">
                Sign In
              </Button>
            </Link>
          </form>
        </div>
      </div>
    );
  }
}
