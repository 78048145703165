import { Container, Typography, Grid, Button } from '@material-ui/core';
import axios from 'axios';
import React, { Component } from 'react';
import renderHTML from 'react-render-html';
import { API } from '../Constants/APIs';
import image31 from '../Assets/Images/image_31.png';
import modelImg1 from '../Assets/Images/modelImg1.png';
import modelImg2 from '../Assets/Images/modelImg2.png';
import modelImg3 from '../Assets/Images/modelImg3.png';
import ModelCard from '../Components/ModelCard';
import image32 from '../Assets/Images/image_32.png';
import { Link } from 'react-router-dom';
class About extends Component {
  state = {
    aboutUs: [],
    description: '',
    blogs: [],
    models: [
      {
        title: 'Retro Television from Herman Miller',
        img_src: modelImg1,
      },
      {
        title: 'Kitchen Furniture by ',
        img_src: modelImg2,
      },
      {
        title: 'Gold Lamp from Herman Miller',
        img_src: modelImg3,
      },

      {
        title: 'Kitchen Furniture by ',
        img_src: modelImg2,
      },
      {
        title: 'Retro Television from Herman Miller',
        img_src: modelImg1,
      },
      {
        title: 'Gold Lamp from Herman Miller',
        img_src: modelImg3,
      },
      {
        title: 'Gold Lamp from Herman Miller',
        img_src: modelImg3,
      },

      {
        title: 'Kitchen Furniture by ',
        img_src: modelImg2,
      },
      {
        title: 'Retro Television from Herman Miller',
        img_src: modelImg1,
      },
    ],
  };
  componentDidMount() {
    // console.log(window.innerWidth);
    window.scrollTo(0, 0);
    window.addEventListener('resize', () => {
      // console.log(window.innerWidth);
    });
    this.getAbout();
    this.fetchModels();
    axios
      .get(API.getUrls.blogs + '?page=1&limit=10&status=active')
      .then((res) => {
        // console.log(res);
        this.setState({
          blogs: res.data.docs,
        });
      });
  }

  fetchModels = () => {
    axios.get('/SingleModel.json').then((res) => {
      this.setState({ models: res.data.models });
    });
  };

  gotoblog_detail = (id) => {
    window.location.href = '/blog-detail' + '?id=' + id;
  };

  getAbout = () => {
    axios.get(API.getUrls.getAboutUs).then((res) => {
      this.setState({
        aboutUs: res.data.docs,
      });
    });
  };
  render() {
    return (
      <>
        <Container>
          {window.innerWidth < 1100 ? (
            <>
              <h1 className="plansNew">
                we help you <span className="enhance">enhance reality</span>
              </h1>
              <section
                style={{
                  paddingLeft: '64px',
                  paddingRight: '64px',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div>
                  <p className="contentParagraph">
                    At Actuality, we are working towards a future where extended
                    reality (XR) is an integral part of everyday life. We
                    believe that immersive technology (AR, VR & MR) will
                    fundamentally change the way we shop, learn, communicate &
                    experience the world around us.
                  </p>
                </div>
              </section>

              <section
                style={{
                  padding: '64px',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div
                  className={
                    window.innerWidth < 1100 ? 'mainClassResp' : 'mainClass'
                  }
                >
                  <div>
                    <div
                      style={{
                        Width: '100%',
                        height: '559px',
                        background: '#396996',
                        borderRadius: '0px 16px 16px 0px',
                      }}
                    ></div>
                    <img
                      src={image31}
                      width="475px"
                      className={
                        window.innerWidth < 1100 ? 'leftImgResp' : 'leftImg'
                      }
                    />
                  </div>
                </div>
              </section>

              <section
                style={{
                  paddingLeft: '64px',
                  paddingRight: '64px',
                  display: 'flex',
                  flexDirection: 'row',
                  textAlign: 'center',
                }}
              >
                <div
                  className={
                    window.innerWidth < 1100 ? 'textRightResp' : 'textRight'
                  }
                >
                  <h2
                    style={{
                      fontWeight: 600,
                      fontSize: '45px',
                      paddingTop: '55px',
                    }}
                  >
                    mission
                  </h2>
                  <p
                    style={{
                      fontSize: window.innerWidth < 1100 ? 18 : 18,
                      lineHeight: '32px',
                      width: window.innerWidth < 1100 ? '100%' : '100%',
                      position: 'relative',
                      bottom: '30px',
                      fontFamily: 'Inter',
                      textAlign: 'justify',
                      left: '0%',
                    }}
                  >
                    our mission is to accelerate the adoption of extended
                    reality (XR) by making it accessible, affordable, and easy
                    to use
                  </p>
                  <h2
                    style={{
                      fontWeight: 600,
                      fontSize: '45px',
                      position: 'relative',
                      bottom: '30px',
                    }}
                  >
                    vision
                  </h2>
                  <p
                    style={{
                      fontSize: window.innerWidth < 1100 ? 18 : 18,
                      lineHeight: '32px',
                      width: window.innerWidth < 1100 ? '100%' : '100%',
                      position: 'relative',
                      bottom: '60px',
                      fontFamily: 'Inter',
                      textAlign: 'justify',
                      left: '0%',
                    }}
                  >
                    our vision is to empower brands to create an engaging
                    customer experience and become the leading ecosystem for all
                    things XR
                  </p>
                </div>
              </section>

              <section
                style={{
                  padding: '64px',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div className="mainClassSecondResp">
                  <div>
                    <div className="helpYouRight"></div>
                    <img
                      src={image32}
                      width="475px"
                      style={{
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        left: '-44%',
                      }}
                    />
                  </div>
                </div>
              </section>

              <section
                style={{
                  paddingLeft: '64px',
                  paddingRight: '64px',
                  paddingBottom: '64px',
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '-60px',
                }}
              >
                <div style={{ width: '40%', flex: 1 }}>
                  <h2
                    style={{
                      fontWeight: 600,
                      fontSize: '45px',
                      paddingTop: '125px',
                    }}
                  >
                    we help you...
                  </h2>
                  <ul>
                    <li
                      style={{
                        lineHeight: '32px',
                        fontSize: 18,
                        fontFamily: "'Inter', sans-serif",
                      }}
                    >
                      create unique customer experiences
                    </li>
                    <li
                      style={{
                        lineHeight: '32px',
                        fontSize: 18,
                        fontFamily: "'Inter', sans-serif",
                      }}
                    >
                      eliminate cognitive overload
                    </li>
                    <li
                      style={{
                        lineHeight: '32px',
                        fontSize: 18,
                        fontFamily: "'Inter', sans-serif",
                      }}
                    >
                      heighten user engagement
                    </li>
                    <li
                      style={{
                        lineHeight: '32px',
                        fontSize: 18,
                        fontFamily: "'Inter', sans-serif",
                      }}
                    >
                      attain competitive differentiation
                    </li>
                  </ul>
                </div>
              </section>
            </>
          ) : (
            <>
              <section>
                <div>
                  <div>
                    <h1 className="plansNew">
                      we help you{' '}
                      <span className="enhance">enhance reality</span>
                    </h1>
                    <p
                      className={
                        window.innerWidth < 1100 ? 'paraNew1013' : 'paraNew'
                      }
                    >
                      At Actuality, we are working towards a future where
                      extended reality (XR) is an integral part of everyday
                      life. We believe that immersive technology (AR, VR & MR)
                      will fundamentally change the way we shop, learn,
                      communicate & experience the world around us.
                    </p>
                  </div>
                </div>
              </section>

              <section
                style={{
                  padding: '64px',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div
                  className={
                    window.innerWidth < 1100 ? 'mainClassResp' : 'mainClass'
                  }
                >
                  <div>
                    <div
                      style={{
                        Width: '100%',
                        height: '559px',
                        background: '#396996',
                        borderRadius: '0px 16px 16px 0px',
                      }}
                    ></div>
                    <img
                      src={image31}
                      width="475px"
                      className={
                        window.innerWidth < 1100 ? 'leftImgResp' : 'leftImg'
                      }
                    />
                  </div>
                </div>
                <div
                  className={
                    window.innerWidth < 1100 ? 'textRightReap' : 'textRight'
                  }
                >
                  <h2
                    style={{
                      fontWeight: 600,
                      fontSize: '45px',
                      paddingTop: '55px',
                    }}
                  >
                    mission
                  </h2>
                  <p
                    style={{
                      fontSize: window.innerWidth < 1100 ? 15 : 18,
                      lineHeight: '32px',
                      width: window.innerWidth < 1100 ? '100%' : '100%',
                      position: 'relative',
                      bottom: '30px',
                    }}
                  >
                    our mission is to accelerate the adoption of extended
                    reality (XR) by making it accessible, affordable, and easy
                    to use
                  </p>
                  <h2
                    style={{
                      fontWeight: 600,
                      fontSize: '45px',
                      position: 'relative',
                      bottom: '30px',
                    }}
                  >
                    vision
                  </h2>
                  <p
                    style={{
                      fontSize: window.innerWidth < 1100 ? 15 : 18,
                      lineHeight: '32px',
                      width: window.innerWidth < 1100 ? '100%' : '100%',
                      position: 'relative',
                      bottom: '60px',
                    }}
                  >
                    our vision is to empower brands to create an engaging
                    customer experience and become the leading ecosystem for all
                    things XR
                  </p>
                </div>
              </section>

              <section
                style={{
                  padding: '64px',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div style={{ width: '40%', flex: 1 }}>
                  <h2
                    style={{
                      fontWeight: 600,
                      fontSize: '45px',
                      paddingTop: '125px',
                    }}
                  >
                    we help you...
                  </h2>
                  <ul>
                    <li
                      style={{
                        lineHeight: '32px',
                        fontSize: 18,
                        fontFamily: "'Inter', sans-serif",
                      }}
                    >
                      create unique customer experiences
                    </li>
                    <li
                      style={{
                        lineHeight: '32px',
                        fontSize: 18,
                        fontFamily: "'Inter', sans-serif",
                      }}
                    >
                      eliminate cognitive overload
                    </li>
                    <li
                      style={{
                        lineHeight: '32px',
                        fontSize: 18,
                        fontFamily: "'Inter', sans-serif",
                      }}
                    >
                      heighten user engagement
                    </li>
                    <li
                      style={{
                        lineHeight: '32px',
                        fontSize: 18,
                        fontFamily: "'Inter', sans-serif",
                      }}
                    >
                      attain competitive differentiation
                    </li>
                  </ul>
                </div>

                <div
                  style={{
                    width: '40%',
                    position: 'relative',
                    flex: '1 1 0%',
                    right: window.innerWidth < 1100 ? '-15%' : '-20%',
                  }}
                >
                  <div>
                    <div className="helpYouRight"></div>
                    <img
                      src={image32}
                      width="475px"
                      style={{
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        left: '-40%',
                      }}
                    />
                  </div>
                </div>
              </section>
            </>
          )}

          <section
            className="sectionInnerSpacingAbout1"
            style={{ marginLeft: window.innerWidth < 1100 ? '4%' : '0' }}
          >
            <Typography
              align={'center'}
              variant={'h5'}
              className={'bolder verticalSpacing browseHead'}
            >
              Learn more about XR on our blog.
            </Typography>
            <Grid container spacing={3} style={{ marginTop: '25px' }}>
              {this.state.blogs.slice(0, 3).map((blog) => (
                <Grid
                  item
                  sm={4}
                  className="blogCard"
                  onClick={() => this.gotoblog_detail(blog._id)}
                >
                  <div className="imgBackground">
                    <div
                      className={
                        window.innerWidth < 1100
                          ? 'imgWrapperOneResp'
                          : 'imgWrapperOne'
                      }
                    >
                      <img
                        src={API.imgUrl + blog.blog_img_url}
                        style={{
                          height: '275px',
                          width: '383px',
                          borderRadius: '26px',
                        }}
                        alt=""
                      />
                    </div>
                  </div>
                  <Typography
                    className="modelTitleAbout bolder"
                    style={{
                      paddingTop: '20px',
                      textAlign: 'left',
                      paddingLeft: '10px',
                      fontSize: '24px',
                    }}
                  >
                    {blog.title}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </section>

          <section
            className="sectionInnerSpacingAbout2"
            style={{ marginLeft: window.innerWidth < 1100 ? '0' : '0' }}
          >
            <Typography
              align={'center'}
              variant={'h5'}
              className={'bolder verticalSpacing browseHead'}
            >
              Browse some of our models in your space
            </Typography>
            <Grid container spacing={3}>
              {this.state.models.slice(0, 3).map((ele, index) =>
                ele.is_visible_in_landing ? (
                  <Grid item sm={4} key={index}>
                    <ModelCard
                      imgSrc={ele.poster_path ? ele.poster_path : modelImg1}
                      id={ele.id}
                      title={ele.title}
                    />
                  </Grid>
                ) : null
              )}
            </Grid>
          </section>

          <section style={{ paddingTop: '125px' }}>
            <Link to="/contact-us" style={{ textDecoration: 'none' }}>
              <Button
                size={'large'}
                variant="contained"
                className="themeBtn txtShadow rounded"
                style={{
                  position: 'relative',
                  left: '49%',
                  transform: 'translate(-50%, -50%)',
                  padding: '23px 80px',
                }}
              >
                BOOK A DEMO
              </Button>
            </Link>
          </section>
        </Container>
      </>
    );
  }
}

export default About;
