import React, { useEffect, useState } from "react";
import "./style.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { API } from "../../Constants/APIs";
import ProductCard from "./ProductCard";
import ImageUpload from "./ImageUpload";
import Footer from "./Footer";
import { Container, Snackbar } from "@material-ui/core";
import SunEditor from '../SunEditor';
import editImg from "../../Assets/Images/edit.svg"

const CreateShare = ({ }) => {
  const location = useLocation();
  const [isEdit, setIsEdit] = useState(
    location.pathname.split("/").pop() != "share"
  );

  useEffect(() => {
    const last = location.pathname.split("/").pop();
    if ((last != "share") != isEdit) setIsEdit(last != "share");
  }, []);

  const [shareProduct, setShareProduct] = useState({
    _id: "",
    title: "",
    sub_title: "",
    products: [],
    logo: "",
    links: [],
  });

  const [submitted, setSubmitted] = useState(false);

  const getUser = async () => {
    if (shareProduct.logo || isEdit) return;
    try {
      const { data } = await axios.get(API.getUrls.getUser);
      if (!shareProduct.logo) {
        setShareProduct((p) => ({ ...p, logo: data.user.company_logo }));
      }
    } catch (err) { }
  };

  const getShareProduct = async (id) => {
    const share_id = location.pathname.split("/").pop();
    id = id ? id : share_id;
    if (id == "share") return;
    try {
      const { data } = await axios.get(`${API.getUrls.share_products}/${id}`);
      const { products, ...rest } = data.shareProduct;
      setShareProduct({ ...rest, products: products.filter((p) => p) });
    } catch (err) {
    } finally {
    }
  };

  useEffect(() => {
    (async () => {
      if (isEdit) await getShareProduct();
      else await getUser();
    })();
  }, [isEdit]);

  const handleDeleteProduct = async (product_id) => {
    try {
      await axios.delete(
        `${API.postUrls.share_products}/products/${shareProduct._id}/${product_id}`
      );
    } catch (err) {
    } finally {
      await getShareProduct();
    }
  };

  const handleChange = (e) => {
    if (errMsg) setErrMsg("");
    const target = e.target;
    setShareProduct((p) => ({ ...p, [target.name]: target.value }));
  };

  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);

  // const handleSubmit = async (logoUpload, links, cb) => {
  //   if (loading) return;
  //   if (!logoUpload && !links) setLoading(true);
  //   const { title, sub_title, logo } = shareProduct;

  //   if ((!title || !sub_title) && !logoUpload && !links) {
  //     setErrMsg("Provide Title and Subtitle");
  //     setLoading(false);
  //     return;
  //   }

  //   try {
  //     let axiosRef = axios;

  //     let uri = API.postUrls.share_products;

  //     if (shareProduct._id) {
  //       axiosRef = axiosRef.put;
  //       uri += `/${shareProduct._id}`;
  //     } else {
  //       axiosRef = axiosRef.post;
  //     }

  //     let axios_body = {};
  //     if (logoUpload) {
  //       axios_body = new FormData();
  //       if (title) axios_body.append("title", title);
  //       if (sub_title) axios_body.append("sub_title", sub_title);
  //       axios_body.append("logo", logoUpload);
  //     } else {
  //       if (title) axios_body.title = title;
  //       if (sub_title) axios_body.sub_title = sub_title;
  //       if (links) axios_body.links = links;
  //       if (logo) axios_body.logo = logo;
  //     }

  //     const { data } = await axiosRef(uri, axios_body);
  //     setShareProduct((p) => ({
  //       ...p,
  //       ...data.shareProduct,
  //     }));
  //     setSubmitted(true);
  //     if (!isEdit) {
  //       const nextURL = `/share/${data.shareProduct._id}`;
  //       const nextState = { additionalInformation: "Updated the URL with JS" };
  //       setIsEdit(true);
  //       window.history.replaceState(nextState, "", nextURL);
  //     }
  //     if (cb) {
  //       cb(data.shareProduct._id);
  //     }
  //   } catch (err) {
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const handleSubmit = async (logoUpload, links, cb) => {
    if (loading) return;
    // if (!logoUpload && !links) setLoading(true);
    const { title, sub_title, logo } = shareProduct;

    // if ((!title || !sub_title) && !logoUpload && !links) {
    //   setErrMsg("Provide Title and Subtitle");
    //   setLoading(false);
    //   return;
    // }

    try {
      let axiosRef = axios;

      let uri = API.postUrls.share_products;

      if (shareProduct._id) {
        axiosRef = axiosRef.put;
        uri += `/${shareProduct._id}`;
      } else {
        axiosRef = axiosRef.post;
      }

      let axios_body = {};
      if (logoUpload) {
        axios_body = new FormData();
        if (title) axios_body.append("title", title);
        if (sub_title) axios_body.append("sub_title", sub_title);
        axios_body.append("logo", logoUpload);
      } else {
        if (title) axios_body.title = title;
        if (sub_title) axios_body.sub_title = sub_title;
        if (links) axios_body.links = links;
        if (logo) axios_body.logo = logo;
      }

      const { data } = await axiosRef(uri, axios_body);
      setShareProduct((p) => ({
        ...p,
        ...data.shareProduct,
      }));
      setSubmitted(true);
      if (!isEdit) {
        const nextURL = `/share/${data.shareProduct._id}`;
        const nextState = { additionalInformation: "Updated the URL with JS" };
        setIsEdit(true);
        window.history.replaceState(nextState, "", nextURL);
      }
      if (cb) {
        cb(data.shareProduct._id);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const [titleInputBlur, setTitleInputBlur] = useState(false);
  const [subTitleInputBlur, setSubTitleInputBlur] = useState(false);
  const [isHover, setIsHover] = useState(false);
  return (
    <Container maxWidth={'lg'}>

      <div
        className="NO_SCROLLBAR"
      // style={{
      //   minHeight: "100vh",
      //   maxWidth: "100vw",
      //   overflow: "hidden",
      // }}
      >
        <Snackbar
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={submitted}
          onClose={() => setSubmitted(false)}
          message="Submitted successfully!"
        />
        <div
          className="flex_center"
          style={{
            position: "relative",
          }}
        >
          <div
            style={{
              flex: 1,
              marginTop: 89,
              position: 'relative'
            }}
            className="flex_center"
          >
            {!shareProduct?.logo ? null : (
              <ImageUpload
                onSubmit={async (logoUpload) => {
                  await handleSubmit(logoUpload);
                }}
                logo={shareProduct.logo}
              />
            )}
            <button
              className="flex_center"
              onClick={async () => {
                await handleSubmit(undefined, undefined, (id) => {
                  // window.open(`https://actuality.live/myproducts/${id}`);
                });
              }}
              onMouseEnter={() => {
                setIsHover(true)
              }}
              onMouseLeave={() => {
                setIsHover(false)
              }}
              style={{
                position: "absolute",
                top: "0",
                // tranform: 'translateY(-50%)',
                right: 0,
                alignSelf: "flex-start",
                outline: 0,
                padding: "10px 20px",
                border: 0,
                marginRight: 0,
                background: isHover ? "#A5C0D7" : "#BDBDBD",
                boxShadow: "1px 5px 5px rgba(0, 0, 0, 0.1)",
                borderRadius: 9,
                color: "#fff",
                fontSize: 20,
                cursor: "pointer",
                width: 166,
                height: 61,
                fontWeight: 700,
                letterSpacing: 0.25
              }}
            >
              Publish
            </button>
          </div>


        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            position: 'relative'
          }}
        >

          <input
            name="title"
            onChange={handleChange}
            value={shareProduct.title}
            style={{
              fontSize: 24,
              fontWeight: 500,
              outline: 0,
              textAlign: "center",
              width: "100%",
              maxWidth: "100%",
              height: "89px",
              borderRadius: 4,
              color: '#2E2E2E',
              // border: `${titleInputBlur ? 1 : 0}px solid #ddd`,
              border: `1px solid #BABABA`,
              marginBottom: 24,
              paddingLeft: 0,
              paddingRight: 0
            }}
            placeholder="Please add heading"
            // maxLength={20}
            onFocus={() => {
              setTitleInputBlur(true);
            }}
            onBlur={() => {
              setTitleInputBlur(false);
            }}
          />
          <img src={editImg} style={{ width: 23, height: 23, position: 'absolute', top: 35, right: 16 }} alt="" />

          {/* <div
            style={{
              alignSelf: "flex-end",
              maxWidth: "100%",
              width: "100%",
              alignSelf: "center",
              textAlign: "end",
              // border: '1px solid red',
            }}
          >
            {titleInputBlur ? `${shareProduct.title?.length}/20 chars` : ""}
          </div> */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
          onMouseEnter={() => {
            setSubTitleInputBlur(true);
          }}
          onMouseLeave={() => {
            setSubTitleInputBlur(false);
          }}
        >
          {/* <textarea
          onFocus={() => {
            setSubTitleInputBlur(true);
          }}
          onBlur={() => {
            setSubTitleInputBlur(false);
          }}
          className="__NO__SCROLLBAR__"
          name="sub_title"
          onChange={handleChange}
          value={shareProduct.sub_title}
          style={{
            border: `${subTitleInputBlur ? 1 : 0}px solid #ddd`,

            fontSize: 23,
            fontWeight: 500,
            outline: 0,
            textAlign: "center",
            width: "100%",
            maxWidth: "80%",
            marginTop: 15,
            height: "100px",
            color: "#919191",
          }}
          maxLength={250}
          placeholder="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Molestias consequatur voluptas laborum consequuntur, ipsa incidunt optio in perferendis temporibus, eius culpa voluptatem beatae suscipit! Placeat quisquam quaerat iure dicta eius! Lorem ipsu!"
        /> */}
          {/* <SunEditor
          value={shareProduct.sub_title}
        /> */}
          <SunEditor
            height="100px"
            width="100%"
            value={shareProduct.sub_title}
            setValue={(e) => {
              setShareProduct((p) => ({ ...p, sub_title: e }));
            }}
          />
          {/* <div
            style={{
              alignSelf: "flex-end",
              width: "100%",
              alignSelf: "center",
              textAlign: "end",
              color: '#ddd'
            }}
          >
            {subTitleInputBlur
              ? `${shareProduct.sub_title?.length}/250 chars`
              : ""}
          </div> */}
        </div>
        {!errMsg ? null : (
          <div className="flex_center">
            <span
              style={{
                fontSize: 20,
                color: "red",
                fontStyle: "italic",
                textAlign: "center",
              }}
            >
              {errMsg}
            </span>
          </div>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            marginBottom: "4rem",
            marginTop: 16,
            paddingBottom: 64
          }}
        >
          {shareProduct.products.map((product, index) => (
            <ProductCard
              key={product._id}
              product={product}
              deleteProduct={() => handleDeleteProduct(product._id)}
              shareId={shareProduct._id}
              index={index}
              length={shareProduct.products.length}
              subTitleInputBlur={subTitleInputBlur}
            />
          ))}
          {shareProduct.products.length ? (
            <ProductCard
              product={shareProduct.products[0]}
              addProductCard={true}
              shareId={shareProduct._id}
            />
          ) : (
            <div>
              {/* {shareProduct._id ? ( */}
              <AddProduct shareId={shareProduct._id} payload={shareProduct} />
              {/* ) : null} */}
            </div>
          )}
        </div>
        <Footer
          shareProduct={shareProduct}
          setShareProduct={setShareProduct}
          handleSubmit={handleSubmit}
        />
      </div>
    </Container>
  );
};

export default CreateShare;

const AddProduct = ({ shareId, shareProduct }) => {
  const navigate = useNavigate();

  return (
    <div onClick={() => {
      if (!shareId) {
        const payload = {
          ...shareProduct
        }
        axios.post(API.postUrls.share_products, payload).then(res => {
          console.log(res);
          const id = res.data.shareProduct._id
          navigate(`/addToShare/${id}`)

        }).catch(error => {
          if (error) {
            console.log(error)
          }
        })
      }
      else {
        navigate(`/addToShare/${shareId}`)

      }
    }} style={{ textDecoration: "none" }}>
      <div
        style={{
          width: 250,
          height: 250,
          border: "1px solid #ddd",
          borderRadius: 15,
          boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
      >
        <span
          style={{
            fontSize: 60,
            fontWeight: 200,
            color: "#828282",
            marginBottom: 5,
          }}
        >
          {" "}
          +
        </span>

        <span
          style={{
            fontSize: 20,
            fontWeight: 400,
            color: "#2e2e2e",
            marginTop: 5,
          }}
        >
          Add Products
        </span>
      </div>
    </div>
  );
}