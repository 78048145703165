import { FormControl, Select, MenuItem, Box } from '@material-ui/core';
import {
  AreaChart,
  Legend,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import React, { useEffect } from 'react';
// () => this.setState({ showModel: true })

import customImg from '../../Assets/Images/timetable (1) 3.png';
import { customLegend } from '../../Helpers/utils';
const ProductViewsGraph = ({ handleChange, propState, showModel }) => {
  useEffect(() => {
    // console.log("propState ", propState.viewsData);
  }, []);
  return (
    <div
      className='graphDivWrapper fontStyleLeg1'
      style={
        {
          // width: '100%',
          // border: '1px solid red',
        }
      }
    >
      <div className='divAlignItem'>
        <h2>Number of Views</h2>
        <Box className=''>
          <FormControl style={{ m: 1, minWidth: 120 }}>
            <Select
              value={propState.set}
              style={{
                paddingLeft: 8,
                height: 40,
                border: '2px solid #dcdada',
                marginTop: '10px',
                borderRadius: 10,
              }}
              variant='outlined'
              id='filled-age-native-simple'
              className='selectStyle'
              displayEmpty
              onChange={handleChange}
              defaultValue={propState.set}
              inputProps={{
                'aria-label': 'Without label',
              }}
            >
              <MenuItem value={'this_week'}> This Week </MenuItem>
              <MenuItem value={'this_month'}> This Month</MenuItem>
              <MenuItem value={'this_year'}> This Year</MenuItem>
              <MenuItem value={'last_week'}> Last Week</MenuItem>
              <MenuItem value={'last_month'}> Last Month</MenuItem>
              <MenuItem value={'last_year'}> Last Year</MenuItem>
              <MenuItem value={'last_60_days'}> Last 60 Days</MenuItem>
              <MenuItem value={'last_90_days'}> Last 90 days</MenuItem>
              <MenuItem
                className='customDiv'
                value={'custom'}
                onClick={showModel}
              >
                <img src={customImg} alt='Img' style={{ cursor: 'pointer' }} />
                <span style={{ paddingLeft: 12 }}>Custom</span>
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </div>
      {propState.viewsData.length > 0 ? (
        <div
          style={{
            width: 480,
            marginBottom: 10,
            // display: 'flex',
            // justifyContent: 'center',
          }}
        >
          <ResponsiveContainer width={480} height={350}>
            <AreaChart
              data={propState.viewsData}
              margin={{ top: 20, right: 16, left: 0 }}
              className='fontGraph'
            >
              <XAxis
                dataKey='_id'
                reversed={
                  propState.set === 'this_year' || propState.set === 'last_year'
                    ? false
                    : true
                }
              />
              <YAxis />
              <CartesianGrid strokeDasharray='3 3' />
              <Tooltip />
              <Legend
                verticalAlign='top'
                height={12}
                iconType='circle'
                iconSize={10}
                align='left'
                content={(props) => customLegend(props, propState.viewsData)}
              />
              <Area
                type='monotone'
                dataKey={`Total`}
                stroke='#43464d'
                fill='#a7dafd'
              />
              <Area
                type='monotone'
                // dataKey={`Unique - ${
                //   propState.totalUniqueViews ? propState.totalUniqueViews : 0
                // }`}
                dataKey={`Unique`}
                stroke='#8884d8'
                fill='#8884d8'
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <h2
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '20%',
          }}
        >
          {' '}
          Data Not Available
        </h2>
      )}
    </div>
  );
};

export default ProductViewsGraph;
