import axios from "axios";
import { isDevEnv } from "./index";
import { authToken } from "./Helpers/Authhelper";
export let authToken2 = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzNTI2NjhjMDBmZWM1NjRmNjFiYTRmNyIsImlhdCI6MTY3NTkyOTU0MiwiZXhwIjoxNzYyMzI5NTQyfQ.LVUESE26cssvA2g3clGtQ6VzwElwgv0U2Iq5hgYwxVY`;

axios.interceptors.request.use(
  function (config) {
    // const user = getCookies();

    // @ts-ignore
    let token = isDevEnv ? authToken2 : authToken;
    config.headers.common["Authorization"] = `Bearer ${token}`; // @ts-ignore
    // config.baseURL = process.env.NEXT_PUBLIC_API_URL;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const TSE_axios = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
};

export default TSE_axios;
