import React from "react";

import { MultiSelect } from "react-multi-select-component";
const TSEMultiSelect = ({ options, value, onChange, labelledBy }) => {
  return (
    <div
      style={{
        textAlign: "left",
      }}
    >
      <MultiSelect
        options={options}
        value={value}
        onChange={onChange}
        labelledBy={labelledBy}
        // className="InputSelect fullWidth"
      />
    </div>
  );
};

export default TSEMultiSelect;
