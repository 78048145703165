import React, { useEffect, useState } from "react";
import { Container, Grid } from "@material-ui/core";
import Sidebar from "../Sidebar";
import ShareUser from "../ShareUser";
import { Link } from "react-router-dom";
import axios from "axios";
import { API } from "../../Constants/APIs";
import ShareTable from "./ShareTable";

const btnStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 10,
  background: "#E3E3E3",
  border: 0,
  outline: 0,
  height: 88,
  width: 292,
  color: "#666666",
  fontSize: 18,
  fontWeight: 600,
  paddingLeft: 20,
  paddingRight: 20,
  textDecoration: "none",
};

const ShareListing = () => {
  const [my_shares, set_my_shares] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const get_shares = async () => {
    try {
      const { data } = await axios.get(
        `${API.getUrls.share_products}/myshares?limit=${rowsPerPage}&page=${page}`
      );
      set_my_shares(data.shareProduct.docs);
      setCount(data.shareProduct.totalPages);
      setPage(data.shareProduct.page);
      setRowsPerPage(data.shareProduct.limit);
    } catch (err) { }
  };
  useEffect(() => {
    (async () => {
      await get_shares();
    })();
  }, [page]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  return (
    <Container className="containerSpacingdashBoard" maxWidth={'lg'}>
      <Grid
        container
        spacing={2}
        className="userDashboard"
      >
        <Grid item sm={2} className="sidebar">
          <Sidebar />
        </Grid>
        <Grid item sm={10} style={{ border: "0px solid red", padding: "32px 64px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 15,
              paddingBottom: 50,
              borderBottom: "1px solid #ddd",
            }}
          >
            <ShareUser btnStyle={btnStyle} />

            <div style={{ width: 20 }} />
            <a
              href="/share"
              style={{
                textDecoration: "none",
              }}
              target="_blank"
            >
              <button style={btnStyle}>Create Webpage</button>
            </a>
          </div>
          <div
            style={{
            }}
          >
            <div
              style={{
                fontSize: 20,
                fontWeight: 400,
                color: "#2e2e2e",
                padding: "0 0 28px",
                marginTop: 34
                // marginBottom: 15,
              }}
            >
              All Product Links
            </div>
            <ShareTable shares={my_shares} get_shares={get_shares} onChangePage={handleChangePage} page={page} count={count} />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ShareListing;
