import { useRef } from "react";

import "./annotBtn.css";

const AnnotationBtns = ({ annots, openHotspotDrawer }) => {
  // console.log(`annots `, annots);
  return !annots?.length
    ? null
    : annots?.map(({ description, ...rest }, index) => (
        <AnnotBtn
          hotspot={rest}
          key={rest?._id || index}
          openHotspotDrawer={(cb) =>
            openHotspotDrawer && openHotspotDrawer({ description, ...rest }, cb)
          }
        />
      ));
};

const AnnotBtn = ({
  openHotspotDrawer,
  hotspot: { title, dataPosition, dataNormal, zoom = 12 },
}) => {
  const ref = useRef();

  const hideTitle = () => {
    ref.current.classList.remove("clicked");
  };

  const onClick = (e) => {
    ref.current.classList.add("clicked");
    openHotspotDrawer(hideTitle);
    e.stopPropagation();
  };
  return (
    <div
      data-position={dataPosition}
      data-normal={dataNormal}
      slot={`hotspot-${title}`}
      onClick={onClick}
      onMouseEnter={() => {
        ref.current.classList.add("show");
      }}
      onMouseLeave={() => {
        ref.current.classList.remove("show");
      }}
      className="annotBtnWrapper__XX"
      style={{
        position: "absolute",
        transform: "translate(-15px,-15px)",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        background: "#fff",
        borderRadius: "15.5px",
        boxShadow: `1px 2px 5px rgba(0, 0, 0, 0.1)`,
        maxHeight: 25,
      }}
    >
      <div
        style={{
          width: 25,
          height: 25,
          border: "1px solid #646464",
          // border: "8px solid #fff",
          // background: "#646464",
          // outline: "1px solid #646464",

          borderRadius: "15.5px",
          display: "inline-grid",
          placeItems: "center",
          background: "#fff",
        }}
      >
        <div
          style={{
            width: 11,
            height: 11,
            borderRadius: "50%",
            backgroundColor: "#646464",
          }}
        />
      </div>
      <div ref={ref} className="annotBtnWrapper__XX_TITLE">
        <div>{title}</div>
      </div>
    </div>
  );
};
export default AnnotationBtns;
