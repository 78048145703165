import { Container, Grid, Typography } from '@material-ui/core';
import axios from 'axios';
import React, { Component } from 'react';
import { API } from '../Constants/APIs';

class BlogSection extends Component {
  state = { blogs: [] };
  componentDidMount() {
    axios
      .get(API.getUrls.blogs + '?page=1&limit=10&status=active')
      .then((res) => {
        this.setState({
          blogs: res.data.docs,
        });
      });
  }
  gotoblog_detail = (id) => {
    window.location.href = '/blog-detail' + '?id=' + id;
  };
  render() {
    return (
      <Container>
        <Typography
          align={'center'}
          variant={'h5'}
          className={'bolder verticalSpacing browseHead'}
        >
          Learn more about AR in the furniture industry
          <br /> on our blog.
        </Typography>

        <Grid container spacing={3} style={{ marginTop: '25px' }}>
          {this.state.blogs.map((blog) => (
            <Grid
              item
              sm={4}
              className="blogCard"
              onClick={() =>
                (window.location.href = '/blog-detail?id=' + blog._id)
              }
            >
              {/* <Link
                    to={{
                      pathname: "/blog-detail?id=" + blog._id,
                      state: { proId: blog._id },
                    }}> */}
              <div className="imgBackground">
                <div
                  className={
                    window.innerWidth < 1100
                      ? 'imgWrapperOneResp'
                      : 'imgWrapperOne'
                  }
                >
                  <img
                    src={API.imgUrl + blog.blog_img_url}
                    style={{
                      height: '275px',
                      width: '383px',
                      borderRadius: '26px',
                    }}
                    // width="100%"
                    alt=""
                  />
                </div>
              </div>
              {/* </Link> */}
              <Typography
                className="modelTitleAbout bolder"
                style={{
                  paddingTop: '20px',
                  textAlign: 'left',
                  paddingLeft: '10px',
                  fontSize: '24px',
                }}
              >
                {blog.title + ' ' + blog.sub_title}
              </Typography>
            </Grid>
          ))}
        </Grid>

        {/* <Grid container spacing={3} style={{ marginTop: "25px" }}>
            {this.state.blogs.map((blog) => (
              <Grid item sm={4} className="blogCard">
              
                <div
                  className="imgBackground"
                  onClick={() => this.gotoblog_detail(blog._id)}
                >
                  <div className="imgWrapperLanding">
                    <img
                      src={API.imgUrl + blog.blog_img_url}
                      width="100%"
                      alt=""
                    />
                  </div>
                </div>
                <Typography className="modelTitle bolder">
                  {blog.title}
                </Typography>
              </Grid>
            ))}
          </Grid> */}
      </Container>
    );
  }
}

export default BlogSection;
