import "@google/model-viewer";
import { Button, Typography } from "@material-ui/core";
import { Check, LinearScaleTwoTone } from "@material-ui/icons";
import React, { Component } from "react";
import arimage from "../Assets/Images/ar_icon.png";
import share from "../Assets/Images/share.png";
const variantControllerHeight = 100;
export default class MobileModelViewer extends Component {
  render() {
    const props = this.props;
    return (
      <div
        className="mobileResponsiveViewer"
        style={{ height: window.innerHeight }}
      >
        {props.is_name_buy_now_visible ? (
          <div className="header">
            <div>
              <Typography className="brand">
                <a href="#">Actuality</a>
              </Typography>
              <Typography className="proName">{props.model.title}</Typography>
            </div>
            <div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div className="shareBtn">
                  <img src={share} height={"20px"} alt="" />
                </div>
                <div className="shareBtn">
                  <LinearScaleTwoTone />
                </div>
              </div>
              <div>
                <Typography className="buyNow">Buy Now</Typography>
              </div>
            </div>
          </div>
        ) : null}
        <model-viewer
          id="reveal"
          // src={model?.variants[this.state.variantIndex].glb_file_path}
          exposure="0.8"
          // ios-src={model?.variants[this.state.variantIndex].usdz_file_path}
          src={
            props.showVariant
              ? props.selectedVariant.glb_file_path
              : props.model.glb_file_path
          }
          ios-src={
            props.showVariant
              ? props.selectedVariant.usdz_file_path
              : props.model.usdz_file_path
          }
          camera-orbit="45deg 55deg 2.5m"
          shadow-intensity="2"
          field-of-view="80deg"
          min-field-of-view={"80deg"}
          max-field-of-view={"80deg"}
          bounds="tight"
          data-js-focus-visible
          camera-controls
          ar
          ar-modes="webxr scene-viewer quick-look"
          quick-look-browsers="safari chrome"
          ar-scale={"auto"}
          ar-placement={"floor"}
          autoplay
          loading="lazy"
          style={{
            background: "#FFFFFF",
            height: window.innerHeight - variantControllerHeight,
            width: "-webkit-fill-available",
            position: "absolute",
            left: "50%",
            top: props.showVariant ? "0%" : "50%",
            transform: props.showVariant
              ? "translateX(-50%)"
              : "translate(-50%, -50%)",
          }}
        >
          <button
            slot="ar-button"
            style={{
              backgroundColor: "rgb(255, 255, 255)",
              boxShadow: "1px 2px 5px rgba(0, 0, 0, 0.1)",
              borderRadius: "15px",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              bottom: "16px",
              border: "none",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "0 12px",
            }}
          >
            <img src={arimage} width="20px" alt="" />
            <p className="view_space_btn">View in your space</p>
          </button>
        </model-viewer>

        {props.showVariant ? (
          <div
            className="variantControls"
            style={{
              height: variantControllerHeight,
              width: window.innerWidth,
            }}
          >
            <div className="framesWrapper">
              <div>
                <Typography style={{ marginLeft: 6 }}>
                  {props.model.is_name_buy_now_visible
                    ? "Frame Finishes"
                    : "Street Type"}
                </Typography>
                <div className="variantListing">
                  {props.frames[0].frame_id
                    ? props.frames.map((variant, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            this.props.selectFrame(variant.frame_id);
                          }}
                          className="paletteBox"
                          style={{ backgroundColor: variant.frame_color }}
                        >
                          {props.selected_frame_id === variant.frame_id ? (
                            <div className="selected">
                              <Check />
                            </div>
                          ) : null}
                        </div>
                      ))
                    : props.model.variants.map((variant, index) => (
                        <Button
                          key={index}
                          size={"small"}
                          variant="contained"
                          className="themeBtn"
                          style={{
                            margin: 4,
                            backgroundColor:
                              variant.text_id === props.selectedVariant.text_id
                                ? "rgba(146, 180, 211, 0.8)"
                                : "#bdbdbd",
                            fontSize: 10,
                            width: "80px",
                            paddingTop: 6,
                            paddingBottom: 6,
                          }}
                          elevation={0}
                          onClick={() => {
                            this.props.selectVariant(variant);
                          }}
                        >
                          {variant.text}
                        </Button>
                      ))}
                </div>
              </div>
              {props.model.is_name_buy_now_visible ? (
                <>
                  <div className="separator"></div>
                  <div>
                    <Typography style={{ marginLeft: 6 }}>
                      Mesh Finishes
                    </Typography>
                    <div className="variantListing">
                      {props.meshes.map((variant, index) => (
                        <>
                          <div
                            key={index}
                            onClick={() => {
                              this.props.selectMesh(variant.mesh_id);
                            }}
                            className="paletteBox"
                            style={{ backgroundColor: variant.mesh_color }}
                          >
                            {props.selected_mesh_id === variant.mesh_id ? (
                              <div className="selected">
                                <Check />
                              </div>
                            ) : null}
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              fontSize: "14px",
                              fontFamily: "Inter",
                            }}
                          >
                            {variant.material_name}
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
