import React, { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';

import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

import { IconButton, Typography } from '@material-ui/core';
import { deleteCombination, deleteRecommend, getAllCombinations } from './lib';
import ProductSearchDialog from './ProductSearchDialog';

import { API as Constants } from '../../Constants/APIs';
import CreateRecommendDialog from './CreateRecommendDialog';
import DeleteConfirmation from './DeleteConfirmation';
import { firstLetterCapital } from '../../Helpers/utils';

const RecommendBox = ({
  recommendProduct,
  fetchRecommends,
  recommendProducts,
  productId,
  parentProduct,
}) => {
  const [expand, setExpand] = useState(true);

  const [combinations, setCombinations] = useState([]);
  const fetchCombinations = () => {
    getAllCombinations(recommendProduct._id)
      .then(({ combinations }) => setCombinations(combinations))
      .catch(() => {});
  };

  useEffect(() => {
    fetchCombinations();
  }, []);

  useEffect(() => {
    // console.clear();
    // console.log("combinations ", combinations);
  }, [combinations]);

  const [openSearch, setOpenSearch] = useState(false);
  const [selectedCombination, setSelectedCombination] = useState('');
  const onClickEdit = (selectedCombination) => {
    setSelectedCombination({
      ...selectedCombination,
      product: selectedCombination.product._id,
    });
    setOpenSearch(true);
  };

  const maxBoxDim = 55;
  return (
    <>
      <ProductSearchDialog
        parentProduct={parentProduct}
        open={openSearch}
        selectedCombination={selectedCombination}
        onSubmitCb={() => {
          fetchCombinations();
        }}
        recommendId={recommendProduct._id}
        handleClose={() => {
          setOpenSearch(false);
          setSelectedCombination(null);
        }}
        reverseSelectedProduct={() => {
          setSelectedCombination(null);
        }}
        combinations={combinations.map(({ product, ...rest }) => ({
          ...rest,
          product: product._id,
        }))}
      />
      <div
        className='box1'
        style={{
          height: expand ? '226px' : '38px',

          // position: "relative",
          transitionDuration: '222ms',
          overflow: 'auto',
          // border: "1px solid blue",
        }}
      >
        <Header
          recommendId={recommendProduct._id}
          expand={expand}
          handleExpand={() => setExpand((p) => !p)}
          recommendProduct={recommendProduct}
          postActionCB={fetchRecommends}
          recommendProducts={recommendProducts}
          productId={productId}
        />
        {/* {loremIpsum()} */}
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            marginTop: 10,
            marginLeft: 3,
            // alignItems: "center",
            // justifyContent: "space-between",
          }}
        >
          {expand &&
            combinations.map((combination) => (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  // justifyContent: "center",
                  marginRight: 5,
                  marginBottom: 15,
                  marginLeft: 10,
                  // border: "1px solid blue",
                  maxWidth: maxBoxDim,
                  width: maxBoxDim,
                  // boxSizing: "border-box",
                }}
                key={combination._id}
              >
                <div
                  style={{
                    position: 'relative',
                    width: maxBoxDim,
                    height: maxBoxDim,

                    // marginBottom: 10,
                    // border: "1px solid aqua",
                  }}
                >
                  <DeleteComb
                    combinationId={combination._id}
                    postDeleteCB={fetchCombinations}
                  />
                  <img
                    className='smallBox'
                    height={maxBoxDim}
                    width={maxBoxDim}
                    src={
                      Constants.imgUrl + combination.product.poster_image_url
                    }
                    style={{
                      borderRadius: 5,
                      margin: 0,
                      padding: 0,
                      marginLeft: 0,
                      // marginRight: 10,
                      // marginBottom: 8,
                      // border: "1px solid black",
                      width: { maxBoxDim },
                      height: { maxBoxDim },
                    }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                      display: 'grid',
                      placeItems: 'center',
                    }}
                  >
                    <IconButton onClick={() => onClickEdit(combination)}>
                      <EditOutlinedIcon
                        style={{
                          color: '#000',
                          fontSize: '22px',

                          cursor: 'pointer',
                        }}
                      />
                    </IconButton>
                  </div>
                </div>
                <p
                  style={{
                    margin: 0,
                    padding: 0,
                    fontSize: '14px',
                    fontFamily: 'Inter',
                    textAlign: 'center',
                    cursor: 'pointer',
                    // textTransform: "capitalize",
                    // border: "1px solid red",
                    wordBreak: 'break-all',
                    textTransform: 'capitalize',
                    marginTop: 5,
                    color: '#565656',
                  }}
                >
                  {combination.title}
                </p>
              </div>
            ))}
          <PlusBox onClick={() => setOpenSearch(true)} />
        </div>
      </div>
    </>
  );
};

export default RecommendBox;

const PlusBox = ({ onClick }) => (
  <div
    className='secondBox'
    style={{
      cursor: 'pointer',
      marginLeft: 10,
      marginRight: 10,
      marginBottom: 10,
      // margin: 0,
      // margi
      // border: "1px solid red",
      //  top: 0
    }}
    onClick={onClick}
  >
    <AddIcon
      style={{
        marginTop: '14px',
        color: 'gray',
        cursor: 'pointer',
      }}
    />

    {/* <Typography
  className="default"
  style={{
    marginLeft: "0px",
    width: "55px",
    lineHeight: "19px",
  }}
>
  Add Material
</Typography> */}
  </div>
);

const Header = ({
  handleExpand,
  recommendProduct,
  expand,
  recommendId,
  postActionCB,
  recommendProducts,
  productId,
}) => {
  const [title, setTitle] = useState(recommendProduct.title);
  const [isLoading, setIsLoading] = useState(false);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const daleteRecommendation = async () => {
    setIsLoading(true);
    try {
      const data = await deleteRecommend(recommendId);
      postActionCB();
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };
  const [openEditDialog, setOpenEditDialog] = useState(false);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        //   border: "1px solid red",
        marginBottom: 7,
      }}
    >
      <DeleteConfirmation
        open={openDeleteConfirmation}
        handleClose={() => setOpenDeleteConfirmation(false)}
        handleSubmit={async () => {
          await daleteRecommendation();
          setOpenDeleteConfirmation(false);
        }}
      />
      <CreateRecommendDialog
        open={openEditDialog}
        handleClose={() => setOpenEditDialog(false)}
        productId={productId}
        previousTitles={recommendProducts.map(({ title }) => title)}
        onSubmitCB={(newTitle) => {
          // postActionCB();
          setTitle(newTitle);
        }}
        recommendId={recommendId}
        isEdit={true}
        value={recommendProduct.title}
      />
      <Typography
        style={{
          fontWeight: 600,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          wordBreak: 'no-break',
          whiteSpace: 'nowrap',
          flex: 1,
          paddingRight: 4,
          paddingLeft: 10,
          textTransform: 'capitalize',
          color: '#2E2E2E',
          fontSize: 16 || 18,
        }}
      >
        {title}
      </Typography>
      <IconButton
        size='small'
        onClick={() => {
          setOpenEditDialog(true);
        }}
      >
        <EditOutlinedIcon
          style={{
            color: '#bdbdbd',
            fontSize: '18px',
            cursor: 'pointer',
          }}
        />
      </IconButton>
      <IconButton size='small' onClick={() => setOpenDeleteConfirmation(true)}>
        <CloseIcon
          style={{
            color: '#bdbdbd',
            fontSize: '18px',
            cursor: 'pointer',
            // marginLeft: 5,
          }}
        />
      </IconButton>
      <IconButton
        size='small'
        onClick={() => {
          handleExpand();
        }}
      >
        {expand ? (
          <ExpandLessIcon
            style={{
              color: '#bdbdbd',
              // fontSize: "30px",
              cursor: 'pointer',
              // marginLeft: 5,
            }}
          />
        ) : (
          <ExpandMoreRoundedIcon
            style={{
              color: '#bdbdbd',
              // fontSize: "30px",
              cursor: 'pointer',
              // marginLeft: 5,
            }}
          />
        )}
      </IconButton>
    </div>
  );
};
const DeleteComb = ({ combinationId, postDeleteCB }) => {
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const handleDelete = () => {
    setOpenDeleteConfirmation(true);
  };

  const deleteCombinationHandle = async () => {
    try {
      await deleteCombination(combinationId);
      postDeleteCB();
    } catch (err) {}
  };
  return (
    <>
      <div
        onClick={handleDelete}
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          transform: 'translate(30%,-40%)',
          padding: 5,
          // border: "1px solid red",
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <CloseIcon
          style={{
            color: '#fff',
            fontSize: '13px',
            // position: "absolute",
            // right: "-7px",
            // top: "-8px",
            // right: 0,
            // top: 0,
            // transform: "translate(50%,-30%)",
            cursor: 'pointer',
            background: 'gray',
            borderRadius: '10px',
          }}
        />
        {/* <IconButton
    size="small"
    style={{
      background: "#ddd",
      color: "white",
    }}
  >
    <HighlightOffIcon fontSize="small" />
  </IconButton> */}
      </div>
      <DeleteConfirmation
        title={'Are you sure you want to delete this?'}
        open={openDeleteConfirmation}
        handleClose={() => {
          // alert("close");
          setOpenDeleteConfirmation(false);
        }}
        handleSubmit={async () => {
          await deleteCombinationHandle();
          setOpenDeleteConfirmation(false);
        }}
      />
    </>
  );
};
