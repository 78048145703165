import axios from '../../RecommendProduct/axios';
import React, { useEffect, useState } from 'react';
import { API } from '../../../Constants/APIs';
import ProductTab from './ProductTab';

const Products = ({ productStatus, searchQuery }) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    axios
      .get(`${API.getUrls.Categories}?limit=10000&page=1`)
      .then((res) => {
        setCategories(res.data.categories.docs);
      })
      .catch((err) => {});
  }, []);

  // useEffect(() => {
  //   let categoriesTemp = categories;
  //   setCategories([]);
  // setTimeout(() => setCategories(categoriesTemp), 100);
  // }, [productStatus, searchQuery]);

  return categories.map((category, index) => (
    <ProductTab
      key={category._id}
      category={category}
      expand={searchQuery || index == 0 ? true : false}
      productStatus={productStatus}
      searchQuery={searchQuery}
      index={index}
    />
  ));
};

export default Products;
