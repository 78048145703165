import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { btnStyle } from '../../Helpers/utils';

import Modal from 'react-awesome-modal';
import { Typography } from '@material-ui/core';
import { useMediaHook } from '../useMediaHook';

export function DeleteConfirmation({ open, handleClose, handleSubmit, title }) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle
          id="alert-dialog-title"
          style={{
            textAlign: 'center',
          }}
        >
          {title ??
            'Are you sure you want to delete this recommendation product ?'}
        </DialogTitle> */}
        <DialogContent
          style={{
            padding: 10,
          }}
        >
          {' '}
          <div
            className="confirmationModal"
            style={{
              textAlign: 'center',
              border: '0px solid black',
              padding: 0,
            }}
          >
            <Typography>
              {title ??
                'Are you sure you want to delete this recommendation product ?'}
            </Typography>

            <Button
              onClick={(e) => {
                e.stopPropagation();
                handleSubmit();
              }}
              className="DeleteBtn"
            >
              Delete{' '}
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation();

                handleClose();
              }}
              className="DeleteBtn"
              style={{ background: '#bdbdbd' }}
            >
              Cancel
            </Button>
          </div>
          {/* <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this recommendation product ?{" "}
          </DialogContentText> */}
        </DialogContent>
        {/* <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            paddingBottom: 20,
          }}
        >
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleClose();
            }}
            style={{
              ...btnStyle,
              width: '30%',
              boxShadow: 0,
              backgroundColor: 'gray',
              color: 'white',
              // border: "1px solid #ddd",
            }}
          >
            Cancel
          </button>
          <button
            style={{
              ...btnStyle,
              width: '30%',
              boxShadow: 0,
              background: 'rgba(255,0,0,0.7)',
              color: 'white',
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleSubmit();
            }}
          >
            Delete
          </button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}

const DeleteConfimationSecondModel = ({
  open,
  handleClose,
  handleSubmit,
  title,
}) => {
  const isMdOrDown = useMediaHook();
  if (!open) return null;
  return (
    <div
      style={{
        zIndex: 2147483647,
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
      }}
    >
      <div
        style={{
          // zIndex: 2147483647,/
          position: 'absolute',
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <Modal
          visible={open}
          width={isMdOrDown ? '100' : '400'}
          height={isMdOrDown ? '200' : '300'}
          effect="fadeInUp"
          onClickAway={() => handleClose()}
        >
          <div
            className="confirmationModal"
            style={{ textAlign: 'center', border: '0px solid black' }}
          >
            <Typography>
              {title ??
                'Are you sure you want to delete this recommendation product ?'}
            </Typography>

            <Button
              onClick={(e) => {
                e.stopPropagation();
                handleSubmit();
              }}
              className="DeleteBtn"
            >
              Delete{' '}
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation();

                handleClose();
              }}
              className="DeleteBtn"
              style={{ background: '#bdbdbd' }}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      </div>
    </div>
  );
};
export default DeleteConfimationSecondModel;
