import "./style.css";
import rightArrow from "../../Assets/newIcons/next.png";
import { useMediaHook } from "../useMediaHook";
import React from "react";
const Drawer = ({ children, open, onClose, style }) => {
  const isMdOrDown = useMediaHook();
  return (
    <div
      style={{
        left: isMdOrDown ? "10%" : "70%",
        borderRadius: 15,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,

        ...style,
      }}
      className={`addHotspotdrawer2 ${open ? "show" : ""}`}
    >
      <ExitBtn onClick={() => onClose && onClose()} />
      <div
        style={{
          width: "100%",

          alignSelf: "stretch",
          position: "relative",
          boxShadow: `-3px 0px 5px rgba(0, 0, 0, 0.08)`,
          borderRadius: 15,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,

          border: "0px solid red",

          height: "100%",
          maxHeight: "100%",
          zIndex: 2147483640,
          background: "white",
          overflow: "hidden",
        }}
      >
        <div
          className="drawer_content_container"
          style={{
            border: "0px solid blue",
            minHeight: "100%",
            maxHeight: "100%",
            height: "100%",

            overflowX: "hidden",
            overflowY: "auto",

            background: "white",
            padding: "1rem 0rem 3rem 1.5rem",
            zIndex: 2147483640,
            background: "white",
            borderRadius: 15,
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
const DrawerTwo = ({ children, open, onClose }) => {
  const isMdOrDown = useMediaHook();
  return (
    <div className={open ? "addHotspotdrawer show" : "addHotspotdrawer"}>
      <div
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          zIndex: 2147483640,
        }}
      >
        <div style={{ width: isMdOrDown ? "10%" : "60%", zIndex: 0 }} />
        <div
          style={{
            width: isMdOrDown ? "90%" : "40%",

            alignSelf: "stretch",
            position: "relative",
            boxShadow: `-3px 0px 5px rgba(0, 0, 0, 0.08)`,
            borderRadius: 15,
            border: "0px solid red",
            // overflowY: "hidden",
            maxHeight: "100%",
            height: "100%",
            zIndex: 2147483640,
            background: "white",
          }}
        >
          <ExitBtn onClick={() => onClose && onClose()} />

          <div
            className="drawer_content_container"
            style={{
              border: "0px solid red",
              maxHeight: "100%",
              height: "100%",

              overflowX: "hidden",
              overflowY: "auto",

              background: "white",
              padding: "1rem 0.3rem 2rem 1.5rem",
              zIndex: 2147483640,
              background: "white",
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drawer;

const ExitBtn = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      style={{
        zIndex: 20,
        width: 52,
        height: 52,
        border: 0,
        outline: "none",
        borderRadius: "50%",
        background: "#fff",
        display: "grid",
        placeItems: "center",
        position: "absolute",
        top: "50%",
        left: "0",
        transform: "translate(-50%,-50%)",

        boxShadow: `0px 1px 4px rgba(0, 0, 0, 0.25)`,
        border: "0px solid red",
        zIndex: 2147483643,
        cursor: "pointer",
      }}
    >
      <img src={rightArrow} alt="" width={30} height={30} />
    </button>
  );
};
