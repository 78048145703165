import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import imgEmpty from "../Assets/Images/image 16.png";
import modelImg1 from "../Assets/Images/modelImg1.png";
import ProductCard from "../Components/ProductCard";
import { API } from "../Constants/APIs";
import init from "../Helpers/Windotoken";
class WIPproducts extends Component {
  state = {
    products0: [],
    categories: [],
    expandMore: false,
    expand: 0,
    scrollIndex: 1,
    pageCount: 1,
    hasNextPage: true,
    page: 1,
    totalpage: 1,
    metaData: {},
    showSearchSuggestions: false,
    showAll: true,
    query: "",
    searching: "",
    selected: [],
  };

  fetchCategories = () => {
    // if (init() === "success") {
    let authToken = localStorage.getItem("token");
    axios
      .get(
        API.getUrls.getUserCat +
          "?status=" +
          this.state.productStatus +
          "&q=" +
          this.state.query,
        { headers: { Authorization: "Bearer " + authToken } }
      )
      .then((res) => {
        this.setState({
          categories: res.data.categories,
        });
      });
    // }
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.categories !== this.props.categories) {
      this.setState({ categories: this.props.categories }, () => {
        this.fetchModels(
          this.props.categories.length > 0
            ? this.props.categories[0]._id
            : null,
          0
        );
        if (this.state.categories.length > 0) {
          this.state.selected.push(this.state.categories[0]._id);
        }
      });
    }
    if (prevProps.productStatus !== this.props.productStatus) {
      this.setState({ productStatus: this.props.productStatus }, () => {
        this.fetchModels(
          this.props.categories.length > 0
            ? this.props.categories[0]._id
            : null,
          0
        );
      });
    }
    if (prevProps.CategoryLength !== this.props.CategoryLength) {
      this.setState({ CategoryLength: this.props.CategoryLength }, () => {});
    }

    if (prevProps.query !== this.props.query) {
      this.setState({ query: this.props.query }, () => {
        this.fetchModels(
          this.props.categories.length > 0
            ? this.props.categories[0]._id
            : null,
          0
        );
      });
    }
    if (prevProps.loading !== this.props.loading) {
      this.setState({ loading: this.props.loading });
    }
    if (prevProps.searching !== this.props.searching) {
      this.setState({ searching: this.props.searching }, () => {
        this.fetchModels(
          this.props.categories.length > 0
            ? this.props.categories[0]._id
            : null,
          0
        );
      });
    }
  }
  selectedIndex = (CompId) => {
    if (this.state.selected.includes(CompId)) {
      let index = this.state.selected.indexOf(CompId);
      this.state.selected.splice(index, 1);
    } else {
      this.state.selected.push(CompId);
    }
  };

  deleteProduct = (catId, index) => {
    // console.log(catId,index);
    this.fetchModels(catId, index);
  };

  fetchModels = (catId, index) => {
    if (catId) {
      this.setState({ products: [], ["loadingProducts" + index]: true });
      // if (init() === "success") {
      axios
        .get(
          API.getUrls.getProducts +
            "?page=" +
            this.state.page +
            "&limit=50&catId=" +
            catId +
            "&q=" +
            this.state.query +
            "&status=" +
            this.state.productStatus
        )
        .then((res) => {
          this.setState({
            ["loadingProducts" + index]: false,
            ["products" + index]: res.data.docs,
            totalpage: res.data.totalPages,
          });
        });
      // }
    }
  };
  render() {
    return (
      <div>
        {this.state.loading ? (
          <div style={{ textAlign: "center", marginTop: "80px" }}>
            <CircularProgress size={40} color={"info"} />
          </div>
        ) : (
          <div>
            {this.props.CategoryLength ? (
              <div style={{ textAlign: "center", marginTop: "100px" }}>
                <img
                  src={imgEmpty}
                  style={{ width: "150px", height: "150px" }}
                  alt=""
                />
                <Typography style={{ marginBottom: "10px" }}>
                  {" "}
                  Nothing yet here{" "}
                </Typography>
                <Link to="/product-create" style={{ textDecoration: "none" }}>
                  <Button className="createFirstProductBtn">
                    {" "}
                    Create your first product
                  </Button>
                </Link>
              </div>
            ) : (
              <div>
                {this.state.categories.map((singleCate, index) => (
                  <Accordion defaultExpanded={index === 0}>
                    <AccordionSummary
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div
                        className="CategoryDiv"
                        onClick={() => this.selectedIndex(singleCate._id)}
                      >
                        <Typography
                          className="Heading"
                          onClick={() => {
                            this.fetchModels(singleCate._id, index);
                          }}
                          key={index}
                        >
                          <span>
                            {this.state.selected.includes(singleCate._id) ? (
                              <RemoveIcon className="expandIcon" />
                            ) : (
                              <AddIcon className="expandIcon" />
                            )}
                          </span>
                          {singleCate.title}
                        </Typography>
                      </div>
                    </AccordionSummary>

                    <AccordionDetails>
                      <div className="cardWrapper">
                        {this.state["loadingProducts" + index] ? (
                          <div
                            style={{ textAlign: "center", marginTop: "80px" }}
                          >
                            <CircularProgress size={40} color={"info"} />
                          </div>
                        ) : (
                          <Grid container spacing={3}>
                            {this.state["products" + index]
                              ? this.state["products" + index].map(
                                  (ele, ind) => (
                                    <Grid item sm={3} key={ind}>
                                      <ProductCard
                                        imgSrc={
                                          ele.poster_image_url
                                            ? API.imgUrl + ele.poster_image_url
                                            : modelImg1
                                        }
                                        id={ele._id}
                                        title={ele.title}
                                        views={ele.views}
                                        catId={singleCate._id}
                                        CardIndex={index}
                                        page={this.state.page}
                                        limit={this.state.perPage}
                                        status={this.state.productStatus}
                                        query={this.state.query}
                                        onDelete={this.deleteProduct}
                                      />
                                    </Grid>
                                  )
                                )
                              : null}
                          </Grid>
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default WIPproducts;
