import { AccordionDetails, CircularProgress, Grid } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { API } from '../../Constants/APIs';
import ProductCard from '../ProductCard';

import modelImg1 from '../../Assets/Images/modelImg1.png';
import { useEffect } from 'react';
import axios from 'axios';

const AllProductPaginated = ({
  products,
  deleteProduct,
  stateProps,
  indexProps,
  singleCate,
  loadingProducts,
  perPage,
  productStatus,
  query,
  page: pageProps,
  getMetaData,
}) => {
  const containerRef = useRef();

  const isLast = () => {
    const triggerHeight =
      containerRef.current.scrollTop + containerRef.current.offsetHeight;

    return triggerHeight >= containerRef.current.scrollHeight;
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [newProducts, setProducts] = useState([]);

  const pageRef = useRef(pageProps);

  const fetchProducts = async () => {
    if (isEnd) return;
    pageRef.current = pageRef.current + 1;
    const page = pageRef.current;

    try {
      setIsLoading(true);
      const { data } = await axios.get(
        API.getUrls.getProducts +
          '?page=' +
          page +
          '&limit=' +
          perPage +
          '&catId=' +
          singleCate._id +
          '&q=' +
          query +
          '&status=' +
          productStatus
      );
      if (page >= data.totalPages) {
        setIsEnd(true);
      }
      setProducts((preProds) => [...preProds, ...data.docs]);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const isHeightNotMax = () => {
    const st = window.getComputedStyle(containerRef.current);
    return st.maxHeight > st.height;
  };

  const setLast = async () => {
    if (isHeightNotMax() && !isEnd) {
      await fetchProducts();
      setLast();
    }
  };

  useEffect(() => {
    setLast();
  }, []);

  return (
    <AccordionDetails>
      <div
        ref={containerRef}
        className='cardWrapper'
        style={{
          border: '0px solid blue',
          // maxHeight: 200,
        }}
        onScroll={async (e) => {
          if (isEnd) return;

          if (isLast()) {
            await fetchProducts();
          }
        }}
      >
        {loadingProducts ? (
          <Loading />
        ) : !products?.length ? null : (
          <Grid container spacing={2}>
            {products.map((ele, ind) => (
              <Grid item sm={3} key={ele?._id}>
                <ProductCard
                  imgSrc={
                    ele.poster_image_url
                      ? API.imgUrl + ele.poster_image_url
                      : modelImg1
                  }
                  id={ele._id}
                  title={ele.title}
                  views={ele.views}
                  publishStatus={ele.status}
                  catId={singleCate._id}
                  CardIndex={indexProps}
                  page={stateProps.page}
                  limit={stateProps.perPage}
                  status={stateProps.productStatus}
                  query={stateProps.query}
                  onDelete={deleteProduct}
                  getMetaData={getMetaData}
                />
              </Grid>
            ))}
            {newProducts.map((ele, ind) => (
              <Grid item sm={3} key={ind}>
                <ProductCard
                  imgSrc={
                    ele.poster_image_url
                      ? API.imgUrl + ele.poster_image_url
                      : modelImg1
                  }
                  id={ele._id}
                  title={ele.title}
                  views={ele.views}
                  publishStatus={ele.status}
                  catId={singleCate._id}
                  CardIndex={indexProps}
                  page={stateProps.page}
                  limit={stateProps.perPage}
                  status={stateProps.productStatus}
                  query={stateProps.query}
                  onDelete={deleteProduct}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </AccordionDetails>
  );
};

export default AllProductPaginated;
const Loading = () => (
  <div
    style={{
      textAlign: 'center',
      marginTop: '80px',
    }}
  >
    <CircularProgress size={40} color={'info'} />
  </div>
);
