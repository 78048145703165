import React from 'react';
import HStack from '../../TSEUI/HStack';

import { spacing } from '../UIConsts';
const Categories = ({ productStatus, setProductStatus }) => {
  const showBorder = (v) => productStatus == v;
  return (
    <HStack
      style={{
        justifyContent: 'center',
      }}
    >
      <TextBox
        text="All Products"
        showBorder={showBorder('')}
        onClick={() => setProductStatus('')}
      />
      <TextBox
        text="Draft"
        onClick={() => setProductStatus('draft')}
        showBorder={showBorder('draft')}
      />
      <TextBox
        text="Live Products"
        showBorder={showBorder('published')}
        onClick={() => setProductStatus('published')}
      />
    </HStack>
  );
};

export default Categories;

const TextBox = ({ text, onClick, showBorder }) => (
  <div
    onClick={onClick}
    style={{
      flex: 1,
      fontSize: 16,
      fontWeight: 700,
      //   lineHeight: '32px',
      color: '##2D3436',
      textAlign: 'center',
      //   border: '1px solid red',
      borderBottom: `2px solid ${showBorder ? '#2D3436' : '#ddd'}`,
      paddingBottom: spacing,
      cursor: 'pointer',
      transition: 'border 300ms',
    }}
  >
    {text}
  </div>
);
