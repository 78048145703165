import { Button, Typography } from '@material-ui/core';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';

import React, { useState, useEffect } from 'react';
import Modal from 'react-awesome-modal';
import { SketchPicker } from 'react-color';
import verticalLine from '../Assets/Images/Vector 12.png';
import yes from '../Assets/Images/yes.png';
import { API as Constants } from '../Constants/APIs';
import 'react-image-crop/dist/ReactCrop.css';
// import "./UnPublishModal/newStyles.css";

import rectangle from '../Assets/Images/Rectangle 54.png';
const MaterialEditModel = ({
  open,
  closeModal,
  updateMaterial,
  data: propData,
  handleDelete,
}) => {
  const [data, setData] = useState(propData);

  const [colorPicker, setColorPicker] = useState(false);

  const [swatchFilePreview, setSwatchFilePreview] = useState(
    propData.material_type != 'color' ? propData.material_value : rectangle
  );

  const [color, setColor] = useState(
    propData.material_type == 'color' ? propData.material_value : '#000'
  );
  const [file, setFile] = useState(
    propData.material_type == 'swatch' ? propData.material_value : null
  );

  const onImageChange = (event) => {
    const file = event.target.files[0];
    let reader = new FileReader();
    reader.onload = (e) => {
      setSwatchFilePreview(e.target.result);
      setFile(file);
      // setData((p) => {
      //   return { ...p, swatchFile: e.target.result, file };
      // });
    };
    reader.readAsDataURL(file);
  };

  return (
    <Modal
      visible={open}
      width="300"
      height="470"
      effect="fadeInUp"
      onClickAway={() => closeModal()}
    >
      <div
        className="modalWrapper"
        style={{
          position: 'relative',
        }}
      >
        <CloseSharpIcon
          style={{
            position: 'absolute',
            right: '10px',
            top: '5px',
            cursor: 'pointer',
          }}
          onClick={() => closeModal()}
        />
        <form
          action=""
          id="materialForm"
          onSubmit={(e) => {
            e.preventDefault();
            const obj = { ...data };
            if (data.material_type == 'color') obj.material_value = color;
            else obj.file = file;
            // console.log("obj", obj);
            // return;
            updateMaterial(e, obj);
          }}
        >
          <h1 className="modalHead">Preview</h1>
          {data?.material_type == 'color' ? (
            <div
              className="modalBox"
              style={{
                backgroundColor: color,
              }}
            />
          ) : (
            <div>
              {/* {data.swatchFile && "File"} */}
              <img
                src={
                  swatchFilePreview?.startsWith('/upload')
                    ? Constants.imgUrl + swatchFilePreview
                    : swatchFilePreview
                }
                width="105px"
                height="105px"
                style={{ marginBottom: '10px' }}
                alt="swatchFile"
              />
            </div>
          )}
          <div className="inputTextWrap">
            <span className="modalText">Name</span>
            <input
              type="text"
              className="modalInput"
              required
              name="material_name"
              value={data.material_name}
              onChange={(e) => {
                const val = e.target.value;
                setData((p) => ({ ...p, material_name: val }));
              }}
            />
          </div>

          <Typography className="modalMaterialHead"> Material</Typography>

          <div style={{ marginBottom: '20px' }}>
            <span
              className="modalMaterialHead"
              style={{
                border: '0px solid red',
                marginRight: '80px',
                borderBottom: 'none',
                fontWeight: data.material_type == 'color' ? 'bold' : 400,
              }}
              onClick={() => {
                setData((p) => ({
                  ...p,
                  material_type: 'color',
                }));
              }}
            >
              Solid Color
            </span>
            <img src={verticalLine} className="varticalLine" alt="" />
            <span
              className="modalText"
              style={{
                border: '0px solid red',
                fontWeight: data.material_type != 'color' ? 'bold' : 400,
              }}
              onClick={() => {
                setData((p) => ({
                  ...p,
                  material_type: 'swatch',
                }));
                colorPicker && setColorPicker((p) => !p);
              }}
            >
              Swatch
            </span>
          </div>

          {data.material_type == 'color' ? (
            <div>
              <div className={colorPicker ? 'inputTextWrap2' : 'inputTextWrap'}>
                <span className="modalText" style={{ marginLeft: ' -8px' }}>
                  Hexcode
                </span>
                <input
                  type="text"
                  className="modalInput"
                  name="material_value"
                  onClick={() => setColorPicker((p) => !p)}
                  value={color}
                />
              </div>
              <div
                style={{
                  position: 'relative',
                  bottom: '210px',
                  left: '300px',
                }}
              >
                {colorPicker ? (
                  <SketchPicker
                    name="material_value"
                    color={data.material_value}
                    onChangeComplete={(e) => {
                      setColor(e.hex);
                      // setData((p) => ({ ...p, material_value: e.hex }));
                    }}
                    width="175px"
                  />
                ) : null}
              </div>
            </div>
          ) : null}

          {data?.material_type != 'color' ? (
            <div
              style={{
                border: '0px solid blue',
                marginBottom: 0,
              }}
            >
              <Button
                variant="outlined"
                size="medium"
                className="uploadbtnswatch"
                component="label"
                style={{ margin: '11px auto' }}
              >
                Upload Swatch
                <input
                  type="file"
                  onChange={(e) => onImageChange(e)}
                  name="file"
                  hidden
                />
              </Button>
              <Typography
                className="modalHead"
                style={{
                  border: '0px solid red',
                  marginBottom: 0,
                }}
              >
                {data.file
                  ? 'Swatch Uploaded'
                  : 'accepted file types : .jpeg, .png'}
              </Typography>
            </div>
          ) : null}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '0 10px 0 10px',
            }}
          >
            {!handleDelete ? null : (
              <Button
                onClick={() => {
                  handleDelete();
                }}
                className={
                  colorPicker
                    ? data.material_type != 'color'
                      ? 'uploadbtnswatchPicker1'
                      : 'uploadbtnswatchPicker'
                    : data.material_type != 'color'
                    ? 'uploadbtnswatch'
                    : 'uploadbtnswatch1'
                }
                style={
                  data.material_type != 'color'
                    ? {
                        // zIndex: 10,
                        marginTop: 0,
                        marginRight: 5,
                      }
                    : { marginRight: 5 }
                }
              >
                Delete
              </Button>
            )}
            <Button
              className={
                colorPicker
                  ? data.material_type != 'color'
                    ? 'uploadbtnswatchPicker1'
                    : 'uploadbtnswatchPicker'
                  : data.material_type != 'color'
                  ? 'uploadbtnswatch'
                  : 'uploadbtnswatch1'
              }
              // className="uploadbtnswatch"
              type="submit "
              style={
                data.material_type != 'color'
                  ? {
                      // zIndex: 10,
                      marginTop: 0,
                    }
                  : {}
              }
            >
              Update
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default MaterialEditModel;
