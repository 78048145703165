import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { IconButton, Snackbar } from '@material-ui/core';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import { Link } from 'react-router-dom';
import { copyDataToClipboard } from '../../Helpers/utils';
import axios from 'axios';
import { API } from '../../Constants/APIs';
import Pagination from '@material-ui/lab/Pagination';
import { RemoveRedEye } from '@material-ui/icons';

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
    border: "1px solid #ddd",
    width: '100%',
    // border: '1px solid red',
  },
  container: {
    // border: '1px solid green',
  },
});

export default function ShareTable({ shares, get_shares, onChangePage, onRowsPerPageChange, count, rowsPerPage, page }) {
  const classes = useStyles();
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);

  const [deletedId, setDeletedId] = useState("")
  const copiedTriger = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleDelete = async (share_id) => {
    if (loading) return;
    setDeletedId(share_id);
    setLoading(true);
    try {
      await axios.delete(`${API.getUrls.share_products}/${share_id}`);
      await get_shares();
    } catch (err) {
    } finally {
      setLoading(false);
      setDeletedId('');
    }
  };
  const handleChangePage = (event, value) => {
    console.log(value);
    onChangePage(event, value)
  }

  return (
    <TableContainer className={classes.container}>
      <Table className={classes.table} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell style={{ borderRight: '1px solid #ddd' }} align='center' >S.No</TableCell>
            <TableCell style={{ borderRight: '1px solid #ddd' }} align='left'>Title</TableCell>
            <TableCell style={{ borderRight: '1px solid #ddd' }} align='left'>Link</TableCell>
            <TableCell style={{ borderRight: '1px solid #ddd' }} align='left'>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {shares.map((share, index) => (
            <TableRow key={share._id}>
              <TableCell style={{ borderRight: '1px solid #ddd', color: '#828282' }} component='th' scope='row' align='center'>
                {index + 1}
              </TableCell>
              <TableCell style={{ borderRight: '1px solid #ddd', color: '#828282' }} align='left'>{share.title?.length > 15 ? share.title.substring(0, 12) + '...' : share.title}</TableCell>
              <TableCell style={{ borderRight: '1px solid #ddd', color: '#828282' }} align='left'>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    // textDecoration:
                    //   share.products.length && share.title && share.sub_title
                    //     ? 'none'
                    //     : 'line-through',
                  }}
                >
                  <span>{`https://actuality.live/myproducts/${share._id}`.length > 49 ? `https://actuality.live/myproducts/${share._id}`.substring(0, 45) + '...' : `https://actuality.live/myproducts/${share._id}`}</span>
                  {/* {share.products.length && share.title && share.sub_title ? (
                    <IconButton
                      onClick={() => {
                        copyDataToClipboard(
                          `https://actuality.live/myproducts/${share._id}`
                        );
                        copiedTriger();
                      }}
                    >
                      <CopyIcon />
                    </IconButton>
                  ) : null} */}
                  <div>
                    <IconButton
                      onClick={() => {
                        copyDataToClipboard(
                          `https://actuality.live/myproducts/${share._id}`
                        );
                        copiedTriger();
                      }}
                    >
                      <CopyIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        window.open(
                          `https://actuality.live/myproducts/${share._id}`
                          , "_blank");
                      }}
                    >
                      <RemoveRedEye />
                    </IconButton>
                  </div>
                </div>
                <Snackbar
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  open={copied}
                  onClose={() => setCopied(false)}
                  message='Link copied'
                />
              </TableCell>
              <TableCell style={{ borderRight: '1px solid #ddd' }} align='left'>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    // border: '1px solid red',
                  }}
                >
                  <a
                    href={`/share/${share._id}`}
                    style={{ textDecoration: 'none' }}
                    target='_blank'
                  >
                    <button
                      style={{
                        outline: 0,
                        width: '112px',
                        padding: '10px 20px',
                        border: 0,
                        marginRight: 0,
                        background: ' #E1E1E1',
                        boxShadow: '1px 5px 5px rgba(0, 0, 0, 0.1)',
                        borderRadius: 6,
                        color: '#2e2e2e',
                        fontSize: 12,
                        cursor: 'pointer',
                        height: 32
                      }}
                    >
                      Edit
                    </button>
                  </a>
                  <button
                    style={{
                      outline: 0,
                      width: '112px',
                      padding: '10px 20px',
                      border: 0,
                      marginRight: 0,
                      background: 'rgba(255,0,0,0.5)',
                      boxShadow: '1px 5px 5px rgba(0, 0, 0, 0.1)',
                      borderRadius: 6,
                      color: '#fff',
                      fontSize: 12,
                      cursor: 'pointer',
                      marginLeft: 12,
                      height: 32,
                    }}
                    onClick={async () => {
                      await handleDelete(share._id);
                    }}
                  >
                    Delete {loading && deletedId == share._id ? '...' : ''}
                  </button>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div style={{ padding: "12px 24px", display: 'flex', justifyContent: 'end' }}>
        <Pagination
          color='standard'
          defaultPage={1}
          count={count}
          page={page}
          onChange={handleChangePage}
        />
      </div>
    </TableContainer>
  );
}
