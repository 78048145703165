import axios from 'axios';
import { API } from '../Constants/APIs';

function containsWhitespace(str) {
  return /\s/.test(str);
}

export const loremIpsum = (n = 10) =>
  [...new Array(n)].map(() => (
    <span>
      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quas tenetur
      aliquid eveniet eos laboriosam numquam doloremque impedit nemo minima quo
      saepe eaque architecto sint, iure in aspernatur ipsam? Maiores, eius!
    </span>
  ));
export function getCombinations(arr) {
  if (arr.length === 0) return [[]];
  let [current, ...rest] = arr;
  let combinations = getCombinations(rest);
  return current.materials.reduce(
    (a, string) => [...a, ...combinations.map((c) => [string, ...c])],
    []
  );
}

export { containsWhitespace };

export const canPublishProduct = async (productId) => {
  try {
    const { data } = await axios.get(
      `${API.getUrls.canPublishProduct}/${productId}`
    );
    return data.noOfVariantMustBe <= data.variantsUploaded &&
      data.noOfVariantMustBe != 0 &&
      data.variantsUploaded != 0
      ? true
      : false;
  } catch (err) {
    return false;
    // `/product-edit?id=${productId}`;
  }
};
export const scrollToTop = () =>
  window.scrollTo({ top: 0, behavior: 'smooth' });
export const moveToScreenUrl = (product) => {
  // console.log(product.title, " product title ", product);
  let url = '';
  if (
    !product.components.length ||
    product.components.every((e) => !e.materials.length)
  ) {
    // return product.variants.length == 0;
    url = `/product-edit?id=${product._id}`;
    // console.log("one");
  } else {
    let noOfVariantMustBe = product.components
      .map((c) => c.materials.length)
      .filter((l) => l)
      .reduce((total, val) => total * val, 1);
    // console.log(product.variants.length, " two ", noOfVariantMustBe);
    url =
      product.variants.length >= noOfVariantMustBe
        ? `/viewer?id=${product._id}`
        : `/product-edit?id=${product._id}`;
  }
  return url;
};

export const customLegend = (props, data) => {
  const { payload } = props;

  return (
    <div
      style={{
        // border: '1px solid red',
        position: 'absolute',
        top: 0,
        right: 0,
        left: '550%',
        width: 'max-content',
        paddingRight: 20,
      }}
    >
      {payload.map((entry, index) => (
        <div
          key={`item-${index}`}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: 6,
          }}
        >
          <div
            style={{
              width: 10,
              height: 10,
              borderRadius: '50%',
              background: entry.color,
            }}
          />
          <span
            style={{
              color: entry.color,
              // fontSize: 16,
              marginLeft: 5,
              whiteSpace: 'nowrap',
            }}
          >
            {`${entry.value} - ${data.reduce(
              (total, row) => (row?.[entry?.dataKey] || 0) + total,
              0
            )}`}
          </span>
        </div>
      ))}
    </div>
  );
};

export const dateAhead = (num) => {
  const d = new Date(Date.now() + num * 24 * 60 * 60 * 1000);

  let month = d.getMonth() + 1;
  month = month >= 10 ? month : `0${month}`;

  let day = d.getDate() + 1;
  day = day >= 10 ? day : `0${day}`;

  const date = `${d.getFullYear()}-${month}-${day}`;
  return date;
};

export const btnStyle = {
  outline: 'none',
  border: 0,
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: 5,
  background: '#ffffff',
  width: 200,
  padding: 10,
  fontSize: 18,
  fontWeight: 500,
};
export const firstLetterCapital = (str) =>
  !str || typeof str != 'string'
    ? str
    : str.charAt(0).toUpperCase() + str.slice(1);
export function getTextWidth(fontSize, text) {
  // inputText = "Geeks For Geeks";
  const font = `${fontSize} Inter`;

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font;
  const width = context.measureText(text).width;
  const formattedWidth = Math.ceil(width);
  return formattedWidth;
  // document.querySelector(".output").textContent = formattedWidth;
}

function copy(e, contentToCopy) {
  e.preventDefault(); // default behaviour is to copy any selected text
  e.clipboardData.setData('text/plain', contentToCopy);
}

export function copyDataToClipboard(contentToCopy) {
  document.addEventListener('copy', (e) => copy(e, contentToCopy));
  try {
    document.execCommand('copy');
  } catch (exception) {
    console.error('Copy to clipboard failed');
  } finally {
    document.removeEventListener('copy', (e) => copy(e, contentToCopy));
  }
}

export const downloadCanvasAsImage = (canvasImg, imageName) => {
  if (!canvasImg || !imageName) return;
  let xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.onload = function () {
    let a = document.createElement('a');
    a.href = window.URL.createObjectURL(xhr.response);
    a.download = imageName + '.png';
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    a.remove();
  };
  xhr.open('GET', canvasImg); // This is to download the canvas Image
  xhr.send();
};
