import React, { useEffect, useState } from 'react';

const useBodyScrollLock = () => {
  const [isLocked, setIsLocked] = useState(false);
  useEffect(() => {
    document.body.style.overflow = isLocked ? 'hidden' : 'auto';
  }, [isLocked]);
  const lockScroll = () => {
    document.body.style.overflow = 'hidden';
  };
  const unLockScroll = () => {
    document.body.style.overflow = 'auto';
  };
  return {
    isLocked,
    lockScroll,
    unLockScroll,
  };
};

export default useBodyScrollLock;
