import { Checkbox, Grid } from '@material-ui/core';
import React from 'react';
import { spacing } from './const';
import { API } from '../../Constants/APIs';

const ProductCard = ({ product, onCheck, selectedProducts }) => {
  return (
    <Grid
      item
      sm={3}
      style={{
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: 10,
          cursor: 'pointer',
        }}
        onClick={onCheck}
      >
        <img
          style={{
            height: 200,
            width: '100%',
            margin: 'auto',
            display: 'block',
            borderRadius: '16px'
          }}
          src={API.imgUrl + product.poster_image_url}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingBottom: spacing,
          }}
        >
          <div
            style={{
              flex: 1,
              fontSize: 18,
              fontWeight: 400,
              padding: spacing,

              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {product.title}
          </div>
          <span style={{}}>
            <Checkbox
              color='primary'
              checked={
                selectedProducts.findIndex((p) => p.product == product._id) !=
                -1
              }
            />
          </span>
        </div>
      </div>
    </Grid>
  );
};

export default ProductCard;
