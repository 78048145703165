import React, { useEffect, useState } from 'react';

import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { API } from '../../Constants/APIs';
import editImg from "../../Assets/Images/edit.svg"
import ReactCropper from '../ReactCropper';
const ImageUpload = ({ logo: propLogo, onSubmit }) => {
  const [logo, setLogo] = useState(propLogo);
  useEffect(() => {
    if (propLogo) setLogo(propLogo);
  }, [propLogo]);
  return (
    <ReactCropper
      handleImageSubmit={async (file) => {
        await onSubmit(file);
      }}
    >
      {(onChange) => (
        <div
          style={{
            position: 'relative',
            borderRadius: 15,
            // overflow: 'hidden',
            marginBottom: 20,
          }}
        >
          <input
            type='file'
            id='file-btn-actuality'
            hidden
            onChange={onChange}
            accept='image/*'
          />
          <label
            htmlFor='file-btn-actuality'
            style={{
              display: 'flex',
              alignItems: "center",
              justifyContent: 'center',
              position: 'absolute',
              top: "-22px",
              right: "-22px",
              // zIndex: 5,
              background: 'white',
              borderRadius: 22,
              // padding: 4,
              // border: '0.5px solid #ddd',
              boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
              cursor: 'pointer',
              height: "44px",
              width: "44px"
            }}
          >
            <img src={editImg} style={{ height: 22, width: 22 }} alt="" />
            {/* <EditOutlinedIcon fontSize='small' /> */}
          </label>
          <img
            src={API.imgUrl + logo}
            width='150'
            height='150'
            style={{
              borderRadius: 15,
              overflow: 'hidden',
            }}
          />
        </div>
      )}
    </ReactCropper>
  );
};

export default ImageUpload;
