import React from 'react';

const HStack = ({ children, style, ...rest }) => {
  return (
    <div
      {...rest}
      style={{
        display: 'flex',
        alignItems: 'center',
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default HStack;
