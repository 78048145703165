import React from "react";
import { useParams } from "react-router-dom";
import Teknion from "./Teknion";

export default function TeknionFunctional() {
  let id = useParams();
  return (
    <div>
      <Teknion matchparams={id} />
    </div>
  );
}
