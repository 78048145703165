import React, { useState } from 'react';
import SearchBox from './SearchBox';
import ProductsListing from './ProductsListing';

const RightContentBox = ({ category }) => {
  const [query, setQuery] = useState('');
  const handleChangeQuery = (p) => setQuery(p);

  return (
    <div
      style={{
        flex: 0.8,
        marginLeft: 32
      }}
    >
      {' '}
      <SearchBox handleChangeQuery={handleChangeQuery} query={query} />
      <ProductsListing query={query} category={category} />
    </div>
  );
};

export default RightContentBox;
