import axios from './axios';
import { API as Constants, domain } from '../../Constants/APIs';
let isDev = !true;
let MainDomain = `${isDev ? 'http://localhost:4000/' : domain}api/`;

let URLS = {
  createRecommend: (productId) => MainDomain + 'recommendProducts/' + productId,
  getRecommendProducts: (productId) =>
    MainDomain + 'recommendProducts/' + productId,
  getAllCombinations: (recommendId) =>
    MainDomain + 'combinations/all/' + recommendId,
  createOrUpdateOrDeleteCombination: (recommendId) =>
    MainDomain + 'combinations/' + recommendId,
  deleteRecommend: (recommendId) =>
    MainDomain + 'recommendProducts/' + recommendId,
  updateRecommend: (recommendId) =>
    MainDomain + 'recommendProducts/' + recommendId,

  deleteCombination: (combinationId) =>
    MainDomain + 'combinations/' + combinationId,
};
export const deleteCombination = async (combinationId) => {
  try {
    const { data } = await axios.delete(URLS.deleteCombination(combinationId));

    return data;
  } catch (err) {
    throw (
      err.response?.data?.message || 'Server is busy, please try again later'
    );
  }
};

export const updateRecommend = async (recommendId, title) => {
  try {
    const { data } = await axios.put(URLS.updateRecommend(recommendId), {
      title,
    });
    return data;
  } catch (err) {
    throw (
      err.response?.data?.message || 'Server is busy, please try again later'
    );
  }
};

export const deleteRecommend = async (recommendId) => {
  try {
    const { data } = await axios.delete(URLS.deleteRecommend(recommendId));
    return data;
  } catch (err) {
    throw (
      err.response?.data?.message || 'Server is busy, please try again later'
    );
  }
};

export const createOrUpdateOrDeleteCombination = async (
  recommendId,
  bodyData
) => {
  try {
    const { data } = await axios.post(
      URLS.createOrUpdateOrDeleteCombination(recommendId),
      bodyData
    );
    return data;
  } catch (err) {
    throw (
      err.response?.data?.message || 'Server is busy, please try again later'
    );
  }
};

export const getAllCombinations = async (recommendId) => {
  try {
    const { data } = await axios.get(URLS.getAllCombinations(recommendId));
    return data;
  } catch (err) {
    throw (
      err.response?.data?.message || 'Server is busy, please try again later'
    );
  }
};

export const createRecommend = async (productId, title) => {
  try {
    const { data } = await axios.post(URLS.createRecommend(productId), {
      title,
    });
    return data;
  } catch (err) {
    throw (
      err.response?.data?.message || 'Server is busy, please try again later'
    );
  }
};

export const getRecommendProducts = async (productId) => {
  try {
    const { data } = await axios.get(URLS.getRecommendProducts(productId));
    return data;
  } catch (err) {
    throw (
      err.response?.data?.message || 'Server is busy, please try again later'
    );
  }
};

export const getAllMyProducts = async (query, page = 1) => {
  // console.log("getAllMyProducts ");
  try {
    const userId = window.location.pathname.split('/').reverse()[1];

    const URL = `${Constants.getUrls.getProducts}?limit=100&q=${query}&page=${page}&status=published`;

    // console.log("URL ", URL);

    const { data } = await axios.get(URL);
    return data;
  } catch (err) {
    return null;
    // console.log("err ", err);
  }
};
